import store from '../store'
// import i18n from '../plugins/i18n'
import {
  MEMBER
} from '../constants/role_code'

export default async (to, from, next) => {
  let user = store.getters['auth/user']
  if (user && MEMBER === user.system_auth_id) {
    next() // Allow access
  } else {
    // let msg = i18n.t('errors.permissions')
    // alert(msg)
    // Show alert and more...
    next({
      name: 'login'
    }) // direct to page if denied
  }
}
