
import * as systemAuth from "@/constants/role_code";
import { TASK_TYPE } from "@/constants/system";
export const dbMasterMixin = {
  data: () => {
    return {}
  },
  methods: {
    mapData(list) {
      return list.map((item) => {
        item.cost_standard = item.cost;
        item.skill_levels.forEach((skill) => {
          if (
            !item.cost_beginner &&
            skill.id === systemAuth.SKILL_LEVELS_BEGIN
          ) {
            item.cost_beginner = skill.cost;
            item.is_target_beginner = true;
          } else if (
            !item.cost_expert &&
            skill.id === systemAuth.SKILL_LEVELS_EXPERT
          ) {
            item.cost_expert = skill.cost;
            item.is_target_expert = true;
          }
        });
        return item;
      });
    },
    getPayloadSkillCost(task) {
      const skill_levels = []
      if (task.is_target_beginner) {
        skill_levels.push({
          id: systemAuth.SKILL_LEVELS_BEGIN,
          cost: +task.cost_beginner,
        })
      }

      if (task.is_target_beginner || task.is_target_expert) {
        skill_levels.push({
          id: systemAuth.SKILL_LEVELS_STANDARD,
          cost: +task.cost_standard,
        })
      }

      if (task.is_target_expert) {
        skill_levels.push({
          id: systemAuth.SKILL_LEVELS_EXPERT,
          cost: +task.cost_expert,
        })
      }
      return skill_levels
    },
    assignValueTask() {
      this.task.category_primary_name = this.primaryName;
      this.task.category_secondary_name = this.secondaryName;
      this.task.process_user_id = this.loginInfo.user_id;
      this.task.cost_beginner = (this.task.is_target_beginner) ? this.task.cost_beginner : '';
      this.task.cost_standard = (this.task.is_target_beginner || this.task.is_target_expert) ? this.task.cost_standard : this.task.cost;
      this.task.cost_expert = (this.task.is_target_expert) ? this.task.cost_expert : '';
    },
    handleInput(event, type) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task[type] = value;
      } else {
        this.task[type] = value.slice(0, 3);
      }
      // override 標準工数
      if (type === "cost_standard") {
        this.task.cost = this.task[type];
      }
      // override スタンダード
      if (type === "cost") {
        this.task.cost_standard = this.task[type];
      }
      
      this.$forceUpdate();
    },
    preventKeyCode($event) {
      const arr = [187, 188, 189, 190, 107, 109, 110]
      if (arr.includes($event.keyCode)) $event.preventDefault();
    },
    resetSkillLevel() {
      if (this.task.task_type_id !== TASK_TYPE.NORMAL_BUSINESS) {
        this.task.is_target_beginner = false;
        this.task.is_target_expert = false;
        this.task.cost_beginner = "";
        this.task.cost_expert = "";
      }
    }
  }
}