<template>
  <v-row>
    <v-col class="ma-auto" md="4">
      <v-card class="pa-5">
        <v-row align="center" justify="center">
          <v-col md="12" cols="12">
            <label class="align-self-center">新しいパスワード</label>
          </v-col>
          <v-col md="10" cols="10" class="pt-0 pb-0">
            <v-text-field
              v-model="password"
              :error-messages="passwordErrors"
              autocomplete="new-password"
              :type="show1 ? 'text' : 'password'"
              name="password"
              maxlength="64"
              @input.native="allowHalfWidth($event, 'password')"
              class="pt-0"
            >
              <template v-slot:append>
                <v-icon @click="show1 = !show1" v-show="show1"
                  >$passwordvisible</v-icon
                >
                <v-icon @click="show1 = !show1" v-show="!show1"
                  >$passwordinvisible</v-icon
                >
              </template>
              <template v-slot:message="{ message }">
                <v-icon small class="mr-2">$error</v-icon>
                <span>{{ message }}</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="passwordErrors.length <= 0">
          <v-col md="12" class="text-center">
            <p :class="[$vuetify.breakpoint.mobile ? 'f-14' : '']">
              大文字、小文字の英数字と記号を含めて
            </p>
            <p :class="[$vuetify.breakpoint.mobile ? 'f-14' : '']">
              8文字以上にしてください。
            </p>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col md="12" cols="12">
            <label class="align-self-center">新しいパスワード（確認用）</label>
          </v-col>
          <v-col md="10" cols="10" class="pt-0 pb-0">
            <v-text-field
              v-model="confirmPassword"
              autocomplete="new-password"
              :error-messages="confirmPasswordErrors"
              :type="show2 ? 'text' : 'password'"
              name="confirmPassword"
              maxlength="64"
              @input.native="allowHalfWidth($event, 'confirmPassword')"
              class="pt-0"
            >
              <template v-slot:append>
                <v-icon @click="show2 = !show2" v-show="show2"
                  >$passwordvisible</v-icon
                >
                <v-icon @click="show2 = !show2" v-show="!show2"
                  >$passwordinvisible</v-icon
                >
              </template>
              <template v-slot:message="{ message }">
                <v-icon small class="mr-2">$error</v-icon>
                <span>{{ message }}</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" cols="12" class="text-center">
            <v-btn
              outlined
              class="mb-1"
              tile
              @click="cancelPassword()"
              color="ligth"
              depressed
              >{{ $t("label.buttons.cancel") }}</v-btn
            >
            <v-btn
              tile
              @click="updatePassword()"
              color="primary"
              depressed
              class="ml-3 mb-1"
              >パスワードを変更する</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import axios from "axios";
import { UPDATE_PROFILE_API } from "../../../constants/api";
import { apiErrorMessages } from "../../../helpers/messages";
import { jReplace, isJapanese, debounce_leading } from "../../../helpers/helpers";
import { FC102 } from "../../../constants/fc";
import { isEmpty } from "../../../helpers/helpers";
export default {
  layout: "default",
  metaInfo() {
    return { title: this.$t("title.updatepassword") };
  },
  mixins: [validationMixin],
  validations: {
    password: { required, minLength: minLength(8) },
    confirmPassword: { sameAsPassword: sameAs("password") },
  },
  props: {
    userId: String,
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (this.$v.password.$dirty) {
        !this.$v.password.required &&
          errors.push(this.$t("label.error.no_data_input"));
        !this.$v.password.minLength &&
          errors.push(this.$t("label.error.pwd_length"));
        !isEmpty(this.passwordErrorsApi) && errors.push(this.passwordErrorsApi);
      }

      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (this.$v.confirmPassword.$dirty) {
        !this.$v.confirmPassword.sameAsPassword &&
          errors.push(this.$t("label.error.pwd_match"));
        !isEmpty(this.passwordErrorsApi) && errors.push(this.passwordErrorsApi);
      }
      return errors;
    },
  },
  data: () => ({
    password: "",
    confirmPassword: "",
    show1: false,
    show2: false,
    passwordErrorsApi: "",
  }),
  methods: {
    updatePassword : debounce_leading(async function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        try {
          let params = {
            password: this.password,
          };
          await axios.put(UPDATE_PROFILE_API(this.userId), params);
          this.verifyPassword = true;
          this.$v.$reset();
          this.$router.push({ name: "profile" });
        } catch (error) {
          const { status } = error.response;
          let errorMsg = apiErrorMessages(this, FC102, status + "_a");
          if (errorMsg) {
            this.passwordErrorsApi = errorMsg;
          }
        }
      }
    }),

    cancelPassword() {
      this.$emit("cancelClick");
    },

    allowHalfWidth(e, field) {
      if (isJapanese(e.target.value)) {
        this[field] = jReplace(e.target.value);
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.f-14 {
  font-size: 14px;
}
</style>
