export const state = () => ({
  isChange: false,
})

export const mutations = {
  SET_CHANGE_FLAG(state, payload) {
    state.isChange = payload
  },
}

export const actions = {
  setChangeFlag({ commit }, isChange) {
    commit('SET_CHANGE_FLAG', isChange)
  },
}

export const getters = {
  getChangeFlag(state) {
    return state.isChange
  },
}
