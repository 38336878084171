<template>
  <v-dialog :value="value" @input="$emit('input')" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <h4>{{ title }}</h4>
      </v-card-title>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col md="5" class="d-flex align-center">
            <span class="red--text">*</span>
            <v-select
              class="ml-1"
              v-model="startTime"
              :items="times"
              clearable
              :error-messages="startTimeErrors"
            >
            </v-select>
          </v-col>
          <v-col class="icon-center" cols="1" md="2">～</v-col>
          <v-col md="5" class="d-flex align-center">
            <span class="red--text">*</span>
            <v-select
              class="ml-1"
              v-model="endTime"
              :items="times"
              clearable
              :error-messages="endTimeErrors"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn tile color="ligth" depressed outlined @click="close()">{{
          $t("label.buttons.cancel")
        }}</v-btn>
        <v-btn tile color="primary" depressed @click="submit()">保存</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { initTimeRange, initBreakTimeRange } from "../../helpers/system";
import { compareDateValidator } from "../../helpers/validator";
import { backMixin } from "../../mixins/back";
import axios from "axios";
import { UPDATE_PROFILE_API } from "../../constants/api";
import { apiErrorMessages } from "../../helpers/messages";
import { FC102 } from "../../constants/fc";
import { debounce_leading } from "@/helpers/helpers";
export default {
  mixins: [validationMixin, backMixin],
  props: {
    title: String,
    value: Boolean,
    inputStartTime: String,
    inputEndTime: String,
    dialogType: String,
  },
  data() {
    return {
      times: [],
      dialog: false,
      startTime: String,
      endTime: String,
      isInfoChange: false,
    };
  },
  validations: {
    startTime: { required },
    endTime: {
      required,
      compareDate: compareDateValidator("startTime"),
    },
  },
  watch: {
    inputStartTime: {
      handler(val) {
        this.startTime = val;
      },
    },
    inputEndTime: {
      handler(val) {
        this.endTime = val;
      },
    },
    startTime: {
      handler(val) {
        this.$store.dispatch("save/setChangeFlag", val !== this.inputStartTime);
      },
    },
    endTime: {
      handler(val) {
        this.$store.dispatch("save/setChangeFlag", val !== this.inputEndTime);
      },
    },
    value: {
      handler() {
        if (this.dialogType === "work") {
          this.startTime = this.inputStartTime;
          this.endTime = this.inputEndTime;
          this.times = initTimeRange();
        } else {
          this.startTime = this.inputStartTime;
          this.endTime = this.inputEndTime;
          this.times = initBreakTimeRange();
        }
      },
    },
  },
  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },
  computed: {
    ...mapGetters("save", ["getChangeFlag"]),
    ...mapGetters("auth", ["user"]),
    endTimeErrors() {
      const errors = [];
      if (this.$v.endTime.$dirty) {
        !this.$v.endTime.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.endTime.compareDate &&
          errors.push(this.$t("label.error.end_time"));
      }
      return errors;
    },
    startTimeErrors() {
      const errors = [];
      if (this.$v.startTime.$dirty) {
        !this.$v.startTime.required &&
          errors.push(this.$t("label.error.required"));
      }
      return errors;
    },
  },
  methods: {
    submit: debounce_leading(async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        let params = {};
        if (this.dialogType === "work") {
          params = {
            start_time: `${this.startTime.replace(":", "")}00`,
            end_time: `${this.endTime.replace(":", "")}00`,
          };
        } else {
          params = {
            start_break_time: `${this.startTime.replace(":", "")}00`,
            end_break_time: `${this.endTime.replace(":", "")}00`,
          };
        }
        try {
          await axios.put(UPDATE_PROFILE_API(this.user.user_id), params);
          this.dialog = false;
          this.$store.dispatch("save/setChangeFlag", false);
          this.$emit("input", this.dialog);
          this.$emit("time-selected");
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC102, status);
        }
      }
    }),
    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.dialog = false;
            this.$emit("input");
          }, 100);
        },
        () => {}
      );
    },
    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.$v.$reset();
          this.dialog = false;
          this.$emit("input");
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>

<style lang="scss" scoped>
.icon-center {
  text-align: center;
}
</style>
