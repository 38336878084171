<template>
  <v-dialog v-model="openMissingTask" persistent max-width="600px" style='z-index: 9999' scrollable>
    <v-card>
      <div v-if="tasks.length > 0" class="margin-botton-setting">
        <v-card-title class="card-header py-2 black--bg">
          未実施のタスク
          <v-btn @click="close()" class="ml-auto" icon>
            <v-icon small>$close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="card-content always-show-task-scrollbar no-top-padding">
          <p v-for="(task,i) in tasks" :key="task.id">{{ i+1 }}. {{ task.name }} {{ task.detail }}</p>
        </v-card-text>
        <v-card-subtitle class="font-weight-bold no-top-padding always-show-reason-scrollbar">
          ▼ 未実施の理由
        </v-card-subtitle>
        <v-card-text class="card-content no-top-padding always-show-reason-scrollbar">
          <div class="ml-5">
          <span class="pre-formatted">{{ subComment }}</span>
          </div>
        </v-card-text>
      </div>
      
      <div v-if="addTasks.length > 0" class="margin-botton-setting">
        <v-card-title class="card-header py-2 black--bg">
          追加で実施したタスク
          <v-btn v-if="tasks.length <= 0" @click="close()" class="ml-auto" icon>
            <v-icon small>$close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="card-content always-show-task-scrollbar no-top-padding">
          <p v-for="(task, i) in addTasks" :key="task.id">{{ i+1 }}. {{ task.title }} {{task.detail}}</p>
        </v-card-text>
      </div>
      
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    tasks: {
      type: Array,
      default: () => [],
    },
    subComment: String,
    addTasks: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        this.openMissingTask = val;
      },
    },
  },
  data() {
    return {
      openMissingTask: false,
    };
  },
  beforeDestroy() {
    this.$emit("close");
  },
  methods: {
    close() {
      this.openMissingTask = false;
      this.$emit("close");
    },
  },
};
</script>

<style>
.black--bg {
  background: none !important;
  font-weight: bold !important;
}
.pre-formatted {
  white-space: pre-wrap;
}

.always-show-task-scrollbar {
  overflow-y: auto !important;
  height: auto;
  max-height: 245px;
  padding-bottom: 0px !important;
}
.always-show-reason-scrollbar {
  overflow-y: auto !important;
  height: auto;
  max-height: 200px;
  padding-bottom: 0px !important;
}

.no-top-padding{
  padding-top: 0px !important;
  background: white !important;
}
.margin-botton-setting{
  margin-bottom: 12px !important;
}
</style>