<template>
  <!-- <v-container fluid> -->
  <div class="mx-5 scroll-comments">
    <div v-for="notification in results" :key="notification.id">
      <v-list-item three-line>
        <v-list-item-avatar>
          <v-img :src="notification.userImage"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <div>{{ notification.comment_user_name }}さん</div>
          <div class="mb-1">
            {{
              formatDate(notification.comment_date, "yyyy/MM/dd(EEEEE) HH:mm")
            }}
          </div>
          <div>
            <v-btn
              class="mt-1 mb-5"
              :class="{
                'reject-text': isRejectText(notification),
                disabledAction: true,
              }"
            >
              {{ notification.phase_name }}
            </v-btn>
          </div>
          <ul
            class="mb-5 word-break"
            v-if="
              isRejectText(notification) &&
              notification.issue_tasks &&
              notification.issue_tasks.length > 0
            "
          >
            <li
              v-for="(issueTask, i) in notification.issue_tasks"
              :key="issueTask.id"
              :class="{ 'mb-2': i !== notification.issue_tasks.length - 1 }"
            >
              {{ displayIssueTask(issueTask) }}
            </li>
          </ul>
          <pre class="notification--comment v-application">{{
            notification.comment
          }}</pre>
          <!-- <div
            v-for="draft in notification.draft_comments"
            :key="draft.comment_id"
          >
            <v-list-item three-line>
              <v-list-item-avatar>
                <v-img :src="draft.userImage"></v-img>
              </v-list-item-avatar>
              <v-list-item-content class="word-break">
                <div>{{ draft.comment_user_name }}さん</div>
                <div class="mb-1">
                  {{
                    formatDate(draft.comment_date, "yyyy/MM/dd(EEEEE) HH:mm")
                  }}
                </div>
                <pre class="notification--comment v-application">{{
                  draft.comment
                }}</pre>
              </v-list-item-content>
            </v-list-item>
          </div> -->
        </v-list-item-content>
      </v-list-item>
    </div>
  </div>
  <!-- </v-container> -->
</template>
<script>
import { STATUS_NO_CONTENT } from "@/constants/status";
import {
  formatDateTime,
  parseDateTime,
} from "../../../helpers/datetime-helpers";
import { blobToBase64, deepClone, isEmpty } from "@/helpers/helpers";
import axios from "axios";
import * as SystemConstant from "../../../constants/system";
import { HHmm2, yyyyMMdd1 } from "@/constants/df";
const DEFAULT_IMAGE = "/assets/images/account_for_no_image.png";
export default {
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      results: [],
    };
  },
  async mounted() {
    await this.display();
  },

  watch: {
    notifications: {
      async handler() {
        await this.display();
      },
    },
  },

  methods: {
    async display(){
      for (let index = 0; index < this.notifications.length; index++) {
        const item = this.notifications[index];
        let existItem = this.notifications.find(
          (x) =>
            x.user_file_api_path === item.user_file_api_path && x.id !== item.id
        );
        if (existItem && existItem.userImage) {
          item.userImage = existItem.userImage;
        } else {
          item.userImage = await this.getImageByPath(item.user_file_api_path);
        }
        if (item.draft_comments && item.draft_comments.length > 0) {
          item.draft_comments.sort((a, b) => Number(a.comment_date) - Number(b.comment_date));
          for (let j = 0; j < item.draft_comments.length; j++) {
            const draft = item.draft_comments[j];
            let existDraft = item.draft_comments.find(
              (x) =>
                x.user_file_api_path === draft.user_file_api_path &&
                x.comment_id !== draft.comment_id
            );
            if (existDraft && existDraft.userImage) {
              draft.userImage = existDraft.userImage;
            } else {
              draft.userImage = await this.getImageByPath(
                draft.user_file_api_path
              );
            }
          }
        }
      }
      this.results = await deepClone(this.notifications);
      this.results.sort(
          (a, b) => Number(a.comment_date) - Number(b.comment_date)
        );
    },

    formatDate(value, format) {
      let date = parseDateTime(value, "yyyyMMddHHmmss");
      return formatDateTime(
        date,
        formatDateTime(date, yyyyMMdd1) ===
          formatDateTime(new Date(), yyyyMMdd1)
          ? HHmm2
          : format
      );
    },
    async getImageByPath(imagePath) {
      let userImage = DEFAULT_IMAGE;

      try {
        if (!isEmpty(imagePath)) {
          const { data, status } = await axios.get(imagePath, {
            responseType: "blob",
          });
          userImage =
            status === STATUS_NO_CONTENT
              ? DEFAULT_IMAGE
              : await blobToBase64(data);
        }
      } catch (error) {
        // const { status } = error.response;
        // apiErrorMessages(this, FC1001, status);
      }

      return userImage;
    },
    isRejectText(item) {
      return (
        item.phase_name === SystemConstant.PHASE_ID_PRIMARY_REJECT_TEXT ||
        item.phase_name === SystemConstant.PHASE_ID_2_NEXT_REJECT_TEXT
      );
    },
    displayIssueTask(issueTask) {
      return `${issueTask?.name} ${issueTask?.detail || ""}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.notification--comment {
  white-space: pre-wrap;
  overflow: visible;
  word-break: break-word;
}
.reject-text {
  color: #dc143c !important;
}
.disabledAction {
  pointer-events: none;
}
.word-break {
  word-break: break-all;
}
.scroll-comments {
  min-height: 132px;
  overflow: auto;
  max-height: calc((var(--vh, 1vh) * 100) - 605px);
}
</style>
