var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{directives:[{name:"dialog-drag",rawName:"v-dialog-drag",value:({
    dialog: 'border-dialog',
  }),expression:"{\n    dialog: 'border-dialog',\n  }"}],attrs:{"persistent":"","content-class":"border-dialog","max-width":"780px","scrollable":"","overlay-color":"transparent","overlay-opacity":"1"},model:{value:(_vm.openExplain),callback:function ($$v) {_vm.openExplain=$$v},expression:"openExplain"}},[_c('v-card',[_c('v-card-title',{staticClass:"card-header py-2"},[_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":"","color":"gray"},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$close")])],1)],1),_c('v-card-text',[_c('h2',{staticClass:"py-2 h2-mobile"},[_vm._v(" タスク色とアイコンは以下のように設定されています。 ")]),_c('v-list',{staticClass:"explain"},[_c('v-list-item',[_c('v-list-item-content',{style:({
              backgroundColor: _vm.COLOR_DB_TASK,
              maxWidth: '22%',
              marginRight: '8%',
            })},[_c('v-list-item-title',{staticClass:"ml-2"},[_vm._v("タスク")])],1),_c('v-list-item-content',[_vm._v("予定通り実施した標準工数タスク")])],1),_c('v-list-item',[_c('v-list-item-content',{style:({
              border: ("1px solid " + _vm.BORDER_COLOR_TASK_NOT_COMPLETE),
              backgroundColor: _vm.COLOR_DB_TASK,
              maxWidth: '22%',
              marginRight: '8%',
            })},[_c('v-list-item-title',{staticClass:"ml-2"},[_vm._v("タスク")])],1),_c('v-list-item-content',[_vm._v("未実施の標準工数タスク")])],1),_c('v-list-item',[_c('v-list-item-content',{style:({
              border: ("1px solid " + _vm.BORDER_COLOR_TASK_NOT_PLAN),
              backgroundColor: _vm.COLOR_DB_TASK,
              maxWidth: '22%',
              marginRight: '8%',
            })},[_c('v-list-item-title',{staticClass:"ml-2"},[_vm._v("タスク")])],1),_c('v-list-item-content',[_vm._v(" 予定に見込んでいなかったが、実施した標準工数タスク ")])],1),_c('v-list-item',[_c('v-list-item-content',{style:({
              backgroundColor: _vm.COLOR_DB_TASK_WITHIN_1_2,
              maxWidth: '22%',
              marginRight: '8%',
              color: 'white',
            })},[_c('v-list-item-title',{staticClass:"ml-2"},[_vm._v("タスク")])],1),_c('v-list-item-content',[_c('div',[_vm._v("標準工数タスクで予定工数を超過した場合(1.2倍以内)")]),_c('div',{staticClass:"mt-2"},[_vm._v(" タスクの実施回数が予定を下回った場合(ただし工数は予定内の場合) ")])])],1),_c('v-list-item',[_c('v-list-item-content',{style:({
              backgroundColor: _vm.COLOR_DB_TASK_GREATER_1_2,
              maxWidth: '22%',
              marginRight: '8%',
              color: 'white',
            })},[_c('v-list-item-title',{staticClass:"ml-2"},[_vm._v("タスク")])],1),_c('v-list-item-content',[_c('div',[_vm._v("標準工数タスクで予定工数を超過した場合(1.2倍以上)")]),_c('div',{staticClass:"mt-2"},[_vm._v(" タスクの実施回数が予定を下回った場合(かつ工数も予定を超過した場合) ")])])],1)],1),_c('div',{staticClass:"divider"},[_c('v-divider')],1),_c('v-list',{staticClass:"explain"},[_c('v-list-item',[_c('v-list-item-content',{style:({
              backgroundColor: _vm.COLOR_FREE_TASK,
              maxWidth: '22%',
              marginRight: '8%',
            })},[_c('v-list-item-title',{staticClass:"ml-2"},[_vm._v("タスク")])],1),_c('v-list-item-content',[_vm._v("予定通り実施した標準工数以外タスク")])],1),_c('v-list-item',[_c('v-list-item-content',{style:({
              border: ("1px solid " + _vm.BORDER_COLOR_TASK_NOT_COMPLETE),
              backgroundColor: _vm.COLOR_FREE_TASK,
              maxWidth: '22%',
              marginRight: '8%',
            })},[_c('v-list-item-title',{staticClass:"ml-2"},[_vm._v("タスク")])],1),_c('v-list-item-content',[_vm._v("未実施の標準工数以外タスク")])],1),_c('v-list-item',[_c('v-list-item-content',{style:({
              border: ("1px solid " + _vm.BORDER_COLOR_TASK_NOT_PLAN),
              backgroundColor: _vm.COLOR_FREE_TASK,
              maxWidth: '22%',
              marginRight: '8%',
            })},[_c('v-list-item-title',{staticClass:"ml-2"},[_vm._v("タスク")])],1),_c('v-list-item-content',[_vm._v(" 予定に見込んでいなかったが、実施した標準工数以外タスク ")])],1),_c('v-list-item',[_c('v-list-item-content',{style:({
              backgroundColor: _vm.COLOR_FREE_TASK_WITHIN_1_2,
              maxWidth: '22%',
              marginRight: '8%',
              color: 'white',
            })},[_c('v-list-item-title',{staticClass:"ml-2"},[_vm._v("タスク")])],1),_c('v-list-item-content',[_c('div',[_vm._v("標準工数以外タスクで予定工数を超過した場合(1.2倍以内)")]),_c('div',{staticClass:"mt-2"},[_vm._v(" タスクの実施回数が予定を下回った場合(ただし工数は予定内の場合) ")])])],1),_c('v-list-item',[_c('v-list-item-content',{style:({
              backgroundColor: _vm.COLOR_FREE_TASK_GREATER_1_2,
              maxWidth: '22%',
              marginRight: '8%',
              color: 'white',
            })},[_c('v-list-item-title',{staticClass:"ml-2"},[_vm._v("タスク")])],1),_c('v-list-item-content',[_c('div',[_vm._v("標準工数以外タスクで予定工数を超過した場合(1.2倍以上)")]),_c('div',{staticClass:"mt-2"},[_vm._v(" タスクの実施回数が予定を下回った場合(かつ工数も予定を超過した場合) ")])])],1)],1),_c('div',{staticClass:"divider"},[_c('v-divider')],1),_c('v-list',{staticClass:"explain"},[_c('v-list-item',[_c('v-list-item-content',{style:({
              backgroundColor: _vm.COLOR_GRAY,
              maxWidth: '22%',
              marginRight: '8%',
              color: 'white',
            })},[_c('v-list-item-title',{staticClass:"ml-2"},[_vm._v("タスク")])],1),_c('v-list-item-content',[_vm._v("休憩・イレギュラー勤怠（遅刻・半休など）")])],1)],1),_c('div',{staticClass:"divider"},[_c('v-divider')],1),_c('v-list',{staticClass:"explain"},[_c('v-list-item',[_c('v-list-item-content',{style:({ maxWidth: '22%', marginRight: '8%' })},[_c('v-icon',[_vm._v("$remandtask")])],1),_c('v-list-item-content',[_vm._v(" 差し戻しタスク ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }