<template>
  <v-menu
    v-if="!$vuetify.breakpoint.mobile"
    v-model="openSettingMenu"
    :close-on-content-click="false"
    @input="keep()"
    offset-y
    bottom
    left
    min-width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on" color="ligth">
        {{ name }}
        <v-icon large right dark>mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="checkAll"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>全て</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="item in listItem" :key="item[id]">
            <v-list-item-action>
              <v-checkbox v-model="item[check]"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item[display] }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn tile color="ligth darken-1" @click="clear()">{{
          $t("label.buttons.cancel")
        }}</v-btn>
        <v-btn tile color="primary darken-1" @click="submit()">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
  <v-dialog
    v-else
    ref="dialog"
    v-model="openSettingMenu"
    width="290px"
    @input="keep()"
  >
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on" color="ligth">
        {{ name }}
        <v-icon large right dark>mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="checkAll"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>全て</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="item in listItem" :key="item[id]">
            <v-list-item-action>
              <v-checkbox v-model="item[check]"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item[display] }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn tile color="ligth darken-1" @click="clear()">{{
          $t("label.buttons.cancel")
        }}</v-btn>
        <v-btn tile color="primary darken-1" @click="submit()">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { deepClone } from "../helpers/helpers";
export default {
  name: "DropdownCheckList",
  props: {
    name: String,
    id: String,
    display: String,
    check: String,
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    checkAll: {
      // getter
      get: function () {
        return !this.listItem.some((x) => !x[this.check]);
      },
      // setter
      set: function (newValue) {
        this.listItem = this.listItem.map((x) => {
          return {
            [this.display]: x[this.display],
            [this.id]: x[this.id],
            [this.check]: newValue,
          };
        });
      },
    },
  },
  watch: {
    items: {
      immediate: true,
      deep: true,
      handler(val) {
        this.listItem = val;
        this.originalFilter = val;
      },
    },
  },
  data() {
    return {
      openSettingMenu: false,
      listItem: [],
      originalFilter: [],
    };
  },
  methods: {
    submit() {
      this.originalFilter = deepClone(this.listItem);
      let filters = this.listItem
        .filter((x) => x.check === true)
        .map((x) => x.id);
      if (filters.includes(10)) {
        filters.push(15);
      }
      if (filters.includes(20)) {
        filters.push(23);
      }

      this.$emit("changeFilter", filters);
      this.openSettingMenu = false;
    },

    clear() {
      this.openSettingMenu = false;
      this.listItem = deepClone(this.originalFilter);
    },

    reset() {
      this.checkAll = true;
      this.originalFilter = this.listItem;
    },

    keep() {
      this.listItem = deepClone(this.originalFilter);
    },
  },
};
</script>

<style>
</style>
