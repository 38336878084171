import Vue from 'vue'
import SnackBar from './index'
import vuetify from '../../../plugins/vuetify'
import i18n from '../../../plugins/i18n'

let instance

function initInstance() {
  const SnackBarConstructor = Vue.extend(Object.assign({
    vuetify,
    i18n
  }, SnackBar))
  instance = new SnackBarConstructor({
    el: document.createElement('div'),
  })
}

function vtoast(msg, timeout, hasClass, collapse) {

  if (!instance) {
    initInstance()
  }
  instance.message = msg
  instance.timeout = timeout
  instance.hasClass = hasClass
  instance.collapse = collapse
  document.querySelector('body').appendChild(instance.$el)
  Vue.nextTick(() => {
    instance.visible = true
  })
}

const Toast = function () {}

Toast.toast = function (msg, timeout = 3000, hasClass = false, collapse = false) {
  vtoast(msg, timeout, hasClass, collapse)
}

export {
  Toast
}

export default Toast
