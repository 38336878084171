var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 pb-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 ml-auto",attrs:{"cols":"6","md":"4"}},[_c('v-text-field',{attrs:{"placeholder":"検索","hide-details":"auto"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.search()}}},[_vm._v("$search")])]},proxy:true}]),model:{value:(_vm.filterBy.searchText),callback:function ($$v) {_vm.$set(_vm.filterBy, "searchText", $$v)},expression:"filterBy.searchText"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex d-md-block justify-space-between align-center py-0",attrs:{"cols":"12","md":"2"}},[_c('v-list',{staticClass:"d-flex d-md-block",attrs:{"rounded":""}},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v("お知らせ一覧")]),_c('v-list-item-group',{staticClass:"d-flex d-md-block",attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.notificationType),function(item,i){return _c('v-list-item',{key:i,class:_vm.changeListItemClass(i),attrs:{"disabled":_vm.disabledLink(i),"href":_vm.editLink(i)}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)],1)}),1)],1),_c('div',{staticClass:"d-md-none"},[_c('v-menu',{attrs:{"left":"","offset-x":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"grey--text"},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('sort-by',{attrs:{"sort":_vm.filterBy.sort,"sortByOld":_vm.noticeConfig.SORT_BY_OLD,"sortByNew":_vm.noticeConfig.SORT_BY_NEW},on:{"sortBy":function($event){return _vm.sortBy($event)}}})],1),_c('v-divider'),_c('v-list-item',[_c('filter-date',{attrs:{"inputDate":_vm.filterDateChosen},on:{"changeDate":function($event){return _vm.filterDate($event)},"deleteFilter":function($event){return _vm.deleteFilter()}}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-row',[_c('v-col',{class:[_vm.mobile ? 'py-0 my-0' : ''],attrs:{"md":"6"}},[(
            _vm.notificationSelected.length > 0 &&
            _vm.notificationListChosen === 0 &&
            _vm.selectAll
          )?_c('v-btn',{staticClass:"ml-2 rounded-15",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.confirmed()}}},[_vm._v(" "+_vm._s(_vm.notificationSelectedLength + _vm.dataTableConfig.counterText)+" ")]):_vm._e(),(
            _vm.notificationItemSelected.length > 0 &&
            _vm.notificationListChosen === 0 &&
            !_vm.selectAll
          )?_c('v-btn',{staticClass:"ml-2 rounded-15",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.itemConfirmed()}}},[_vm._v(" "+_vm._s(_vm.notificationItemSelectedLength + _vm.dataTableConfig.counterText)+" ")]):_vm._e()],1),(!_vm.mobile)?_c('v-col',{staticClass:"text-right",attrs:{"md":"6"}},[[_c('div',[_c('sort-by',{attrs:{"sort":_vm.filterBy.sort,"sortByOld":_vm.noticeConfig.SORT_BY_OLD,"sortByNew":_vm.noticeConfig.SORT_BY_NEW},on:{"sortBy":function($event){return _vm.sortBy($event)}}}),_c('span',{staticClass:"ma-1"}),_c('filter-date',{attrs:{"inputDate":_vm.filterDateChosen},on:{"changeDate":function($event){return _vm.filterDate($event)},"deleteFilter":function($event){return _vm.deleteFilter()}}})],1)]],2):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pb-2",class:{
          'height-custom-pc': _vm.$vuetify.breakpoint.md,
          'height-custom': !_vm.$vuetify.breakpoint.md && !_vm.isShowSelected(),
          'height-custom-selected':
            !_vm.$vuetify.breakpoint.md && _vm.isShowSelected(),
        },attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 h-100",attrs:{"height":"100%","fixed-header":"","item-key":"status_id","headers":_vm.dataTableConfig.headers,"items-per-page":_vm.dataTableConfig.options.itemsPerPage,"items":_vm.notificationList,"show-select":_vm.notificationListChosen === _vm.noticeConfig.UNCONFIRMED,"no-data-text":_vm.isFilter
                ? _vm.dataTableConfig.noDataSeachText
                : _vm.dataTableConfig.noDataText,"options":_vm.dataTableConfig.options,"server-items-length":_vm.dataTableConfig.total,"footer-props":{
              'items-per-page-options': _vm.dataTableConfig.itemsPerPageOptions,
            },"mobile-breakpoint":"0"},on:{"update:options":[function($event){return _vm.$set(_vm.dataTableConfig, "options", $event)},_vm.optionsChanged]},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){return [_c('v-checkbox',{attrs:{"input-value":_vm.notificationSelected.length > 0,"indeterminate":_vm.isSelectItem,"color":_vm.toggleColor,"hide-details":""},on:{"change":function($event){return _vm.toggleSelectAll($event)}}})]}},{key:"item.data-table-select",fn:function(ref){
            var item = ref.item;
            var isSelected = ref.isSelected;
return [_c('v-checkbox',{attrs:{"disabled":!_vm.isCanMark(item),"input-value":_vm.isCanMark(item) && _vm.isCheck ? isSelected : null,"hide-details":""},on:{"change":function($event){return _vm.select($event, item)}}})]}},{key:"item.subject",fn:function(ref){
            var item = ref.item;
return [_c('a',{staticClass:"subject-name dt-comment text-decoration-none",attrs:{"href":_vm.moveTo(item)}},[_vm._v(_vm._s(item.subject_format))])]}},{key:"item.comment",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"dt-comment"},[(
                  item.tasks &&
                  item.tasks.length > 0 &&
                  item.comment_notice_id
                )?_c('div',_vm._l((item.tasks),function(p,i){return _c('div',{key:i},[_vm._v(" 【"+_vm._s(p.name)),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(p.detail)+"】")])])}),0):_vm._e(),_c('div',[_vm._v(_vm._s(item.comment))])])]}}],null,true),model:{value:(_vm.notificationSelected),callback:function ($$v) {_vm.notificationSelected=$$v},expression:"notificationSelected"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }