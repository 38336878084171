<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="400px">
    <v-card class="py-3 mx-auto">
      <v-card-title class="mt-6 d-flex justify-center">{{
        message
      }}</v-card-title>
      <v-card-actions class="justify-center">
        <v-btn tile color="primary" dark @click="submit()">削除</v-btn>
        <v-btn tile color="ligth" outlined @click="close()">{{
          $t("label.buttons.cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { UPDATE_PROFILE_API } from "../../../constants/api";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC103 } from "../../../constants/fc";
import { debounce_leading } from "@/helpers/helpers";
export default {
  props: {
    title: String,
    message: String,
    inputUserInfo: Object,
    value: Boolean,
  },
  data() {
    return {
      userInfo: {},
      dialog: false,
    };
  },
  watch: {
    inputUserInfo: {
      handler(val) {
        this.userInfo = val;
      },
    },
  },
  methods: {
    submit: debounce_leading(async function () {
      try {
        await axios.delete(UPDATE_PROFILE_API(this.userInfo.user_id));
        this.dialog = false;
        this.$emit("input", this.dialog);
        this.$emit("deleteAccountSuccess", this.userInfo);
      } catch (error) {
        const { status } = error.response;
        if (status === 404) {
          this.$alert(error.response.data.message, {
            width: "600px",
            wrapText: true,
          });
        } else {
          apiErrorMessages(this, FC103, status);
        }
      }
    }),
    close() {
      this.dialog = false;
      this.$emit("input", this.dialog);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
