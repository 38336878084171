export const queryString = {
    /**
     * Simulating qs package for query string
     * 
     * Doesn't support recursive query stringify.
     * Doesn't support nested object, array, etc.
     * 
     * @param queryObject Query object contains properties like page, limit,...
     * @returns Query string
     */
     stringify(queryObject) {
        return queryObject 
        ? Object.keys(queryObject).map((key) => {
            return `${key}=${queryObject[key]}`
        }).join('&')
        : '';
    },
    clean(obj) {
        for (var propName in obj) {
          if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
          }
        }
        return obj
    }
}