/**
 * Modern JavaScript date utility library
 * date-fns provides the most comprehensive, yet simple and consistent toolset for manipulating JavaScript dates in a browser & Node.js.
 *
 * Documentation: https://date-fns.org/docs/Getting-Started
 */
import * as DateFns from 'date-fns'
import {
  ja,
  enGB
} from 'date-fns/locale'


/**
 * Parse value to datetime
 * @param String dt Value
 * @param String fmt Format
 */
export const parseDateTime = (dt, fmt = "yyyyMMdd") => {
  if (DateFns.isValid(DateFns.parse(dt, fmt, new Date()))) {
    return DateFns.parse(dt, fmt, new Date()) // Set from server if available
  }
  return null
}

/**
 * Format datetime
 * @param {*} dt
 * @param String format Format
 * @param String initial default value
 * @param {*} locale
 */
export const formatDateTime = (dt, format = 'yyyy-MM-dd', initial = '--/--/--', locale = 'ja') => {

  if (dt == undefined || dt == null || dt == '') {
    return initial; // Default value
  }

  switch (locale) {
    case 'ja':


      if (typeof dt === 'string') {

        return DateFns.format(new Date(DateFns.parseISO(dt)), format, {
          locale: ja
        })
      } else {
        return DateFns.format(dt, format, {
          locale: ja
        })
      }
    case 'en':
      if (typeof dt === 'string') {
        return DateFns.format(new Date(DateFns.parseISO(dt)), format, {
          locale: enGB
        })
      } else {
        return DateFns.format(dt, format, {
          locale: enGB
        })
      }

    default:
      if (typeof dt === 'string') {
        return DateFns.format(new Date(DateFns.parseISO(dt)))
      } else {
        return DateFns.format(dt, format)
      }
  }
}


/**
 * Asc: Compare the two dates and return 1 if the first date is after the second, -1 if the first date is before the second or 0 if dates are equal.
 * Desc: Compare the two dates and return -1 if the first date is after the second, 1 if the first date is before the second or 0 if dates are equal.
 * @param DateTime dateLeft
 * @param DateTime dateRight
 */
export const compareDate = (dateLeft, dateRight, order = 'asc') => {
  if (!DateFns.isValid(dateLeft))
    throw 'dateLeft is invalid';

  if (!DateFns.isValid(dateRight))
    throw 'dateRight is invalid';

  switch (order) {
    case 'asc':
      return DateFns.compareAsc(dateLeft, dateRight);
    case 'desc':
      return DateFns.compareAsc(dateLeft, dateRight);
    default:
      return DateFns.compareDesc(dateLeft, dateRight); // default asc
  }
}

export const formatTime = (time, format = 'HH:mm') => {
  if (time == undefined || time == null || time == '') {
    return '';
  }
  let now = new Date();
  let date = DateFns.parse(`${formatDateTime(now, "yyyyMMdd")} ${time}`, "yyyyMMdd HHmmss", new Date());
  return DateFns.format(date, format);
}

export const formatTimeEx = (time, format = 'HH:mm') => {
  if (time == undefined || time == null || time == '') {
    return '';
  }
  let now = new Date();
  let date = DateFns.parse(`${formatDateTime(now, "yyyyMMdd")} ${time}`, `yyyyMMdd ${format}`, new Date());
  return date
}

export const toMinute = (time, splitFormat = '') => {
  if (!time) return 0;
  if (splitFormat === "") {
    let arg = time.split(splitFormat);
    return parseInt(`${arg[0]}${arg[1]}`) * 60 + parseInt(`${arg[2]}${arg[3]}`);
  } else {
    let newTimes = time.split(splitFormat);
    return parseInt(newTimes[0] * 60) + parseInt(newTimes[1]);
  }
}

export const toMinuteForHHmm = (time) => {
  if (!time) return 0;
  let arg = time.split("");
  return arg.length === 3 ? parseInt(`${arg[0]}`) * 60 + parseInt(`${arg[1]}${arg[2]}`)
    : parseInt(`${arg[0]}${arg[1]}`) * 60 + parseInt(`${arg[2]}${arg[3]}`);
}

export const toHHmm = (time) => {
  let minute = time % 60 < 10 ? `0${time % 60}` : time % 60;
  let hours = `${Math.trunc(time / 60)}:${minute}`;
  return hours;
}

export const toHHmmText = (time) => {
  if (time) {
  let minute = time % 60 < 10 ? `0${time % 60}` : time % 60;
  let hours = `${Math.trunc(time / 60)}h${minute}m`;
  return hours;
  }
  return "0h0m";
}

export const toHHmmFormat = (time) => {
  if (!time) return 0;
  let arg = time.split("");
  return arg.length === 3 ? `${arg[0]}:${arg[1]}${arg[2]}` : `${arg[0]}${arg[1]}:${arg[2]}${arg[3]}`;
}

export const toHHmmNoColon = (time) => {
  let minute = time % 60 < 10 ? `0${time % 60}` : time % 60;
  let hours = Math.trunc(time / 60) < 10 ? `0${Math.trunc(time / 60)}` : Math.trunc(time / 60);
  return `${hours}${minute}`;
}

/**
 * Add more days
 * @param String dt datetime value
 * @param {*} num day number
 * @param {*} fmt format
 */
export const addDays = (dt, num, fmt = "yyyy-MM-dd") => {
  let newDt = parseDateTime(dt, fmt)
  return DateFns.addDays(newDt, num)
}

export const convertDate = (dt, fmt = "yyyy-MM-dd") => {
  let newDt = parseDateTime(dt, fmt)
  return newDt
}

// Accepts a Date object or date string that is recognized by the Date.parse() method
export const getDayOfWeek = (date) => {
  const dayOfWeek = new Date(date).getDay();
  return isNaN(dayOfWeek)
    ? null
    : ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek];
}
