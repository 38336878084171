<template>
  <v-container class="profile pa-0" fill-height fluid>
    <v-row class="height-custom">
      <v-col offset-md="3" md="6" cols="12">
        <section class="profile-header">
          <v-row class="pb-4" align="center" justify="center">
            <v-col class="text-left" md="4">
              <h3>プロフィール情報</h3>
            </v-col>
            <v-col class="text-center profile-avatar" md="8">
              <avatar-upload
                :fileId="userInfo.file_id"
                @fileChanged="uploadImage($event)"
              ></avatar-upload>
            </v-col>
          </v-row>
        </section>

        <section class="profile-content">
          <v-row class="py-1" align="center" justify="center">
            <v-col class="text-left" md="4">名前</v-col>
            <v-col class="text-left" md="8">{{ userInfo.name }}</v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="py-1" align="center" justify="center">
            <v-col class="text-left" md="4">ナマエ</v-col>
            <v-col class="text-left" md="8">{{ userInfo.name_kana }}</v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="py-1" align="center" justify="center">
            <v-col class="text-left" md="4">雇用形態</v-col>
            <v-col class="text-left" md="8">
              {{ userInfo.employee_type_name }}
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="py-1" align="center" justify="center">
            <v-col class="text-left" md="4">就業時間</v-col>
            <v-col class="text-left" md="8">
              <span>{{ workingTime }}</span>
              <a v-if="!isInternOrPartTime"
                @click="openTimeRange('work')"
                class="ml-2 text-decoration-none text--secondary"
                >就業時間を変更する</a
              >
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="py-1" align="center" justify="center">
            <v-col class="text-left" md="4">所属部署＞所属Gr</v-col>
            <v-col class="text-left" md="8">{{ dgName }}</v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="py-1" align="center" justify="center">
            <v-col class="text-left" md="4">役職</v-col>
            <v-col class="text-left" md="8">{{ userInfo.position_name }}</v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="py-1" align="center" justify="center">
            <v-col class="text-left" md="4">直属上長</v-col>
            <v-col class="text-left" md="8">{{ (userInfo.position_id === 630 && userInfo.approval_auth_id === 30) ? '' : userInfo.manage_name }}</v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="py-1" align="center" justify="center">
            <v-col class="text-left" md="4">システム権限</v-col>
            <v-col class="text-left" md="8">{{
              userInfo.system_auth_name
            }}</v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="py-1" align="center" justify="center">
            <v-col class="text-left" md="4">承認権限</v-col>
            <v-col class="text-left" md="8">{{
              userInfo.approval_auth_name
            }}</v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="py-1" align="center" justify="center">
            <v-col class="text-left" md="4">入社日</v-col>
            <v-col class="text-left" md="8">{{ joiningDate }}</v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="py-1" align="center" justify="center">
            <v-col class="text-left" md="4">日次予定の提出</v-col>
            <v-col class="text-left" md="8">{{ isNeedSubmit }}</v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="py-1" align="center" justify="center">
            <v-col class="text-left" md="4">パスワード</v-col>
            <v-col class="text-left" md="8">
              <span>●●●●●●●●</span>
              <a
                @click="resetPassword()"
                class="ml-2 text-decoration-none text--secondary"
                >パスワードを変更する</a
              >
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row align="center" justify="center">
            <v-col class="mr-auto text-left" md="4">
              <h3>連絡先情報</h3>
            </v-col>
          </v-row>
          <v-row class="py-2" align="center" justify="center">
            <v-col class="text-left" md="4" cols="6">メールアドレス</v-col>
            <v-col
              class="text-left d-flex text-wrap custom-text"
              align-self="center"
              md="8"
              cols="6"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">{{ userInfo.mail }}</div>
                </template>
                <span>{{ userInfo.mail }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </section>
      </v-col>
    </v-row>
    <TimeRange
      :title="dialogTitle"
      :dialog-type="dialogType"
      :input-end-time="inputEndTime"
      :input-start-time="inputStartTime"
      @time-selected="timeSelected()"
      v-model="openDialog"
    ></TimeRange>
  </v-container>
</template>

<script>
import AvatarUpload from "../../components/AvatarUpload.vue";
import TimeRange from "./TimeRange.vue";
import axios from "axios";
import { GET_PROFILE_API, UPDATE_PROFILE_API } from "../../constants/api";
import {
  formatDateTime,
  parseDateTime,
  formatTime,
} from "../../helpers/datetime-helpers";
import { apiErrorMessages } from "../../helpers/messages";
import * as Role from "../../constants/role";
import { FC102, FC109 } from "../../constants/fc";
import { yyyyMMdd3 } from "../../constants/df";
import { STATUS_NO_CONTENT } from "../../constants/status";

export default {
  middleware: Role.AUTH,
  components: { AvatarUpload, TimeRange },
  metaInfo() {
    return { title: this.$t("title.profile") };
  },
  data() {
    return {
      userInfo: {
        user_id: "",
        mail: "",
        name: "",
        name_kana: "",
        employee_type_id: "",
        employee_type_name: "",
        position_id: "",
        position_name: "",
        manage_id: "",
        manage_name: "",
        system_auth_id: "",
        system_auth_name: "",
        approval_auth_id: "",
        approval_auth_name: "",
        joining_date: "",
        is_need_submit: "",
        file_id: "",
        file_api_path: "",
        start_time: "",
        end_time: "",
        is_enabled: "",
        department_id: "",
        department_name: "",
        group_id: "",
        group_name: "",
      },
      openDialog: false,
      dialogTitle: "",
      dialogType: "",
      inputStartTime: "",
      inputEndTime: "",
    };
  },
  methods: {
    openTimeRange(type) {
      this.dialogType = type;
      switch (type) {
        case "work":
          this.dialogTitle = "就業時間を選択";
          this.inputStartTime = formatTime(this.userInfo.start_time);
          this.inputEndTime = formatTime(this.userInfo.end_time);
          break;
        default:
          break;
      }
      this.openDialog = true;
    },

    async timeSelected() {
      this.userInfo = await this.getProfile();
      this.$store.dispatch("common/saveLoginInfo", {
        loginInfo: this.userInfo,
      });
    },

    resetPassword() {
      this.$router.push({
        name: "reupdate-password",
        params: { id: this.userInfo.user_id },
        query: { m: this.userInfo.mail, n: this.userInfo.name },
      });
    },

    async uploadImage(fileId) {
      let params = { file_id: fileId };
      try {
        await axios.put(UPDATE_PROFILE_API(this.userInfo.user_id), params);
        this.userInfo = {
          ...this.userInfo,
          file_id: fileId,
        };
        this.$store.dispatch("common/saveLoginInfo", {
          loginInfo: this.userInfo,
        });
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC102, status);
      }
    },

    async getProfile() {
      try {
        const { data, status } = await axios.get(GET_PROFILE_API());
        return status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC109, status);
      }
    },
  },
  computed: {
    workingTime: function () {
      if (this.userInfo.start_time || this.userInfo.end_time)
        return (
          formatTime(this.userInfo.start_time) +
          "~" +
          formatTime(this.userInfo.end_time)
        );
      else return "シフト制のため未設定";
    },

    dgName: function () {
      if (!this.userInfo.department_name) {
        return this.userInfo.group_name || "";
      } else {
        return `${this.userInfo.department_name} ＞ ${this.userInfo.group_name}`;
      }
    },

    joiningDate: function () {
      return formatDateTime(
        parseDateTime(this.userInfo.joining_date),
        yyyyMMdd3
      );
    },
    isNeedSubmit: function () {
      return this.userInfo.is_need_submit !== 1 ? "不要" : "要";
    },
    isInternOrPartTime: function () {
      return this.userInfo.position_id === 100 || this.userInfo.position_id === 200;
    },
  },
  async mounted() {
    this.userInfo = await this.getProfile();
  },
};
</script>

<style scoped lang="scss">
.profile {
  .profile-avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .custom-text {
    word-break: break-word;
  }
}
.height-custom {
  height: calc((var(--vh, 1vh) * 100) - 56px - 12px - 56px);
  overflow: auto;
}
</style>
