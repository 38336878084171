<template>
  <v-dialog :value="show" max-width="400px" persistent>
    <v-card class="py-3 mx-auto">
      <v-card-title class="d-flex font-weight-bold">Gr.移動</v-card-title>
      <v-card-text>
        <v-radio-group v-model="department_id">
          <v-radio
            v-for="department in departments"
            :key="department.id"
            :label="department.name"
            :value="department.id"
          ></v-radio>
        </v-radio-group>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          tile
          color="primary"
          :disabled="department_id === this.group.department_id"
          @click="submit()"
          >OK</v-btn
        >
        <v-btn tile color="ligth" outlined @click="close()">{{
          $t("label.buttons.cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { BELONG_GROUP } from "../../../constants/api";
import axios from "axios";
import { apiErrorMessages } from "@/helpers/messages";
import { FC303 } from "@/constants/fc";
import { debounce_leading, deepClone } from "@/helpers/helpers";
import { backMixin } from "@/mixins/back";
import { mapGetters } from "vuex";
export default {
  mixins: [backMixin],
  data() {
    return {
      department_id: this.group.department_id,
      originalId: this.group.department_id,
    };
  },
  computed: {
    ...mapGetters("save", ["getChangeFlag"]),
  },
  props: {
    show: Boolean,
    departments: Array,
    group: Object,
  },
  watch: {
    group(val) {
      this.originalId = val.department_id;
      this.department_id = val.department_id;
    },
    department_id(val) {
      this.$store.dispatch("save/setChangeFlag", this.originalId !== val);
    },
  },
  methods: {
    submit : debounce_leading(async function() {
      try {
        await axios.put(BELONG_GROUP(this.group.group_id), {
          department_id: this.department_id,
          name: this.group.group_name,
        });
        this.$toast(
          `${
            this.departments.find((x) => x.id === this.department_id).name
          }部に移動しました`,
          3000
        );
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC303, status);
      }
      let temp = deepClone(this.group);
      temp.department_id = this.department_id;
      temp.department_name = this.departments.find(
        (x) => x.id === this.department_id
      ).name;
      this.$emit("submit", temp);
    }),
    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$emit("close");
          }, 100);
        },
        () => {}
      );
    },
  },
};
</script>
