export const evalMixin = {
    methods: {
      /**
       * To evaluate a parameter in <template>
       * @param {any} param Keep as many annotations as possible
       * @param {string} optionalChainingString Example: something?.somethingElse
       * @returns {any} Return evaluated param?.something?.somethingElse
       */
      $e(param, optionalChainingString) {
        // eslint-disable-next-line no-useless-escape
        if (/^([a-zA-Z0-9_\[\].?])*$/g.test(optionalChainingString)) {
          // eslint-disable-next-line no-eval
          return eval('param?.' + optionalChainingString)
        } else {
          return null
        }
      },
    },
  }
  