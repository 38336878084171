<template>
  <v-menu v-model="openSortMenu" :close-on-content-click="false" offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-if="mobile" v-bind="attrs" v-on="on"  >
        <v-icon small left dark>$reorder</v-icon>
        並び替え
      </v-btn>
      <v-btn v-else v-bind="attrs" v-on="on" color="ligth" class="sort-border" rounded outlined>
        <v-icon small left dark>$reorder</v-icon>
        並び替え
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group v-model="groupItemSelected" mandatory @change="change()">
        <v-list-item :value="sortByNew">
          <v-list-item-content>
            <v-list-item-title>新着順 </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item :value="sortByOld">
          <v-list-item-content>
            <v-list-item-title>時系列順 </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      openSortMenu: false,
      groupItemSelected: this.sort // TODO:: waiting for API
    };
  },
  props: {
    sort: String, // TODO:: waiting for API
    sortByOld: String, // TODO:: waiting for API
    sortByNew: String // TODO:: waiting for API
  },
  watch: {
    sort(val){
      this.groupItemSelected = val
    }
  },
  methods: {
    change(){
      this.openSortMenu = false;
      this.$emit('sortBy', this.groupItemSelected);
    }
  },
  computed:{
    mobile() {
      return ["sm", "xs"].includes(this.$vuetify.breakpoint.name);
    },
  },
};
</script>
<style lang="scss" scoped>
.sort-border {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
</style>