<template>
  <v-dialog
    v-model="dialog"
    width="300"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-card-title class="card-header">
        <span class="font-bold">
          添付ファイル
          <span class="ml-10">{{ files.length }} 件</span></span
        >
        <v-btn @click="dialog = false" class="ml-auto" icon
          ><v-icon small>$close</v-icon></v-btn
        >
      </v-card-title>
      <v-list>
        <v-list-item v-for="item in files" :key="item.id" class="ml-4">
          <v-list-item-icon>
            <v-icon small>$attachedfile</v-icon>
          </v-list-item-icon>
          <v-list-item-content @click="isViewFile() && viewAttachFile(item)">
            <v-list-item-title class="text-wrap work-break">{{
              item.name
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <PreviewFile :show="showPreview" :src="src" :text="text"> </PreviewFile>
  </v-dialog>
</template>

<script>
import { ATTACH_FILE_API } from "../../../constants/api";
import axios from "axios";
import { FC1003 } from "../../../constants/fc";
import { apiErrorMessages } from "../../../helpers/messages";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import { saveFile, isSafari } from "../../../helpers/helpers";
import PreviewFile from "./PreviewFile.vue";
export default {
  components: { PreviewFile },
  props: {
    show: Boolean,
    inputFiles: Array,
  },
  data() {
    return {
      dialog: this.show,
      files: [],
      src: "",
      text: "",
      showPreview: false,
    };
  },
  watch: {
    show: {
      handler() {
        this.dialog = true;
        this.files = this.inputFiles;
      },
    },
  },
  methods: {
    isViewFile() {
      return (
        this.$root.$refs.actualComponent.isSupervisor() ||
        this.$root.$refs.actualComponent.isSelf()
      );
    },

    async viewAttachFile(item) {
      try {
        const { data, headers, status } = await axios.get(
          ATTACH_FILE_API(item.id),
          {
            responseType: "blob",
          }
        );

        if (status === STATUS_NO_CONTENT) return;
        const blob = new Blob([data], {
          type: contentType,
        });
        var contentType = headers["content-type"];
        if (
          contentType.startsWith("image/") ||
          contentType.startsWith("text/")
        ) {
          let that = this;
          if (isSafari() && typeof FileReader !== "undefined") {
            if (contentType.startsWith("image/")) {
              let reader = new FileReader();
              reader.onloadend = function () {
                that.src = reader.result;
                that.text= ""
                that.showPreview = !that.showPreview;
              };
              reader.readAsDataURL(blob);
            } else {
              let reader = new FileReader();
              reader.onloadend = function () {
                that.text = reader.result;
                that.src= ""
                that.showPreview = !that.showPreview;
              };
              reader.readAsText(blob);
            }
          } else {
            saveFile(data, headers, item.name);
          }
        } else {
          saveFile(data, headers, item.name);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC1003, status);
      }
    },
  },
};
</script>

<style>
.card-header {
  background-color: #e0e0e0;
}
</style>
