<template>
  <v-list class="overflow-y-auto" max-height="50vh" max-width="400px">
    <v-list-item>
      <v-list-item-action>
        <v-checkbox v-model="checkAll"></v-checkbox>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>全て</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-for="item in listItem" :key="item[id]">
      <v-list-item-action>
        <v-checkbox
          @change="departmentChanged(item)"
          v-model="item[check]"
        ></v-checkbox>
      </v-list-item-action>
      <v-menu open-on-click :close-on-content-click="false" right offset-x>
        <template v-slot:activator="{ on }">
          <v-list-item-content @click="toggle(item.id)" v-on="on">
            <v-tooltip right >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title v-bind="attrs" v-on="on">
                  {{ item[display] }}
                </v-list-item-title>
              </template>
              <span> {{ item[display] }}</span>
            </v-tooltip>
          </v-list-item-content>
        </template>
        <GroupItemCheckList
          display="name"
          id="id"
          check="check"
          :checkAllDepartment="checkAll"
          v-if="item.groups && open && item.id === departmentSelected.id"
          :items="item.groups"
          :parent="departmentSelected"
          @groupChanged="groupChanged($event)"
        ></GroupItemCheckList>
      </v-menu>

      <v-list-item-action v-if="item.groups">
        <v-menu open-on-click :close-on-content-click="false" right offset-x>
          <template v-slot:activator="{ on }">
            <v-btn @click="toggle(item.id)" v-on="on" icon>
              <v-icon>mdi-menu-right</v-icon>
            </v-btn>
          </template>
          <GroupItemCheckList
            display="name"
            id="id"
            check="check"
            class="group"
            :checkAllDepartment="checkAll"
            v-if="item.groups && open && item.id === departmentSelected.id"
            :items="item.groups"
            :parent="departmentSelected"
            @groupChanged="groupChanged($event)"
          ></GroupItemCheckList>
        </v-menu>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>
<script>
import { isEmpty } from "@/helpers/helpers";
import GroupItemCheckList from "./GroupItemCheckList.vue";
export default {
  name: "DepartmentCheckList",
  components: { GroupItemCheckList },
  model: {
    prop: "departments",
    event: "departmentUpdated",
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    departments: Array,
    groupSelecteds: Array,
    display: String,
    check: String,
    id: String,
    value: Boolean,
  },
  data() {
    return {
      groups: [],
      open: false,
      departmentSelected: {},
      dirty: false,
      listItem: [],
    };
  },
  watch: {
    items: {
      immediate: true,
      handler(val) {
        this.listItem = val;
      },
    },
    listItem: {
      deep: true,
      handler(val) {
        this.$emit("departmentUpdated", val);
      },
    },
  },
  computed: {
    checkAll: {
      // getter
      get: function () {
        return !this.listItem.some((x) => !x[this.check]);
      },
      // setter
      set: function (newValue) {
        this.listItem = this.listItem.map((x) => {
          if (!isEmpty(x.groups)) {
            x.groups = x.groups.map((s) => {
              return {
                ...s,
                [this.check]: newValue,
              };
            });
          }
          return {
            ...x,
            [this.check]: newValue,
          };
        });
      },
    },
  },
  methods: {
    toggle(departmentId) {
      this.open = true;
      this.departmentSelected = this.items.find((x) => x.id === departmentId);
    },

    groupChanged(value) {
      let departmentItem = this.listItem.find(
        (x) => x.id === value.department.id
      );
      if (value.groups.some((x) => x[this.check])) {
        departmentItem[this.check] = true;
      } else {
        departmentItem[this.check] = false;
      }
      departmentItem.groups = value.groups;
    },

    departmentChanged(item) {
      let departmentItem = this.listItem.find((x) => x.id === item.id);
      departmentItem.groups = departmentItem?.groups.map((x) => {
        return {
          ...x,
          [this.check]: item[this.check],
        };
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.department {
  position: relative;
}
.group {
  width: 200px;
}
</style>
