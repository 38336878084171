export const yyyyMMdd1 = "yyyyMMdd";
export const yyyyMMdd2 = "yyyy-MM-dd";
export const yyyyMMdd3 = "yyyy年MM月dd日";
export const yyyyMMdd4 = "yyyy/M/d";

export const yyyyMM1 = "yyyyMM";
export const yyyyMM3 = "yyyy年MM月";

export const yyyyMMddHHmm1= "yyyy-MM-dd HH:mm";
export const yyyyMMddHHmm2 = "yyyy年MM月dd日 HH:mm";
export const yyyyMMddHHmm3 = "yyyy-MM-dd HHmm";

export const yyyyMMddHHmmss1= "yyyy-MM-dd HHmmss";
export const yyyyMMddHHmmss2 = "yyyy-MM-dd HH:mm:ss";
export const yyyyMMddHHmmss3 = "yyyyMMddHHmmss";
export const yyyyMMddHHmmss4 = "yyyyMMdd HHmmss";

export const yyyyMMddEEEEE3 = "yyyy年MM月dd日(EEEEE)";
export const MMddEEEEE = "M/d (EEEEE)";
export const MMddEEEEE2 = "MM/dd(EEEEE)";
export const yyyyMMddEEEEE4 = "yyyy/M/d(EEEEE)";
export const yyyyMMddHHmmEEEEE2 = "yyyy年MM月dd日(EEEEE) HH:mm";

export const HHmm1= "HHmm";
export const HHmm2= "HH:mm";
export const HHmm3= "HHmmss";
export const HHmm4= "HH:mm:ss";

export const HHmmss1= "HHmmss";
export const HHmmss2= "HH:mm:ss";