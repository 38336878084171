<template>
  <v-dialog
    v-model="openRangeDate"
    persistent
    content-class="range-date-dialog"
    max-width="400px"
    scrollable
  >
    <v-card>
      <v-card-title class="card-header py-2">
        <v-btn @click="close()" class="ml-auto" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="card-content">
        <v-row class="d-flex align-center">
          <v-col cols="4"> 開始年月日 </v-col>
          <v-col cols="8">
            <v-menu
              bottom
              left
              v-model="openStartDate"
              :close-on-content-click="false"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formatStartDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="range.start_date"
                @input="openStartDate = false"
                :min="minStartDay"
                :day-format="(date) => new Date(date).getDate()"
                :max="range.end_date"
                scrollable
                locale="ja"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center">
          <v-col cols="4"> 終了年月日 </v-col>
          <v-col cols="8">
            <v-menu
              bottom
              left
              v-model="openEndDate"
              :disabled="!range.start_date"
              :close-on-content-click="false"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :disabled="!range.start_date"
                  :value="formatEndDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="range.end_date"
                @input="openEndDate = false"
                :min="range.start_date"
                :max="maxDate"
                :day-format="(date) => new Date(date).getDate()"
                scrollable
                locale="ja"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn class="text-change-month" @click="currentMonth">当月</v-btn>
        <v-btn class="text-change-month" @click="previousMonth">前月</v-btn>
        <v-btn
          class="text-date"
          depressed
          outlined
          color="light"
          @click="closeRange"
        >
          キャンセル
        </v-btn>
        <v-btn
          class="text-btn"
          color="primary"
          @click="submitRange"
          :disabled="!range.start_date || !range.end_date"
          depressed
        >
          表示
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDateTime, parseDateTime } from "@/helpers/datetime-helpers";
import { yyyyMMdd2, yyyyMMdd3 } from "@/constants/df";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {},
    //
    show: Boolean,
  },
  watch: {
    show: {
      handler() {
        this.openRangeDate = true;
        document.getElementById("app").style.position = "fixed";
      },
    },
    value: {
      handler(value) {
        this.range = {
          start_date: value.start_date,
          end_date: value.end_date,
        };
      },
    },
    "range.start_date": {
      handler(val) {
        if (
          formatDateTime(this.range.end_date, yyyyMMdd2) <
          formatDateTime(val, yyyyMMdd2)
        ) {
          this.range.end_date = val;
        }
      },
    },
  },
  computed: {
    formatStartDate() {
      return formatDateTime(this.range.start_date, yyyyMMdd3);
    },
    formatEndDate() {
      return formatDateTime(this.range.end_date, yyyyMMdd3);
    },
    maxDate() {
      // let date = parseDateTime(this.range.start_date, yyyyMMdd2);
      // if (
      //   +formatDateTime(date.setMonth(date.getMonth() + 12), yyyyMMdd1) >
      //   +formatDateTime(new Date(), yyyyMMdd1)
      // ) {
      //   return formatDateTime(new Date(), yyyyMMdd2);
      // } else {

      let date = parseDateTime(this.range.start_date, yyyyMMdd2);
      return formatDateTime(date.setMonth(date.getMonth() + 3), yyyyMMdd2);
      // }
    },
    toDay() {
      return formatDateTime(new Date(), yyyyMMdd2);
    },
    minStartDay() {
      let date = parseDateTime(this.range.end_date, yyyyMMdd2);
      return formatDateTime(date.setMonth(date.getMonth() - 3), yyyyMMdd2);
    },
  },
  data() {
    return {
      range: {
        start_date: this.value.start_date,
        end_date: this.value.end_date,
      },
      openRangeDate: false,
      openStartDate: false,
      openEndDate: false,
    };
  },
  methods: {
    formatDateTime,
    close() {
      this.openRangeDate = false;
      document.getElementById("app").style.position = "unset";
    },
    closeRange() {
      // this.range = {
      //   start_date: null,
      //   end_date: null,
      // }
      this.close();
    },
    submitRange() {
      this.$emit("change", this.range);
      this.close();
    },
    changeRangeDate(date, isCurrent) {
      let y = date.getFullYear(),
        m = isCurrent ? date.getMonth() : date.getMonth() - 1;
      let firstDay = new Date(y, m, 1);
      let lastDay = new Date(y, m + 1, 0);
      this.range = {
        start_date: formatDateTime(firstDay, yyyyMMdd2),
        end_date: formatDateTime(lastDay, yyyyMMdd2),
      };
    },
    previousMonth() {
      let startDate = parseDateTime(this.range.start_date, yyyyMMdd2);
      this.changeRangeDate(startDate, false);
    },
    currentMonth() {
      this.changeRangeDate(new Date(), true);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .range-date-dialog {
  min-width: 350px;
}
.text-change-month {
  font-size: 16px;
}
.text-date {
  font-size: 14px;
  color: #7f7f7f;
}

.text-btn {
  font-size: 14px;
}
</style>
