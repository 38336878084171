import {
  HHmm1,
  HHmm3,
  HHmm4,
  yyyyMMddHHmm1,
  yyyyMMddHHmmss1,
  yyyyMMddHHmmss2,
  yyyyMMddHHmmss3,
  HHmmss1,
  HHmmss2
} from "@/constants/df";
import {
  formatDateTime,
  parseDateTime,
  formatTime,
  toMinute
} from "@/helpers/datetime-helpers";
import { adjustMinEnd, adjustMinStart } from "@/helpers/system";
const DEFAULT_SLOT_MIN_TIME_HHMMSS2 = '08:00:00'
const DEFAULT_SLOT_MIN_TIME_HHMMSS1 = '080000'
const DEFAULT_SLOT_MAX_TIME_HHMMSS2 = '21:00:00'
const DEFAULT_SLOT_MAX_TIME_HHMMSS1 = '210000'

export const calendarMixin = {
  methods: {
    /**
     * Get task min of Calendar
     * @return {object}
     */
    getTaskMinValue(tasks) {
      const [first] = tasks;
      const task = tasks.reduce((acc, value) => {
        return +acc.start_time >= +value.start_time ? value : acc;
      }, first);

      return task
    },

    /**
     * Get task max of Calendar
     * @return {object}
     */
    getTaskMaxValue(tasks) {
      const [first] = tasks;
      const task = tasks.reduce((acc, value) => {
        return +acc.end_time >= +value.end_time ? acc : value;
      }, first);

      return task
    },
    
    isRegisteredTasksInside13h(minValue, maxValue){
      const min = minValue.start_time // HHmmss
      const max = maxValue.end_time // HHmmss

      return max - min <= 130000
    },

    /**
     * Get slot min time calendar print
     * @return {String}
     */
    getSlotMinTimeModePrint(calendarOptions, minValue, maxValue, isRegisteredTasksInside13h) {
      // Default 08:00:00
      if (!minValue.start) return DEFAULT_SLOT_MIN_TIME_HHMMSS2;
      
      // inside 13h
      if (isRegisteredTasksInside13h) {
        if(minValue.start_time >= 110000 && maxValue.end_time >= 230000) {
          return '11:00:00'
        }
        // >= 08:00:00 && max <= 21:00:00
        if (minValue.start_time >= DEFAULT_SLOT_MIN_TIME_HHMMSS1 && maxValue.end_time <= DEFAULT_SLOT_MAX_TIME_HHMMSS1) {
          return DEFAULT_SLOT_MIN_TIME_HHMMSS2
        }
        // >= 08:00:00 && max > 21:00:00
        if (minValue.start_time >= DEFAULT_SLOT_MIN_TIME_HHMMSS1 && maxValue.end_time > DEFAULT_SLOT_MAX_TIME_HHMMSS1) {
          let maxHHMMSS1 = this.getMaxMinutesBySlotDuration(calendarOptions.slotDuration, maxValue.end_time)
          maxHHMMSS1 = maxHHMMSS1.replaceAll(':', '')
          const minHHMMSS1 = (maxHHMMSS1 - 130000).toString().padStart(6, '0')
          return this.getMinMinutesBySlotDuration(calendarOptions.slotDuration, minHHMMSS1);
        }
        // < 08:00:00
        if (minValue.start_time < DEFAULT_SLOT_MIN_TIME_HHMMSS1) {
          return this.getMinMinutesBySlotDuration(calendarOptions.slotDuration, minValue.start_time);
        }
      }
      // outside 13h
      return this.getMinMinutesBySlotDuration(calendarOptions.slotDuration, minValue.start_time);
    },

    /**
     * Get min minutes by slot duration 
     * @return {String HH:mm:ss}
     */
    getMinMinutesBySlotDuration(slotDuration, startTime) {
      const minDt = this.parseDt(startTime, HHmmss1)
      const hours = this.timeToStrTime(minDt.getHours());
      const minutes = this.timeToStrTime(minDt.getMinutes());

      switch (slotDuration) {
        case "00:05:00":
        case "00:15:00":
          if (+minutes >= 45) return `${hours}:45:00`
          else if (+minutes >= 30) return `${hours}:30:00`
          else if (+minutes >= 15) return `${hours}:15:00`
          else return `${hours}:00:00`
        case "00:30:00":
        case "00:60:00":
          return `${hours}:00:00`
        default:
          break;
      }
    },

    /**
     * Get slot max time calendar print
     * @return {String}
     */
    getSlotMaxTimeModePrint(calendarOptions, minValue, maxValue, isRegisteredTasksInside13h) {
      // Default 21:00:00
      if (!maxValue.end) return DEFAULT_SLOT_MAX_TIME_HHMMSS2;
      if (calendarOptions.slotMinTime === '11:00:00') {
        return '24:00:00';
      }

      // inside 13h
      if (isRegisteredTasksInside13h) {
        // max <= 21:00:00 && min >= 08:00:00 
        if (maxValue.end_time <= DEFAULT_SLOT_MAX_TIME_HHMMSS1 && minValue.start_time >= DEFAULT_SLOT_MIN_TIME_HHMMSS1) {
          return DEFAULT_SLOT_MAX_TIME_HHMMSS2
        }
        // max <= 21:00:00 && < 08:00:00
        if (maxValue.end_time <= DEFAULT_SLOT_MAX_TIME_HHMMSS1 && minValue.start_time < DEFAULT_SLOT_MIN_TIME_HHMMSS1) {
          let minHHMMSS1 = this.getMinMinutesBySlotDuration(calendarOptions.slotDuration, minValue.start_time)
          minHHMMSS1 = minHHMMSS1.replaceAll(':', '')

          const maxHHMMSS1 = (parseInt(minHHMMSS1) + 130000).toString().padStart(6, '0')
          return this.getMaxMinutesBySlotDuration(calendarOptions.slotDuration, maxHHMMSS1);
        }
        // max > 21:00:00 
        if (maxValue.end_time > DEFAULT_SLOT_MAX_TIME_HHMMSS1) {
          return this.getMaxMinutesBySlotDuration(calendarOptions.slotDuration, maxValue.end_time);
        }
      }
      // outside 13h
      return this.getMaxMinutesBySlotDuration(calendarOptions.slotDuration, maxValue.end_time);
    },

    /**
     * Get slot max time calendar
     * @return {String HH:mm:ss}
     */
    getMaxMinutesBySlotDuration(slotDuration, endTime) {
      const maxDt = this.parseDt(endTime, HHmmss1)
      const hours = maxDt.getHours();
      const minutes = maxDt.getMinutes();
      switch (slotDuration) {
        case "00:05:00":
        case "00:15:00":
          if (hours === 23 && +minutes > 45) {
            return '24:00:00'
          } else {
            if (minutes > 45) return `${this.timeToStrTime(+hours + 1)}:00:00`
            else if (minutes > 30) return `${this.timeToStrTime(hours)}:45:00`
            else if (minutes > 15) return `${this.timeToStrTime(hours)}:30:00`
            else if (minutes > 0) return `${this.timeToStrTime(hours)}:15:00`
            else return `${this.timeToStrTime(hours)}:00:00`
          }
        case "00:30:00":
          if (hours === 23 && +minutes > 30) {
            return '24:00:00'
          } else {
            if (minutes > 30) return `${this.timeToStrTime(hours + 1)}:00:00`
            else if (minutes > 0) return `${this.timeToStrTime(hours)}:30:00`
            else return `${this.timeToStrTime(hours)}:00:00`
          }
        case "00:60:00":
          if (+hours === 23 && +minutes > 0) {
            return '24:00:00'
          } else {
            if (+minutes > 0) return `${this.timeToStrTime(hours + 1)}:00:00`
            else return `${this.timeToStrTime(hours)}:00:00`
          }
        default:
          break;
      }
    },

    /**
     * Get timegrid slot by duration of Calendar
     * @return {object}
     */
    getTimeGridSlotByDuration(slotDuration, slotMinTime, slotMaxTime) {
      const minTimeToTime = parseDateTime(
        `20200101${slotMinTime}`,
        "yyyyMMddHH:mm:ss"
      ).getTime() || 0

      const maxTimeToTime = (slotMaxTime === '24:00:00') ? parseDateTime(
        "20200102000000",
        "yyyyMMddHHmmss"
      ).getTime() : parseDateTime(
        `20200101${slotMaxTime}`,
        "yyyyMMddHH:mm:ss"
      ).getTime() || 0

      const calcTimegridLenght = maxTimeToTime - minTimeToTime
      switch (slotDuration) {
        case "00:05:00":
        case "00:15:00":
          return calcTimegridLenght / (15 * 60 * 1000)
        case "00:30:00":
          return calcTimegridLenght / (30 * 60 * 1000)
        default: // case "00:60:00"
          return calcTimegridLenght / (60 * 60 * 1000)
      }
    },

    /**
     * get Min slot print
     * @return {Number}
     */
    getMinSlotByDurationModePrint(slotDuration, timeGridLength = 0) {
      switch (slotDuration) {
        case "00:05:00":
        case "00:15:00":
          switch (true) {
            case timeGridLength <= 52:
              return 15;
            case timeGridLength < 76:
              return 10
            default:
              return 15;
          }
        case "00:30:00":
          switch (true) {
            case timeGridLength < 30:
              return 15
            case timeGridLength < 38:
              return 20
            default:
              return 30;
          }
        case "00:60:00":
          switch (true) {
            case timeGridLength < 15:
              return 15
            case timeGridLength < 19:
              return 20
            default:
              return 30;
          }
        default:
          return 15
      }
    },

    /**
     * Get slot duration
     * @return {String}
     */
    getSlotDuration(slotDuration) {
      switch (slotDuration) {
        case "00:05:00":
        case "00:15:00":
          return "00:15:00"
        default:
          return slotDuration
      }
    },

    /**
     * Convert string to datetime
     * @param timeStr : HHmmss
     * @return {DateTime}
     */
    convertStringToDateTime(timeStr) {
      return parseDateTime(`20200101${timeStr}`, "yyyyMMddHHmmss")
    },

    /**
     * get end time actualTask
     * @return {String : YYYY-MM-DD HH:mm:ss}
     */
    getEndTimeActualTask(event, selectedDate, minSlot) {
      let date = selectedDate
      if (!event.isPlan) {
        date = formatDateTime(new Date(selectedDate).setDate(new Date(selectedDate).getDate() + 1), 'yyyy-MM-dd')
      }

      const startTime = (!event.start_time) ? formatDateTime(
        parseDateTime(event.start, yyyyMMddHHmm1),
        HHmm3
      ) : event.start_time
      const endTime = (!event.end_time) ? formatDateTime(
        parseDateTime(event.end_real, yyyyMMddHHmm1),
        HHmm3
      ) : event.end_time

      return formatDateTime(
        parseDateTime(
          date +
          " " +
          adjustMinEnd(
            startTime,
            endTime,
            HHmmss1,
            minSlot
          ), // adjustMinEnd HHmmss1
          yyyyMMddHHmmss1
        ),
        yyyyMMddHHmmss2
      )
    },

    /**
     * Change slot min max view calendar
     * @return {void}
     */
    changeSlotMinMax(calendarOptions, tasks, startWorkTime, endWorkTime) {
      // Default 08:00:00 ~ 22:00:00
      calendarOptions.slotMinTime = DEFAULT_SLOT_MIN_TIME_HHMMSS2;
      calendarOptions.slotMaxTime = "22:00:00";
      if (startWorkTime && calendarOptions.slotMinTime > formatTime(startWorkTime, HHmm4)){
        calendarOptions.slotMinTime = formatTime(startWorkTime, HHmm4);
      }

      if (endWorkTime && calendarOptions.slotMaxTime < formatTime(endWorkTime, HHmm4)){
        calendarOptions.slotMaxTime = formatTime(endWorkTime, HHmm4);
      }
      if (tasks.length === 0) return
      // Set slotMinTime
      const minValue = this.getTaskMinValue(tasks);
      if (minValue?.start
        && typeof minValue.start === 'string'
        && formatDateTime(minValue.start, HHmm4) < calendarOptions.slotMinTime
      ) {
        calendarOptions.slotMinTime = this.getSlotMinTime(
          calendarOptions.slotDuration,
          minValue.start
        );
      }

      // Set slotMaxTime
      const maxValue = this.getTaskMaxValue(tasks);
      if (maxValue?.end
        && typeof maxValue.end === 'string'
        && formatDateTime(maxValue.end, HHmm4) > calendarOptions.slotMaxTime
      ) {

        calendarOptions.slotMaxTime = this.getSlotMaxTime(
          calendarOptions.slotDuration,
          maxValue.end
        );
      }
    },
    isNoTask(calendarOptions) {
      if (calendarOptions.events.length === 0) return true
      return false
    },
     /**
     * Change slot min max view calendar
     * @return {void}
     */
    changeSlotMinMaxModePrint(calendarOptions) {
      if (this.isNoTask(calendarOptions)) {
        // Default 08:00:00 ~ 21:00:00
        calendarOptions.slotMinTime = DEFAULT_SLOT_MIN_TIME_HHMMSS2
        calendarOptions.slotMaxTime = DEFAULT_SLOT_MAX_TIME_HHMMSS2
        return
      }
      
      const minValue = this.getTaskMinValue(calendarOptions.events);
      const maxValue = this.getTaskMaxValue(calendarOptions.events);

      // check inside 13h
      const isRegisteredTasksInside13h = this.isRegisteredTasksInside13h(minValue, maxValue)

      // Set slotMinTime
      calendarOptions.slotMinTime = this.getSlotMinTimeModePrint(
        calendarOptions,
        minValue,
        maxValue,
        isRegisteredTasksInside13h
      );
      // Set slotMaxTime
      calendarOptions.slotMaxTime = this.getSlotMaxTimeModePrint(
        calendarOptions,
        minValue,
        maxValue,
        isRegisteredTasksInside13h
      );
    },

    /**
     * Get slot min time in view calendar
     * @return {String}
     */
    getSlotMinTime(slotDuration, startTime) {
      const totalMinute = toMinute(formatDateTime(startTime, HHmm1));
      const hours = Math.floor(totalMinute / 60);
      const minutes = totalMinute % 60;
      if (slotDuration === "00:05:00") {
        if (minutes % 15 === 0) {
          return formatDateTime(startTime, HHmm4);
        } else {
          if (minutes > 45) {
            return hours >= 10 ? `${hours}:45:00` : `0${hours}:45:00`;
          } else if (minutes > 30) {
            return hours >= 10 ? `${hours}:30:00` : `0${hours}:30:00`;
          } else if (minutes > 15) {
            return hours >= 10 ? `${hours}:15:00` : `0${hours}:15:00`;
          } else {
            return hours >= 10 ? `${hours}:00:00` : `0${hours}:00:00`;
          }
        }
      } else if (slotDuration === "00:15:00") {
        if (minutes % 30 === 0) {
          return formatDateTime(startTime, HHmm4);
        } else {
          if (minutes > 30) {
            return hours >= 10 ? `${hours}:30:00` : `0${hours}:30:00`;
          } else {
            return hours >= 10 ? `${hours}:00:00` : `0${hours}:00:00`;
          }
        }
      } else if (slotDuration === "00:30:00" || slotDuration === "00:60:00") {
        return hours >= 10 ? `${hours}:00:00` : `0${hours}:00:00`;
      }
    },

    /**
     * Get slot max time in view calendar
     * @return {String}
     */
    getSlotMaxTime(slotDuration, endTime) {
      const totalMinute = toMinute(formatDateTime(endTime, HHmm1));
      const hours = Math.floor(totalMinute / 60);
      const minutes = totalMinute % 60;
      if (slotDuration === "00:05:00") {
        if (minutes % 15 === 0) {
          return formatDateTime(endTime, HHmm4);
        } else {
          if (minutes > 45) {
            return hours >= 10 ? `${hours + 1}:00:00` : `0${hours + 1}:00:00`;
          } else if (minutes > 30) {
            return hours >= 10 ? `${hours}:45:00` : `0${hours}:45:00`;
          } else if (minutes > 15) {
            return hours >= 10 ? `${hours}:30:00` : `0${hours}:30:00`;
          } else {
            return hours >= 10 ? `${hours}:15:00` : `0${hours}:15:00`;
          }
        }
      } else if (slotDuration === "00:15:00") {
        if (minutes % 30 === 0) {
          return formatDateTime(endTime, HHmm4);
        } else {
          if (minutes > 30) {
            return hours >= 10 ? `${hours + 1}:00:00` : `0${hours + 1}:00:00`;
          } else {
            return hours >= 10 ? `${hours}:30:00` : `0${hours}:30:00`;
          }
        }
      } else if (slotDuration === "00:30:00" || slotDuration === "00:60:00") {
        if (minutes === 0) {
          return formatDateTime(endTime, HHmm4);
        } else {
          return hours >= 10 ? `${hours + 1}:00:00` : `0${hours + 1}:00:00`;
        }
      }
    },

    /**
     * get adjust start of task
     * @return {String : YYYY-MM-DD HH:mm:ss}
     */
    getAdjustStartOfTask(task, selectedDate, minSlot) {
      return formatDateTime(
        parseDateTime(
          selectedDate +
          " " +
          adjustMinStart(
            task.start_time,
            task.end_time,
            HHmmss1,
            minSlot
          ),
          yyyyMMddHHmmss1
        ),
        yyyyMMddHHmmss2
      )
    },

    /**
     * get adjust end of task
     * @return {String : YYYY-MM-DD HH:mm:ss}
     */
    getAdjustEndOfTask(task, selectedDate, minSlot) {
      return formatDateTime(
        parseDateTime(
          selectedDate +
          " " +
          adjustMinEnd(
            task.start_time,
            task.end_time,
            HHmmss1,
            minSlot
          ),
          yyyyMMddHHmmss1
        ),
        yyyyMMddHHmmss2
      )
    },

    /**
     * get end of task
     * @return {String : YYYY-MM-DD HH:mm:ss}
     */
    getEndOfTask(task, selectedDate) {
      return formatDateTime(
        parseDateTime(
          selectedDate + " " + task.end_time,
          yyyyMMddHHmmss1
        ),
        yyyyMMddHHmmss2
      )
    },

    /**
     * Get slot min time in view calendar
     * @param {date : DateTime}
     * @return {String}
     */
    fmtDateToString(date, fmt = 'yyyy-MM-dd HH:mm:ss') {
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      const seconds = date.getSeconds().toString().padStart(2, '0')

      if (fmt === 'yyyy-MM-dd HH:mm:ss') {
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      }

      throw 'date is invalid';
    },

    getTimeForScroll(calendarOptions, startWorkTime) {
      // const DEFAULT_SLOT_MIN_TIME_HHMMSS2 = "08:00:00"

      let events = calendarOptions.events.filter(
        (x) => x.title !== "休憩"
      );
      if (events && events.length > 0) {
        const [first] = events;
        const minValue = calendarOptions.events.reduce((acc, value) => {
          return +acc.start_time >= +value.start_time ? value : acc;
        }, first);
        if (
          +formatDateTime(minValue.start, HHmm1) <
          +formatDateTime(
            parseDateTime("20200101" + startWorkTime, "yyyyMMddHHmmss"),
            HHmm1
          )
        ) {
          return formatDateTime(minValue.start, HHmm4);
        } else {
          return formatTime(startWorkTime, HHmm4);
        }
      } else {
        return formatTime(startWorkTime, HHmm4);
      }
    },

    timeToStrTime(number, numberOfPad = 2) {
      return number.toString().padStart(numberOfPad, '0')
    },

    parseDt(valueTime, type = HHmmss1) {
      switch (type) {
        case HHmmss1:
          return parseDateTime(`20200101${valueTime}`, yyyyMMddHHmmss3)
        case HHmmss2:
          return parseDateTime(`2020-01-01 ${valueTime}`, yyyyMMddHHmmss2)
        default:
          return;
      }
    },

    isBreakLineTaskContent(cost, count) {
      return cost * count >= 30
    }
  },
}