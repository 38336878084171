<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text color="black--text" v-bind="attrs" v-on="on">
        {{ selectedCategory.category_primary_name }}
        <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <v-list class="overflow-y-auto" max-height="60vh">
      <v-list-item
        :class="[
          {
            active:
              item.category_primary_id === selectedCategory.category_primary_id,
          },
        ]"
        @click="change(item)"
        v-for="(item, index) in categories"
        :key="index"
      >
        <v-list-item-title>{{ item.category_primary_name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    selectetedObject: Object,
  },
  data: () => ({
    selectedCategory: {},
  }),
  watch:{
    selectetedObject(newValue){
      this.selectedCategory = newValue
    }
  },
  methods: {
    change(item) {
      this.selectedCategory = item;
      this.$emit("change", this.selectedCategory);
    },
  },
  mounted(){
    this.selectedCategory = this.selectetedObject
  }
};
</script>
<style lang="scss" >
.active {
  background-color: gray;
}
</style>
