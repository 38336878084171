import axios from 'axios';
import * as types from '../mutation-types';

// state
export const state = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  authInfo: localStorage.getItem('authInfo') ?
    JSON.parse(localStorage.getItem('authInfo')) :
    {
      form: {
        remember: false
      }
    },
  token: localStorage.getItem('token')
};

// getters
export const getters = {
  user: state => state.user ?? localStorage.getItem('user'),
  authInfo: state => state.authInfo,
  isRemember: state => state.authInfo.form.remember,
  token: state => state.token,
  check: state => state.user !== null,
  expired: state => state.expired
};

// mutations
export const mutations = {
  [types.SAVE_TOKEN](state, {
    token,
    user,
    form,
    remember,
    expired
  }) {
    state.token = token;
    state.user = user;
    state.authInfo = {
      form: form,
      expired: expired
    };
    state.expired = expired;

    if (remember) {
      let setCookies = true;
      if (setCookies) {
        localStorage.setItem('authInfo', JSON.stringify({
          form: {
            email: form.email,
            remember: remember
          }
        })
        );
      }
      localStorage.setItem('emailInfo', JSON.stringify({
        form: {
          email: form.email,
          remember: true
        }
      })
      );
    } else {
      localStorage.setItem(
        'authInfo', JSON.stringify({
        form: {
          remember: false
        }
      })
      );
    }

    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
    localStorage.setItem('expired', expired);
  },

  [types.SAVE_REMEMBER_TOKEN](state, {
    token
  }) {
    state.token = token;
    localStorage.setItem('token', token);
  },

  [types.FETCH_USER_SUCCESS](state, {
    user
  }) {
    state.user = user;
  },

  [types.FETCH_USER_FAILURE](state) {
    state.token = null;
    state.expired = null;
    localStorage.removeItem('token');
    localStorage.removeItem('expired');
  },

  [types.LOGOUT](state) {
    state.user = null;
    state.token = null;
    state.expired = null;
    state.authInfo = {
      form: {
        remember: false
      }
    };
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('expired');
  },

  [types.UPDATE_USER](state, {
    user
  }) {
    state.user = user;
  }
};

// actions
export const actions = {
  saveToken({
    commit
  }, payload) {
    commit(types.SAVE_TOKEN, payload);
  },

  saveRememberToken({
    commit
  }, payload) {
    commit(types.SAVE_REMEMBER_TOKEN, payload);
  },

  updateUser({
    commit
  }, payload) {
    commit(types.UPDATE_USER, payload);
  },

  async logout({
    commit
  }) {
    commit(types.LOGOUT);

  },

  async fetchOauthUrl(ctx, {
    provider
  }) {
    const {
      data
    } = await axios.post(`/api/oauth/${provider}`);

    return data.url;
  }
};
