<template>
  <v-dialog :value="value" @input="$emit('input')" max-width="300">
    <v-card>
      <v-list>
        <v-list-item-group>
          <v-list-item class="align-center"> タグを付ける </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="checkAll"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>全て</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="item in listItem" :key="item.id">
            <v-list-item-action>
              <v-checkbox
                :disabled="isDisabled(item)"
                v-model="item.check"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-title v-bind="attrs" v-on="on">{{
                    item.name
                  }}</v-list-item-title>
                </template>
                <span>{{ item.name }}</span>
              </v-tooltip>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu right offset-x top :nudge-width="50">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon class="grey--text">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="updateTag(item)">
                    <v-list-item-title>編集</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="delTag(item)">
                    <v-list-item-title>削除 </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="align-center" v-if="!oneCheck" @click="newTag">新規作成</v-list-item>
          <v-list-item class="align-center" v-if="oneCheck" @click="selectTag">適用</v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import { TAG_API } from "../../../constants/api";
import axios from "axios";
import { mapGetters } from "vuex";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC814, FC815 } from "../../../constants/fc";
import { debounce_leading } from '@/helpers/helpers';
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    existItems: {
      type: Array,
      default: () => [],
    },
    value: Boolean,
    userId: String,
  },
  data() {
    return {
      listItem: [],
    };
  },
  created() {
    this.listItem = this.items;
  },
  watch: {
    items: {
      handler(val) {
        this.listItem = val;
      },
    },
  },
  methods: {
    selectTag: debounce_leading(function() {
      let items = this.listItem.filter(
        (x) => x.check && !this.existItems.some((y) => y.tag_id === x.id)
      );
      if (items.length > 0) {
        this.$emit(
          "selectTag",
          this.listItem.filter(
            (x) => x.check && !this.existItems.some((y) => y.tag_id === x.id)
          )
        );
      } else {
        this.$emit("input");
      }
    }),
    reset() {},
    async delTag(tag) {
      this.$confirm(
        [
          this.$t("label.confirm.delete_tag.line1"),
          this.$t("label.confirm.delete_tag.line2"),
        ],
        {
          buttons: {
            ok: {
              text: this.$t("label.buttons.delete"),
              type: "primary",
            },
          },
          rtl: true,
        }
      )
        .then(async () => {
          try {
            await axios.delete(TAG_API(tag.id));
            this.listItem = this.listItem.filter((x) => x.id !== tag.id);
            this.$emit("deleteTag", tag);
            this.$root.$refs.scheduleDbList?.removeTag(tag);
            this.$root.$refs.scheduleDbTabList?.removeTag(tag);
            this.$root.$refs.scheduleTodoList?.removeTag(tag);
            this.$root.$refs.scheduleTodoTabList?.removeTag(tag);
            this.$root.$refs.scheduleFavoriteList?.removeTag(tag);
            this.$root.$refs.scheduleFavoriteTabList?.removeTag(tag);
            this.$root.$refs.actualDbList?.removeTag(tag);
            this.$root.$refs.actualDbTabList?.removeTag(tag);
            this.$root.$refs.actualTodoList?.removeTag(tag);
            this.$root.$refs.actualTodoTabList?.removeTag(tag);
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC815, status);
          }
        })
        .catch(() => {
          return;
        });
    },
    async newTag() {
      this.$prompt("新しいタグ名を入力", {
        maxlength: 64,
        validations: [(v) => !!v || this.$t("label.error.required")],
      })
        .then(async (x) => {
          try {
            const { data } = await axios.post(TAG_API(), {
              name: x,
              user_id: this.user.user_id,
            });
            this.listItem.push({ name: x, id: data.result.id });
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC814, status);
          }
        })
        .catch(() => {});
    },
    async updateTag(tag) {
      this.$prompt("タグ名を編集", {
        maxlength: 64,
        validations: [(v) => !!v || this.$t("label.error.required")],
        defaultValue: tag.name,
        button: {
          ok: {
            text: "保存",
            type: "primary",
          },
        },
      })
        .then(async (x) => {
          try {
            await axios.put(TAG_API(tag.id), {
              name: x,
              user_id: this.user.user_id,
            });
            let t = this.listItem.filter((y) => y.id === tag.id)[0];
            if (t) {
              t.name = x;
            }
            this.$emit("updateTag", t);
            this.$root.$refs.scheduleDbList?.editTag(t);
            this.$root.$refs.scheduleDbTabList?.editTag(t);
            this.$root.$refs.scheduleTodoList?.editTag(t);
            this.$root.$refs.scheduleTodoTabList?.editTag(t);
            this.$root.$refs.scheduleFavoriteList?.editTag(t);
            this.$root.$refs.scheduleFavoriteTabList?.editTag(t);

            this.$root.$refs.actualDbList?.editTag(t);
            this.$root.$refs.actualDbTabList?.editTag(t);
            this.$root.$refs.actualTodoList?.editTag(t);
            this.$root.$refs.actualTodoTabList?.editTag(t);

            this.openTagDialog = false;
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC814, status);
          }
        })
        .catch(() => {});
    },
    isDisabled(tag) {
      return this.existItems.some((x) => x.tag_id == tag.id);
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    checkAll: {
      // getter
      get: function () {
        return !this.listItem.some((x) => !x.check);
      },
      // setter
      set: function (newValue) {
        this.listItem = this.listItem.map((x) => {
          return {
            name: x.name,
            id: x.id,
            check: newValue,
          };
        });
      },
    },
    oneCheck() {
      return this.listItem.some((x) => x.check);
    },
  },
};
</script>
