export const CALENDAR_PRINT_LENGHT_MM = 275.6 
export const DEFAULT_SCALE = {
  13.25 : 12.5,
  13.5 : 1.5,
  13.75 : 11,

  14 : 12.5,
  14.25 : 12.5,
  14.5 : 1.5,
  14.75 : 11,
  
  15 : -10.5,
  15.25 : -2,
  15.5 : -10,
  15.75 : 0,

  16 : -9.5,
  16.25 : -0.6,
  16.5 : -9.5,
  16.75 : -0.6,

  17 : 7.5,
  17.25 : -2.5,
  17.5 : 5.5,
  17.75 : 12.5,

  18 : 20.5,
  18.25 : 9.5,
  18.5 : 18.5,
  18.75 : 5.5,

  19 : 12,
  19.25 : 1,
  19.5 : 7.5,
  19.75 : 12,

  20 : 0,
  20.25 : 6.5,
  20.5 : 12,
  20.75 : -2.5,

  21 : 5,
  21.25 : 12,
  21.5 : 0, // Not G
  21.75 : 5,

  22 : 9,
  22.25 : -7.5,
  22.5 : 0.5, // Not G
  22.75 : 9, // Not G

  23 : 9.5,
  23.25 : -6.5,
  23.5 : -3,
  23.75 : -17.5, // Not G

  24 : -17.5,
}
export const SLOT_30_SCALE =  {
  26 : 0,
  27 : -11,
  28 : -1,
  29 : -5,
  30 : -5,
  31 : -4.5, 
  32 : -4.5,
  33 : -4.5, 
  34 : -5, 
  35 : -7, 
  36 : -8.5,
  37 : -1,  
  38 : -3.5,
  39 : -7, 
  40 : 0,  
  41 : -3.5, 
  42 : -8.5, 
  43 : -2, 
  44 : -7.5, 
  45 : -1,
  46 : -7,
  47 : -2,
  48 : -8.5,
}
export const SLOT_60_SCALE = {
  13 : 0,
  14 : -1,
  15 : -1.5,
  16 : -1,
  17 : -1,
  18 : -3.5,
  19 : -3.5,
  20 : -5,
  21 : -3.5,
  22 : -7,
  23 : -7.5,
  24 : -3,
}

export const ACTUAL_SLOT_60_SCALE = {
  13 : 0,
  14 : -3,
  15 : -3.5,
  16 : -3,
  17 : -4,
  18 : -3,
  19 : -3.5, 
  20 : -5,
  21 : -3.5, 
  22 : -7.5,
  23 : -7.5,
  24 : -3,
}

export const ACTUAL_SLOT_30_SCALE =  {
  26 : 0,
  27 : -11,
  28 : -2,
  29 : 1,//
  30 : -7.5,
  31 : -7.5, //
  32 : -7.5,
  33 : -8.5, //
  34 : -8.5, 
  35 : -12.5, //
  36 : -12.5,
  37 : -8,  //
  38 : -8,
  39 : -5, //
  40 : -5,  
  41 : -3.5, //
  42 : -3.5, 
  43 : -2.5, //
  44 : -2.5, 
  45 : -2.5, //
  46 : -2.5,
  47 : -3,
  48 : -2.5,
}

export const ACTUAL_DEFAULT_SCALE = {
  13.25 : 0,//
  13.5 : -7,
  13.75 : 0, //

  14 : 12.5, 
  14.25 : 8.5,
  14.5 : 1.5,
  14.75 : 11,
  
  15 : -10.5, 
  15.25 : -2,
  15.5 : -10, 
  15.75 : 0,

  16 : -9.5, 
  16.25 : -0.6,
  16.5 : -9.5,
  16.75 : -0.6,

  17 : 7.5,
  17.25 : -2.5,
  17.5 : 5.5,
  17.75 : 12.5,

  18 : 0, 
  18.25 : 9.5,
  18.5 : -2.5,
  18.75 : 5.5,

  19 : 12,
  19.25 : 0,
  19.5 : 6.5,
  19.75 : 13,

  20 : 0,
  20.25 : 6.5,
  20.5 : 2.5,
  20.75 : -2.5,

  21 : 5,
  21.25 : 12,
  21.5 : -1.5,
  21.75 : 5,

  22 : 10,
  22.25 : -8,
  22.5 : -1.5,
  22.75 : 4,

  23 : 10.5,
  23.25 : -7.5,
  23.5 : -1.5,
  23.75 : 4,

  24 : -17.5,
}
export const ACTUAL_DEFAULT_SCALE_1_PAGE = {
  13 : -7.5, 
}