export const backMixin = {
  methods: {
    addWindowPopstateEvent(url, callback) {
      window.addEventListener('test', callback)
    },
    removeWindowPopstateEvent(callback) {
      window.removeEventListener('test', callback)
    },

    popupUnSave(flg, next, back) {
      if (flg) {
        this.$confirm(this.$t('label.confirm.unsave'), {
            buttons: {
              ok: {
                text: this.$t('label.buttons.discard'),
                type: "primary"
              },
            },
            rtl: true
          })
          .then(() => {
            this.$store.dispatch('save/setChangeFlag', false)
            next()
          })
          .catch(() => {
            back()
          })
      } else {
        next()
      }
    },
  },
}
