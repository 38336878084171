// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const SAVE_REMEMBER_TOKEN = 'SAVE_REMEMBER_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'

// common.js
export const IS_BUSY = 'IS_BUSY'
export const SAVE_LOGIN_INFO = 'SAVE_LOGIN_INFO'
export const REMOVE_LOGIN_INFO = 'REMOVE_LOGIN_INFO'

export const LOADING = 'LOADING'
export const PARTIAL_LOADING = 'PARTIAL_LOADING'
export const SET_MEMBER = 'SET_MEMBER'
export const REMOVE_SELECTED_MEMBER = 'REMOVE_SELECTED_MEMBER'
export const IS_SCHEDULE_EXPAND = 'IS_SCHEDULE_EXPAND'
export const IS_ACTUAL_EXPAND = 'IS_ACTUAL_EXPAND'
export const IS_SHOW_DRAWER = 'IS_SHOW_DRAWER'
