<template>
  <img alt="" src="../../assets/images/pulldown.png">
</template>

<script>
export default {
  name:"PulldownIcon"
};
</script>

<style>
</style>
