<template>
  <v-dialog
    v-model="showDialog"
    width="400"
    :scrollable="$vuetify.breakpoint.mobile"
    content-class="sbreakTask"
    v-dialog-drag="{
      dialog: 'sbreakTask',
    }"
    overlay-color="transparent"
    overlay-opacity="1"
  >
    <v-card v-if="view">
      <!-- <v-card-title class="headline grey lighten-2 mb-2">
        <v-spacer></v-spacer>
        <v-btn icon @click="close()">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title> -->
      <v-card-subtitle class="headline grey lighten-2 py-3 mb-2 text-right">
        <v-btn icon @click="close()">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-subtitle>
      <v-card-text>
        <span class="pl-1"> 休憩 </span>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-text>
        <v-icon small>$taskstartendtime</v-icon>
        <span class="ml-2"
          >{{ form.startTime }}<span>~</span>{{ form.endTime }}</span
        >
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
         v-if="$vuetify.breakpoint.mobile && isAllowDrag()"
          @click="registerBreak()"
          tile
          depressed
          color="primary"
        >
          カレンダーに配置
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <!-- <v-card-title class="headline grey lighten-2 mb-2">
        <v-spacer></v-spacer>
        <v-btn icon @click="close()">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title> -->
      <v-card-subtitle class="headline grey lighten-2 py-3 mb-2 text-right">
       <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="archive"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$archive</v-icon>
            </v-btn>
          </template>
          <span>タスクをアーカイブ</span>
        </v-tooltip>
        <v-btn icon @click="close()">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-subtitle>

      <v-card-text>
        <span class="pl-1">休憩</span>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="2" class="align-self-center">
            <span class="red--text mr-2">*</span>
            <v-icon small> $taskstartendtime </v-icon>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="form.startTime"
              type="text"
              maxlength="5"
              clearable
              :error-messages="startTimeErrors"
              @blur="formatStartTime($event)"
              @keyup.enter.native="formatStartTime($event)"
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="align-self-center"> ～ </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="form.endTime"
              type="text"
              maxlength="5"
              clearable
              :error-messages="endTimeErrors"
              @blur="formatEndTime($event)"
              @keyup.enter.native="formatEndTime($event)"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="submit"
          :disabled="!isEditing"
          depressed
          tile
          color="primary"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { backMixin } from "../../../mixins/back";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  timeValidator,
  compareDateValidator,
} from "../../../helpers/validator";
import {
  toHHmmFormat,
} from "../../../helpers/datetime-helpers";
import { mapGetters } from "vuex";
import { GET_SCHEDULE_API } from "../../../constants/api";
import axios from "axios";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC817 } from "../../../constants/fc";
import { debounce_leading } from "@/helpers/helpers";
import DialogDragDirective from "../../../directives/dialogDrag";
export default {
  mixins: [validationMixin, backMixin],
  directives: {
    "dialog-drag": DialogDragDirective,
  },
  props: {
    start: String,
    end: String,
    planId: String,
    isBreakTask: {type:[Boolean], default:false },
    show: {
      type: Boolean,
      default: false,
    },
    canView: {
      type: Boolean,
      default: false,
    },
    isPlan: {type:[Boolean], default:true },
    isView: {type:[Boolean], default:false },
  },
  watch: {
    start: {
      handler(val) {
        this.form.startTime = val;
        this.orginalForm.startTime = val;
      },
    },
    end: {
      handler(val) {
        this.form.endTime = val;
        this.orginalForm.endTime = val;
      },
    },
    show: {
      handler() {
        this.showDialog = true;
        this.form.endTime = this.end;
        this.form.startTime = this.start;
        this.orginalForm.endTime = this.end;
        this.orginalForm.startTime = this.start;
        this.$v.$reset();
        this.isEditing = false;
        this.$store.dispatch("save/setChangeFlag", false);
      },
    },
    form: {
      deep: true,
      handler(val) {
        this.isEditing =
          JSON.stringify(val) !== JSON.stringify(this.orginalForm);
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    canView: {
      handler(val) {
        this.view = val;
      },
    },
  },
  validations: {
    form: {
      startTime: { required, timeValidator },
      endTime: {
        required,
        timeValidator,
        compareDate: compareDateValidator("startTime"),
      },
    },
  },

  computed: {
    ...mapGetters("save", ["getChangeFlag"]),
    endTimeErrors() {
      if (this.view) return;
      const errors = [];
      if (this.$v.form.endTime.$dirty) {
        !this.$v.form.endTime.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.form.endTime.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
        !this.$v.form.endTime.compareDate &&
          errors.push(this.$t("label.error.end_time"));
      }

      return errors;
    },
    startTimeErrors() {
      if (this.view) return;
      const errors = [];
      if (this.$v.form.startTime.$dirty) {
        !this.$v.form.startTime.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.form.startTime.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
  },
  data() {
    return {
      showDialog: false,
      view: this.canView,
      form: {
        startTime: "",
        endTime: "",
      },
      orginalForm: {
        startTime: "",
        endTime: "",
      },
      isEditing: false,
      start_time:"12:00",
      end_time:"13:00",
    };
  },
  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },
  methods: {
    isAllowDrag() {
      if (this.isView) return false;
      if (this.isPlan){
        return (
          this.$root.$refs.scheduleComponent.isSelf() &&
          this.$root.$refs.scheduleComponent.isNoSubmmit() &&
          this.$root.$refs.scheduleComponent.isCanSubmit()
        );
      }
      return (
          this.$root.$refs.actualComponent.isNoSubmmit() &&
          this.$root.$refs.actualComponent.isCanSubmit()
        );
    },

    formatStartTime($event) {
      if (!this.$v.form.startTime.$invalid) {
        const value = $event.target.value;
        let hasColon = value.indexOf(":") >= 0;
        if (!hasColon) {
          this.form.startTime = toHHmmFormat(value);
        }
      }
    },

    formatEndTime($event) {
      if (!this.$v.form.endTime.$invalid) {
        const value = $event.target.value;
        let hasColon = value.indexOf(":") >= 0;
        if (!hasColon) {
          this.form.endTime = toHHmmFormat(value);
        }
      }
    },

    archive:debounce_leading(async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        /// call api
        try {
          await axios.put(GET_SCHEDULE_API(this.planId), {
            start_break_time: null,
            end_break_time: null,
          });
          this.$emit("archiveBreakTime", {
            start_break_time: this.form.startTime.replace(null),
            end_break_time: null,
          });
          this.showDialog = false;
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC817, status);
        }
      }
    }),

    registerBreak() {
      if (this.isPlan){
        this.$root.$refs.scheduleComponent.addEventFromTodoListbreak();
      }
      else{
        this.$root.$refs.actualComponent.addEventFromTodoListbreak();
      }
      this.$emit("activeCalendarTab");
      this.showDialog = false;
    },

    submit: debounce_leading(async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        /// call api
        try {
          await axios.put(GET_SCHEDULE_API(this.planId), {
            start_break_time: (
              this.form.startTime.replace(":", "") + "00"
            ).padStart(6, "0"),
            end_break_time: (
              this.form.endTime.replace(":", "") + "00"
            ).padStart(6, "0"),
          });
          this.$emit("changeBreakTime", {
            start_break_time: (this.form.startTime.replace(":", "") + "00").padStart(6, "0"),
            end_break_time: (this.form.endTime.replace(":", "") + "00").padStart(6, "0"),
          });
          this.showDialog = false;
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC817, status);
        }
      }
    }),

    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.showDialog = false;
          }, 100);
        },
        () => {}
      );
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.$v.$reset();
          this.showDialog = false;
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>
<style scoped>
.v-dialog.v-dialog--active .v-card__title {
  cursor: grab;
}
</style>