<template>
  <v-container fill-height fluid class="pa-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <v-breadcrumbs large :items="breadcrumbs">
          <template v-slot:divider>
            <v-icon small>$bradcrumbtrail</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <div
              class="text-truncate"
              :class="$vuetify.breakpoint.mobile ? 'm-w-128' : 'm-w-250'"
            >
              <a
                class="font-weight-bold black--text text-decoration-none"
                :href="item.href"
                :disabled="item.disabled"
              >
                {{ item.text }}
              </a>
            </div>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-col class="mx-auto pt-0" cols="12">
        <v-row>
          <v-col md="4" sm="6" cols="12">
            <department-pull-down
              :title="title"
              :items="departments"
              @groupChanged="searchGroup($event)"
              @selectedAll="selectedAll()"
            ></department-pull-down>
          </v-col>

          <v-col class="ml-auto" md="4" cols="12" sm="6">
            <v-text-field
              placeholder="ユーザーを検索する"
              v-model="searchText"
              @keydown.enter="search()"
              hide-details="auto"
              class="pt-0"
            >
              <template v-slot:append>
                <v-icon small @click="search()">$search</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="searched">
      <v-col class="mx-auto" cols="12"
        ><span class="ml-1">{{ this.accounts.length }}</span> 件の検索結果
      </v-col>
    </v-row>
    <v-row v-if="!noAccount">
      <v-col class="mx-auto" cols="12" :class="tableClass">
        <v-data-table
          fixed-header
          mobile-breakpoint="0"
          height="100%"
          :headers="headers"
          :items="accounts"
          :hide-default-footer="true"
          disable-pagination
          class="h-100"
        >
          <template v-slot:[`item.auth`]="{ item }">
            {{ item.system_auth_name }}
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:[`item.dept`]="{ item }">
            {{ item.department_name }} ＞ {{ item.group_name }}
          </template>
          <template v-slot:[`item.position`]="{ item }">
            {{ item.position_name }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn @click="viewAccount(item)" depressed text color="ligth"
              >詳細を見る</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- <v-row v-else>
      <v-col> データはありません。</v-col>
    </v-row> -->
    <detail-account :user="userSelected" v-model="openDialog"></detail-account>
  </v-container>
</template>

<script>
import DetailAccount from "./DetailAccount.vue";
import DepartmentPullDown from "../../../components/DepartmentPullDown.vue";
import axios from "axios";
import { GET_ALL_PROFILE_API, GET_BELONG_API } from "../../../constants/api";
import { apiErrorMessages } from "../../../helpers/messages";
import { deepClone, isEmpty } from "../../../helpers/helpers";
import { FC101, FC301 } from "../../../constants/fc";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import { queryRouterMixin } from "@/mixins/queryRouter.js";
import { taskMixin } from "@/mixins/task.js";
export default {
  components: { DetailAccount, DepartmentPullDown },
  layout: "default",
  mixins: [queryRouterMixin, taskMixin],
  metaInfo() {
    return { title: this.$t("title.accountmanagement") };
  },

  data() {
    return {
      title: "部署・Grから検索する",
      accounts: [],
      orgAccounts: [],
      openDialog: false,
      noAccount: false,
      searched: false,
      userSelected: {},
      searchText: "",
      departments: [],
      breadcrumbs: [
        {
          text: "",
          disabled: true,
          href: "",
        },
        {
          text: "全メンバー",
          disabled: true,
          href: "profiles",
        },
      ],
      headers: [
        { text: "権限", value: "auth", sortable: false, width: "100px" },
        { text: "名前", value: "name", sortable: false, width: "200px" },
        { text: "所属部署", value: "dept", sortable: false, width: "200px" },
        { text: "役職", value: "position", sortable: false, width: "100px" },
        {
          text: "詳細",
          value: "action",
          sortable: false,
          align: "center",
          width: "100px",
        },
      ],
      queryData: {},
    };
  },
  computed: {
    tableClass() {
      switch (true) {
        case this.$vuetify.breakpoint.xs:
          if (this.searched) return "height-custom-xs-search";
          return "height-custom-xs";
        default:
          if (this.searched) return "height-custom-search";
          return "height-custom";
      }
    },
  },
  async mounted() {
    await this.getBelong();
    let groupId = this.$route.query.groupId || undefined;
    let departmentId = this.$route.query.departmentId || undefined;
    if (groupId && departmentId) {
      let department = this.departments.find((x) => x.id === +departmentId);
      this.searchGroup({
        group: department.groups.find((x) => x.id === +groupId),
        department: department,
      });
    } else {
      if (+departmentId === 0) {
        this.title = "全メンバー";
      }
      this.accounts = await this.getAccount();
      this.orgAccounts = deepClone(this.accounts);
    }
  },

  methods: {
    viewAccount(item) {
      this.userSelected = item;
      this.openDialog = true;
    },

    search() {
      const searchConvert = this.convertSearchKey(this.searchText);
      let accounts = this.orgAccounts.filter((x) => {
        return (x.name.includes(this.searchText) || isEmpty(this.searchText)) || (this.convertSearchKey(x.name).includes(searchConvert));
      });
      if (!accounts || accounts.length === 0) {
        this.accounts = [];
        this.noAccount = true;
      } else {
        this.noAccount = false;
        this.accounts = accounts;
      }
      if (this.searchText !== "") {
        this.searched = true;
      } else {
        this.searched = false;
        this.noAccount = false;
      }
    },

    async searchGroup(event) {
      this.searchText = "";
      this.searched = false;
      this.noAccount = false;
      this.accounts = await this.getAccount(event.group.id);
      this.orgAccounts = deepClone(this.accounts);

      this.title = `${event.department.name} ＞ ${event.group.name}`;
      this.breadcrumbs = [
        {
          text: "",
          disabled: true,
          href: "",
        },
        {
          text: event.department.name,
          disabled: false,
          href: `profiles?groupId=${event.group.id}&departmentId=${event.department.id}`,
        },
        {
          text: event.group.name,
          disabled: false,
          href: `profiles?groupId=${event.group.id}&departmentId=${event.department.id}`,
        },
      ];

      const setQueryDepartment = this.checkExistQueryString(
        this.$route.query.departmentId,
        `${event.department.id}`
      );
      if (setQueryDepartment) {
        this.queryData["departmentId"] = event.department.id;
      }

      const setQueryGroup = this.checkExistQueryString(
        this.$route.query.groupId,
        `${event.group.id}`
      );
      if (setQueryGroup) {
        this.queryData["groupId"] = event.group.id;
      }
      if (setQueryDepartment || setQueryGroup) {
        this.setQueryRouter();
      }
    },

    async selectedAll() {
      this.searchText = "";
      this.searched = false;
      this.noAccount = false;
      this.accounts = await this.getAccount();
      this.orgAccounts = deepClone(this.accounts);
      this.breadcrumbs = [
        {
          text: "",
          disabled: true,
          href: "",
        },
        {
          text: "全メンバー",
          disabled: true,
          href: "profiles",
        },
      ];

      const setQueryDepartment = this.checkExistQueryString(
        this.$route.query.departmentId,
        "0"
      );
      if (setQueryDepartment) {
        this.$router.replace({
          path: "/profiles",
          query: { departmentId: 0 },
        });
      }
    },

    async getBelong() {
      try {
        const { data } = await axios.get(GET_BELONG_API());
        this.departments = data.result.departments;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC301, status);
      }
    },

    async getAccount(groupId = undefined) {
      try {
        const { data, status } = await axios.get(
          GET_ALL_PROFILE_API("", groupId)
        );
        return status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC101, status);
      }
    },

    setQueryRouter() {
      this.$router.replace({
        path: "/profiles",
        query: this.queryData,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.m-w-128 {
  max-width: 128px;
}
.m-w-250 {
  max-width: 250px;
}
.height-custom {
  height: calc((var(--vh, 1vh) * 100) - 64px - 84px - 22px);
  &-search {
      height: calc((var(--vh, 1vh) * 100) - 64px - 84px - 22px - 50px);
    }
  &-xs {
    height: calc((var(--vh, 1vh) * 100) - 64px - 84px - 60px - 18px);
    &-search {
      height: calc((var(--vh, 1vh) * 100) - 64px - 84px - 60px - 18px - 50px);
    }
  }
}
.h-100 {
  height: 100%;
}
</style>
