<template>
  <v-container fill-height fluid class="pa-0">
    <v-row class="d-flex my-0" :class="{ 'ml-1': !isMobile }">
      <v-col cols="12" class="py-0 my-0">▼フィルタ </v-col>
      <v-col xs="12" xl="3" lg="4" md="6">
        <v-row>
          <v-col cols="12" class="py-0 my-0"
            >期間(指定可能期間：3カ月以内)</v-col
          >
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-icon dark v-if="isMobile" @click="selectRangeDate()"
              >$calendar</v-icon
            >
            <v-btn
              class="__calendar"
              outlined
              text
              color="light"
              @click="selectRangeDate()"
              v-else
            >
              {{ formatStartDate }} ~ {{ formatEndDate }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" v-if="isMobile">
        <v-row>
          <v-col cols="12" class="py-0 my-0">対象</v-col>
        </v-row>
        <v-row>
          <v-col cols="7" v-if="isMaster">
            <department-pull-down
              :title="title"
              :items="departments"
              :isAll="false"
              @groupChanged="searchGroup($event)"
            ></department-pull-down>
          </v-col>
          <v-col cols="6" v-else>
            <v-combobox
              class="__combobox-w"
              placeholder="‐‐‐Grを選択"
              v-model="group"
              :items="groups"
              item-text="name"
              item-value="id"
              hide-details
              :disabled="isMember"
              @change="groupChange($event)"
              solo
              :menu-props="{
                maxWidth: 300,
                allowOverflow: true,
              }"
            >
            </v-combobox>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" xl="2" lg="4" v-else>
        <v-row>
          <v-col cols="12" class="py-0 my-0">対象</v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <department-pull-down
              v-if="isMaster"
              :title="title"
              :items="departments"
              :isAll="false"
              @groupChanged="searchGroup($event)"
            ></department-pull-down>
            <v-combobox
              v-else
              class="__combobox-w"
              placeholder="‐‐‐Grを選択"
              v-model="group"
              :items="groups"
              item-text="name"
              item-value="id"
              hide-details
              :disabled="isMember"
              @change="groupChange($event)"
              solo
              :menu-props="{
                maxWidth: 300,
                allowOverflow: true,
              }"
            >
            </v-combobox>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="3" xl="2" lg="3">
        <v-row>
          <v-col
            cols="12"
            class="d-none d-lg-flex d-md-flex d-sm-flex print-show"
            >&nbsp;&nbsp;</v-col
          >
        </v-row>
        <v-row>
          <v-col
            cols="12"
            :class="[
              { 'd-flex align-group': isMaster && isXl && isAlignRight },
            ]"
          >
            <v-combobox
              v-model="member"
              :class="[
                isMaster && isXl && isAlignRight
                  ? '__combobox-group'
                  : '__combobox-w',
              ]"
              placeholder="‐‐‐メンバを選択"
              :items="members"
              item-text="name"
              item-value="id"
              hide-details
              :disabled="isMember && isLowerGM"
              @change="membersChange($event)"
              solo
              :menu-props="{
                maxWidth: 300,
                allowOverflow: true,
              }"
            >
            </v-combobox>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="3" lg="2">
        <v-row
          ><v-col cols="12" class="d-none d-lg-flex d-md-flex d-sm-flex"
            >&nbsp;&nbsp;</v-col
          ></v-row
        >
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary darken-1"
              class="btn-apply"
              @click="handleApply"
              >適用</v-btn
            >
            <v-btn class="ml-3" @click="handleClear">クリア</v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="3" lg="2">
        <v-row class="d-none d-lg-flex d-md-flex"
          ><v-col cols="12">&nbsp;&nbsp;</v-col></v-row
        >
        <v-row class="d-none d-lg-flex d-md-flex">
          <v-col cols="12" class="text-right">
            <v-btn
              class="__import"
              @click="exportCsv()"
              outlined
              v-if="!$vuetify.breakpoint.mobile"
            >
              <v-icon small dark class="mr-2">$csvexport</v-icon>
              CSVエクスポート
              <!-- エクスポート -->
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="py-0 my-0">
      <v-col cols="12" class="py-0 my-0">
        <v-icon
          class="scroll-icon"
          large
          :style="{
            display: scrollDisplay,
          }"
          @click="scrollTop()"
          >$scrolltop</v-icon
        >
      </v-col>
    </v-row>
    <v-row :class="{ 'ml-1': !isMobile }">
      <v-col
        cols="12"
        :class="[!isMobile ? '__scroll' : '__scroll-mobile']"
        @scroll="onScroll"
      >
        <!-- start of task work -->
        <ProductivityDetail
          :content="contentTaskWork"
          :productivity="dataProductivity.task_work"
          :header="headerTaskWork"
          :isStandard="false"
          :isTaskWork="true"
        ></ProductivityDetail>
        <!-- end of task work -->
        <!-- start of plan actual task -->
        <ProductivityDetail
          :content="contentPlanActualTask"
          :productivity="dataProductivity.plan_actual_task"
          :header="headerPlanActualTask"
          :isStandard="false"
          :isTaskWork="false"
        ></ProductivityDetail>
        <!-- end of plan actual task -->
        <!-- start of standard task -->
        <ProductivityDetail
          :content="contentStandardTask"
          :productivity="dataProductivity.standard_task"
          :header="headerStandardTask"
          :isStandard="true"
          :isTaskWork="false"
        ></ProductivityDetail>
        <!-- end of standard task -->

        <!-- start of irregular task -->
        <v-row class="py-5">
          <v-col cols="12" class="py-0 my-0">
            <div class="__header font-weight-bold py-2 pl-1">
              イレギュラー勤怠取得実績
            </div>
            <div class="row d-flex font-weight-bold __content py-4 mx-0">
              <div
                class="col-12 col-lg-6 col-md-4 d-flex sub-content"
                :class="[isMobile ? 'sub-content-mobile' : 'sub-content']"
              >
                <span class="align-self-baseline">
                  <span class="text-count ml-3">{{
                    dataProductivity.irregular_task.irregular_total_count
                  }}</span>
                  <span class="text-count">回</span>
                </span>
              </div>
              <div class="col-12 col-lg-6 col-md-8">
                <div class="row d-flex align-center">
                  <div class="col-7 col-xl-4 col-lg-5 col-md-5 text-detail">
                    <div class="w-rate-detail ml-auto">
                      <div class="d-flex align-baseline rate-detail">
                        <span>イレギュラー勤怠 : </span>
                        <span class="ml-2">{{
                          dataProductivity.irregular_task
                            .sum_cost_irregular_text
                        }}</span>
                      </div>
                      <v-divider class="__divider mx-2"></v-divider>
                      <div class="d-flex align-baseline rate-detail">
                        <span>月次予定工数 : </span>
                        <span class="ml-2">{{
                          dataProductivity.irregular_task
                            .sum_cost_scheduled_text
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-5 col-xl-8 col-lg-7 col-md-7">
                    <div
                      v-for="item in dataProductivity.irregular_task
                        .irregular_detail"
                      :key="item.irregular_task_id"
                      class="text-irregular"
                    >
                      <div class="mt-1 align-self-center">
                        {{ item.irregular_content }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="text-center __footer"
              @click="openIrregularTask = !openIrregularTask"
            >
              <div class="mt-3">
                <span class="text-show-hide"
                  >日別のイレギュラー勤怠取得実績を見る</span
                >
              </div>
              <div>
                <v-btn color="#7F7F7F" text small>
                  <v-icon v-if="openIrregularTask"> mdi-chevron-down </v-icon>
                  <v-icon v-else> mdi-chevron-up </v-icon>
                </v-btn>
              </div>
            </div>
            <div class="__table" v-show="openIrregularTask">
              <v-data-table
                :headers="headerIrregularTask"
                class="h-100 contentTable font-weight-bold"
                mobile-breakpoint="0"
                fixed-header
                :items="dataProductivity.irregular_task.day_detail"
                :hide-default-footer="true"
                item-key="day"
                disable-pagination
              >
                <template v-slot:[`item.day`]="{ item }">
                  {{ item.day_format }}
                </template>
                <template v-slot:[`item.userDetail`]="{ item }">
                  <v-row
                    v-for="(user, i) in item.user_detail"
                    :key="i"
                    class="my-0 py-0"
                  >
                    <v-col cols="4" class="text-irregular-user"
                      ><a v-if="+user.is_deleted == 0"
                        class="url-detail"
                        :href="editUserLink(user, item)"
                        >{{ user.user_name || "" }}</a
                      >
                      <span v-else>
                        {{ user.user_name }}
                      </span></v-col
                    >
                    <v-col cols="4" class="text-item">
                      <div v-if="user.show_icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                        >
                          <g transform="translate(-1.351 -0.437)">
                            <path
                              d="M2.06.342,3.7.229,2.625,15.79.987,15.9Z"
                              transform="translate(11.899 -0.423) rotate(45)"
                              fill="#7F7F7F"
                            />
                            <path
                              d="M0,0,1.638.113,2.711,15.674l-1.638-.113Z"
                              transform="translate(1.351 2.354) rotate(-45)"
                              fill="#7F7F7F"
                            />
                          </g>
                        </svg>
                      </div>
                      <span v-else>
                        {{ user.task_name || "-" }}
                      </span>
                    </v-col>
                    <v-col cols="4" class="text-item">{{
                      user.task_cost_text
                    }}</v-col></v-row
                  >
                </template>
                <template v-slot:[`item.day_total_cost`]="{ item }">
                  {{ item.day_total_cost_text }}
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
        <!-- end of irregular task -->

        <!-- start of evaluation calc list -->
        <v-row class="py-2">
          <v-col cols="12" class="py-0 my-0">
            <div class="table-header border-outside">画面項目</div>
            <div class="border-outside-all">
              <table class="evaluation-tbl">
                <thead class="table-header">
                  <tr height="30px">
                    <th class="col-1 border-td-right" scope="col">種別</th>
                    <th class="col-3 col-xl-2 border-td-right" scope="col">
                      項目名
                    </th>
                    <th class="col-8 col-xl-9" scope="col">処理概要</th>
                  </tr>
                </thead>
                <tbody class="text-detail font-weight-bold">
                  <tr class="row-height">
                    <td rowspan="3" class="vertical-text border-td-bottom">
                      前提
                    </td>
                    <td>生産性評価対象タスクの定義</td>
                    <td>
                      標準工数DBに登録されていて生産性評価対象業務のフラグがつけられているもの
                    </td>
                  </tr>
                  <tr class="row-height">
                    <td>生産性評価対象タスクの基準</td>
                    <td>
                      ①事業遂行上必要なタスク<br />
                      ②部署の役割を果たすタスク<br />
                      ※上記２点を対象とする基準として各部署判断にて設定する。
                    </td>
                  </tr>
                  <tr class="row-height">
                    <td class="border-td-bottom">イレギュラー勤怠の定義</td>
                    <td class="border-td-bottom">
                      イレギュラー勤怠（遅刻・早退・代休・半休）に伴い登録するタスク
                    </td>
                  </tr>
                  <tr class="row-height">
                    <td rowspan="2" class="vertical-text border-td-bottom">
                      タスク業務効率
                    </td>
                    <td>タスク業務効率の計算式</td>
                    <td>
                      <div class="d-flex align-center td-detail">
                        <div class="d-flex align-center">
                          <span>【タスクの業務効率】</span>
                          <span class="ml-1">＝</span>
                        </div>
                        <div class="w-td-detail ml-3">
                          <span
                            >標準工数DBに登録されているタスクの標準工数合計‐生産性評価対象外業務工数‐イレギュラー勤怠工数</span
                          >
                          <v-divider class="td-divider"></v-divider>
                          <span
                            >標準工数DBに登録されているタスクの実績工数合計‐生産性評価対象外業務工数‐イレギュラー勤怠工数</span
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="row-height">
                    <td class="border-td-bottom">タスク業務効率の評価</td>
                    <td class="border-td-bottom">
                      ・100%以上「〇」<br />
                      ・100%未満「×」
                    </td>
                  </tr>
                  <tr class="row-height">
                    <td rowspan="2" class="vertical-text border-td-bottom">
                      計画実行率<br />
                      予定タスクの
                    </td>
                    <td>
                      予定タスクの<br />
                      計画実行率の計算式
                    </td>
                    <td>
                      <div class="d-flex align-center td-detail">
                        <div class="d-flex align-center">
                          <span>【予定タスクの計画実行率】</span>
                          <span class="ml-1">＝</span>
                        </div>
                        <div class="w-td-detail ml-3">
                          <span
                            >予定していたタスクの中で完了したタスクの工数合計‐生産性評価対象外業務工数‐イレギュラー勤怠工数</span
                          >
                          <v-divider class="td-divider"></v-divider>
                          <span
                            >予定していたタスクの工数合計‐生産性評価対象外業務工数‐イレギュラー勤怠工数</span
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="row-height">
                    <td class="border-td-bottom">
                      予定タスクの<br />計画実行率の評価
                    </td>
                    <td class="border-td-bottom">
                      ・100%以上「〇」<br />
                      ・100%未満「ー」
                    </td>
                  </tr>
                  <tr class="row-height">
                    <td rowspan="2" class="vertical-text">標準工数タスク率</td>
                    <td>標準工数タスク率の計算式</td>
                    <td>
                      <div class="d-flex align-center td-detail">
                        <div class="d-flex align-center">
                          <span>【標準工数タスク率】</span>
                          <span class="ml-1">＝</span>
                        </div>
                        <div class="w-td-detail ml-3">
                          <span
                            >標準工数DBに登録されているタスクの実績工数合計‐生産性評価対象外業務工数‐イレギュラー勤怠工数</span
                          >
                          <v-divider class="td-divider"></v-divider>
                          <span
                            >全タスクの実績工数合計‐生産性評価対象外業務工数‐イレギュラー勤怠工数</span
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="row-height">
                    <td>標準工数タスク率の評価</td>
                    <td>
                      ・80%以上「〇」<br />
                      ・80%未満「ー」
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
        <!-- end of evaluation calc list -->
        <!-- <img
          class="evaluation_size"
          alt=""
          src="../../assets/images/evaluation_calc_list.png"
        /> -->
      </v-col>
    </v-row>
    <RangeDate
      @change="changeDate($event)"
      v-model="rangeDate"
      :show="openRangeDate"
    ></RangeDate>
    <Export :show="openExport" :query-productivity="queryProductivity"></Export>
  </v-container>
</template>

<script>
import * as Role from "../../constants/role";
import RangeDate from "./components/RangeDate";
import Export from "./components/Export";
import {
  GET_BELONG_API,
  GET_MEMBERS_API,
  PRODUCTIVITY_REPORT_API,
} from "../../constants/api";
import axios from "axios";
import {
  formatDateTime,
  parseDateTime,
  toHHmmText,
} from "@/helpers/datetime-helpers";
import { apiErrorMessages } from "@/helpers/messages";
import { FC108, FC1202, FC301 } from "@/constants/fc";
import { mapGetters } from "vuex";
import {
  yyyyMMdd1,
  yyyyMMdd2,
  yyyyMMdd3,
  yyyyMMddEEEEE4,
} from "@/constants/df";
import { isEmpty, deepClone } from "@/helpers/helpers";
import { STATUS_NO_CONTENT } from "@/constants/status";
import ProductivityDetail from "./components/ProductivityDetail";
import * as systemAuth from "../../constants/role_code";
import DepartmentPullDown from "../../components/DepartmentPullDown.vue";
import { queryRouterMixin } from "@/mixins/queryRouter.js";
const HEADER_DEFAULT = [
  {
    text: "日付",
    value: "day",
    sortable: false,
    width: "30%",
    align: "center",
    class: "text-header border-right",
    cellClass: "text-item border-right",
  },
  {
    text: "評価",
    value: "is_good_rate",
    sortable: false,
    align: "center",
    width: "10%",
    class: "text-header border-right",
    cellClass: "border-right",
  },
];
const HEADER_URL = {
  text: "",
  value: "url",
  sortable: false,
  align: "center",
  width: "30%",
  cellClass: "text-item",
};
const MEMBER_ITEM_ALL = {
  id: "0",
  name: "全員",
};
export default {
  name: "ProductivityEvaluationPage",
  middleware: Role.AUTH,
  components: {
    RangeDate,
    Export,
    ProductivityDetail,
    DepartmentPullDown,
  },
  mixins: [queryRouterMixin],
  metaInfo() {
    return { title: this.$t("title.evaluation") };
  },
  data() {
    return {
      title: "‐‐‐Grを選択",
      departments: [],
      groups: [],
      group: {},
      membersResult: [],
      members: [],
      member: {},
      dataProductivityDefault: {
        task_work: {
          rate: 0,
          sum_cost_plan_text: "0h0m",
          sum_cost_actual_text: "0h0m",
        },
        plan_actual_task: {
          rate: 0,
          sum_cost_completed_text: "0h0m",
          sum_cost_plan_text: "0h0m",
        },
        standard_task: {
          rate: 0,
          sum_cost_std_completed_text: "0h0m",
          sum_cost_completed_text: "0h0m",
        },
        irregular_task: {
          irregular_total_count: 0,
          sum_cost_irregular_text: "0h0m",
          sum_cost_scheduled_text: "0h0m",
        },
      },
      dataProductivity: {},
      headerTaskWork: [],
      headerPlanActualTask: [],
      headerStandardTask: [],
      headerIrregularTask: [
        {
          text: "日付",
          value: "day",
          sortable: false,
          width: "30%",
          align: "center",
          class: "text-header border-right",
          cellClass: "text-item border-right",
        },
        {
          text: "イレギュラー勤怠",
          value: "userDetail",
          width: "45%",
          sortable: false,
          align: "center",
          class: "text-header border-right",
          cellClass: "border-right",
        },
        {
          text: "合計時間",
          value: "day_total_cost",
          sortable: false,
          align: "center",
          width: "25%",
          class: "text-header",
          cellClass: "text-item",
        },
      ],
      rangeDate: {},
      queryProductivity: {
        group_id: null,
        user_id: null,
        start_date: null,
        end_date: null,
      },
      openRangeDate: false,
      openExport: false,
      openIrregularTask: false,
      contentTaskWork: {},
      contentPlanActualTask: {},
      contentStandardTask: {},
      scrollDisplay: "none",
      queryData: {},
      isSetDefault: false,
      originalQueryData: {},
    };
  },
  watch: {
    rangeDate: {
      deep: true,
      handler() {
        this.queryProductivity = {
          ...this.queryProductivity,
          ...this.rangeDate,
        };
      },
    },
  },
  created() {
    this.setDefaultDate();
    this.dataProductivity = deepClone(this.dataProductivityDefault);
    this.queryData = this.setQuery(this.$route.query);
    this.originalQueryData = deepClone(this.queryData);
  },
  async mounted() {
    document.documentElement.style.overflowY = "hidden";
    document.documentElement.style.overflowX = "auto";
    document.getElementById("app").style.position = "unset";
    document.getElementById("app").style.minWidth = "556px";
    this.setHeader();
    this.setContent();
    await this.fetchMembers();
    await this.getBelong();
    this.setDefaultValue();
    await this.fetchProductivityReport();
  },
  destroyed() {
    document.documentElement.style.overflowY = "auto";
    document.documentElement.style.overflowX = "unset";
    document.getElementById("app").style.position = "fixed";
    document.getElementById("app").style.minWidth = "unset";
  },
  computed: {
    ...mapGetters("common", ["loginInfo"]),
    ...mapGetters({
      user: "auth/user",
    }),
    ...mapGetters("common", ["isShowDrawer"]),
    isMaster() {
      if (isEmpty(this.loginInfo)) return false;
      else return this.loginInfo.system_auth_id === systemAuth.MASTER;
    },
    isManager() {
      if (isEmpty(this.loginInfo)) return false;
      else return this.loginInfo.system_auth_id === systemAuth.MANAGER;
    },
    isMember() {
      if (isEmpty(this.loginInfo)) return false;
      else return this.loginInfo.system_auth_id === systemAuth.MEMBER;
    },
    isEqualOrHigherGM() {
      if (isEmpty(this.loginInfo)) return false;
      else return this.loginInfo.position_id >= systemAuth.GM;
    },
    isLowerGM() {
      if (isEmpty(this.loginInfo)) return false;
      else return this.loginInfo.position_id < systemAuth.GM;
    },
    formatStartDate() {
      return this.queryProductivity.real_start_date
        ? formatDateTime(this.queryProductivity.real_start_date, yyyyMMdd3)
        : "";
    },
    formatEndDate() {
      return this.queryProductivity.real_end_date
        ? formatDateTime(this.queryProductivity.real_end_date, yyyyMMdd3)
        : "";
    },
    isMobile() {
      return ["xs", "sm"].includes(this.$vuetify.breakpoint.name);
    },
    isXl() {
      return ["xl"].includes(this.$vuetify.breakpoint.name);
    },
    isAlignRight() {
      return this.title.length >= 18 && this.isShowDrawer;
    },
  },
  methods: {
    setDefaultDate() {
      let date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      let firstDay = new Date(y, m, 1);
      let lastDay = new Date(y, m + 1, 0);
      this.rangeDate = {
        start_date:
          this.$route.query.startDate || formatDateTime(firstDay, yyyyMMdd2),
        end_date:
          this.$route.query.endDate || formatDateTime(lastDay, yyyyMMdd2),
        real_start_date:
          this.$route.query.startDate || formatDateTime(firstDay, yyyyMMdd2),
        real_end_date:
          this.$route.query.endDate || formatDateTime(lastDay, yyyyMMdd2),
      };
      this.queryProductivity = {
        ...this.queryProductivity,
        ...this.rangeDate,
      };
    },
    membersChange(e) {
      this.queryProductivity.user_id = e.id;

      this.queryData["memberId"] = this.queryProductivity.user_id || 0;
    },
    async changeDate(e) {
      this.queryProductivity.start_date = e.start_date;
      this.queryProductivity.end_date = e.end_date;
      this.queryProductivity.real_start_date = e.start_date;
      this.queryProductivity.real_end_date = e.end_date;

      const setQueryStartDate = this.checkExistQueryString(
        this.$route.query.startDate,
        this.queryProductivity.start_date
      );
      if (setQueryStartDate) {
        this.queryData["startDate"] = this.queryProductivity.start_date;
      }

      const setQueryEndDate = this.checkExistQueryString(
        this.$route.query.endDate,
        this.queryProductivity.end_date
      );
      if (setQueryEndDate) {
        this.queryData["endDate"] = this.queryProductivity.end_date;
      }
      if (!setQueryStartDate && !setQueryEndDate) {
        this.originalQueryData = deepClone(this.queryData);
      }
      let groupId = this.queryProductivity.group_id;
      await this.fetchMembers();
      this.queryProductivity.group_id = groupId;
      this.members = this.getMembers(this.queryProductivity.group_id);
      let selectMember = this.members.find((x) => x.id === this.queryProductivity.user_id);
      if (this.isLowerGM && this.isMember) {
        this.member = {
              id: this.loginInfo.user_id,
              name: this.loginInfo.name,
            };
      }
      else{
        this.member = selectMember || MEMBER_ITEM_ALL;
      }
      
      this.queryProductivity.user_id = this.member.id;
    },
    async getBelong() {
      try {
        const { data } = await axios.get(GET_BELONG_API());
        this.departments = data.result.departments;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC301, status);
      }
    },
    groupChange(event) {
      this.queryProductivity.group_id = event.id;
      this.members = this.getMembers(+event.id);
      this.member = MEMBER_ITEM_ALL;
      this.queryProductivity.user_id = null;

      this.queryData["groupId"] = this.queryProductivity.group_id;
      this.queryData["memberId"] = 0;
    },
    async fetchMembers() {
      try {
        const { data } = await axios.get(GET_MEMBERS_API(), {
          params: {
            has_deleted_user: 1,
            start_date: formatDateTime(
              this.queryProductivity.start_date,
              yyyyMMdd1
            ),
            end_date: formatDateTime(
              this.queryProductivity.end_date,
              yyyyMMdd1
            ),
          },
        });
        this.membersResult = data.result;
        if (this.loginInfo){
          this.queryProductivity.group_id = this.loginInfo.group_id;
        }
        else if (this.membersResult && this.membersResult.length > 0){
          this.queryProductivity.group_id = this.membersResult[0].group_id;
        }
      } catch (err) {
        const { status } = err.response;
        apiErrorMessages(this, FC108, status);
      }
    },
    getMembers(groupId) {
      if (this.membersResult){
        return [
          MEMBER_ITEM_ALL,
          ...this.membersResult.filter((item) => item.group_id === groupId),
        ];
      }
      return [
          MEMBER_ITEM_ALL,
        ];
      
    },
    setDefaultValue() {
      this.setDefaultDate();
      let userGroupId = this.$route.query.groupId
        ? +this.$route.query.groupId
        : +this.loginInfo.group_id || undefined;
      let userDepartmentId = this.$route.query.departmentId
        ? +this.$route.query.departmentId
        : +this.loginInfo.department_id || undefined;
      let userId = this.$route.query.memberId
        ? this.$route.query.memberId
        : this.loginInfo.user_id || undefined;

      switch (true) {
        // メンバ
        case this.isMember:
          this.groups = (
            this.departments.find((x) => x.id === userDepartmentId)?.groups ||
            []
          ).filter((item) => item.id === userGroupId);
          this.group = this.groups.find((item) => item.id === userGroupId);
          if (this.isEqualOrHigherGM) {
            // >= GM
            // this.members = [
            //   MEMBER_ITEM_ALL,
            //   ...this.membersResult.filter((item) => item.id === userId),
            // ];
            this.members = [
              MEMBER_ITEM_ALL,
              ...this.membersResult.filter(
                (item) => item.group_id === userGroupId
              ),
            ];
            this.member = this.$route.query.memberId
              ? this.members.find((item) => item.id === userId)
              : MEMBER_ITEM_ALL;
          }

          if (this.isLowerGM) {
            if (!this.membersResult){
              this.member = {
                id: this.loginInfo.user_id,
                name: this.loginInfo.name,
              };
              break;
            }
            // >= GM でない
            this.members = this.membersResult.filter(
              (item) => item.id === userId
            );
            this.member = this.members.find((item) => item.id === userId);
          }
          break;

        // 管理者
        case this.isManager:
          this.groups =
            this.departments.find((x) => x.id === userDepartmentId)?.groups ||
            [];

          this.group = this.groups.find((item) => item.id === userGroupId);
          this.members = this.getMembers(userGroupId);
          if (this.isEqualOrHigherGM) {
            // >= GM
            this.member = this.$route.query.memberId
              ? this.members.find((item) => item.id === userId)
              : MEMBER_ITEM_ALL;
          }
          if (this.isLowerGM) {
            // >= GM でない
            this.member = this.members.find((item) => item.id === userId);
          }
          break;

        // マスタ
        case this.isMaster:
          // this.departments.forEach((item) => {
          //   if (item.groups && item.groups.length > 0) {
          //     this.groups = [...this.groups, ...item.groups];
          //   }
          // });
          // this.group = this.groups.find((item) => item.id === userGroupId);
          if (userGroupId && userDepartmentId) {
            let department = this.departments.find(
              (x) => x.id === +userDepartmentId
            );
            this.isSetDefault = true;
            this.searchGroup({
              group: department.groups.find((x) => x.id === +userGroupId),
              department: department,
            });
          }
          this.members = this.getMembers(userGroupId);
          if (this.isEqualOrHigherGM) {
            // >= GM
            this.member = this.$route.query.memberId
              ? this.members.find((item) => item.id === userId)
              : MEMBER_ITEM_ALL;
          }
          if (this.isLowerGM) {
            // >= GM でない
            this.member = this.members.find((item) => item.id === userId);
          }
          break;

        default:
          break;
      }
      if (!this.member){
        if (this.isLowerGM && this.isMember) {
          this.member = {
                id: this.loginInfo.user_id,
                name: this.loginInfo.name,
              };
        }
        else{
          this.member = MEMBER_ITEM_ALL;
        }
      }
      
      this.queryProductivity.group_id = userGroupId;
      if (this.member === MEMBER_ITEM_ALL) {
        this.queryProductivity.user_id = null;
      } else {
        this.queryProductivity.user_id = this.member.id;
      }
    },
    async searchGroup(event) {
      this.title = `${event.department.name} ＞ ${event.group.name}`;
      this.queryProductivity.group_id = event.group.id;
      this.members = this.getMembers(+event.group.id);
      this.member = MEMBER_ITEM_ALL;
      this.queryProductivity.user_id = null;

      if (!this.isSetDefault) {
        const setQueryDepartment = this.checkExistQueryString(
          this.$route.query.departmentId,
          `${event.department.id}`
        );
        if (setQueryDepartment) {
          this.queryData["departmentId"] = event.department.id;
        }

        const setQueryGroup = this.checkExistQueryString(
          this.$route.query.groupId,
          `${this.queryProductivity.group_id}`
        );
        if (setQueryGroup) {
          this.queryData["groupId"] = this.queryProductivity.group_id;
        }

        if (!setQueryDepartment && !setQueryGroup) {
          this.originalQueryData = deepClone(this.queryData);
        }
      }
      this.isSetDefault = false;
    },

    setQueryRouter() {
      this.queryData["memberId"] = this.queryProductivity.user_id || 0;
      this.$router.replace({
        path: "/productivity",
        query: this.queryData,
      });
    },
    handleApply() {
      this.setQueryRouter();
      this.fetchProductivityReport();
    },
    handleClear() {
      this.setDefaultValue();
      this.fetchProductivityReport();
    },
    setContent() {
      this.contentTaskWork = {
        header: "タスク業務効率（標準工数があるタスクのみ対象）",
        sum_cost_1: "標準工数",
        sum_cost_2: "標準工数タスク実績",
        footer: "日別のタスク業務効率を見る",
      };
      this.contentPlanActualTask = {
        header: "予定タスク計画実行率",
        sum_cost_1: "完了したタスクの予定工数",
        sum_cost_2: "予定タスク",
        footer: "日別の予定タスクの計画実行率を見る",
      };
      this.contentStandardTask = {
        header: "標準工数タスク率",
        sum_cost_1: "標準工数タスク実績",
        sum_cost_2: "完了タスク実績",
        footer: "日別の標準工数タスク率を見る",
      };
    },

    setHeader() {
      this.headerTaskWork = [...HEADER_DEFAULT];
      this.headerTaskWork.push({
        text: "タスク業務効率",
        value: "day_rate",
        sortable: false,
        align: "center",
        width: "30%",
        class: "text-header border-right",
        cellClass: "text-item border-right",
      });
      this.headerTaskWork.push(HEADER_URL);

      this.headerPlanActualTask = [...HEADER_DEFAULT];
      this.headerPlanActualTask.push({
        text: "予定タスク計画実行率",
        value: "day_rate",
        sortable: false,
        align: "center",
        width: "30%",
        class: "text-header border-right",
        cellClass: "text-item border-right",
      });
      this.headerPlanActualTask.push(HEADER_URL);

      this.headerStandardTask = [...HEADER_DEFAULT];
      this.headerStandardTask.push({
        text: "標準工数タスク率",
        value: "day_rate",
        sortable: false,
        align: "center",
        width: "30%",
        class: "text-header border-right",
        cellClass: "text-item border-right",
      });
      this.headerStandardTask.push(HEADER_URL);
    },

    async fetchProductivityReport() {
      try {
        const queryProductivity = JSON.parse(
          JSON.stringify({
            group_id:
              this.queryProductivity.group_id || this.loginInfo.group_id,
            user_id: this.queryProductivity.user_id,
            start_date: formatDateTime(
              this.queryProductivity.start_date,
              yyyyMMdd1
            ),
            end_date: formatDateTime(
              this.queryProductivity.end_date,
              yyyyMMdd1
            ),
            ...(this.queryProductivity.user_id
              ? { user_id: this.queryProductivity.user_id }
              : {}),
          })
        );
        if (+this.queryProductivity.user_id === 0){
          this.$delete(queryProductivity, 'user_id')
        }
        // Fetch API
        const { data, status } = await axios.get(
          PRODUCTIVITY_REPORT_API(queryProductivity)
        );

        this.dataProductivity =
          status === STATUS_NO_CONTENT
            ? deepClone(this.dataProductivityDefault)
            : this.productivityReportList(data.result);      
      } catch (err) {
        const { status } = err.response;
        apiErrorMessages(this, FC1202, status);
      }
    },

    productivityReportList(report) {
      let newReport = {};
      newReport = deepClone(report);
      // report.task_work
      let taskWork = report.task_work;
      newReport.task_work.sum_cost_plan_text = toHHmmText(
        taskWork.sum_cost_plan
      );
      newReport.task_work.sum_cost_1 = newReport.task_work.sum_cost_plan_text;
      newReport.task_work.sum_cost_actual_text = toHHmmText(
        taskWork.sum_cost_actual
      );
      newReport.task_work.sum_cost_2 = newReport.task_work.sum_cost_actual_text;
      newReport.task_work.day_detail.map((item) => {
        item.day_format = this.customerFormatDateTime(item.day);
        item.user_list = deepClone(report.users);
      });
      // report.plan_actual_task
      let planActualTask = report.plan_actual_task;
      newReport.plan_actual_task.sum_cost_completed_text = toHHmmText(
        planActualTask.sum_cost_completed
      );
      newReport.plan_actual_task.sum_cost_1 =
        newReport.plan_actual_task.sum_cost_completed_text;
      newReport.plan_actual_task.sum_cost_plan_text = toHHmmText(
        planActualTask.sum_cost_plan
      );
      newReport.plan_actual_task.sum_cost_2 =
        newReport.plan_actual_task.sum_cost_plan_text;
      newReport.plan_actual_task.day_detail.map((item) => {
        item.day_format = this.customerFormatDateTime(item.day);
        item.user_list = deepClone(report.users);
      });
      // report.standard_task
      let standardTask = report.standard_task;
      newReport.standard_task.sum_cost_std_completed_text = toHHmmText(
        standardTask.sum_cost_std_completed
      );
      newReport.standard_task.sum_cost_1 =
        newReport.standard_task.sum_cost_std_completed_text;
      newReport.standard_task.sum_cost_completed_text = toHHmmText(
        standardTask.sum_cost_completed
      );
      newReport.standard_task.sum_cost_2 =
        newReport.standard_task.sum_cost_completed_text;
      newReport.standard_task.day_detail.map((item) => {
        item.day_format = this.customerFormatDateTime(item.day);
        item.user_list = deepClone(report.users);
      });
      // report.irregular_task
      let irregularTask = report.irregular_task;
      newReport.irregular_task.sum_cost_irregular_text = toHHmmText(
        irregularTask.sum_cost_irregular
      );
      newReport.irregular_task.sum_cost_scheduled_text = toHHmmText(
        irregularTask.sum_cost_scheduled
      );
      newReport.irregular_task.irregular_detail.map((item) => {
        item.irregular_content = `${item.irregular_task_name} : ${
          item.irregular_count
        }回 (${toHHmmText(item.irregular_cost)})`;
      });
      newReport.irregular_task.day_detail.map((item) => {
        item.day_format = this.customerFormatDateTime(item.day);
        if (item.day_total_cost === 0) {
          item.day_total_cost_text = "-";
          if (item.is_holiday === 1) {
            let user = {
              show_icon: true,
              task_cost_text: "",
            };
            item.user_detail.push(user);
          } else {
            let user = {
              show_icon: false,
              task_cost_text: "-",
            };
            item.user_detail.push(user);
          }
        } else {
          item.day_total_cost_text = toHHmmText(item.day_total_cost);
          item.user_detail.map((user) => {
            user.show_icon = false;
            user.task_cost_text = toHHmmText(user.task_cost);
          });
        }
      });
      return newReport;
    },

    // Format dateTime for list (dateTime : yyyyMMddHHmmss)
    customerFormatDateTime(dateTime) {
      return formatDateTime(parseDateTime(dateTime), yyyyMMddEEEEE4);
    },

    selectRangeDate() {
      this.rangeDate = Object.assign({}, this.rangeDate);
      this.openRangeDate = !this.openRangeDate;
    },

    exportCsv() {
      this.openExport = !this.openExport;
    },

    onScroll(e) {
      const { scrollTop } = e.target;
      if (scrollTop > 20) {
        this.scrollDisplay = "block";
      } else {
        this.scrollDisplay = "none";
      }
    },

    scrollTop() {
      if (!this.isMobile) {
        document.getElementsByClassName("__scroll")[0].scrollTop = 0;
      } else {
        document.getElementsByClassName("__scroll-mobile")[0].scrollTop = 0;
      }
    },

    editUserLink(user, item){
      let redirectDate = formatDateTime(
        parseDateTime(item.day, yyyyMMdd1),
        yyyyMMdd2
      );
      let router = `/actual?uid=${user.user_id}&date=${redirectDate}`;
      return router;
    },
  },
};
</script>
<style lang="scss" src="@/assets/scss/_productivity.scss" scoped></style>
<style lang="scss" scoped>
@media print {
  .__scroll {
    overflow: hidden;
    max-height: none;
  }
  .__content .sub-content {
    justify-content: center;
  }
  .print-show {
    display: block !important;
  }
  .scroll-icon {
    display: none !important;
  }
  ::v-deep .dropdown-btn {
    max-width: 90% !important;
  }
}
@media screen and (max-width: 1900px) {
  .__scroll {
    max-height: calc((var(--vh, 1vh) * 100) - 284px);
  }
}
</style>