<template>
  <v-snackbar
    :class="[
      { 'position-custom': hasClass },
      { collapse: collapse },
      { uncollapse: !collapse },
      { 'left-100': $vuetify.breakpoint.mobile },
    ]"
    v-model="visible"
    :multi-line="true"
    :timeout="timeout"
  >
    <p v-for="(p, i) in content" :key="i">{{ p }}</p>
  </v-snackbar>
</template>
<script>
export default {
  name: "SnackError",
  props: {},
  data() {
    return {
      visible: false,
      hasClass: false,
      collapse: false,
      message: "",
      timeout: 3000,
    };
  },
  computed: {
    content() {
      if (!Array.isArray(this.message)) {
        return [this.message];
      }
      return this.message;
    },
  },
  methods: {
    show(visible, hasClass, collapse, message, timeout) {
      this.visible = visible;
      this.hasClass = hasClass;
      this.collapse = collapse;
      this.message = message;
      this.timeout = timeout;
    },
    hide() {
      this.visible = false;
    },
  },
};
</script>
<style  lang="scss">
.position-custom.uncollapse {
  .v-snack__wrapper {
    position: absolute;
    top: 180px;
    right: 100px !important;
  }
}
.position-custom.collapse {
  .v-snack__wrapper {
    position: absolute;
    top: 180px;
    right: 500px !important;
  }
}
.left-100 {
  left: 100px !important;
}
</style>
