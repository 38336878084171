var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"black--text"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.selectedCategory.category_primary_name)+" "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',{staticClass:"overflow-y-auto",attrs:{"max-height":"60vh"}},_vm._l((_vm.categories),function(item,index){return _c('v-list-item',{key:index,class:[
        {
          active:
            item.category_primary_id === _vm.selectedCategory.category_primary_id,
        } ],on:{"click":function($event){return _vm.change(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.category_primary_name))])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }