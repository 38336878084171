<template>
  <account-management v-if="isManager"></account-management>
  <account-master v-else-if="isMaster"></account-master>
</template>

<script>
import AccountMaster from './list/AccountMaster.vue';
import AccountManagement from './list/AccountManagement.vue';
import { mapGetters } from 'vuex';
import * as systemAuth from '../../constants/role_code';
import * as Role from '../../constants/role';
import { isEmpty } from '../../helpers/helpers';
export default {
  components: { AccountManagement, AccountMaster },
  middleware: Role.ADMIN,
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    isMaster() {
      if (isEmpty(this.user)) return false;
      else return this.user.system_auth_id === systemAuth.MASTER;
    },
    isManager() {
      if (isEmpty(this.user)) return false;
      else return this.user.system_auth_id === systemAuth.MANAGER;
    }
  },
  data() {
    return {
      systemAuth: systemAuth
    };
  }
};
</script>

<style></style>
