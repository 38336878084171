<template>
  <v-dialog
    v-model="openExplain"
    persistent
    content-class="border-dialog"
    max-width="780px"
    scrollable
    v-dialog-drag="{
      dialog: 'border-dialog',
    }"
    overlay-color="transparent"
    overlay-opacity="1"
  >
    <v-card>
      <v-card-title class="card-header py-2">
        <v-btn @click="close()" class="ml-auto" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <h2 class="py-2 h2-mobile">
          タスク色とアイコンは以下のように設定されています。
        </h2>
        <v-list class="explain">
          <v-list-item>
            <v-list-item-content
              :style="{
                backgroundColor: COLOR_DB_TASK,
                maxWidth: '22%',
                marginRight: '8%',
              }"
            >
              <v-list-item-title class="ml-2">タスク</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content
              >予定通り実施した標準工数タスク</v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :style="{
                border: `1px solid ${BORDER_COLOR_TASK_NOT_COMPLETE}`,
                backgroundColor: COLOR_DB_TASK,
                maxWidth: '22%',
                marginRight: '8%',
              }"
            >
              <v-list-item-title class="ml-2">タスク</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>未実施の標準工数タスク</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :style="{
                border: `1px solid ${BORDER_COLOR_TASK_NOT_PLAN}`,
                backgroundColor: COLOR_DB_TASK,
                maxWidth: '22%',
                marginRight: '8%',
              }"
            >
              <v-list-item-title class="ml-2">タスク</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              予定に見込んでいなかったが、実施した標準工数タスク
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :style="{
                backgroundColor: COLOR_DB_TASK_WITHIN_1_2,
                maxWidth: '22%',
                marginRight: '8%',
                color: 'white',
              }"
            >
              <v-list-item-title class="ml-2">タスク</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <div>標準工数タスクで予定工数を超過した場合(1.2倍以内)</div>
              <div class="mt-2">
                タスクの実施回数が予定を下回った場合(ただし工数は予定内の場合)
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :style="{
                backgroundColor: COLOR_DB_TASK_GREATER_1_2,
                maxWidth: '22%',
                marginRight: '8%',
                color: 'white',
              }"
            >
              <v-list-item-title class="ml-2">タスク</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <div>標準工数タスクで予定工数を超過した場合(1.2倍以上)</div>
              <div class="mt-2">
                タスクの実施回数が予定を下回った場合(かつ工数も予定を超過した場合)
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!-- <h2 class="h2-mobile">アイコンは以下のように設定されています。</h2> -->
        <div class="divider">
          <v-divider></v-divider>
        </div>
        <v-list class="explain">
          <v-list-item>
            <v-list-item-content
              :style="{
                backgroundColor: COLOR_FREE_TASK,
                maxWidth: '22%',
                marginRight: '8%',
              }"
            >
              <v-list-item-title class="ml-2">タスク</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content
              >予定通り実施した標準工数以外タスク</v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :style="{
                border: `1px solid ${BORDER_COLOR_TASK_NOT_COMPLETE}`,
                backgroundColor: COLOR_FREE_TASK,
                maxWidth: '22%',
                marginRight: '8%',
              }"
            >
              <v-list-item-title class="ml-2">タスク</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content
              >未実施の標準工数以外タスク</v-list-item-content
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :style="{
                border: `1px solid ${BORDER_COLOR_TASK_NOT_PLAN}`,
                backgroundColor: COLOR_FREE_TASK,
                maxWidth: '22%',
                marginRight: '8%',
              }"
            >
              <v-list-item-title class="ml-2">タスク</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              予定に見込んでいなかったが、実施した標準工数以外タスク
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :style="{
                backgroundColor: COLOR_FREE_TASK_WITHIN_1_2,
                maxWidth: '22%',
                marginRight: '8%',
                color: 'white',
              }"
            >
              <v-list-item-title class="ml-2">タスク</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <div>標準工数以外タスクで予定工数を超過した場合(1.2倍以内)</div>
              <div class="mt-2">
                タスクの実施回数が予定を下回った場合(ただし工数は予定内の場合)
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :style="{
                backgroundColor: COLOR_FREE_TASK_GREATER_1_2,
                maxWidth: '22%',
                marginRight: '8%',
                color: 'white',
              }"
            >
              <v-list-item-title class="ml-2">タスク</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <div>標準工数以外タスクで予定工数を超過した場合(1.2倍以上)</div>
              <div class="mt-2">
                タスクの実施回数が予定を下回った場合(かつ工数も予定を超過した場合)
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div class="divider">
          <v-divider></v-divider>
        </div>
        <v-list class="explain">
          <v-list-item>
            <v-list-item-content
              :style="{
                backgroundColor: COLOR_GRAY,
                maxWidth: '22%',
                marginRight: '8%',
                color: 'white',
              }"
            >
              <v-list-item-title class="ml-2">タスク</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>休憩・イレギュラー勤怠（遅刻・半休など）</v-list-item-content>
          </v-list-item>
        </v-list>
        <div class="divider">
          <v-divider></v-divider>
        </div>
        <v-list class="explain">
          <v-list-item>
            <v-list-item-content
              :style="{ maxWidth: '22%', marginRight: '8%' }"
            >
              <v-icon>$remandtask</v-icon>
            </v-list-item-content>
            <v-list-item-content> 差し戻しタスク </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-content
              :style="{ maxWidth: '25%', marginRight: '8%' }"
            >
              <v-icon>$spottask</v-icon>
            </v-list-item-content>
            <v-list-item-content> 予定未登録のタスク </v-list-item-content>
          </v-list-item> -->
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogDragDirective from "../../../directives/dialogDrag";
import {
  COLOR_FREE_TASK,
  COLOR_DB_TASK,
  COLOR_FREE_TASK_WITHIN_1_2,
  COLOR_FREE_TASK_GREATER_1_2,
  COLOR_DB_TASK_WITHIN_1_2,
  COLOR_DB_TASK_GREATER_1_2,
  BORDER_COLOR_TASK_NOT_COMPLETE,
  BORDER_COLOR_TASK_NOT_PLAN,
  COLOR_GRAY,
} from "../../../constants/color";
export default {
  directives: {
    "dialog-drag": DialogDragDirective,
  },
  props: {
    show: Boolean,
  },
  watch: {
    show: {
      handler() {
        this.openExplain = true;
      },
    },
  },
  data() {
    return {
      openExplain: this.show,
      COLOR_FREE_TASK: COLOR_FREE_TASK,
      COLOR_DB_TASK: COLOR_DB_TASK,
      COLOR_FREE_TASK_WITHIN_1_2: COLOR_FREE_TASK_WITHIN_1_2,
      COLOR_FREE_TASK_GREATER_1_2: COLOR_FREE_TASK_GREATER_1_2,
      COLOR_DB_TASK_WITHIN_1_2: COLOR_DB_TASK_WITHIN_1_2,
      COLOR_DB_TASK_GREATER_1_2: COLOR_DB_TASK_GREATER_1_2,
      BORDER_COLOR_TASK_NOT_COMPLETE: BORDER_COLOR_TASK_NOT_COMPLETE,
      BORDER_COLOR_TASK_NOT_PLAN: BORDER_COLOR_TASK_NOT_PLAN,
      COLOR_GRAY: COLOR_GRAY,
    };
  },
  methods: {
    close() {
      this.openExplain = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .border-dialog {
  // border-radius: 30px;
  .v-card__text {
    padding-top: 10px;
    text-align: center;
    .v-list-item {
      padding: 4px 5px !important;
    }
    .v-list-item__content {
      text-align: left;
      padding: 8px 0 !important;
    }
    .explain {
      padding-left: 30px;
      width: 100%;
      margin: 0 auto;
    }
    .divider {
      padding: 5px 25px 5px 35px;
      hr {
        border-top: 1px solid gray;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    min-width: 340px;
    .v-card__text {
      padding-left: 2px;
      padding-right: 2px;
      .h2-mobile {
        font-size: medium;
        // white-space: nowrap;
      }
      .v-list-item {
        padding-left: 0px;
        padding-right: 0px;
        text-align: left;
      }
      .v-list-item__content {
      margin-right: 8px !important;
    }
      .explain {
        padding-left: 0px;
        width: 100%;
        margin: 0 auto;
      }
      .divider {
        padding: 2px 12px 2px 5px;
      }
    }
  }
}
</style>
<style scoped>
.v-dialog.v-dialog--active .v-card__title {
  cursor: grab;
}
</style>