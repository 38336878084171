import axios from 'axios'
import store from '../store'
import router from '../router'
// import i18n from './i18n'
// import Swal from 'sweetalert2'

// Request interceptor
axios.interceptors.request.use(request => {
  const token = store.getters['auth/token']
  if ((request.url.includes('/api/tasks/standard/user?user_id=') || 
  request.url.includes('/api/tasks/todo?user_id=') 
  || request.url.includes(
      '/api/tasks/split')) && request.method.toUpperCase() == "GET") {
    store.dispatch('loading/partialLoading', true)
  } else {
    store.dispatch('loading/loading', true)
  }
  if (token) {
    // set request header
    request.headers.common['Authorization'] = `Bearer ${token}`
  }

  const locale = store.getters['lang/locale']
  if (locale) {
    request.headers.common['Accept-Language'] = locale
  }

  // request.headers['X-Socket-Id'] = Echo.socketId()

  return request
})

// Response interceptor
axios.interceptors.response.use(response => {
  store.dispatch('loading/partialLoading', false)
  store.dispatch('loading/loading', false)
  return response
}, error => {
  store.dispatch('loading/partialLoading', false)
  store.dispatch('loading/loading', false)
  const {
    status
  } = error.response

  if (status >= 500) {
    //TODO: should replace sweetalert2
    // Swal.fire({
    //   type: 'error',
    //   title: i18n.t('errors.title'),
    //   text: i18n.t('errors.text'),
    //   reverseButtons: true,
    //   confirmButtonText: i18n.t('ok'),
    //   cancelButtonText: i18n.t('cancel')
    // })
  }

  if (status === 401 && store.getters['auth/check']) {
    //TODO: should replace sweetalert2
    // Swal.fire({
    //   type: 'warning',
    //   title: i18n.t('errors.token_expired_title'),
    //   text: i18n.t('errors.token_expired_text'),
    //   reverseButtons: true,
    //   confirmButtonText: i18n.t('ok'),
    //   cancelButtonText: i18n.t('cancel')
    // }).then(() => {
    //   store.commit('auth/LOGOUT')

    //   router.push({ name: 'login' })
    // })
  }
  if(status === 402){
    store.dispatch("auth/logout");
    router.push({ name: 'login' , back_url: location.href})
  }

  return Promise.reject(error)
})
