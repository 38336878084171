import * as types from '../mutation-types'
/**
 * Store state
 */
export const state = {
  isLoading: false,
  isPartialLoading: false,
  refCount: 0,
  refPartialCount: 0
};

/**
 * Store mutations
 */
export const mutations = {
  [types.LOADING](state, payload) {
    if (payload) {
      state.refCount++;
      state.isLoading = true;
    } else if (state.refCount > 0) {
      state.refCount--;
      state.isLoading = (state.refCount > 0);
    }
  },
  [types.PARTIAL_LOADING](state, payload) {
    if (payload) {
      state.refPartialCount++;
      state.isPartialLoading = true;
    } else if (state.refPartialCount > 0) {
      state.refPartialCount--;
      state.isPartialLoading = (state.refPartialCount > 0);
    }
  },
};

/**
 * Store actions
 */
export const actions = {
  loading(context, isLoading) {
    context.commit("LOADING", isLoading);
  },
  partialLoading(context, isPartialLoading) {
    context.commit("PARTIAL_LOADING", isPartialLoading);
  },
};

/**
 * State Getters
 */
export const getters = {
  isLoading: state => state.isLoading,
  isPartialLoading: state => state.isPartialLoading
}
