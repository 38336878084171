<template>
  <v-dialog :value="openDialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title class="card-header pb-2">
        {{ date }}<span class="px-2">日次実績</span
        ><strong class="px-2" v-if="loginInfo">
          {{ ownerName }}
        </strong>
      </v-card-title>

      <v-card-text>
        <v-card-subtitle class="mt-5 ml-5">
          <span class="text-h6 font-weight-bold"> 差し戻し対象</span>
          <p class="ml-3 mt-2" v-if="items.length === 0">全体に対する差し戻し<p>
          <p v-for="item in items" :key="item.id">・{{ item.title }} {{item.detail}}</p>
        </v-card-subtitle>
        <v-container fluid>
          <v-textarea
            v-model="comment"
            clearable
            auto-grow
            clear-icon="mdi-close"
            placeholder="コメントを追加"
            maxlength="512"
            counter
          ></v-textarea>
        </v-container>
      </v-card-text>

      <v-card-actions :class="{
          'custom-button': $vuetify.breakpoint.mobile,
        }">
        <v-spacer></v-spacer>
        <v-btn depressed outlined color="ligth" @click="close()">{{
          $t("label.buttons.cancel")
        }}</v-btn>
        <v-btn
          class="font-weight-bold"
          depressed
          @click="reject"
          outlined
          ><v-icon class="blue--text mr-2" small left>$return</v-icon
          ><span v-if="items.length > 0">{{ `上記${items.length}件を` }}</span>
          <span class="text-large">差し戻し</span></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { backMixin } from "../../../mixins/back";
import {
  formatDateTime,
  parseDateTime,
} from "../../../helpers/datetime-helpers";
import * as Role from "../../../constants/role";
import { yyyyMMddEEEEE3, yyyyMMdd2 } from "../../../constants/df";
import { debounce_leading, isEmpty } from "../../../helpers/helpers";
export default {
  middleware: Role.AUTH,
  props: {
    show: Boolean,
    inputComment: String,
    items: Array,
    ownerName: String,
    selectedDate: String,
  },
  mixins: [backMixin],
  data() {
    return {
      openDialog: this.show,
      comment: this.inputComment,
    };
  },
  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },
  watch: {
    comment: {
      handler(val) {
        this.$store.dispatch("save/setChangeFlag", !isEmpty(val));
      },
    },
    show() {
      this.openDialog = true;
      this.comment = "";
    },
  },
  computed: {
    ...mapGetters("save", ["getChangeFlag"]),
    ...mapGetters("common", ["loginInfo"]),
    date() {
      return formatDateTime(
        parseDateTime(this.selectedDate, yyyyMMdd2),
        yyyyMMddEEEEE3
      );
    },
  },
  methods: {
    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.openDialog = false;
            this.$emit("input");
          }, 100);
        },
        () => {}
      );
    },
    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.openDialog = false;
          this.$emit("input");
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },
    reject : debounce_leading(function() {
      this.$store.dispatch("save/setChangeFlag", false);
      this.openDialog = false;
      this.$emit("reject", this.comment);
    }),
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>

<style lang="scss" scoped>
.text-large {
  font-size: 1rem;
  font-weight: 900;
}
.custom-button {
  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0;
  }
}
</style>
