<template>
  <v-row class="py-5">
    <v-col cols="12" class="py-0 my-0">
      <div class="__header font-weight-bold py-2 pl-1">
        {{ content.header }}
      </div>
      <div class="row d-flex font-weight-bold __content py-4 mx-0">
        <div class="col-5 col-lg-6 col-md-6 d-flex sub-content">
          <div class="align-self-baseline" v-if="productivity.is_good_rate">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="33"
              viewBox="0 0 33 33"
            >
              <circle
                cx="13"
                cy="13"
                r="13"
                transform="translate(5 5)"
                fill="none"
                stroke="#66DAFF"
                stroke-width="4"
              />
            </svg>
          </div>
          <div v-else class="align-self-baseline">
            <div v-if="isTaskWork">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30.774"
                height="30.774"
                viewBox="0 0 30.774 30.774"
              >
                <g transform="translate(-1.351 1.376)">
                  <path
                    d="M6.049,1.1,9.864.769,6.725,37.006l-3.814.33Z"
                    transform="translate(25.694 -3.978) rotate(45)"
                    fill="#ff0000"
                  />
                  <path
                    d="M0,0,3.814.33,6.953,36.568l-3.814-.33Z"
                    transform="translate(1.351 3.541) rotate(-45)"
                    fill="#ff0000"
                  />
                </g>
              </svg>
            </div>
          </div>
          <span
            class="align-self-baseline"
            :class="[
              productivity.is_good_rate
                ? 'is-good-rate'
                : isTaskWork
                ? 'is-not-good-rate'
                : '',
            ]"
          >
            <span class="text-rate" :class="[isMobile ? 'ml-1' : 'ml-5']">{{
              productivity.rate
            }}</span>
            <span class="text-percent">%</span>
          </span>
        </div>
        <div class="col-7 col-lg-6 col-md-6 text-detail">
          <div class="w-rate-detail">
            <div class="d-flex align-baseline rate-detail">
              <span>{{ content.sum_cost_1 }} : </span>
              <span class="ml-2">{{ productivity.sum_cost_1 || "0h0m" }}</span>
            </div>
            <v-divider class="__divider mx-2"></v-divider>
            <div class="d-flex align-baseline rate-detail">
              <span>{{ content.sum_cost_2 }} : </span>
              <span class="ml-2">{{ productivity.sum_cost_2 || "0h0m" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center __footer" @click="showTable = !showTable">
        <div class="mt-3">
          <span class="text-show-hide">{{ content.footer }}</span>
        </div>
        <div>
          <v-btn color="#7F7F7F" text small>
            <v-icon v-if="showTable"> mdi-chevron-down </v-icon>
            <v-icon v-else> mdi-chevron-up </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="__table" v-show="showTable">
        <v-data-table
          :headers="header"
          class="h-100 contentTable font-weight-bold"
          mobile-breakpoint="0"
          fixed-header
          :items="productivity.day_detail"
          :hide-default-footer="true"
          item-key="day"
          disable-pagination
        >
          <template v-slot:[`item.day`]="{ item }">
            {{ item.day_format }}
          </template>
          <template v-slot:[`item.is_good_rate`]="{ item }">
            <v-tooltip bottom color="transparent">
              <template v-slot:activator="{ on }">
                <div v-on="on" v-if="item.is_good_rate === 1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <circle
                      cx="6"
                      cy="6"
                      r="6"
                      transform="translate(2 2)"
                      fill="none"
                      stroke="#66DAFF"
                      stroke-width="2"
                    />
                  </svg>
                </div>
                <div v-else v-on="on">
                  <div v-if="isTaskWork">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                    >
                      <g transform="translate(-1.351 -0.437)">
                        <path
                          d="M2.06.342,3.7.229,2.625,15.79.987,15.9Z"
                          transform="translate(11.899 -0.423) rotate(45)"
                          fill="#FF0000"
                        />
                        <path
                          d="M0,0,1.638.113,2.711,15.674l-1.638-.113Z"
                          transform="translate(1.351 2.354) rotate(-45)"
                          fill="#FF0000"
                        />
                      </g>
                    </svg>
                  </div>
                  <div v-else>
                    <div class="dash-box d-flex align-center">
                      <div class="dash"></div>
                    </div>
                  </div>
                </div>
              </template>
              <v-card>
                <v-card-text class="pt-5">
                  <h2>【評価の基準について】</h2>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <div class="d-flex align-center">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <circle
                                cx="6"
                                cy="6"
                                r="6"
                                transform="translate(2 2)"
                                fill="none"
                                stroke="#66DAFF"
                                stroke-width="2"
                              />
                            </svg>
                          </div>
                          <span v-if="isStandard">・・・80%以上</span>
                          <span v-else>・・・100%以上</span>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <div v-if="isTaskWork" class="d-flex align-center">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                            >
                              <g transform="translate(-1.351 -0.437)">
                                <path
                                  d="M2.06.342,3.7.229,2.625,15.79.987,15.9Z"
                                  transform="translate(11.899 -0.423) rotate(45)"
                                  fill="#FF0000"
                                />
                                <path
                                  d="M0,0,1.638.113,2.711,15.674l-1.638-.113Z"
                                  transform="translate(1.351 2.354) rotate(-45)"
                                  fill="#FF0000"
                                />
                              </g>
                            </svg>
                          </div>
                          <span>・・・100%未満</span>
                        </div>
                        <div v-else class="d-flex align-center">
                          <div class="dash ml-1"></div>
                          <span v-if="isStandard">・・・80%未満</span>
                          <span v-else>・・・100%未満</span>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tooltip>
          </template>
          <template v-slot:[`item.day_rate`]="{ item }">
            {{ item.day_rate }}%
          </template>
          <template v-slot:[`item.url`]="{ item }">
            <div v-if="hasActual(item, item.day)">
              <p v-for="user in getUserList(item, item.day)" :key="user.user_id">
                <a v-if="user.is_deleted == 0"
                  class="url-detail"
                  :href="editUserLink(user, item)"
                  >{{ user.user_name }}</a
                >
                <span v-else>
                  {{ user.user_name }}
                        </span>
              </p>
            </div>
            
          </template>
        </v-data-table>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { formatDateTime, parseDateTime } from "@/helpers/datetime-helpers";
import { yyyyMMdd1, yyyyMMdd2 } from "@/constants/df";
import { queryRouterMixin } from "@/mixins/queryRouter.js";
export default {
  name: "ProductivityDetail",
  mixins: [queryRouterMixin],
  props: {
    content: Object,
    productivity: Object,
    header: {
      type: Array,
      default: () => [],
    },
    isStandard: Boolean,
    isTaskWork: Boolean,
  },
  data() {
    return {
      showTable: false,
    };
  },
  computed: {
    isMobile() {
      return ["xs", "sm"].includes(this.$vuetify.breakpoint.name);
    },
  },
  methods: {
    editUserLink(user, item){
      let redirectDate = formatDateTime(
        parseDateTime(item.day, yyyyMMdd1),
        yyyyMMdd2
      );
      let router = `/actual?uid=${user.user_id}&date=${redirectDate}`;
      return router;
    },

    getUserList(item, day){
      return item.user_list[day];
    },

    hasActual(item, day){
      return item.user_list[day] !== undefined && item.user_list[day] !== null;
    },
  },
};
</script>
<style lang="scss" src="@/assets/scss/_productivity.scss" scoped></style>