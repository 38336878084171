

<script>
import { VListItemContent } from "vuetify/lib";
export default {
   components: {
    'v-list-item-content': VListItemContent,
  },
  render(createElement) {
    return createElement(
      this.tag,
      {
        on: Object.assign(this.$listeners, {
          click: this.handleClick
        })
      },
      this.$slots.default
    );
  },
  data() {
    return {
      clickCount: 0,
      clickTimer: null
    };
  },
  props: {
    tag: {
      type: String,
      default: "div"
    },
    delay: {
      type: Number,
      default: 250
    }
  },
  methods: {
    handleClick(e) {
      e.preventDefault();

      this.clickCount++;

      if (this.clickCount === 1) {
        this.clickTimer = setTimeout(() => {
          this.clickCount = 0;
          this.$emit("single-click");
        }, this.delay);
      } else if (this.clickCount === 2) {
        clearTimeout(this.clickTimer);
        this.clickCount = 0;
        this.$emit("double-click");
      }
    }
  }
};
</script>
