<template>
  <v-dialog scrollable :value="value" @input="$emit('input')" max-width="600px">
    <v-card class="px-3">
      <v-card-title>
        <v-row class="py-1" align="center" justify="center">
          <v-col class="text-center" md="5">
            <h3 v-if="!mobile">プロフィール情報</h3>
            <span class="customTextP" v-else>プロフィール情報</span>
          </v-col>
          <v-col class="text-right profile-avatar" md="7">
            <avatar-upload
              :fileId="userInfo.file_id"
              class="ml-auto"
              :visibled="false"
            ></avatar-upload>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row class="py-1" align="center" justify="center">
          <v-col class="text-center" md="5">名前</v-col>
          <v-col class="text-left" md="7">{{ userInfo.name }}</v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="py-1" align="center" justify="center">
          <v-col class="text-center" md="5">ナマエ</v-col>
          <v-col class="text-left" md="7">{{ userInfo.name_kana }}</v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="py-1" align="center" justify="center">
          <v-col class="text-center" md="5">雇用形態</v-col>
          <v-col class="text-left" md="7">
            {{ userInfo.employee_type_name }}
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="py-1" align="center" justify="center">
          <v-col class="text-center" md="5">就業時間</v-col>
          <v-col class="text-left" md="7">
            <span>{{ workingTime }}</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="py-1" align="center" justify="center">
          <v-col class="text-center" md="5">所属部署＞所属Gr</v-col>
          <v-col class="text-left" md="7">{{ dgName }}</v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="py-1" align="center" justify="center">
          <v-col class="text-center" md="5">役職</v-col>
          <v-col class="text-left" md="7">{{ userInfo.position_name }}</v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="py-1" align="center" justify="center">
          <v-col class="text-center" md="5">直属上長</v-col>
          <v-col class="text-left" md="7">{{ (userInfo.position_id === 630 && userInfo.approval_auth_id === 30) ? '' : userInfo.manage_name }}</v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="py-1" align="center" justify="center">
          <v-col class="text-center" md="5">システム権限</v-col>
          <v-col class="text-left" md="7">{{
            userInfo.system_auth_name
          }}</v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="py-1" align="center" justify="center">
          <v-col class="text-center" md="5">承認権限</v-col>
          <v-col class="text-left" md="7">{{
            userInfo.approval_auth_name
          }}</v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="py-1" align="center" justify="center">
          <v-col class="text-center" md="5">入社日</v-col>
          <v-col class="text-left" md="7">{{ joiningDate }}</v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="py-1" align="center" justify="center">
          <v-col class="text-center" md="5">日次予定の提出</v-col>
          <v-col class="text-left" md="7">{{ isNeedSubmit }}</v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="py-1" align="center" justify="center">
          <v-col class="text-center" md="5" cols="6">メールアドレス</v-col>
          <v-col class="text-left" md="7" cols="6">{{ userInfo.mail }}</v-col>
        </v-row>
        <v-divider></v-divider>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn depressed outlined color="light" @click="close()"> 閉じる</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  formatDateTime,
  parseDateTime,
  formatTime,
} from "../../../helpers/datetime-helpers";
import AvatarUpload from "../../../components/AvatarUpload.vue";
import { yyyyMMdd3 } from "../../../constants/df";
export default {
  components: { AvatarUpload },
  props: {
    value: Boolean,
    user: {},
  },
  data() {
    return {
      userInfo: {},
      openDialog: false,
    };
  },
  watch: {
    user: {
      handler(val) {
        this.userInfo = val;
      },
    },
  },
  computed: {
    breakTime: function () {
      if (this.userInfo.start_break_time || this.userInfo.end_break_time)
        return (
          formatTime(this.userInfo.start_break_time) +
          "~" +
          formatTime(this.userInfo.end_break_time)
        );
      else return "";
    },

    workingTime: function () {
      if (this.userInfo.start_time || this.userInfo.end_time) {
        return (
          formatTime(this.userInfo.start_time) +
          "~" +
          formatTime(this.userInfo.end_time)
        );
      } else return "シフト制のため未設定";
    },
    dgName: function () {
      if (!this.userInfo.department_name) {
        return this.userInfo.group_name || "";
      } else {
        return `${this.userInfo.department_name} ＞ ${this.userInfo.group_name}`;
      }
    },
    joiningDate: function () {
      return formatDateTime(
        parseDateTime(this.userInfo.joining_date),
        yyyyMMdd3
      );
    },
    isNeedSubmit: function () {
      return this.userInfo.is_need_submit !== 1 ? "不要" : "要";
    },
    mobile() {
      return ["sm", "xs"].includes(this.$vuetify.breakpoint.name);
    },
  },
  methods: {
    close() {
      this.openDialog = false;
      this.$emit("input", this.openDialog);
    },
  },
};
</script>

<style scoped lang="scss">
.profile-avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.customTextP {
  font-size: 11px;
  font-weight: 900;
}
</style>
