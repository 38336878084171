<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="mr-4" icon>
        <v-icon small>$tool</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="allowChangeGapFunction()">
        <v-list-item-icon>
          <v-icon small>$hamburgermenu</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>表示間隔を変更</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item @click="allowChangeOrderFuction()">
        <v-list-item-icon>
          <v-icon small>$reorder</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>列を並び替え</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "SortSetting",
  props: {
    show: Boolean,
  },
  watch: {
    show: {
      handler() {
        this.openSetting = true;
      },
    },
  },
  data() {
    return {
      openSetting: false,
      allowChangeOrder: false,
      allowChangeGap: false,
    };
  },
  methods: {
    allowChangeOrderFuction() {
      this.allowChangeOrder = true;
      this.$emit("allowChangeOrder", this.allowChangeOrder);
    },
    allowChangeGapFunction() {
      this.allowChangeOrder = true;
      this.$emit("allowChangeGap", this.allowChangeGap);
    },
  },
};
</script>

<style>
</style>
