<template>
  <v-dialog
    v-model="openTodoTask"
    persistent
    max-width="600px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card class="hidden">
      <v-card-title class="card-header py-2">
        <v-btn class="ml-auto" @click="close()" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <template>
          <v-row class="py-4">
            <v-col class="d-flex align-center">
              <v-icon v-if="task.is_favorite" @click="setFavorite(false)" small
                >$favoriteactive</v-icon
              >
              <v-icon v-else small @click="setFavorite(true)"
                >$favoriteinactive</v-icon
              >
              <span class="ml-2 work-break">{{ task.name }}</span>
              <div class="detail" :class="[{ 'd-none': isEmptyFreeDetail(task) }]">
                <span class="ml-2">{{ task.detail }}</span>
              </div>
              <!-- <div class="detail">
                <v-text-field
                  class="ml-2"
                  v-model="task.detail"
                  maxlength="40"
                  placeholder="タスク詳細を追加"
                ></v-text-field>
              </div> -->
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="py-3 my-0">
            <v-col>
              <v-icon small>$taskexplanation</v-icon>
              <span class="ml-2 work-break">{{ task.description }}</span>
            </v-col>
          </v-row>

          <v-row class="py-0 my-0">
            <v-col cols="12" class="py-0 my-0">
              <v-icon small>$taskcategory</v-icon>
              <span class="ml-2"> {{ primaryCategoryName }}</span>
            </v-col>
            <v-col cols="12" class="pt-2 pb-0 my-0">
              <span class="ml-7"> {{ task.category_secondary_name }}</span>
            </v-col>
          </v-row>

          <v-row class="py-1 my-1" v-if="isDbTask(task) && task.task_type_id">
            <v-col class="d-flex align-center">
              <v-icon small>$taskType</v-icon>
              <span class="ml-2 work-break"
                >{{ labelType }} : {{ task.task_type_name || "" }}</span
              >
            </v-col>
          </v-row>

          <!-- <v-row class="py-0 my-0 d-flex align-baseline">
            <v-col cols="3" class="py-0">
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2 w-120">工数</span></v-col
            >
            <v-col
              cols="9"
              class="py-0 d-flex align-baseline"
              :class="[$vuetify.breakpoint.mobile ? 'pl-0' : '']"
            >
              <v-text-field
                :error-messages="costErrors"
                suffix="m/回"
                type="number"
                max="999"
                min="1"
                readonly
                v-model="task.cost"
                class="w-90"
              >
              </v-text-field>
              <span class="mx-2">×</span>
              <v-text-field
                type="number"
                max="999"
                min="1"
                :error-messages="countErrors"
                suffix="回"
                readonly
                v-model="task.count"
                class="w-60"
              >
              </v-text-field>
              <span class="mx-2">=</span>
              <v-text-field
                class="w-95"
                readonly
                type="number"
                :value="task.cost * task.count"
                suffix="m"
              ></v-text-field
            ></v-col>
          </v-row> -->
          <v-row v-if="!isSplit" class="py-1 my-1">
            <v-col class="d-flex align-center py-0 my-0"
              ><v-icon small>$taskworktime</v-icon>
              <span class="ml-2" v-if="isDbTask(task)">標準工数</span>
              <span class="ml-2" v-if="isFreeTask">工数</span>
              <div class="ml-3 d-flex align-center">
                <span>{{ task.cost }}m/回</span>
                <span class="mx-2">×</span>
                <span>{{ task.count }}回</span>
                <span class="mx-2">=</span>
                <span>{{ task.cost * task.count }}m</span>
              </div>
            </v-col>
            <v-col class="col-12 pt-2 pb-0 pl-10" v-if="isDbTask(task)">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="text-break-all">{{ task.unit || '' }}</span>
            </v-col>
          </v-row>
        <template v-if="isSplit">
          <v-row>
            <v-col>
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2">標準工数</span>
              <span class="mx-2">{{ task.cost }}m/回</span>
            </v-col>
          </v-row>
          <div>
            <v-row>
              <v-col>
                <div class="d-flex">
                  <span class="ml-7">累計実績</span>
                  <span class="ml-1">{{ task.total_actual_cost }} m</span>
                  <span class="ml-1 mr-1">/</span>
                  <span class="">{{ task.total_cost }} m</span>
                </div>
              </v-col>
               <v-col class="col-12 pb-1 pl-10" v-if="isDbTask(task)">
                  <span>※標準工数/回の業務量定義（件数など）：</span>
                  <span class="text-break-all">{{ task.unit || '' }}</span>
                </v-col>
            </v-row>
          </div>
        </template>
          <v-row class="py-0 my-0">
            <v-col class="py-0 d-flex align-center">
              <span style="margin-left: -16px" class="red--text mr-2">*</span>
              <v-icon small>$taskstartendtime</v-icon>
              <div class="ml-2 d-flex align-center">
                <v-text-field
                  v-model="task.start_time"
                  :error-messages="startTimeErrors"
                  maxlength="5"
                  type="text"
                  @input="changeStartTime()"
                  @blur="formatStartTime($event)"
                  @keyup.enter.native="formatStartTime($event)"
                >
                </v-text-field>
                <span class="mx-2">~</span>
                <v-text-field
                  v-model="task.end_time"
                  :error-messages="endTimeErrors"
                  maxlength="5"
                  readonly
                  type="text"
                >
                </v-text-field>
              </div>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col class="py-0">
              <div class="header d-flex align-center">
                <v-icon small>$output</v-icon>
                <span class="ml-2">アウトプット</span>
                <v-checkbox
                  v-model="task.is_need_output"
                  class="ml-3"
                  :label="!!task.is_need_output ? '必要' : '不要'"
                  readonly
                ></v-checkbox>
              </div>
              <div v-if="!!task.is_need_output" class="mt-2 ml-2 category">
                アウトプット内容： {{ task.output_detail }}
              </div>
            </v-col>
          </v-row> -->
        </template>

        <v-row class="mt-6">
          <v-col class="py-0">
            <v-icon small class="mr-2">$tag</v-icon>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pa-0">
            <v-container justify="space-around">
              <v-sheet class="px-1">
                <v-chip-group mandatory>
                  <v-tooltip bottom v-for="tag in task.tags" :key="tag.id">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        close
                        @click:close="deleteTag(tag)"
                        close-icon="mdi-close"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 100px"
                        >
                          {{ tag.name }}
                        </span>
                      </v-chip>
                    </template>
                    <span>{{ tag.name }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-if="!showComment" class="py-0">
            <v-icon small>$comment</v-icon>
            <v-list v-if="hasComment">
              <v-list-item v-for="item in task.comments" :key="item.id">
                <v-list-item-icon>
                  <v-icon small>$commonuser</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <p>
                    {{ item.user_name }}
                    <span v-if="!$vuetify.breakpoint.mobile" class="ml-5">{{
                      getCommentDate(item.updated_at)
                    }}</span>
                  </p>
                  <p v-if="$vuetify.breakpoint.mobile">
                    {{ getCommentDate(item.updated_at) }}
                  </p>
                  <p class="text-wrap work-break">
                    {{ item.comment }}
                  </p>
                </v-list-item-content>
                <v-list-item-action
                  style="align-self: flex-start"
                  @click="deleteComment(item)"
                >
                  <v-icon small>$delete</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col v-else class="py-0 d-flex align-items-baseline">
            <v-icon small>$comment</v-icon>
            <v-textarea
              class="mt-0 ml-2"
              v-model="userComment"
              placeholder="コメントを追加"
              counter
              maxlength="512"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          tile
          :disabled="!isEditing"
          class="ml-auto"
          color="primary"
          @click="addToCalendar()"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
    <tag-list
      v-model="openTagDialog"
      :items="tagList"
      :existItems="existItems"
      @selectTag="selectTagModal"
      @deleteTag="deleteTagModal"
      @updateTag="updateTagModal"
    ></tag-list>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { backMixin } from "../../../mixins/back";
import { taskMixin } from '@/mixins/task.js';
import { required } from "vuelidate/lib/validators";
import {
  CATEGORIES_API,
  TODO_TASK_API,
  TAG_API,
  DELETE_COMMENT_API,
  SPLIT_TASK_API,
} from "../../../constants/api";
import axios from "axios";
import { mapGetters } from "vuex";
import {
  debounce_leading,
  deepClone,
  isEmptyNumber,
  orderBy,
  isEmpty,
} from "../../../helpers/helpers";
import {
  workTimeValidator,
  timeValidator,
  compareDateValidator,
} from "../../../helpers/validator";
import {
  formatDateTime,
  parseDateTime,
  toHHmm,
  toMinute,
  toMinuteForHHmm,
  toHHmmFormat,
} from "../../../helpers/datetime-helpers";
import TagList from "./TagList.vue";
import { apiErrorMessages } from "../../../helpers/messages";
import {
  FC701,
  FC807,
  FC809,
  FC813,
  FC815,
  FC820,
  FC823,
} from "../../../constants/fc";
import { yyyyMMddHHmm2, yyyyMMddHHmmss3 } from "../../../constants/df";
import { STATUS_NO_CONTENT } from "../../../constants/status";
export default {
  components: { TagList },
  props: {
    show: Boolean,
    taskId: Number,
    isSplit: Boolean
  },
  mixins: [validationMixin, backMixin, taskMixin],
  validations: {
    task: {
      cost: {
        required,
        workCompare: workTimeValidator("count"),
      },
      count: {
        required,
        workCompare: workTimeValidator("cost"),
      },
      start_time: { required, timeValidator },
      end_time: {
        required,
        timeValidator,
        compareDate: compareDateValidator("start_time"),
      },
    },
  },
  data() {
    return {
      isFavorite: true,
      openTodoTask: false,
      openTagDialog: false,
      showComment: false,
      parentCategories: [],
      childCategories: [],
      primaryCategory: {},
      tagList: [],
      existItems: [],
      userComment: "",
      originalTask: {},
      task: {
        name: "",
        description: "",
        cost: "",
        count: "",
        category_secondary_id: null,
        is_need_output: false,
        output_detail: "",
        is_favorite: false,
        tags: [],
        comments: [],
      },
      isEditing: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["loginInfo"]),
    ...mapGetters("save", ["getChangeFlag"]),
    nameErrors() {
      const errors = [];
      if (this.$v.task.name.$dirty) {
        !this.$v.task.name.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    primaryCategoryName() {
      return !isEmptyNumber(this.task.category_secondary_id)
        ? this.task.category_primary_name
        : "未分類";
    },
    outputDetailErrors() {
      const errors = [];
      if (this.$v.task.output_detail.$dirty) {
        !this.$v.task.output_detail.required &&
          this.task.is_need_output &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    costErrors() {
      const errors = [];
      if (this.$v.task.cost.$dirty) {
        !this.$v.task.cost.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.cost.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    countErrors() {
      const errors = [];
      if (this.$v.task.count.$dirty) {
        !this.$v.task.count.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.count.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },

    startTimeErrors() {
      const errors = [];
      if (this.$v.task.start_time.$dirty) {
        !this.$v.task.start_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.start_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    endTimeErrors() {
      const errors = [];
      if (this.$v.task.end_time.$dirty) {
        !this.$v.task.end_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.end_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
        !this.$v.task.end_time.compareDate &&
          errors.push(this.$t("label.error.end_time"));
      }
      return errors;
    },
    primaryName() {
      let p = this.parentCategories.find(
        (x) => x.id === this.task.category_primary_id
      );
      return p ? p.name : "未分類";
    },
    secondaryName() {
      if (this.childCategories) {
        let s = this.childCategories.find(
          (x) => x.id === this.task.category_secondary_id
        );
        return s ? s.name : "";
      } else {
        return "";
      }
    },
    showSecondCategory() {
      return !isEmptyNumber(this.task.category_primary_id);
    },
    hasComment() {
      return this.task && this.task.comments && this.task.comments.length > 0;
    },
    isSpotTask() {
      return isEmptyNumber(this.task.schedule_task_id);
    },
    isFreeTask() {
      return !isEmptyNumber(this.task.free_task_id);
    },
  },
  watch: {
    async show() {
      this.openTodoTask = true;
      this.$v.$reset();
      await this.getData();
      this.showComment = false;
    },
    task: {
      deep: true,
      handler() {
        this.isEditing = this.compareValue();
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
  },
  methods: {
    changeStartTime() {
      if (!this.$v.task.start_time.$invalid) {
        let hasColon = this.task.start_time.indexOf(":") >= 0;
        if (hasColon) this.calculateTime();
        else this.calculateTimeForHHmm();
      }
    },

    formatStartTime($event) {
      if (!this.$v.task.start_time.$invalid) {
        const value = $event.target.value;
        let hasColon = value.indexOf(":") >= 0;
        if (!hasColon) {
          this.task.start_time = toHHmmFormat(value);
        }
      }
    },

    calculateTime() {
      let startTime = toMinute(this.task.start_time, ":");
      let endTime = toMinute(this.task.end_time, ":");
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },
    calculateTimeForHHmm() {
      let startTime = toMinuteForHHmm(this.task.start_time);
      let endTime = toMinuteForHHmm(this.task.end_time);
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },
    isOwner(item) {
      return item.user_id === this.loginInfo.user_id;
    },

    isEmptyFreeDetail(item){
      return !isEmptyNumber(item.free_task_id) && isEmpty(item.detail);
    },

    isDbTask(item) {
      return (
        isEmptyNumber(item.free_task_id) &&
        !isEmptyNumber(item.standard_task_id) &&
        !isEmptyNumber(item.standard_task_revision)
      );
    },

    checkMaxLength(type, $event) {
      if ($event.keyCode === 8 || $event.keyCode === 46) return true;
      if (type === "cost") {
        if (this.$v.task.cost.required) {
          if (this.task.cost.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      } else {
        if (this.$v.task.count.required) {
          if (this.task.count.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      }
    },

    deleteComment(item) {
      if (!this.isOwner(item)) return;
      this.$confirm(this.$t("label.confirm.delete_comment"), {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
        rtl: true,
      })
        .then(async () => {
          try {
            await axios.delete(DELETE_COMMENT_API(item.id));
            this.task.comments = this.task.comments.filter(
              (x) => x.id !== item.id
            );
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC820, status);
          }
        })
        .catch(() => {});
    },

    async deleteTag(item) {
      try {
        let param = {};
        if (!this.isFreeTask) {
          param = { standard_task_id: this.task.standard_task_id };
        } else {
          param = { free_task_id: this.task.free_task_id };
        }
        await axios.delete(TAG_API(item.id), {
          data: param,
        });
        this.task.tags = this.task.tags.filter((x) => x.id !== item.id);
        this.originalTask.tags = this.originalTask.tags.filter(
          (x) => x.id !== item.id
        );
        this.$emit("updateTag", this.task);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC815, status);
      }
    },

    deleteTagModal(tag) {
      this.task.tags = this.task.tags.filter((x) => x.id !== tag.id);
      this.originalTask.tags = this.originalTask.tags.filter(
        (x) => x.id !== tag.id
      );
    },

    updateTagModal(tag) {
      let t = this.task.tags.filter((x) => x.id === tag.id)[0];
      if (t) t.name = tag.name;
      let ot = this.originalTask.tags.filter((x) => x.id === tag.id)[0];
      if (ot) ot.name = tag.name;
    },

    selectTagModal(items) {
      this.task.tags = this.task.tags.concat(
        items.map((x) => {
          return {
            id: x.id,
            name: x.name,
          };
        })
      );
      this.openTagDialog = false;
    },

    createTag: debounce_leading(async function () {
      try {
        let { data, status } = await axios.get(
          `${TAG_API()}` + `?user_id=${this.user.user_id}`
        );
        this.tagList =
          status === STATUS_NO_CONTENT
            ? []
            : data.result.map((x) => {
                return { ...x, ...{ check: false } };
              });
        this.existItems = this.task.tags.map((x) => {
          return {
            tag_id: x.id,
            tag_name: x.name,
          };
        });
        this.openTagDialog = true;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC813, status);
      }
    }),

    setFavorite(value) {
      this.task.is_favorite = value;
    },

    primaryCategoryChange(e) {
      this.childCategories = this.parentCategories.find(
        (x) => x.id === e.id
      )?.secondaries;

      this.primaryCategory = e;
      this.task.category_primary_id = e.id;
      this.task.category_secondary_id = null;
    },

    secondaryCategoryChange(e) {
      this.task.category_secondary_id = e.id;
    },

    updateComment() {
      this.showComment = true;
    },

    addToCalendar() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        // type something
      } else {
        this.openTodoTask = false;
        this.$emit("addToCalendar", this.task);
      }
    },

    getCommentDate(date) {
      return formatDateTime(
        parseDateTime(date, yyyyMMddHHmmss3),
        yyyyMMddHHmm2
      );
    },

    async getCategories() {
      try {
        const { data, status } = await axios.get(CATEGORIES_API());

        this.parentCategories = status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC701, status);
      }
    },

    async getData() {
       try {
        const { data, status } = !this.isSplit
          ? await axios.get(TODO_TASK_API(this.taskId))
          : await axios.get(SPLIT_TASK_API(this.taskId));
        this.task =
          status === STATUS_NO_CONTENT ? {} : deepClone(data.result[0]);
        this.originalTask =
          status === STATUS_NO_CONTENT ? {} : deepClone(data.result[0]);
        if (this.isSplit) {
          this.task.count = 1;
          this.originalTask.count = 1;
          this.task.isSplit = this.isSplit;
          this.originalTask.isSplit = this.isSplit;
        }
        this.task.comments = orderBy(
          this.task.comments,
          ["updated_at"],
          ["desc"]
        );
        this.originalTask.comments = orderBy(
          this.originalTask.comments,
          ["updated_at"],
          ["desc"]
        );
        await this.getCategories();
        this.parentCategories.push({
          id: null,
          name: "未分類",
          secondaries: [],
        });

        this.childCategories = this.parentCategories.find(
          (x) => x.id === this.originalTask.category_primary_id
        )?.secondaries;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, this.isSplit ? FC823 : FC807, status);
      }
    },

    async deleteTask() {
      this.$confirm(this.$t("label.confirm.delete_task"), {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
        rtl: true,
      })
        .then(async () => {
          try {
            await axios.delete(TODO_TASK_API(this.taskId));
            this.openTodoTask = false;
            this.$emit("deleteTodoTask", this.task);
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC809, status);
          }
        })
        .catch(() => {});
    },

    compareValue() {
      return (
        JSON.stringify({
          is_favorite: this.task.is_favorite,
          name: this.task.name,
          description: this.task.description,
          is_need_output: this.task.is_need_output,
          output_detail: this.task.output_detail,
          category_primary_id: this.task.category_primary_id,
          category_secondary_id: this.task.category_secondary_id,
          userComment: "",
          files: this.task.files,
          cost: this.task.cost,
          count: this.task.count,
          tags: this.task.tags,
          start_time: this.task.start_time,
          end_time: this.task.end_time,
        }) !==
        JSON.stringify({
          is_favorite: this.originalTask.is_favorite,
          name: this.originalTask.name,
          description: this.originalTask.description,
          is_need_output: this.originalTask.is_need_output,
          output_detail: this.originalTask.output_detail,
          category_primary_id: this.originalTask.category_primary_id,
          category_secondary_id: this.originalTask.category_secondary_id,
          userComment: "",
          files: this.originalTask.files,
          cost: this.originalTask.cost,
          count: this.originalTask.count,
          tags: this.originalTask.tags,
          start_time: this.originalTask.start_time,
          end_time: this.originalTask.end_time,
        })
      );
    },

    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.openTodoTask = false;
          }, 100);
        },
        () => {}
      );
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.$v.$reset();
          this.openTodoTask = false;
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}
.w-120 {
  width: 120px;
}

.w-90 {
  width: 90px;
}

.align-items-baseline {
  align-items: baseline;
}
.w-60 {
  width: 60px;
}
.w-95 {
  width: 95px;
}
.w-95,
.w-60 {
  .v-text-field__details {
    width: 100%;
  }
}
.work-break {
  word-break: break-all;
}
.detail {
  margin-left: auto;
  margin-right: 0;
  min-width: 200px;
  max-width: 250px;
}
</style>
