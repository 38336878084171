<template>
  <v-dialog
    :value="openDialog"
    @input="$emit('input')"
    persistent
    max-width="500px"
    scrollable
  >
    <v-card>
      <v-card-title class="pb-3"> 日次実績 </v-card-title>
      <v-card-text>
        <v-card-subtitle class="ml-4">
          <v-icon small>$calendar</v-icon>
          <span> {{ date }}</span>
        </v-card-subtitle>
        <v-card-subtitle class="pb-2 ml-4 font-weight-bold">
          ▼ 「タスクを一部着手して後日継続予定」に変更したタスク
        </v-card-subtitle>
        <v-list-item v-for="(task, i) in tasks" :key="task.id" class="word-break">
          {{ i + 1 }}. {{ task.name }}
        </v-list-item>
        <v-card-subtitle class="ml-4 font-weight-bold">
          ▼ 変更した理由
        </v-card-subtitle>
        <v-container fluid>
          <v-textarea
            v-model="comment"
            clearable
            auto-grow
            clear-icon="mdi-close"
            placeholder="コメントを追加"
            maxlength="512"
            counter
          ></v-textarea>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed outlined color="ligth" @click="close()">{{
          $t("label.buttons.cancel")
        }}</v-btn>
        <v-btn
          depressed
          outlined
          @click="submit"
          color="ligth"
          class="font-weight-bold"
          :disabled="!isEditing"
          ><v-icon class="blue--text mr-2" small right>$submitorconfirm</v-icon
          >提出</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { backMixin } from "../../../mixins/back";
import {
  formatDateTime,
  parseDateTime,
} from "../../../helpers/datetime-helpers";
import * as Role from "../../../constants/role";
import { yyyyMMddEEEEE3, yyyyMMdd2 } from "../../../constants/df";
import { debounce_leading, isEmpty } from "../../../helpers/helpers";
export default {
  middleware: Role.AUTH,
  props: {
    show: Boolean,
    inputComment: {type: String, default: ""},
    selectedDate: String,
    tasks: {
      type: Array,
      default: () => [],
    }
  },
  mixins: [backMixin],
  data() {
    return {
      openDialog: this.show,
      comment: "",
      isEditing: false,
    };
  },
  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },
  watch: {
    comment: {
      handler(val) {
        this.$store.dispatch("save/setChangeFlag", !isEmpty(val));
        if (!isEmpty(val)){
          this.isEditing = true;
        }
        else{
          this.isEditing = false;
        }
      },
    },
    async show() {
      this.openDialog = true;
      this.isEditing = false;
      this.comment = this.inputComment;
    },
  },
  computed: {
    ...mapGetters("save", ["getChangeFlag"]),
    date() {
      return formatDateTime(
        parseDateTime(this.selectedDate, yyyyMMdd2),
        yyyyMMddEEEEE3
      );
    },
  },
  methods: {
    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.openDialog = false;
            this.$emit("input");
          }, 100);
        },
        () => {}
      );
    },
    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.openDialog = false;
          this.$emit("input");
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },
    submit: debounce_leading(function () {
      this.$store.dispatch("save/setChangeFlag", false);
      this.openDialog = false;
      this.$emit("submit", this.comment);
    }),
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>
<style lang="scss">
.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.v-list-item {
  align-items: inherit;
}
.word-break {
  word-break: break-all;
}
.actual_cheak.label{
  font-size: 6px !important;
}
</style>

