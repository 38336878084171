<template>
  <v-dialog
    v-model="openFreeTask"
    persistent
    max-width="600px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
    content-class="safrTask"
    v-dialog-drag="{
      dialog: 'safrTask',
    }"
    overlay-color="transparent"
    overlay-opacity="1"
  >
    <v-card :id="parentId">
      <v-card-title class="card-header py-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="copyLink()"
              class="ml-auto"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$link</v-icon>
            </v-btn>
          </template>
          <span>タスクのリンクをコピー</span>
        </v-tooltip>

        <v-tooltip bottom v-if="!isSupervisor() && canSplitCheck()">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="isCanSubmit() && !isSupervisor() && archiveTask()"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$archive</v-icon>
            </v-btn>
          </template>
          <span>タスクをアーカイブ</span>
        </v-tooltip>

        <v-tooltip bottom v-if="!isSupervisor() && canSplitCheck()">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="isCanSubmit() && !isSupervisor() && duplicateTask()"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$copy</v-icon>
            </v-btn>
          </template>
          <span>タスクを複製</span>
        </v-tooltip>

        <v-tooltip bottom v-if="!isSupervisor() && canSplitCheck()">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="isCanSubmit() && !isSupervisor() && deleteTask()"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$delete</v-icon>
            </v-btn>
          </template>
          <span>タスクを削除</span>
        </v-tooltip>
        <v-btn @click="close()" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="card-content">
        <v-row class="py-0 my-0">
          <v-col class="d-flex align-center" v-if="!isSupervisor()">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <div @click="setFavorite(task)">
              <v-icon v-if="task.is_favorite" small>$favoriteactive</v-icon>
              <v-icon v-else small>$favoriteinactive</v-icon>
            </div>
            <v-text-field
              class="ml-2"
              maxlength="60"
              v-model="task.name"
              :error-messages="nameErrors"
              placeholder="タスク名を追加"
            >
            </v-text-field>
          </v-col>
          <v-col v-else class="d-flex align-center">
            <v-icon v-if="task.is_favorite" small>$favoriteactive</v-icon>
            <v-icon v-else small>$favoriteinactive</v-icon>
            <span class="ml-2 work-break">{{ task.name }}</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="py-0 my-0">
          <v-col v-if="!isSupervisor()" class="d-flex align-center">
            <v-icon small>$taskexplanation</v-icon>
            <v-textarea
              rows="1"
              class="ml-2"
              auto-grow
              maxlength="512"
              v-model="task.description"
              placeholder="説明を追加"
            ></v-textarea>
          </v-col>
          <v-col v-else class="d-flex align-center">
            <v-icon small>$taskexplanation</v-icon>
            <span class="ml-2 work-break">{{ task.description }}</span>
          </v-col>
        </v-row>

        <v-row v-if="!isSupervisor()" class="py-0 my-0">
          <v-col class="py-0">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskcategory</v-icon>
            <dropdown-button
              :id="'id'"
              :display="'name'"
              :name="primaryName || '大カテゴリ名'"
              :items="parentCategories"
              :type="'text'"
              :has-total="false"
              :showMenu="!isSupervisor()"
              @dropdownSelected="primaryCategoryChange($event)"
            ></dropdown-button>
            <div class="mt-2 ml-5 category">
              <dropdown-button
                :id="'id'"
                :display="'name'"
                :name="secondaryName || '小カテゴリ名'"
                :items="childCategories"
                :type="'text'"
                :has-total="false"
                :showMenu="!isSupervisor()"
                @dropdownSelected="secondaryCategoryChange($event)"
                v-if="showSecondCategory"
              ></dropdown-button>
              <div
                v-if="
                  !this.$v.task.category_secondary_id.required &&
                  this.$v.task.category_secondary_id.$dirty
                "
                class="v-text-field__details"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ $t("label.error.required") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-else class="py-2">
          <v-col>
            <div class="header">
              <v-icon small>$taskcategory</v-icon>
              <span class="ml-2"> {{ primaryName }} </span>
            </div>
            <div v-if="showSecondCategory" class="mt-2 ml-5 category">
              <span class="ml-2"> {{ secondaryName || "小カテゴリ名" }} </span>
            </div>
          </v-col>
        </v-row>
        <v-row class="py-0" v-if="!isSupervisor()">
          <v-col class="py-0">
            <v-row class="py-0 align-baseline">
              <v-col cols="3" class="align-self-start mt-5">
                <span style="margin-left: -16px" class="red--text mr-2">*</span>
                <v-icon small>$taskworktime</v-icon>
                <span class="ml-2">日次予定</span></v-col
              >
              <v-col
                cols="9"
                class="d-flex align-start align-self-start"
                :class="[$vuetify.breakpoint.mobile ? 'pl-0' : '']"
              >
              <span class="ml-10 align-baseline">
                <v-text-field
                  :error-messages="costErrors"
                  :suffix="displaySplit?'m' : 'm/回'"
                  type="number"
                  max="999"
                  min="1"
                  v-model="task.cost"
                  :readonly="isSupervisor()"
                  @input="changeCost()"
                  @input.native="updateCost"
                  @keydown="checkMaxLength('cost', $event)"
                  class="w-130"
                >
                </v-text-field>
              </span>
                
                <span v-if="!displaySplit" class="mx-2 mt-5">×</span>
                <v-text-field v-if="!displaySplit"
                  type="number"
                  max="999"
                  min="1"
                  :error-messages="countErrors"
                  suffix="回"
                  :readonly="isSupervisor()"
                  @input.native="updateCount"
                  @keydown="checkMaxLength('count', $event)"
                  v-model="task.count"
                  @input="changeCount()"
                  class="w-60 align-self-start"
                >
                </v-text-field>
                <span v-if="!displaySplit" class="mx-2 mt-5">=</span>
                <v-text-field v-if="!displaySplit"
                  class="w-90 align-self-start"
                  readonly
                  type="number"
                  :value="task.cost * task.count"
                  suffix="m"
                ></v-text-field
              ></v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="py-0" v-else>
          <v-col class="py-0 d-flex align-center">
            <v-icon small>$taskworktime</v-icon>
            <span class="ml-2">日次予定</span>
            <div class="text-number ml-2 d-flex align-center">
              <span>{{ task.cost }} m/回</span>
              <span v-if="!displaySplit" class="mx-2">×</span>
              <span v-if="!displaySplit">{{ task.count }} 回</span>
              <span v-if="!displaySplit" class="mx-2">=</span>
              <span v-if="!displaySplit">{{ task.cost * task.count }} m</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="py-0" v-if="displaySplit">
          <v-col class="py-0 d-flex align-center">
            <div class="d-flex">
              <span class="ml-7">累計実績</span>
              <span class="ml-1">{{ task.total_actual_cost || 0 }} m</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="py-2">
          <v-col v-if="!isSupervisor()" class="d-flex align-center py-0">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskstartendtime</v-icon>
            <div class="text-number ml-2 d-flex align-center">
              <v-text-field
                maxlength="5"
                :error-messages="startTimeErrors"
                v-model="task.start_time"
                :readonly="isSupervisor()"
                @input="!isSupervisor() && changeStartTime()"
                @blur="formatStartTime($event)"
                @keyup.enter.native="formatStartTime($event)"
                type="text"
              ></v-text-field>
              <span class="mx-2">~</span>
              <v-text-field
                class="align-self-start"
                maxlength="5"
                :error-messages="endTimeErrors"
                v-model="task.end_time"
                readonly
                type="text"
              ></v-text-field>
            </div>
          </v-col>
          <v-col v-else class="d-flex align-center py-0">
            <v-icon small>$taskstartendtime</v-icon>
            <div class="ml-2 d-flex align-center">
              <span>{{ task.start_time }}</span>
              <span class="mx-3"> ~ </span>
              <span>{{ task.end_time }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col>
            <div class="d-flex align-center">
              <v-checkbox
                class="mt-0"
                hide-details
                :disabled="!canSplitCheck() || +this.task.count !== 1"
                v-model="splitCheck"
                @change="updateSplitCheck"
              >
              </v-checkbox>
              <span class="mx-2"> タスクを分割実施（一部のみ実施） </span>
            </div>
            <div class="d-flex align-center">
              <span class="mx-2 ml-10"
                >※標準工数60分のうち30分作業する場合など</span
              >
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center py-0">
            <v-icon small class="mr-2">$tag</v-icon>
            <span v-if="task.tags.length === 0">無し</span>
            <v-container v-else justify="space-around">
              <v-sheet class="px-1">
                <v-chip-group mandatory>
                  <v-tooltip bottom v-for="tag in task.tags" :key="tag.id">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on">
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 100px"
                        >
                          {{ tag.name }}
                        </span>
                      </v-chip>
                    </template>
                    <span>{{ tag.name }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-if="!showComment">
            <v-icon small>$comment</v-icon>
            <v-btn text color="ligth" @click="updateComment()">
              コメントを追加
            </v-btn>
            <v-list v-if="hasComment">
              <v-list-item v-for="item in task.comments" :key="item.id">
                <v-list-item-icon>
                  <v-icon small>$commonuser</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <p>
                    {{ item.user_name }}
                    <span v-if="!$vuetify.breakpoint.mobile" class="ml-5">{{
                      getCommentDate(item.updated_at)
                    }}</span>
                  </p>
                  <p v-if="$vuetify.breakpoint.mobile">
                    {{ getCommentDate(item.updated_at) }}
                  </p>
                  <p class="work-break">{{ item.comment }}
                  </p>
                </v-list-item-content>
                <v-list-item-action
                  style="align-self: flex-start"
                  @click="deleteComment(item)"
                >
                  <v-icon small>$delete</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col class="py-0 d-flex align-items-baseline" v-else>
            <v-icon small>$comment</v-icon>
            <v-textarea
              class="ml-2 py-0 mt-0"
              v-model="userComment"
              placeholder="コメントを追加"
              counter
              maxlength="512"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          :disabled="!isEditing"
          tile
          class="ml-auto"
          color="primary"
          @click="isCanSubmit() && updateTask()"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { backMixin } from "../../../mixins/back";
import { mapGetters } from "vuex";
import axios from "axios";
import {
  DELETE_COMMENT_API,
  GET_SCHEDULE_TASK_API,
  CATEGORIES_API,
  TODO_TASK_API,
  FAVORITE_TASK_API,
  NOTIFICATION_API
} from "../../../constants/api";
import {
  isEmpty,
  copyToClipboard,
  deepClone,
  isEmptyNumber,
  debounce_leading,
} from "../../../helpers/helpers";
import { required, requiredIf } from "vuelidate/lib/validators";
import DropdownButton from "../../../components/DropdownButton.vue";

import {
  formatTime,
  formatDateTime,
  parseDateTime,
  toMinute,
  toMinuteForHHmm,
  toHHmmFormat,
  toHHmm,
} from "../../../helpers/datetime-helpers";
import {
  timeValidator,
  compareDateValidator,
  workTimeValidator,
} from "../../../helpers/validator";
import { apiErrorMessages } from "../../../helpers/messages";
import {
  FC701,
  FC801,
  FC802,
  FC803,
  FC808,
  FC820,
  FC832,
  FC833,
  FC501,
  FC502
} from "../../../constants/fc";
import { yyyyMMddHHmm2, yyyyMMddHHmmss3 } from "../../../constants/df";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import DialogDragDirective from "../../../directives/dialogDrag";

export default {
  props: {
    show: Boolean,
    planId: String,
    freeId: Number,
  },
  components: { DropdownButton },
  directives: {
    "dialog-drag": DialogDragDirective,
  },

  mixins: [validationMixin, backMixin],
  validations: {
    task: {
      name: { required },
      category_secondary_id: {
        required: requiredIf((task) => {
          return task.category_primary_id !== null;
        }),
      },
      output_detail: {
        required: requiredIf((task) => {
          return task.is_need_output;
        }),
      },
      cost: {
        required,
        workCompare: workTimeValidator("count"),
      },
      count: {
        required,
        workCompare: workTimeValidator("cost"),
      },
      start_time: { required, timeValidator },
      end_time: {
        required,
        timeValidator,
        compareDate: compareDateValidator("start_time"),
      },
    },
  },
  data() {
    return {
      parentId: "actionFreeTaskId",
      parentCategories: [],
      childCategories: [],
      openFreeTask: false,
      originalTask: {},
      showComment: false,
      isEditing: false,
      isSelecting: false,
      userComment: "",
      task: {
        is_favorite: false,
        name: "",
        description: "",
        is_need_output: false,
        category_primary_id: null,
        category_secondary_id: null,
        tags: [],
        comments: [],
        start_time: "",
        end_time: "",
      },
      splitCheck: false, // 標準タスク、フリータスクはfalse  最後の分割タスクもFalse
      originSplitCheck: false,
      canSplit: true,
      listNotification: [],
    };
  },
  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },
  watch: {
    async show() {
      this.openFreeTask = true;
      this.userComment = "";
      this.$v.$reset();
      this.task = {
        is_favorite: false,
        name: "",
        description: "",
        is_need_output: false,
        category_primary_id: null,
        category_secondary_id: null,
        tags: [],
        comments: [],
        start_time: "",
        end_time: "",
      };
      await this.getTask();
      await this.getNotification();
      this.showComment = false;
    },
    task: {
      deep: true,
      handler(value) {
        this.isEditing = this.hasChange(value) || this.splitCheck !== this.originSplitCheck;
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    userComment: {
      handler: function (value) {
        this.isEditing = value !== null || value !== "";
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    splitCheck: {
      handler: function (value) {
        this.isEditing = value !== this.originSplitCheck || this.hasChange(this.task);
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
  },
  computed: {
    ...mapGetters("common", ["loginInfo"]),
    ...mapGetters("save", ["getChangeFlag"]),
    nameErrors() {
      const errors = [];
      if (this.$v.task.name.$dirty) {
        !this.$v.task.name.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    outputDetailErrors() {
      const errors = [];
      if (this.$v.task.output_detail.$dirty) {
        !this.$v.task.output_detail.required &&
          this.task.is_need_output &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    endTimeErrors() {
      const errors = [];
      if (this.$v.task.end_time.$dirty) {
        !this.$v.task.end_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.end_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
        !this.$v.task.end_time.compareDate &&
          errors.push(this.$t("label.error.end_time"));
      }

      return errors;
    },
    startTimeErrors() {
      const errors = [];
      if (this.$v.task.start_time.$dirty) {
        !this.$v.task.start_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.start_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    costErrors() {
      const errors = [];
      if (this.$v.task.cost.$dirty) {
        !this.$v.task.cost.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.cost.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    countErrors() {
      const errors = [];
      if (this.$v.task.count.$dirty) {
        !this.$v.task.count.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.count.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    primaryName() {
      let p = this.parentCategories.find(
        (x) => x.id === this.task.category_primary_id
      );
      return p ? p.name : "";
    },
    secondaryName() {
      if (this.childCategories) {
        let s = this.childCategories.find(
          (x) => x.id === this.task.category_secondary_id
        );
        return s ? s.name : "";
      } else {
        return "";
      }
    },
    showSecondCategory() {
      return !isEmptyNumber(this.task.category_primary_id);
    },

    hasComment() {
      return this.task && this.task.comments && this.task.comments.length > 0;
    },

    // display as split task
    displaySplit(){
      // Free or db task
      if (this.originalTask.is_split_task === 0){
        if (this.splitCheck){
          return true;
        }
        return false;
      }

      // First Split task
      if (this.originalTask.is_first_split_task === 1){
        if (this.splitCheck){
          return true;
        }
        return false;
      }
      return true;
    },

    isCanSplit() {
      return this.canSplit;
    },
  },
  methods: {
    /**
    * set task favorite
    * @return {void}
    */
    /**
     * set task favorite
     * @return {void}
     */
     async setFavorite(item) {
        item.is_favorite = !item.is_favorite;
        // お気に入り追加
        if (item.is_favorite){
          try {
            const {data} = await axios.post(FAVORITE_TASK_API(), {
              name: item.name,
              description: item.description,
              category_secondary_id: item.category_secondary_id,
              cost: +item.cost,
              count: +item.count,
              is_need_output: +item.is_need_output,
              output_detail:
                +item.is_need_output === 0 ? null : item.output_detail,
              tags: item.tags,
              schedule_task_id: item.id
            });
            item.favorite_task_id = data.result.id;
          }
          catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC832, status);
          }
        }
        else{
          try {
            await axios.delete(FAVORITE_TASK_API(item.favorite_task_id));
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC833, status);
          }
        }

        this.$emit("setFavorite", item);
    },

    /**
    * Handle update cost event
    * @return {void}
    */
    updateCost(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.cost = value;
      } else {
        this.task.cost = value.slice(0, 3);
      }
      this.$forceUpdate();
    },

    /**
    * Handle update count event
    * @return {void}
    */
    updateCount(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.count = value;
      } else {
        this.task.count = value.slice(0, 3);
      }
      this.$forceUpdate();
    },

    /**
    * Check max length
    * @return {boolean}
    */
    checkMaxLength(type, $event) {
      if (
        $event.keyCode === 8 ||
        $event.keyCode === 46 ||
        $event.keyCode === 38 ||
        $event.keyCode === 40 ||
        $event.keyCode === 37 ||
        $event.keyCode === 39
      )
        return true;
      if (type === "cost") {
        if (this.$v.task.cost.required) {
          if (this.task.cost.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      } else {
        if (this.$v.task.count.required) {
          if (this.task.count.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      }
    },

    /**
    * Check if suppervisor
    * @return {boolean}
    */
    isSupervisor() {
      return this.$root.$refs.scheduleComponent.isSupervisor();
    },

    /**
    * Check if is before twelve
    * @return {boolean}
    */
    isBeforeTwelve() {
      return this.$root.$refs.scheduleComponent.isBeforeTwelve();
    },

    /**
    * Check if is can submit
    * @return {boolean}
    */
    isCanSubmit() {
      return this.$root.$refs.scheduleComponent.isCanSubmit();
    },

    /**
    * Handle change count event
    * @return {void}
    */
    changeCount() {
      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        this.calculateTime();
      }
    },

    /**
    * change start time
    * @return {void}
    */
    changeStartTime() {
      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        let hasColon = this.task.start_time.indexOf(":") >= 0;
        if (hasColon) this.calculateTime();
        else this.calculateTimeForHHmm();
      }
    },

    /**
    * format start time
    * @param {object} event
    * @return {void}
    */
    formatStartTime($event) {
      if (!this.$v.task.start_time.$invalid) {
        const value = $event.target.value;
        let hasColon = value.indexOf(":") >= 0;
        if (!hasColon) {
          this.task.start_time = toHHmmFormat(value);
        }
      }
    },

    /**
    * Change cost
    * @return {void}
    */
    changeCost() {
      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        this.calculateTime();
      }
    },

    /**
    * Get comment date
    * @return {string}
    */
    getCommentDate(date) {
      return formatDateTime(
        parseDateTime(date, yyyyMMddHHmmss3),
        yyyyMMddHHmm2
      );
    },

    /**
    * Calculate time
    * @return {void}
    */
    calculateTime() {
      let startTime = toMinute(this.task.start_time, ":");
      let endTime = toMinute(this.task.end_time, ":");
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },

    /**
    * Calculate time for HHmm
    * @return {void}
    */
    calculateTimeForHHmm() {
      let startTime = toMinuteForHHmm(this.task.start_time);
      let endTime = toMinuteForHHmm(this.task.end_time);
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },

    /**
    * Handle update comment event
    * @return {void}
    */
    updateComment() {
      this.showComment = true;
    },

    /**
    * Handle change primary category event
    * @param {object} primaryCategory
    * @return {void}
    */
    primaryCategoryChange(primaryCategory) {
      this.childCategories = this.parentCategories.find(
        (x) => x.id === primaryCategory.id
      )?.secondaries;

      if (!isEmptyNumber(primaryCategory.id)) {
        this.parentCategories = this.parentCategories.filter(
          (x) => !isEmptyNumber(x.id)
        );
      }
      this.task.category_primary_id = primaryCategory.id;
      this.task.category_secondary_id = null;
    },

    /**
    * Handle change secondary category event
    * @param {object} secondCategory
    * @return {void}
    */
    secondaryCategoryChange(secondCategory) {
      this.task.category_secondary_id = secondCategory.id;
    },

    /**
    * Handle delete comment event
    * @param {object} item
    * @return {void}
    */
    deleteComment(item) {
      if (!this.isOwner(item)) return;
      this.$confirm(this.$t("label.confirm.delete_comment"), {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
        rtl: true,
      })
        .then(async () => {
          try {
            await axios.delete(DELETE_COMMENT_API(item.id));
            this.task.comments = this.task.comments.filter(
              (x) => x.id !== item.id
            );
            this.originalTask.comments = this.originalTask.comments.filter(
              (x) => x.id !== item.id
            );
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC820, status);
          }
        })
        .catch(() => {});
    },

    /**
    * get data from API FC801
    * @return {void}
    */
    async getTask() {
      try {
        const { data, status } = await axios.get(
          GET_SCHEDULE_TASK_API(this.planId, this.freeId)
        );
        if (status === STATUS_NO_CONTENT) {
          this.openFreeTask = false;
          this.$alert("タスクが見つかりません。");
        } else {
          this.task = deepClone(data.result[0]);
          this.originalTask = deepClone(data.result[0]);
          this.task.end_time = formatTime(data.result[0].end_time);
          this.task.start_time = formatTime(data.result[0].start_time);
          this.originalTask.start_time = formatTime(data.result[0].start_time);
          this.originalTask.end_time = formatTime(data.result[0].end_time);

          await this.getCategories();
          this.parentCategories.push({
            id: null,
            name: "未分類",
            secondaries: [],
          });

          this.childCategories = this.parentCategories.find(
            (x) => x.id === this.originalTask.category_primary_id
          )?.secondaries;
          if (this.task.split_task_id && this.task.is_last_split_task === 0){
            this.splitCheck = true;
          }
          else if (this.task.split_task_id && this.task.is_last_split_task === 1 && this.task.is_first_split_task === 1){
            this.splitCheck = true;
          }
          else{
            this.splitCheck = false;
          }
          this.originSplitCheck = this.splitCheck;

          if (this.task.can_split && +this.task.can_split !== 1){
            this.canSplit = false;
          }
          else{
            this.canSplit = true;
          }
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC801, status);
      }
    },

    /**
    * get categories data from API FC701
    * @return {void}
    */
    async getCategories() {
      try {
        const { data, status } = await axios.get(CATEGORIES_API());

        this.parentCategories = status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC701, status);
      }
    },

    /**
    * Handle update task event
    * @return {void}
    */
    updateTask: debounce_leading(async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        try {
          if (this.isSupervisor()) {
            if (!isEmpty(this.userComment) && this.showComment) {
              await axios.put(GET_SCHEDULE_TASK_API(this.planId), {
                schedule_tasks: [
                  {
                    id: this.task.id,
                    comments: [
                      {
                        user_id: this.loginInfo.user_id,
                        comment: this.userComment,
                      },
                    ],
                  },
                ],
              });
              this.openFreeTask = false;
            }
          } else {
            let params = [
              {
                name: this.task.name,
                description: this.task.description,
                id: this.task.id,
                count: +this.task.count,
                cost: +this.task.cost,
                is_need_output: +this.task.is_need_output,
                category_secondary_id: this.task.category_secondary_id,
                is_split_task:
                      +this.task.count === 1 && this.splitCheck ? 1 : 0,
                output_detail:
                  +this.task.is_need_output === 0
                    ? null
                    : this.task.output_detail,
                is_favorite: +this.task.is_favorite,
                start_time: (
                  this.task.start_time.replace(":", "") + "00"
                ).padStart(6, "0"),
                end_time: (this.task.end_time.replace(":", "") + "00").padStart(
                  6,
                  "0"
                ),
                tags: this.task.tags,
                comments:
                  !isEmpty(this.userComment) && this.showComment
                    ? [
                        {
                          user_id: this.loginInfo.user_id,
                          comment: this.userComment,
                        },
                      ]
                    : [],
              },
            ];
            let response = await axios.put(GET_SCHEDULE_TASK_API(this.planId), {
              schedule_tasks: params,
            });
            this.openFreeTask = false;
            let data = response.data.result[0];
            this.task.changeTodoList = this.splitCheck !== this.originSplitCheck;
            if (+data.is_split_task === 1){
              this.task.can_split = data.can_split;
              this.task.is_first_split_task = data.is_first_split_task;
              this.task.is_last_split_task = data.is_last_split_task;
              this.task.is_source_completed = data.is_source_completed;
              this.task.is_split_task = data.is_split_task;
              this.task.split_task_id = data.split_task_id;
              this.task.split_task_no = data.split_task_no;
              this.task.split_task_source_id = data.split_task_source_id;
            }
            this.$emit("updateFreeTask", this.task);
          }
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC802, status);
        }
      }
    }),

    /**
    * Handle delete task event
    * @return {void}
    */
    async deleteTask() {
      this.$confirm(this.$t("label.confirm.delete_task"), {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
        rtl: true,
      })
        .then(async () => {
          try {
            await axios.delete(GET_SCHEDULE_TASK_API(this.planId, this.freeId));
            this.openFreeTask = false;
            this.$emit("deleteFreeTask", {
              planId: this.planId,
              freeId: this.freeId,
              changeTodoList: this.originSplitCheck
            });
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC803, status);
          }
        })
        .catch(() => {});
    },

    /**
    * Handle archive task event
    * @return {void}
    */
    archiveTask: debounce_leading(async function () {
      this.openFreeTask = false;
      try {
        let params = {
          name: this.originalTask.name,
          description: this.originalTask.description,
          cost: this.originalTask.cost,
          count: this.originalTask.count,
          category_secondary_id: this.originalTask.category_secondary_id,
          is_need_output: this.originalTask.is_need_output,
          output_detail: this.originalTask.output_detail,
          is_favorite: this.originalTask.is_favorite,
          tags: this.originalTask.tags,
          comments: this.originalTask.comments,
          schedule_task_id: this.originalTask.id,
        };
        const { data } = await axios.post(TODO_TASK_API(), {tasks: [params]});
        this.openDbTask = false;
        this.$store.dispatch("save/setChangeFlag", false);
        this.originalTask.todoTaskId = data.result.id[0];
        this.$emit("archiveFreeTask", this.originalTask);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC808, status);
      }
    }),

    /**
    * Handle dupplicate task event
    * @return {void}
    */
    async duplicateTask() {
      if (this.isEditing) {
        await this.updateTask();
      }
      this.openFreeTask = false;
      this.$emit("duplicateTask", this.task);
    },

    /**
    * Handle copy link event
    * @return {void}
    */
    copyLink() {
      copyToClipboard(
        window.location.origin +
          `/schedule/${this.planId}?taskId=${this.task.id}`,
        this.parentId
      );
      this.$toast(this.$t("label.toast.copied"), 3000);
    },

    getNotification: debounce_leading(async function () {
      try {
        const { data, status } = await axios.get(NOTIFICATION_API(), {
          params: {
            is_verified: 0,
            sort: "new", // TODO:
          },
        });
        let list = status === STATUS_NO_CONTENT ? [] : data.result.notices;
        this.$store.dispatch(
          "notification/setCount",
          status === STATUS_NO_CONTENT ? 0 : data.result.total_count
        );
        this.$store.dispatch(
          "notification/setUnReadList",
          status === STATUS_NO_CONTENT ? [] : data.result.notices
        );
        // Add images to list
        for (let index = 0; index < list.length; index++) {
          const item = list[index];
          let comment = this.task.comments.find(
            x => +x.id === +item.comment_id && !!item.can_verified
          );
          if (comment){
            this.listNotification.push(item);
          }
          
        }
        if (this.listNotification.length > 0){
          await this.confirmed();
          this.$store.dispatch("notification/setCount", data.result.total_count - this.listNotification.length);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC501, status);
      }
    }),

    // Call Api comfirm notice
    async confirmed() {
      try {
        let params = [];
        this.listNotification.forEach(item => {
          let param = {
            status_notice_id: null,
            comment_notice_id: item.comment_notice_id,
            is_verified: 1,
          };
          params.push(param);
        });
        await axios.put(NOTIFICATION_API(), {
          notices: params,
        });
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC502, status);
      }
    },

    /**
    * Handle close popup event
    * @return {void}
    */
    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.openFreeTask = false;
          }, 100);
        },
        () => {}
      );
    },

    /**
    * Handle click browser system button event
    * @return {void}
    */
    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.$v.$reset();
          this.openFreeTask = false;
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },

    /**
    * Check if item is owner
    * @return {boolean}
    */
    isOwner(item) {
      return item.user_id === this.loginInfo.user_id;
    },

    updateSplitCheck(event) {
      if (!event && this.originalTask.is_first_split_task === 1) {
        this.task.cost = this.task.total_cost;
      }
    },
    canSplitCheck(){
      // タスクが実装した場合、分割できない
      if (+this.task.is_completed === 1){
        return false;
      }

      // 一部実装した場合、分割できない
      if (this.task.total_actual_cost > 0 && +this.task.is_last_split_task !== 1){
        return false;
      }

      // 最後のタスクではない
      if (!this.task.can_split && +this.task.is_split_task === 1){
        return false;
      }
      return true;
    },

    hasChange(value){
      return JSON.stringify({
            name: value.name,
            description: value.description,
            is_need_output: value.is_need_output,
            output_detail: value.output_detail,
            category_primary_id: value.category_primary_id,
            category_secondary_id: value.category_secondary_id,
            start_time: value.start_time,
            end_time: value.end_time,
            userComment: this.userComment,
            cost: +this.task.cost,
            count: +this.task.count,
          }) !==
          JSON.stringify({
            name: this.originalTask.name,
            description: this.originalTask.description,
            is_need_output: this.originalTask.is_need_output,
            output_detail: this.originalTask.output_detail,
            category_primary_id: this.originalTask.category_primary_id,
            category_secondary_id: this.originalTask.category_secondary_id,
            start_time: this.originalTask.start_time,
            end_time: this.originalTask.end_time,
            userComment: "",
            cost: +this.originalTask.cost,
            count: +this.originalTask.count,
          });
    },
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}
.w-120 {
  width: 120px;
}
.w-90 {
  width: 90px;
}
.align-items-baseline {
  align-items: baseline;
}
.v-dialog.v-dialog--active .v-card__title {
  cursor: grab;
}
.w-60 {
  width: 60px;
}
.w-95 {
  width: 95px;
}
.w-95,
.w-60,
.w-90 {
  .v-text-field__details {
    width: 100%;
  }
}
.work-break {
  white-space: pre-wrap;
}
</style>
