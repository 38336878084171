<template>
  <v-app id="app">
    <loading ref="loading" />
    <transition name="page" mode="out-in">
      <component :is="layout" v-if="layout" />
    </transition>
    <!-- //TODO: add back to top here -->
  </v-app>
</template>

<script>
import Loading from "./components/Loading";

// Load layout components dynamically.
const requireContext = require.context("./layouts", false, /.*\.vue$/);

const layouts = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.vue$)/g, ""), requireContext(file)])
  .reduce((components, [name, component]) => {
    components[name] = component.default || component;
    return components;
  }, {});

export default {
  el: "#app",

  components: {
    Loading,
  },

  data: () => ({
    layout: null,
    defaultLayout: "default",
    settings: {
      btt: {
        // size: 32, // :size 	Number 	70 	width/height pixel size
        // right: 50, // :right 	String, Number 	50 	margin from right
        // bottom: 32, // :bottom 	String, Number 	100 	margin from bottom
        // bottomGap: 0, // :bottom-gap 	String, Number 	0 	gap of bottom margin
        // zIndex: 999, // :z-index 	Number 	1000 	z-index size
        // fgColor: "#ffffff", // fg-color 	String 	#ffffff 	text color
        // bgColor: "#ffc966", // bg-color 	String 	#ffc966 	background color
        // :radius 	String, Number 	50% 	border-radius size
        // weight 	String 	normal 	line weight of arrow
        // ripple-bg 	String 	rgba(255, 255, 255, .5) 	ripple animation color
        // :boundary 	Number 	200 	top position where the element is displayed
        // :max-width 	Number 	640 	media query max-width(px)
        // src 	String 	null 	image url
        // alt 	String 	`` 	alternative text for image
        // :has-outline 	Boolean 	true 	show outline when focus
      },
    },
  }),

  metaInfo() {
    // const appName = "Task Management System";
    const appName = "工数管理システム";
    // const {appName} =  window.config // TODO: window.config;

    return {
      title: appName,
      titleTemplate: `%s · ${appName}`,
    };
  },

  methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    setLayout(layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout;
      }

      this.layout = layouts[layout];
    },
  },

  mounted() {
    this.$root.$refs.loading = this.$refs.loading;
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/common.scss";
</style>
