<template>
  <v-dialog
    v-model="openDbTask"
    persistent
    max-width="600px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
    content-class="sacnDbTask"
    v-dialog-drag="{
      dialog: 'sacnDbTask',
    }"
    overlay-color="transparent"
    overlay-opacity="1"
  >
    <v-card class="hidden">
      <v-card-title class="card-header pb-2">
        <v-btn @click="close()" icon color="gray" class="ml-auto">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="card-content">
        <v-row class="py-0 my-0">
          <v-col cols="12" class="py-0 d-flex align-center">
            <v-icon v-if="task.is_favorite" small>$favoriteactive</v-icon>
            <v-icon v-else small>$favoriteinactive</v-icon>
            <span class="py-2 ml-2 work-break">{{ task.name }}</span>
            <div class="detailCopyText">
              <v-text-field
                class="ml-2"
                maxlength="40"
                v-model="task.detail"
                placeholder="タスク詳細を追加"
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="12" class="py-0 my-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row class="py-0 my-2">
          <v-col class="d-flex align-center">
            <v-icon small class="yellow--text">$taskexplanation</v-icon>
            <span class="ml-2 work-break">{{ task.description }}</span>
          </v-col>
        </v-row>

        <v-row class="py-2">
          <v-col class="py-0" cols="12">
            <v-icon small>$taskcategory</v-icon>
            <span class="mx-2">{{ task.category_primary_name }}</span>
          </v-col>
          <v-col cols="12">
            <span class="ml-7">{{ task.category_secondary_name }}</span>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col class="d-flex align-center">
            <v-icon small>$taskType</v-icon>
            <span class="ml-2 work-break"
              >{{ labelType }} : {{ task.task_type_name || "" }}</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!task.is_split_task">
            <v-row class="py-0 my-0 align-baseline">
              <v-col cols="3" class="py-0 align-self-start mt-5">
                <span style="margin-left: -16px" class="red--text mr-2">*</span>
                <v-icon small>$taskworktime</v-icon>
                <span class="ml-2 ">日次予定</span>
              </v-col>
              <v-col cols="9" class="py-0 d-flex align-start align-self-start">
                <span
                  class="w-95 mt-5"
                  :class="[$vuetify.breakpoint.mobile ? 'pl-0' : '']"
                  >{{ task.cost }}m/回</span
                >
                <span class="mx-1 mt-5">×</span>
                <v-text-field
                  suffix="回"
                  type="number"
                  max="999"
                  min="1"
                  :error-messages="countErrors"
                  v-model="task.count"
                  @input.native="updateCount"
                  @keydown="checkMaxLength('count', $event)"
                  @input="changeCount()"
                  class="w-60"
                >
                </v-text-field>
                <span class="mx-1 mt-5">=</span>
                <v-text-field
                  readonly
                  class="w-90"
                  type="number"
                  :value="task.cost * task.count"
                  suffix="m"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0 my-0">
                <div class="ml-7">
                  <span>※標準工数/回の業務量定義（件数など）：</span>
                  <span class="work-break">{{ task.unit || '' }}</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else class="py-0">
            <div class="d-flex align-center">
              <span style="margin-left: -16px" class="red--text mr-2">*</span>
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2">日次予定</span>
              <span class="ml-10 align-baseline">
                <v-text-field
                  class="w-130"
                  type="number"
                  suffix="m"
                  max="999"
                  min="1"
                  :error-messages="costErrors"
                  v-model="task.cost"
                  @input.native="updateCost"
                  @keydown="checkMaxLength('cost', $event)"
                  @input="changeCost()"
                ></v-text-field>
              </span>
            </div>
            <div class="d-flex">
              <span class="ml-7">累計実績</span>
              <span class="ml-1">{{ task.total_actual_cost }} m</span>
              <span class="ml-1 mr-1">/</span>
              <span class="">{{ task.total_cost }} m</span>
            </div>
            <div class="ml-7">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="work-break">{{ task.unit || '' }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0">
          <v-col class="py-0 d-flex align-center">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskstartendtime</v-icon>
            <div class="ml-2 d-flex align-center">
              <v-text-field
                maxlength="5"
                type="text"
                :error-messages="startTimeErrors"
                v-model="task.start_time"
                @input="changeStartTime()"
                @blur="formatStartTime($event)"
                @keyup.enter.native="formatStartTime($event)"
              ></v-text-field>
              <span class="mx-2">~</span>
              <v-text-field
                class="align-self-start"
                maxlength="5"
                type="text"
                readonly
                :error-messages="endTimeErrors"
                v-model="task.end_time"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col>
            <div class="d-flex align-center">
              <v-checkbox
                class="mt-0"
                hide-details
                :disabled="+task.count !== 1 || task.total_actual_cost > 0"
                v-model="task.is_split_task"
                @change="updateSplitCheck"
              >
              </v-checkbox>
              <span class="mx-2"> タスクを分割実施（一部のみ実施） </span>
            </div>
            <div class="d-flex align-center">
              <span class="mx-2 ml-10"
                >※標準工数60分のうち30分作業する場合など</span
              >
            </div>
          </v-col>
        </v-row>

        <!-- <v-row class="py-0 my-0">
          <v-col class="py-0">
            <div class="header d-flex align-center">
              <v-icon small>$output</v-icon>
              <span class="ml-2">アウトプット</span>
              <v-checkbox
                class="ml-3"
                :label="!!task.is_need_output ? '必要' : '不要'"
                v-model="task.is_need_output"
                readonly
              ></v-checkbox>
            </div>
            <div v-if="!!task.is_need_output" class="ml-2 category">
              アウトプット内容：{{ task.output_detail }}
            </div>
          </v-col>
        </v-row> -->

        <v-row class="py-1 my-0 mt-3">
          <v-col class="py-0 d-flex align-center">
            <v-icon small class="mr-2">$tag</v-icon>
            <span v-if="task.tags.length === 0">無し</span>
            <v-container v-else justify="space-around">
              <v-sheet class="px-1">
                <v-chip-group mandatory>
                  <v-tooltip bottom v-for="tag in task.tags" :key="tag.id">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on">
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 100px"
                        >
                          {{ tag.name }}
                        </span>
                      </v-chip>
                    </template>
                    <span>{{ tag.name }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>

        <v-row class="py-1 my-0">
          <v-col v-if="!showComment" class="py-0">
            <v-icon small>$comment</v-icon>
            <v-btn text color="ligth" @click="updateComment()">
              コメントを追加
            </v-btn>
            <v-list v-if="hasComment">
              <v-list-item v-for="item in task.comments" :key="item.id">
                <v-list-item-icon>
                  <v-icon small>$commonuser</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <p>
                    {{ item.user_name }}
                    <span v-if="!$vuetify.breakpoint.mobile" class="ml-5">{{
                      getCommentDate(item.updated_at)
                    }}</span>
                  </p>
                  <p v-if="$vuetify.breakpoint.mobile">
                    {{ getCommentDate(item.updated_at) }}
                  </p>
                  <p class="text-wrap work-break">
                    {{ item.comment }}
                  </p>
                </v-list-item-content>
                <v-list-item-action
                  style="align-self: flex-start"
                  @click="deleteComment(item)"
                >
                  <v-icon small>$delete</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col class="py-0 d-flex align-items-baseline" v-else>
            <v-icon small>$comment</v-icon>
            <v-textarea
              class="ml-2 mt-0"
              v-model="userComment"
              placeholder="コメントを追加"
              counter
              maxlength="512"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          :disabled="!isEditing"
          tile
          class="ml-auto"
          color="primary"
          @click="isCanSubmit() && updateTask()"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { backMixin } from "../../../mixins/back";
import { taskMixin } from '@/mixins/task.js';
import { GET_SCHEDULE_TASK_API } from "../../../constants/api";
import axios from "axios";
import { debounce_leading, deepClone, isEmpty } from "../../../helpers/helpers";
import {
  formatDateTime,
  parseDateTime,
  toMinute,
  toHHmm,
  toMinuteForHHmm,
  toHHmmFormat,
} from "../../../helpers/datetime-helpers";
import {
  timeValidator,
  compareDateValidator,
  workTimeValidator,
} from "../../../helpers/validator";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC802 } from "../../../constants/fc";
import { yyyyMMddHHmm2, yyyyMMddHHmmss3 } from "../../../constants/df";
import DialogDragDirective from "../../../directives/dialogDrag";

export default {
  components: {},
  directives: {
    "dialog-drag": DialogDragDirective,
  },

  props: {
    show: Boolean,
    planId: String,
    copyDbData: Object,
  },
  mixins: [validationMixin, backMixin, taskMixin],
  validations: {
    task: {
      cost: {
        required,
        workCompare: workTimeValidator("count"),
      },
      count: {
        required,
        workCompare: workTimeValidator("cost"),
      },
      start_time: { required, timeValidator },
      end_time: {
        required,
        timeValidator,
        compareDate: compareDateValidator("start_time"),
      },
    },
  },
  data() {
    return {
      openDbTask: false,
      showComment: false,
      isEditing: false,
      userComment: "",
      task: {
        tags: [],
        comments: [],
      },
      originalTask: {},
    };
  },
  watch: {
    show: {
      handler() {
        this.openDbTask = true;
        this.userComment = "";
        this.showComment = false;
      },
    },
    task: {
      deep: true,
      handler: function () {
        this.isEditing = true;
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    userComment: {
      handler: function (value) {
        this.isEditing = !isEmpty(value);
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    copyDbData: {
      deep: true,
      handler(val) {
        this.task = {
          count: "",
          output_detail: "",
          start_time: "",
          end_time: "",
          detail: "",
          is_favorite: false,
          tags: [],
          comments: [],
        };
        this.task = {
          ...this.task,
          ...val,
          ...{ is_favorite: false, tags: [], comments: [] },
          total_actual_cost: 0,
        };
        this.originalTask = deepClone(this.task);
      },
    },
  },
  methods: {
    /**
    * Handle change count event
    * @return {void}
    */
    changeCount() {
      if (!this.$v.task.count.$invalid && !this.$v.task.start_time.$invalid) {
        this.calculateTime();
      }
    },

    /**
    * Handle change cost event
    * @return {void}
    */
    changeCost() {
      if (!this.$v.task.count.$invalid && !this.$v.task.start_time.$invalid) {
        this.calculateTime();
      }
    },

    /**
    * Handle change start time event
    * @return {void}
    */
    changeStartTime() {
      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        let hasColon = this.task.start_time.indexOf(":") >= 0;
        if (hasColon) this.calculateTime();
        else this.calculateTimeForHHmm();
      }
    },

    /**
    * Format start time
    * @return {void}
    */
    formatStartTime($event) {
      if (!this.$v.task.start_time.$invalid) {
        const value = $event.target.value;
        let hasColon = value.indexOf(":") >= 0;
        if (!hasColon) {
          this.task.start_time = toHHmmFormat(value);
        }
      }
    },

    /**
    * Check if is before twelve
    * @return {boolean}
    */
    isBeforeTwelve() {
      return this.$root.$refs.scheduleComponent.isBeforeTwelve();
    },

    /**
    * Check if can submit
    * @return {boolean}
    */
    isCanSubmit() {
      return this.$root.$refs.scheduleComponent.isCanSubmit();
    },

    /**
    * Set task favorite
    * @return {void}
    */
    setFavorite(value) {
      this.task.is_favorite = value;
    },

    /**
    * Handle update count event
    * @return {void}
    */
    updateCount(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.count = value;
      } else {
        this.task.count = value.slice(0, 3);
      }
      this.$forceUpdate();
    },

    /**
    * Handle update cost event
    * @return {void}
    */
    updateCost(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.cost = value;
      } else {
        this.task.cost = value.slice(0, 3);
      }
      this.$forceUpdate();
    },

    /**
    * check maxlength
    * @param {string} type
    * @param {object} event
    * @return {boolean}
    */
    checkMaxLength(type, $event) {
      if ($event.keyCode === 8 || $event.keyCode === 46) return true;
      if (type === "cost") {
        if (this.$v.task.cost.required) {
          if (this.task.cost.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      } else {
        if (this.$v.task.count.required) {
          if (this.task.count.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      }
    },

    /**
    * Handle close popup event
    * @return {void}
    */
    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.openDbTask = false;
          }, 100);
        },
        () => {}
      );
    },

    /**
    * Get comment date
    * @param {string} date
    * @return {string}
    */
    getCommentDate(date) {
      return formatDateTime(
        parseDateTime(date, yyyyMMddHHmmss3),
        yyyyMMddHHmm2
      );
    },

    /**
    * Handle update comment event
    * @return {void}
    */
    updateComment() {
      this.showComment = true;
    },

    /**
    * Handle update task event
    * @return {void}
    */
    updateTask: debounce_leading(async function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        //logic processing
        try {
          const { data } = await axios.post(
            GET_SCHEDULE_TASK_API(this.planId),
            {
              schedule_tasks: [
                {
                  name: this.task.name,
                  description: this.task.description,
                  cost: +this.task.cost,
                  count: +this.task.count,
                  is_need_output: +this.task.is_need_output,
                  output_detail:
                    +this.task.is_need_output === 0
                      ? null
                      : this.task.output_detail,
                  category_secondary_id: +this.task.category_secondary_id,
                  is_split_task:
                    +this.task.count === 1 && this.task.is_split_task ? 1 : 0,
                  split_task_id:
                    +this.task.count === 1 && this.task.is_split_task
                      ? null
                      : null,
                  is_favorite: +this.task.is_favorite,
                  start_time: (
                    this.task.start_time.replace(":", "") + "00"
                  ).padStart(6, "0"),
                  standard_task_id: this.task.standard_task_id,
                  standard_task_revision: this.task.standard_task_revision,
                  tags: this.task.tags,
                  comments:
                    !isEmpty(this.userComment) && this.showComment
                      ? [
                          {
                            user_id: this.loginInfo.user_id,
                            comment: this.userComment,
                          },
                        ]
                      : [],
                  is_remand: 0,
                  detail: this.task.detail,
                  skill_level_id: this.task.skill_level_id || null,
                },
              ],
            }
          );
          this.openDbTask = false;
          this.task.id = data.result[0].id;
          if (+this.task.count === 1 && this.task.is_split_task) {
            this.task.split_task_id = data.result[0].id;
          } else {
            this.task.split_task_id = null;
          }
          this.$emit("registerDbTask", this.task);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC802, status);
        }
      } else {
        this.openDbTask = true;
      }
    }),

    /**
    * Calculate time
    * @return {void}
    */
    calculateTime() {
      let startTime = toMinute(this.task.start_time, ":");
      let endTime = toMinute(this.task.end_time, ":");
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },

    /**
    * Calculate time for HHmm
    * @return {void}
    */
    calculateTimeForHHmm() {
      let startTime = toMinuteForHHmm(this.task.start_time);
      let endTime = toMinuteForHHmm(this.task.end_time);
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },

    /**
    * Handle update split check event
    * @param {boolean} event
    * @return {void}
    */
    updateSplitCheck(event) {
      if (!event && this.originalTask.is_split_task === 1) {
        this.task.cost = this.task.total_cost;
      }
    },
  },
  computed: {
    ...mapGetters("common", ["loginInfo"]),
    ...mapGetters("save", ["getChangeFlag"]),
    endTimeErrors() {
      const errors = [];
      if (this.$v.task.end_time.$dirty) {
        !this.$v.task.end_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.end_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
        !this.$v.task.end_time.compareDate &&
          errors.push(this.$t("label.error.end_time"));
      }

      return errors;
    },
    startTimeErrors() {
      const errors = [];
      if (this.$v.task.start_time.$dirty) {
        !this.$v.task.start_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.start_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    countErrors() {
      const errors = [];
      if (this.$v.task.count.$dirty) {
        !this.$v.task.count.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.count.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    costErrors() {
      const errors = [];
      if (this.$v.task.cost.$dirty) {
        !this.$v.task.cost.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.cost.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    hasComment() {
      return this.task && this.task.comments && this.task.comments.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}
.w-120 {
  width: 120px;
}
.w-100 {
  width: 100px;
}
.w-80 {
  width: 80px;
}
.align-items-baseline {
  align-items: baseline;
}
.v-dialog.v-dialog--active .v-card__title {
  cursor: grab;
}
.w-60 {
  width: 60px;
}
.w-95 {
  width: 95px;
}
.w-90 {
  width: 90px;
}
.w-95,
.w-60,
.w-90 {
  .v-text-field__details {
    width: 100%;
  }
}
.detailCopyText {
  margin-left: auto;
  margin-right: 0;
  min-width: 200px;
  max-width: 250px;
}
.work-break {
  word-break: break-all;
}
</style>
