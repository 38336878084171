<template>
  <v-container class="status-container pa-0" fill-height fluid>
    <v-row>
      <v-col cols="12" class="mx-auto">
        <v-row>
          <v-col cols="12" md="8" class="d-flex align-center flex-wrap">
            <dropdown-button
              :id="'id'"
              :display="'name'"
              :name="ownerDepartment.name"
              :items="departments"
              :showMenu="ownerDepartment.show"
              :hasTotal="false"
              :type="'text'"
              @dropdownSelected="departmentChange($event)"
            ></dropdown-button>
            <div class="mx-2"><v-icon small>$bradcrumbtrail</v-icon></div>
            <dropdown-button
              :id="'id'"
              :display="'name'"
              :name="ownerGroup.name"
              :showMenu="ownerGroup.show"
              :type="'text'"
              :hasTotal="false"
              :items="groups"
              @dropdownSelected="groupChange($event)"
            ></dropdown-button>
            <v-icon dark v-if="isMobile" @click="selectRangeDate()" class="ml-2"
              >$calendar</v-icon
            >
            <v-btn
              outlined
              text
              color="light"
              @click="selectRangeDate()"
              v-else
              class="ml-2"
            >
              {{ formatStartDate }} ~ {{ formatEndDate }}
            </v-btn>
          </v-col>
          <v-col cols="6" md="2">
            <span class="ml-3 font-weight-bold">{{
              $t("title.daily-plan")
            }}</span>
          </v-col>
          <v-col class="text-right" cols="6" md="2">
            <v-menu
              v-model="openSortMenu"
              :close-on-content-click="false"
              offset-y
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn tile outlined v-bind="attrs" v-on="on" color="ligth">
                  <v-icon small left dark>$reorder</v-icon>
                  並び替え
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group v-model="groupItemSelected">
                  <v-list-item @click="sortName('asc')">
                    <v-list-item-content>
                      <v-list-item-title>ユーザー名昇順 </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="sortName('desc')">
                    <v-list-item-content>
                      <v-list-item-title>ユーザー名降順 </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="py-0 text-right"
        md="12"
        :class="[{ 'btn-width': isMobile }]"
      >
        <v-btn class="mr-2" @click="moveToSunday()">
          <v-icon color="#7F7F8F">mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-btn class="mr-2" @click="moveToDay()">
          <v-icon color="#7F7F8F">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn class="mr-2" @click="toDay()"> 今日 </v-btn>
        <v-btn class="mr-2" @click="moveToDay(true)">
          <v-icon color="#7F7F8F">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn class="mr-3" @click="moveToSunday(true)">
          <v-icon color="#7F7F8F">mdi-chevron-double-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- <v-row v-if="noStatusData">
      <v-col class="mx-auto" md="12">0件の検索結果 </v-col>
    </v-row> -->
    <v-row>
      <v-col
        class="mx-auto"
        cols="12"
        :class="
          isMobile
            ? 'height-data-table-custom-sp'
            : 'height-data-table-custom-pc'
        "
      >
        <v-data-table
          class="h-100 contentTable"
          fixed-header
          height="100%"
          :headers="headers"
          :items="statusList"
          :hide-default-footer="true"
          :no-data-text="$t('label.no-data.status')"
          disable-pagination
          mobile-breakpoint="0"
        >
          <template
            v-for="(header, i) in headers"
            v-slot:[`header.${header.value}`]="{}"
          >
            <div class="mb-2 d-flex flex-column" :key="i">
              <span class="mb-1">{{ header.text }}</span>
              <dropdown-check-list
                v-if="i > 1 && !noStatusData"
                :name="'ステータス'"
                :id="'id'"
                :display="'name'"
                :check="'check'"
                @changeFilter="changeFilterDay($event, header.value)"
                :items="statusTypeListDays[i - 2]"
              ></dropdown-check-list>
            </div>
          </template>
          <template v-slot:body="props" v-if="!noStatusData">
            <tbody>
              <tr
                v-for="(item, i) in props.items"
                :key="i"
                style="border-bottom: 1px solid black"
              >
                <td
                  :class="[{ 'text-center': index > 1 }]"
                  v-for="(header, index) in headers"
                  :key="header.text"
                >
                  <div
                    v-if="header.text === `名前`"
                    class="d-flex my-0 justify-start align-center"
                  >
                    <v-icon small> $commonuser </v-icon>
                    <span class="ml-5"> {{ item.user_name }}</span>
                  </div>
                  <div v-else>
                    <v-btn
                      text
                      depressed
                      v-if="item[`${header.value}`].schedule_id && index > 1"
                    >
                      <a
                        class="status-url"
                        :href="goPlan(item[header.value].schedule_id)"
                        >{{ item[header.value].phase_name }}</a
                      >
                    </v-btn>
                    <span v-else>
                      {{
                        item[header.value].phase_name || item[header.value]
                      }}</span
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <RangeSevenDate
      @change="changeDate($event)"
      v-model="rangeDate"
      :show="openRangeDate"
    ></RangeSevenDate>
  </v-container>
</template>

<script>
import DropdownButton from "../../components/DropdownButton.vue";
import DropdownCheckList from "../../components/DropdownCheckList.vue";
import RangeSevenDate from "../../components/RangeSevenDate.vue";
import { formatDateTime, parseDateTime } from "../../helpers/datetime-helpers";
import { mapGetters } from "vuex";
import * as Role from "../../constants/role";
import * as systemAuth from "../../constants/role_code";
import axios from "axios";
import {
  GET_BELONG_API,
  GET_STATUS_SCHEDULE_API,
  GET_ALL_PROFILE_API,
  GET_PROFILE_API,
} from "../../constants/api";
import { apiErrorMessages } from "../../helpers/messages";
import { deepClone, isEmpty } from "../../helpers/helpers";
import * as SystemConstant from "../../constants/system";
import { FC301, FC904, FC101 ,FC109} from "../../constants/fc";
import {
  yyyyMMdd1,
  yyyyMMdd2,
  yyyyMMdd3,
  yyyyMMddEEEEE3,
} from "../../constants/df";
import { STATUS_NO_CONTENT } from "../../constants/status";
import { queryRouterMixin } from "@/mixins/queryRouter.js";
const HEADER_DEFAULT = [
  {
    text: "名前",
    value: "user",
    sortable: false,
    width: "225px",
    class: "text-header",
  },
  {
    text: "役職",
    value: "position_name",
    sortable: false,
    width: "135px",
    class: "text-header",
  },
];
const HEADER_DATE_ITEM = {
  sortable: false,
  class: "text-header",
  align: "center",
  cellClass: "text-center",
};
export default {
  middleware: Role.AUTH,
  components: {
    DropdownButton,
    DropdownCheckList,
    RangeSevenDate,
  },
  mixins: [queryRouterMixin],
  metaInfo() {
    return { title: this.$t("title.status-schedules") };
  },
  data() {
    return {
      noStatusData: false,
      init: false,
      originalStatusList: [],
      statusList: [],
      openSortMenu: false,
      users: [],
      user_own: [],
      display_users: [],
      departments: [],
      groups: [],
      groupItemSelected: -1,
      statusTypeList: [
        { id: 10, name: "未提出", check: true },
        { id: 20, name: "1次確認待ち", check: true },
        { id: 25, name: "1次差し戻し", check: true },
        { id: 30, name: "2次確認待ち", check: true },
        { id: 35, name: "2次差し戻し", check: true },
        { id: 40, name: "確認済み", check: true },
      ],
      ownerDepartment: {
        id: "",
        type: "",
        name: "部署名",
        show: false,
      },
      ownerGroup: {
        id: "",
        type: "",
        name: "Gr名",
        show: false,
      },
      headers: [],
      groupItemAllMember: {
        count: "",
        id: "",
        name: "全て",
      },
      planApprover: {
        id: "plan",
        name: "予定承認対象者",
      },
      rangeDate: {},
      openRangeDate: false,
      queryStatus: {
        start_date: null,
        end_date: null,
      },
      statusTypeListDays: [],
      queryData: {},
      dateList: [],
    };
  },

  computed: {
    ...mapGetters("common", ["loginInfo"]),
    isMobile() {
      return ["xs", "sm", "md"].includes(this.$vuetify.breakpoint.name);
    },
    formatStartDate() {
      return this.queryStatus.real_start_date
        ? formatDateTime(this.queryStatus.real_start_date, yyyyMMdd3)
        : "";
    },
    formatEndDate() {
      return this.queryStatus.real_end_date
        ? formatDateTime(this.queryStatus.real_end_date, yyyyMMdd3)
        : "";
    },
  },
  watch: {
    loginInfo: {
      deep: true,
      handler(val) {
        if (!isEmpty(val)) {
          this.initData();
        }
      },
    },
    rangeDate: {
      deep: true,
      handler() {
        this.queryStatus = {
          ...this.queryStatus,
          ...this.rangeDate,
        };
      },
    },
  },
  created() {
    const l = deepClone(this.statusTypeList);
    this.statusTypeListDays = [l, l, l, l, l, l, l];

    const date = new Date();
    const lastDay = new Date(date.setDate(date.getDate() + 6));
    const firstDay = new Date();
    this.rangeDate = {
      start_date:
        this.$route.query.startDate || formatDateTime(firstDay, yyyyMMdd2),
      end_date: this.$route.query.endDate || formatDateTime(lastDay, yyyyMMdd2),
      real_start_date:
        this.$route.query.startDate || formatDateTime(firstDay, yyyyMMdd2),
      real_end_date:
        this.$route.query.endDate || formatDateTime(lastDay, yyyyMMdd2),
    };
    this.queryData = this.setQuery(this.$route.query);

    this.queryStatus = {
      ...this.queryStatus,
      ...this.rangeDate,
    };
  },
  mounted() {
    if (!isEmpty(this.loginInfo)) {
      this.initData();
    }
  },
  methods: {
    /**
     * init data
     * @return {void}
     */
    async initData() {
      if (this.init) return;
      else {
        this.init = true;
        await this.getBelong();
        await this.setAuth();
        await this.getUsers();
        await this.getProfile();
        await this.getStatusList();
        await this.setQueryFilter();
        await this.setSortStatusList();
      }
    },

    async setSortStatusList() {
      const type = this.$route.query.sort;
      if (!type) return;
      let collator = new Intl.Collator("ja", {
        numeric: true,
        sensitivity: "base",
      });
      if (type === "asc") {
        this.groupItemSelected = 0;
        this.statusList = this.statusList.sort((a, b) => {
          let x = a.name_kana;
          let y = b.name_kana;
          return collator.compare(x, y);
        });
      } else {
        this.groupItemSelected = 1;
        this.statusList = this.statusList.sort((a, b) => {
          let x = a.name_kana;
          let y = b.name_kana;
          return collator.compare(y, x);
        });
      }
    },

    /**
     * get all users from Api
     * @return {void}
     */
    async getUsers() {
      try {
        const { data, status } = await axios.get(
          GET_ALL_PROFILE_API(
            this.ownerDepartment.id || "",
            this.ownerGroup.id || ""
          )
        );
        this.users = status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC101, status);
      }
    },

    /**
     * get login users from Api
     * @return {void}
     */
    async getProfile() {
      try {
        const { data, status } = await axios.get(GET_PROFILE_API());
        this.users_own = status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC109, status);
      }
    },

    /**
     * get departments, groups from Api
     * @return {void}
     */
    async getBelong() {
      try {
        const { data } = await axios.get(GET_BELONG_API());
        this.departments = data.result.departments;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC301, status);
      }
    },

    /**
     * department change event
     * @param {object} department
     * @return {void}
     */
    departmentChange(department) {
      this.ownerDepartment.id = department.id;
      this.groups = [];
      this.groups = deepClone(
        this.departments.find((x) => x.id === department.id).groups
      );
      // Push opt all member when auth : ADMIN, MANAGER
      if (this.ownerGroup.show) {
        this.groups.unshift(this.planApprover, this.groupItemAllMember);
      }
      this.ownerGroup = {
        show: true,
        id: this.groups[0]?.id || "",
        name: this.groups[0]?.name || "",
      };
      this.groupChange(this.ownerGroup);
    },

    /**
     * group change event
     * @param {object} group
     * @return {void}
     */
    async groupChange(group) {
      this.ownerGroup.id = group.id || "";
      this.ownerGroup.name = group.name || "";
      await this.getUsers();
      await this.getStatusList();
      await this.setQueryFilter();
      await this.setSortStatusList();

      let gr = this.ownerGroup.id || 0;
      const setQueryDepartment = this.checkExistQueryString(
        this.$route.query.departmentId,
        `${this.ownerDepartment.id}`
      );
      if (setQueryDepartment) {
        this.queryData["departmentId"] = this.ownerDepartment.id;
      }

      const setQueryGroup = this.checkExistQueryString(
        this.$route.query.groupId,
        `${gr}`
      );
      if (setQueryGroup) {
        this.queryData["groupId"] = gr;
      }
      if (setQueryDepartment || setQueryGroup) {
        this.setQueryRouter();
      }
    },

    /**
     * set list status filter to specific day from query router
     * @return {void}
     */
    async setQueryFilter() {
      if (this.queryData["header"]) {
        let header = formatDateTime(this.queryStatus.start_date, yyyyMMdd1);
        let startDate = parseDateTime(this.queryStatus.start_date, yyyyMMdd2);
        const endDate = parseDateTime(this.queryStatus.end_date, yyyyMMdd2);
        let num = 0;
        while (startDate <= endDate) {
          if (this.queryData[`filters-${header}`]) {
            let list = JSON.parse(this.queryData[`filters-${header}`]);
            let statusType = deepClone(this.statusTypeListDays[num]);
            statusType.map((a) => (a.check = false));
            if (list[0] !== 0) {
              statusType.map((x) => {
                for (var i = 0; i < list.length; i++) {
                  if (x.id === list[i]) {
                    x.check = true;
                    break;
                  }
                }
              });
            }
            this.statusTypeListDays.splice(num, 1, deepClone(statusType));
          }
          let y = startDate.getFullYear(),
            m = startDate.getMonth(),
            d = startDate.getDate() + 1;
          startDate = new Date(y, m, d);
          header = formatDateTime(startDate, yyyyMMdd1);
          num++;
        }

        const dayHeader = parseDateTime(this.queryData["header"], yyyyMMdd1);
        const startDateConst = parseDateTime(
          this.queryStatus.start_date,
          yyyyMMdd2
        );
        if (dayHeader < startDateConst || dayHeader > endDate) return;
        const headerFilter = this.queryData["header"];
        let filterList = deepClone(this.originalStatusList);
        if (this.queryData[`filters-${headerFilter}`]) {
          const filters = JSON.parse(this.queryData[`filters-${headerFilter}`]);
          if (filters.length === 8 || filters[0] === 0) {
            this.statusList = filterList;
          } else {
            let list = [];
            filterList.filter((x) => {
              x.headers.filter((y) => {
                if (y.schedule_date === `${headerFilter}`) {
                  if (filters.includes(y.phase_id)) {
                    list.push(x);
                  }
                }
              });
            });
            this.statusList = deepClone(list);
          }
        }
      }
    },

    setQueryRouter() {
      this.$router.replace({
        path: "/status-schedules",
        query: this.queryData,
      });
    },

    /**
     * change list status filter of specific day
     * @param {array} filters
     * @param {string} header
     * @return {void}
     */
    changeFilterDay(filters, header) {
      let setQueryStatusDays = false;
      let queryArr = [0];
      if (isEmpty(filters)) {
        this.statusList = deepClone(this.originalStatusList);
      } else {
        let filterList = deepClone(this.originalStatusList);
        //TODO CHECK ALL
        if (filters.length === 8) {
          this.statusList = filterList;
        } else {
          let list = [];
          filterList.filter((x) => {
            x.headers.filter((y) => {
              if (y.schedule_date === `${header}`) {
                if (filters.includes(y.phase_id)) {
                  list.push(x);
                }
              }
            });
          });
          this.statusList = deepClone(list);
        }
        queryArr = filters;
      }
      setQueryStatusDays = this.checkExistQueryArray(
        this.queryData[`filters-${header}`],
        queryArr
      );
      if (setQueryStatusDays) {
        this.queryData[`filters-${header}`] = JSON.stringify(queryArr);
      }
      const setQueryHeader = this.checkExistQueryString(
        this.$route.query.header,
        `${header}`
      );
      if (setQueryHeader) {
        this.queryData["header"] = header;
      }
      if (setQueryStatusDays || setQueryHeader) this.setQueryRouter();
      this.setSortStatusList();
    },

    /**
     * get list status of specific day
     * @return {void}
     */
    async getStatusList() {
      try {
        const fromDate = +formatDateTime(
          this.queryStatus.start_date,
          yyyyMMdd1
        );
        const toDate = +formatDateTime(this.queryStatus.end_date, yyyyMMdd1);
        const { data, status } = await axios.get(
          GET_STATUS_SCHEDULE_API(
            this.ownerDepartment.id,
            this.ownerGroup.id,
            fromDate,
            toDate
          )
        );
        let statusData = status === STATUS_NO_CONTENT ? [] : data.result;
        let list;
        if (this.ownerGroup.id === "plan") {
          let listUser = this.mapUsers(statusData, this.ownerGroup.id);
          list = this.mapStatus(listUser);
        } else {
          let listUser = this.mapUsers(statusData, this.ownerGroup.id);
          list = this.mapStatus(listUser);
        }

        this.headers = [];
        this.noStatusData = isEmpty(list);
        if (this.noStatusData) {
          this.addDayHeader();
          this.statusList = [];
          return;
        }
        this.originalStatusList = deepClone(list);
        this.statusList = deepClone(list);
        this.dateList = this.statusList[0].headers || [];
        this.dateList.sort(
          (a, b) =>
            +formatDateTime(a.schedule_date, yyyyMMdd1) -
            +formatDateTime(b.schedule_date, yyyyMMdd1)
        );
        const dateHeader = this.dateList.map((item) => {
          return {
            text: formatDateTime(item.schedule_date, yyyyMMddEEEEE3),
            value: item.schedule_date,
            ...HEADER_DATE_ITEM,
          };
        });
        this.headers = [...HEADER_DEFAULT, ...dateHeader];
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC904, status);
      }
    },

    /**
     * add header if no data return
     * @return {void}
     */
    addDayHeader() {
      let startDateStr = formatDateTime(
        this.queryStatus.start_date,
        yyyyMMddEEEEE3
      );
      let startDate = parseDateTime(this.queryStatus.start_date, yyyyMMdd2);
      const endDate = parseDateTime(this.queryStatus.end_date, yyyyMMdd2);
      let dateHeader = [];
      let i = 0;
      while (startDate <= endDate) {
        dateHeader.push({
          text: startDateStr,
          value: i,
          ...HEADER_DATE_ITEM,
        });
        let y = startDate.getFullYear(),
          m = startDate.getMonth(),
          d = startDate.getDate() + 1;
        startDate = new Date(y, m, d);
        startDateStr = formatDateTime(startDate, yyyyMMddEEEEE3);
        i++;
      }
      this.headers = [...HEADER_DEFAULT, ...dateHeader];
    },

    /**
     * get default data base on user login role
     * @return {void}
     */
    setAuth() {
      switch (this.loginInfo?.system_auth_id) {
        case systemAuth.MASTER:
          this.ownerDepartment.show = true;
          this.ownerGroup.show = true;
          break;
        case systemAuth.MANAGER:
          this.ownerDepartment.show = false;
          this.ownerGroup.show = true;
          break;
        case systemAuth.MEMBER:
          this.ownerDepartment.show = false;
          this.ownerGroup.show = false;
          break;
        default:
          break;
      }

      this.ownerDepartment.name = this.loginInfo?.department_name;
      this.ownerDepartment.id = this.loginInfo?.department_id;
      if (this.ownerDepartment.show) {
        if (this.$route.query.departmentId) {
          this.ownerDepartment.id = +this.$route.query.departmentId;
          this.ownerDepartment.name =
            this.departments.find((x) => x.id === this.ownerDepartment.id)
              ?.name || "";
        }
      }
      this.ownerGroup.name = this.loginInfo?.group_name;
      this.ownerGroup.id = this.loginInfo?.group_id;

      let group = this.departments.find(
        (x) => x.id === this.ownerDepartment.id
      );
      this.groups = !group ? [] : deepClone(group.groups);

      // Push opt all member in case auth : ADMIN, MANAGER
      if (this.ownerGroup.show) {
        this.groups.unshift(this.planApprover, this.groupItemAllMember);
        this.ownerGroup.id = this.groups[0]?.id || "";
        this.ownerGroup.name = this.groups[0]?.name || "";
        if (this.$route.query.groupId) {
          if (this.$route.query.groupId === "plan") {
            this.ownerGroup.id = this.groups[0]?.id || "";
            this.ownerGroup.name = this.groups[0]?.name || "";
          } else if (this.$route.query.groupId === "0") {
            this.ownerGroup.id = this.groups[1]?.id || "";
            this.ownerGroup.name = this.groups[1]?.name || "";
          } else {
            const groupId = +this.$route.query.groupId;
            this.ownerGroup.id = groupId;
            this.ownerGroup.name =
              this.groups.find((x) => x.id === groupId)?.name || "";
          }
        }
      }
    },

    /**
     * calculation processing status list
     * @param {array} list
     * @return {array} list
     */
    mapStatus(list) {
      list.map((item) => {
        let startDateStr = formatDateTime(
          this.queryStatus.start_date,
          yyyyMMdd1
        );
        let startDate = parseDateTime(this.queryStatus.start_date, yyyyMMdd2);
        const endDate = parseDateTime(this.queryStatus.end_date, yyyyMMdd2);
        let j = 0;
        let arrHeader = [];
        if (item.schedules.length > 0) {
          while (j < 7 && startDate <= endDate) {
            if (item.schedules[j]) {
              let schedule = item.schedules[j];
              if (startDateStr === schedule.schedule_date) {
                item[`${startDateStr}`] = {
                  phase_name: schedule.phase_name,
                  schedule_id: schedule.schedule_id,
                };
                let newHeader = {};
                newHeader[`${startDateStr}`] = schedule.phase_name;
                newHeader["schedule_date"] = startDateStr;
                newHeader["phase_id"] = schedule.phase_id;
                arrHeader.push(newHeader);
                item["headers"] = arrHeader;
                j++;
              } else {
                item[`${startDateStr}`] = {
                  phase_name: !!item.is_need_submit === true ? "未提出" : "-",
                  schedule_id: null,
                };
                arrHeader.push(
                  this.addDefaultStatus(startDateStr, item.is_need_submit)
                );
                item["headers"] = arrHeader;
              }
            } else {
              item[`${startDateStr}`] = {
                phase_name: !!item.is_need_submit === true ? "未提出" : "-",
                schedule_id: null,
              };
              arrHeader.push(
                this.addDefaultStatus(startDateStr, item.is_need_submit)
              );
              item["headers"] = arrHeader;
            }
            let y = startDate.getFullYear(),
              m = startDate.getMonth(),
              d = startDate.getDate() + 1;
            startDate = new Date(y, m, d);
            startDateStr = formatDateTime(startDate, yyyyMMdd1);
          }
        } else {
          while (startDate <= endDate) {
            item[`${startDateStr}`] =
              !!item.is_need_submit === true ? "未提出" : "-";
            arrHeader.push(
              this.addDefaultStatus(startDateStr, item.is_need_submit)
            );
            item["headers"] = arrHeader;
            let y = startDate.getFullYear(),
              m = startDate.getMonth(),
              d = startDate.getDate() + 1;
            startDate = new Date(y, m, d);
            startDateStr = formatDateTime(startDate, yyyyMMdd1);
          }
        }
      });
      return list;
    },

    /**
     * processing users from status list
     * @param {array} list
     * @return {array} list
     */
    mapUsers(statusList, ownerGroupId) {
      this.display_users = [];
      if(ownerGroupId === "plan"){
        let count_member = 0;
        let count_display_users = 0;
        while(count_member < this.users.length){
          if(this.users[count_member].manage_id === this.users_own.user_id){
            this.display_users[count_display_users] = this.users[count_member];
            count_display_users++;
          }
          count_member++;
        }
      }else{
        this.display_users = this.users;
      }
      return this.display_users.map((x) => {
        let plan = statusList.find((y) => {
          return y.user_id === x.user_id && !isEmpty(y.schedules);
        });

        return {
          user_id: x.user_id,
          user_name: x.name,
          name_kana: x.name_kana,
          position_id: x.position_id,
          position_name: x.position_name,
          is_need_submit: x.is_need_submit,
          schedules: !isEmpty(plan) ? deepClone(plan.schedules) : [],
        };
      });
    },

    /**
     * return status default
     * @param {string} startDateStr
     * @param {boolean} isNeedSubmit
     * @return {array}
     */
    addDefaultStatus(startDateStr, isNeedSubmit) {
      let statusDefault = {};
      statusDefault[`${startDateStr}`] =
        !!isNeedSubmit === true ? "未提出" : "-";
      statusDefault["schedule_date"] = startDateStr;
      statusDefault["phase_id"] = !!isNeedSubmit === true ? 10 : null;
      return statusDefault;
    },

    /**
     * assign range date to query
     * @param {date} day
     * @return {void}
     */
    async assignRangeDate(day) {
      this.queryStatus.start_date = formatDateTime(day, yyyyMMdd2);
      this.queryStatus.real_start_date = formatDateTime(day, yyyyMMdd2);
      let endDay = formatDateTime(day.setDate(day.getDate() + 6), yyyyMMdd2);
      this.queryStatus.end_date = formatDateTime(endDay, yyyyMMdd2);
      this.queryStatus.real_end_date = formatDateTime(endDay, yyyyMMdd2);
      this.rangeDate = {
        ...this.rangeDate,
        ...this.queryStatus,
      };
      const setQueryStartDate = this.checkExistQueryString(
        this.$route.query.startDate,
        this.queryStatus.start_date
      );
      if (setQueryStartDate) {
        this.queryData["startDate"] = this.queryStatus.start_date;
      }

      const setQueryEndDate = this.checkExistQueryString(
        this.$route.query.endDate,
        this.queryStatus.end_date
      );
      if (setQueryEndDate) {
        this.queryData["endDate"] = this.queryStatus.end_date;
      }

      if (setQueryStartDate || setQueryEndDate) this.setQueryRouter();

      const l = deepClone(this.statusTypeList);
      this.statusTypeListDays = [l, l, l, l, l, l, l];
      await this.getStatusList();
      await this.setQueryFilter();
      await this.setSortStatusList();
    },

    /**
     * move to previous or next sunday
     * @param {boolean} isNext
     * @return {void}
     */
    async moveToSunday(isNext = false) {
      var sunday = parseDateTime(`${this.queryStatus.start_date}`, yyyyMMdd2);
      sunday.setDate(
        isNext
          ? sunday.getDate() +
              (sunday.getDay() > 0 ? (7 + (7 - sunday.getDay())) % 7 : 7)
          : sunday.getDate() - (sunday.getDay() > 0 ? sunday.getDay() + 7 : 7)
      );
      await this.assignRangeDate(sunday);
    },

    /**
     * move to previous or next day
     * @param {boolean} isNext
     * @return {void}
     */
    async moveToDay(isNext = false) {
      const startDate = parseDateTime(
        `${this.queryStatus.start_date}`,
        yyyyMMdd2
      );
      let y = startDate.getFullYear(),
        m = startDate.getMonth(),
        d = isNext ? startDate.getDate() + 1 : startDate.getDate() - 1;
      const day = new Date(y, m, d);
      await this.assignRangeDate(day);
    },

    /**
     * move today
     * @return {void}
     */
    async toDay() {
      const day = new Date();
      await this.assignRangeDate(day);
    },

    /**
     * open modal range date
     * @return {void}
     */
    selectRangeDate() {
      this.rangeDate = Object.assign({}, this.rangeDate);
      this.openRangeDate = !this.openRangeDate;
    },

    /**
     * change date
     * @param {event} e
     * @return {void}
     */
    async changeDate(e) {
      this.queryStatus.start_date = e.start_date;
      this.queryStatus.end_date = e.end_date;
      this.queryStatus.real_start_date = e.start_date;
      this.queryStatus.real_end_date = e.end_date;
      const setQueryStartDate = this.checkExistQueryString(
        this.$route.query.startDate,
        this.queryStatus.start_date
      );
      if (setQueryStartDate) {
        this.queryData["startDate"] = this.queryStatus.start_date;
      }

      const setQueryEndDate = this.checkExistQueryString(
        this.$route.query.endDate,
        this.queryStatus.end_date
      );
      if (setQueryEndDate) {
        this.queryData["endDate"] = this.queryStatus.end_date;
      }

      if (setQueryStartDate || setQueryEndDate) this.setQueryRouter();

      const l = deepClone(this.statusTypeList);
      this.statusTypeListDays = [l, l, l, l, l, l, l];
      await this.getStatusList();
      await this.setQueryFilter();
      await this.setSortStatusList();
    },

    /**
     * get status class from phaseId
     * @param {int} phaseId
     * @return {string} class name
     */
    getStatusClass(phaseId) {
      if (
        phaseId === SystemConstant.PHASE_ID_PRIMARY_REJECT ||
        phaseId === SystemConstant.PHASE_ID_2_NEXT_REJECT
      ) {
        return "reject-color";
      } else return "";
    },

    /**
     * modify plan href
     * @param {string} scheduleId
     * @return {string} href
     */
    goPlan(scheduleId) {
      let routeData = this.$router.resolve({
        name: "plan",
        params: { id: scheduleId },
      });
      return routeData.href;
    },

    /**
     * sort data table by user_name
     * @param {string} type
     * @return {void}
     */
    sortName(type) {
      let collator = new Intl.Collator("ja", {
        numeric: true,
        sensitivity: "base",
      });
      if (type === "asc") {
        this.statusList = this.statusList.sort((a, b) => {
          let x = a.name_kana;
          let y = b.name_kana;
          return collator.compare(x, y);
        });
      } else {
        this.statusList = this.statusList.sort((a, b) => {
          let x = a.name_kana;
          let y = b.name_kana;
          return collator.compare(y, x);
        });
      }
      this.openSortMenu = false;
      const setQuerySort = this.checkExistQueryString(
        this.$route.query.sort,
        type
      );
      if (setQuerySort) {
        this.queryData["sort"] = type;
        this.setQueryRouter();
      }
    },

    /**
     * clear sort, filter data table
     * @return {void}
     */
    clearCondition() {
      this.groupItemSelected = -1;
      let delSort = false;
      if ("sort" in this.queryData) {
        delSort = true;
        delete this.queryData["sort"];
      }

      let delFilter = false;
      for (var i = 0; i < 7; i++) {
        this.statusTypeListDays[i] = deepClone(this.statusTypeList);
        var filters = `filters-${i}`;
        if (filters in this.queryData) {
          delFilter = true;
          delete this.queryData[filters];
        }
      }
      if (delFilter) {
        if ("header" in this.queryData) {
          delete this.queryData.header;
        }
      }
      if (delSort || delFilter) {
        this.setQueryRouter();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col-90 {
  width: 90%;
}
.reject-color {
  color: #dc143c;
}

.status-container {
  max-height: 100vh;
}

.height-data-table-custom-pc {
  height: calc((var(--vh, 1vh) * 100) - 36px - 60px - 65px);
}

@media (max-width: 599px) {
  .height-data-table-custom-sp {
    height: calc((var(--vh, 1vh) * 100) - 56px - 120px);
  }
}

@media (min-width: 600px) {
  .height-data-table-custom-sp {
    height: calc((var(--vh, 1vh) * 100) - 56px - 60px);
  }
}

.status-url {
  color: rgba(0, 0, 0, 0.87) !important;
  text-decoration: none;
}

.h-100 {
  height: 100%;
}
::v-deep .contentTable {
  .text-header {
    font-size: 15px;
  }
}
</style>
