<template>
  <v-container fill-height fluid class="pa-0">
    <v-row>
      <v-col class="mx-auto" md="12">
        <v-btn
          dark
          tile
          large
          class="grey darken-1"
          @click="toggleDepartmentModal(true)"
          >部署を追加する</v-btn
        >
        <v-btn
          dark
          tile
          large
          class="grey darken-1 ml-2"
          @click="toggleGroupModal(true)"
          >Grを追加する</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mx-auto height-custom" cols="12">
        <v-data-table
          mobile-breakpoint="0"
          :headers="headers"
          :items="departmentsList"
          group-by="department_id"
          class="elevation-1 h-100"
          disable-pagination
          hide-default-footer
          fixed-header
          height="100%"
          ref="dbTable"
        >
          <template v-slot:[`body.prepend`]="">
            <tr>
              <th class="text-start ml-2">株式会社イトクロ</th>
              <th class="text-right">{{ totalMember }}</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </template>
          <template v-slot:[`group.header`]="{ items, toggle, isOpen }">
            <th
              @click="toggle"
              class="fz-m d-flex align-center"
              :data-open="isOpen"
            >
              <v-icon v-if="isOpen" color="black"> mdi-chevron-down </v-icon>
              <v-icon v-else color="black"> mdi-chevron-right </v-icon>
              <span class="ml-8">{{ items[0].department_name }}</span>
            </th>
            <th class="text-right">{{ items[0].department_count }}</th>
            <th>
              <v-btn
                medium
                @click="toggleDepartmentModal(true, 'modify', items[0])"
                >修正</v-btn
              >
            </th>
            <th>
              <v-btn
                medium
                @click="toggleDepartmentModal(true, 'delete', items[0])"
                >削除</v-btn
              >
            </th>
            <th style="with: 96px; min-width: 96px"></th>
            <th class="text-left">{{ items[0].department_manager_name }}</th>
          </template>
          <template v-slot:item="{ item }">
            <tr v-if="item.group_id" @click="gotoProfile($event, item)">
              <td>
                <a v-if="$vuetify.breakpoint.mobile" class="ml-16 d-inline-block black--text text-decoration-none" :href="moveTo(item)">{{
                  item.group_name
                }}</a>
                <span v-else class="ml-16 d-inline-block">{{
                  item.group_name
                }}</span>
              </td>
              <td class="text-right">
                {{ item.group_count }}
              </td>
              <td>
                <v-btn
                  medium
                  @click="
                    $event.stopPropagation();
                    toggleGroupModal(true, 'modify', item);
                  "
                  >修正</v-btn
                >
              </td>
              <td>
                <v-btn
                  medium
                  @click="
                    $event.stopPropagation();
                    toggleGroupModal(true, 'delete', item);
                  "
                  >削除</v-btn
                >
              </td>
              <td>
                <v-btn
                  medium
                  @click="
                    $event.stopPropagation();
                    toggleGroupModal(true, 'moveTo', item);
                  "
                  >移動</v-btn
                >
              </td>
              <td class="text-left">
                {{ item.group_manager_name }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- for Department -->
    <department-update
      :show="departmentUpdateModal"
      :inputDepartment="departmentSeleted"
      :departments="departments"
      @close="toggleDepartmentModal(false)"
      @submit="updateDepartment($event)"
    ></department-update>
    <!-- for Group-->
    <group-update
      :show="groupUpdateModal"
      :departments="departments"
      :mode="groupUpdateModalMode"
      :group="groupSeleted"
      @close="toggleGroupModal(false)"
      @submit="updateGroupModal($event)"
    ></group-update>
    <group-move-to
      :show="groupMoveToModal"
      :group="groupSeleted"
      :departments="departments"
      @close="toggleGroupModal(false, 'moveTo')"
      @submit="moveToDepartment($event)"
    ></group-move-to>
  </v-container>
</template>
<script>
import DepartmentUpdate from "./modal/DepartmentUpdate.vue";
import GroupMoveTo from "./modal/GroupMoveTo.vue";
import GroupUpdate from "./modal/GroupUpdate.vue";
import { GET_BELONGS, BELONG } from "../../constants/api";
import axios from "axios";
import { STATUS_NO_CONTENT } from "../../constants/status";
import { deepClone, isEmpty } from "@/helpers/helpers";
import { apiErrorMessages } from "@/helpers/messages";
import { FC301, FC304 } from "@/constants/fc";
import * as Role from "../../constants/role";
import { queryRouterMixin } from "@/mixins/queryRouter.js";
export default {
  middleware: Role.MASTER,
  components: {
    DepartmentUpdate,
    GroupUpdate,
    GroupMoveTo,
  },
  metaInfo() {
    return { title: this.$t("title.department") };
  },
  mixins: [queryRouterMixin],
  data: () => ({
    headers: [
      {
        text: "部署名",
        value: "",
        groupable: false,
        sortable: false,
        width: "807px",
      },
      {
        text: "メンバー数",
        value: "",
        align: "right",
        groupable: false,
        sortable: false,
        width: "100px",
      },
      {
        text: "",
        value: "",
        align: "",
        groupable: false,
        sortable: false,
        width: "50px",
      },
      {
        text: "",
        value: "",
        align: "",
        groupable: false,
        sortable: false,
        width: "50px",
      },
      {
        text: "",
        value: "",
        align: "left",
        groupable: false,
        sortable: false,
        width: "50px",
      },
      {
        text: "組織長",
        value: "",
        align: "left",
        groupable: false,
        sortable: false,
        width: "300px",
      },
      {
        text: "部署名",
        value: "department_id",
        groupable: false,
      },
    ],
    departments: [],
    departmentSeleted: {},
    departmentUpdateModal: false,
    groupUpdateModalMode: "",
    departmentDeleteModal: false,
    groupSeleted: {},
    groupUpdateModal: false,
    groupDeleteModal: false,
    groupMoveToModal: false,
    isSelecting: false,
    departmentsList: [],
    totalMember: 0,
    expanded: [],
  }),
  methods: {
    async toggleDepartmentModal(flag, mode = "add", department = {}) {
      switch (mode) {
        case "add":
          this.departmentSeleted = {};
          this.departmentUpdateModal = flag;
          break;
        case "modify":
          this.departmentSeleted = department;
          this.departmentUpdateModal = flag;
          break;
        case "delete":
          {
            const departmentGroup = this.departmentsList.filter((item) => {
              return item.department_id == department.department_id;
            });

            if (departmentGroup.length == 1 && !department.group_id) {
              this.$confirm(`${department.department_name}を削除しますか？`, {
                buttons: {
                  ok: {
                    text: this.$t("label.buttons.delete"),
                    type: "primary",
                  },
                  cancel: {
                    text: this.$t("label.buttons.cancel"),
                    type: "ligth",
                  },
                },
                rtl: true,
              })
                .then(async () => {
                  try {
                    await axios.delete(BELONG(department.department_id));
                    this.$toast(
                      `${department.department_name}を削除しました。`,
                      3000
                    );
                    this.departmentsList = this.departmentsList.filter(
                      (x) => x.department_id !== department.department_id
                    );
                    // Remove from departments
                    const departmentIndex = this.departments.findIndex(
                      (x) => x.id === department.department_id
                    );
                    this.departments.splice(departmentIndex, 1);
                  } catch (error) {
                    const { status } = error.response;
                    if (status === 401) {
                      this.$alert([error.response.data.message], {
                        width: "auto",
                      });
                    } else {
                      apiErrorMessages(this, FC304, status);
                    }
                  }
                })
                .catch(() => {});
            } else {
              this.$alert(
                "紐づいているグループが存在します。削除できません。",
                {
                  width: "650px",
                }
              );
            }
          }
          break;
        default:
          break;
      }
    },
    toggleGroupModal(flag, mode = "add", group = {}) {
      switch (mode) {
        case "add":
          this.groupSeleted = {};
          this.groupUpdateModal = flag;
          this.groupUpdateModalMode = mode;
          break;
        case "modify":
          this.groupSeleted = group;
          this.groupUpdateModal = flag;
          this.groupUpdateModalMode = mode;
          break;
        case "delete":
          {
            if (group.group_count <= 0) {
              this.$confirm(`${group.group_name}を削除しますか？`, {
                buttons: {
                  ok: {
                    text: this.$t("label.buttons.delete"),
                    type: "primary",
                  },
                  cancel: {
                    text: this.$t("label.buttons.cancel"),
                    type: "ligth",
                  },
                },
                rtl: true,
              })
                .then(async () => {
                  try {
                    await axios.delete(
                      BELONG(group.department_id, group.group_id)
                    );
                    // group.group_id = null;
                    this.$toast(`${group.group_name}を削除しました。`, 3000);
                    // Remove record from department list
                    const length = this.departmentsList.filter((x) => {
                      x.group_id === group.group_id &&
                        x.department_id === group.department_id;
                    })?.length;
                    if (length > 1) {
                      const recordIndex = this.departmentsList.findIndex(
                        (x) =>
                          x.group_id === group.group_id &&
                          x.department_id === group.department_id
                      );
                      this.departmentsList.splice(recordIndex, 1);
                    } else {
                      const recordIndex = this.departmentsList.findIndex(
                        (x) =>
                          x.group_id === group.group_id &&
                          x.department_id === group.department_id
                      );
                      let item = this.departmentsList.find(
                        (x) =>
                          x.group_id === group.group_id &&
                          x.department_id === group.department_id
                      );
                      delete item.group_id;
                      delete item.group_name;
                      delete item.group_count;
                      delete item.group_manager_id;
                      delete item.group_manager_name;
                      this.departmentsList.splice(recordIndex, 1);
                      this.departmentsList.splice(recordIndex, 0, item);
                    }
                  } catch (error) {
                    const { status } = error.response;
                    apiErrorMessages(this, FC304, status);
                  }
                })
                .catch(() => {});
            } else {
              this.$alert(
                "紐づいているメンバーが存在します。削除できません。",
                {
                  width: "650px",
                }
              );
            }
          }
          break;
        case "moveTo":
          this.groupSeleted = group;
          this.groupMoveToModal = flag;
          break;
        default:
          break;
      }
    },

    async getDepartments() {
      try {
        const { data, status } = await axios.get(GET_BELONGS());
        let list = status === STATUS_NO_CONTENT ? [] : data.result.departments;
        this.departments = deepClone(list);
        this.totalMember = this.getTotalMember(list); // Set total
        this.departmentsList = this.customerList(list); // Set list
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC301, status);
      }
    },
    //
    customerList(list) {
      let newList = [];

      list.forEach((item, index) => {
        let pushItem = {
          id: index,
          department_id: item.id,
          department_name: item.name,
          department_count: item.count,
          department_manager_id: item.manager_id,
          department_manager_name: item.manager_name,
        };

        if (isEmpty(item.groups)) {
          newList.push({ ...pushItem });
        } else {
          item.groups.forEach((childItem) => {
            let pushChildItem = {
              group_id: childItem.id,
              group_name: childItem.name,
              group_count: childItem.count,
              group_manager_id: childItem.manager_id,
              group_manager_name: childItem.manager_name,
            };
            newList.push({ ...pushItem, ...pushChildItem });
          });
        }
      });

      return newList;
    },

    //
    getTotalMember(list) {
      return list.reduce((total, item) => total + item.count, 0);
    },

    updateDepartment(department) {
      if (this.departmentsList.some((x) => x.department_id === department.id)) {
        this.departmentsList = this.departmentsList.map((x) => {
          if (x.department_id === department.id) {
            x.department_id = department.id;
            x.department_name = department.departmentName;
            x.department_manager_id = department.organizationHead;
            x.department_manager_name = department.organizationHeadName;
            return x;
          } else {
            return x;
          }
        });
        this.departments = this.departments.map((x) => {
          if (x.id === department.id) {
            x.id = department.id;
            x.name = department.departmentName;
            x.manager_id = department.organizationHead;
            x.manager_name = department.organizationHeadName;
            return x;
          } else {
            return x;
          }
        });
      } else {
        // Add to department List
        this.departmentsList.push({
          department_id: department.id,
          department_name: department.departmentName,
          department_manager_id: department.organizationHead,
          department_manager_name: department.organizationHeadName,
          department_count: 0,
        });
        // Add to departments
        this.departments.push({
          count: 0,
          id: department.id,
          name: department.departmentName,
          manager_id: department.organizationHead,
          manager_name: department.organizationHeadName,
          groups: [],
        });
      }
      this.toggleDepartmentModal(false);
    },

    updateGroupModal(group) {
      switch (this.groupUpdateModalMode) {
        case "add":
          this.departmentsList.push(group);

          this.$toast(`${group.group_name}を追加しました。`, 3000);
          break;
        case "modify":
          this.departmentsList = this.departmentsList.filter((item) => {
            return !(
              item.group_id == group.group_id &&
              item.department_id == group.department_id
            );
          });
          this.departmentsList.push(group);

          this.$toast(`${group.group_name}を編集しました。`, 3000);
          break;
        default:
          break;
      }

      this.toggleGroupModal(false);
    },

    gotoProfile(e, item) {
      e.preventDefault();
      if (e.ctrlKey) {
        let routeData = this.$router.resolve({
          path: "/profiles",
          query: {
            groupId: item.group_id,
            departmentId: item.department_id,
          },
        });
        this.openNewBackgroundTab(routeData.href);
      } else {
        this.$router.push({
          path: `/profiles?groupId=${item.group_id}&departmentId=${item.department_id}`,
        });
      }
    },

    moveTo(item) {
      return `/profiles?groupId=${item.group_id}&departmentId=${item.department_id}`;
    },

    moveToDepartment(group) {
      let item = this.departmentsList.find(
        (x) => x.group_id === +group.group_id
      );
      let oldId = item.department_id;
      item.department_id = group.department_id;
      item.department_name = group.department_name;
      this.departmentsList.map((x) => {
        if (x.department_id === +group.department_id) {
          x.department_count += group.group_count;
        }
        if (x.department_id === +oldId) {
          x.department_count -= group.group_count;
        }
      });
      this.toggleGroupModal(false, "moveTo");
    },
  },

  async mounted() {
    await this.getDepartments();
    const self = this;

    for (const name of Object.keys(self.$refs.dbTable.openCache)) {
      self.$refs.dbTable.openCache[name] = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.text-transform {
  text-transform: inherit;
}
.fz-m {
  font-size: 0.875em;
}
.height-custom {
  height: calc((var(--vh, 1vh) * 100) - 64px - 60px);
}
.h-100 {
  height: 100%;
}
// TODO: Change color
::v-deep .theme--light.v-data-table .v-row-group__header:hover {
  background-color: #eeeeee !important;
}
::v-deep .theme--light.v-data-table .v-row-group__header {
  background-color: white !important;
}
</style>
