<template>
  <v-menu v-model="openMenu" open-on-click bottom offset-y>
    <template v-slot:activator="{ on }">
      <v-btn
        :disabled="disabled"
        tile
        depressed
        outlined
        v-on="on"
        color="ligth"
        class="text-capitalize"
        :class="[
          $vuetify.breakpoint.mobile ? 'dropdown-btn-mobile' : 'dropdown-btn',
        ]"
      >
        <span class="text-truncate">{{ dropdownName }}</span>
        <v-icon large right dark>mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <v-list class="overflow-y-auto" max-height="70vh">
      <v-list-item-group>
        <v-list-item v-if="isAll" @click="selectAll()">
          <v-list-item-content>
            <v-list-item-title>全メンバー</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="item in items" :key="item.id">
          <v-menu
            open-on-click
            right
            offset-x
            v-if="item.groups && item.groups.length > 0"
          >
            <template v-slot:activator="{ on }">
              <div
                class="d-flex v-list-item text-truncate"
                v-on="on"
                @click="toggle(item.id)"
              >
                <v-list-item-content>
                  <v-list-item-title class="max-w-department" v-text="item.name"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="toggle(item.id)" v-on="on" icon>
                    <v-icon>mdi-menu-right</v-icon>
                  </v-btn>
                </v-list-item-action>
              </div>
            </template>
            <group-item
              class="group"
              v-if="item.groups && open && item.id === departmentSelected.id"
              :items="item.groups"
              :parent="departmentSelected"
              @groupChanged="groupChanged($event)"
            ></group-item>
          </v-menu>

          <div v-else class="d-flex v-list-item">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </div>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>
<script>
import GroupItem from "./GroupItem.vue";
export default {
  components: { GroupItem },
  name: "DepartmentPullDown",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    isAll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      groups: [],
      dropdownName: this.title,
      open: false,
      departmentSelected: {},
      groupSelected: {},
      checkAllMember: false,
      openMenu: false,
    };
  },

  watch: {
    title: {
      handler(val) {
        this.dropdownName = val;
      },
    },
  },

  methods: {
    toggle: function (departmentId) {
      this.open =
        departmentId !== this.departmentSelected.id ? true : !this.open;
      this.departmentSelected = this.items.find((x) => x.id === departmentId);
    },

    groupChanged(value) {
      this.open = false;
      this.$emit("input", this.open);
      this.departmentSelected = value.department;
      this.groupSelected = value.group;
      this.dropdownName = `${this.departmentSelected.name} ＞ ${this.groupSelected.name}`;
      this.selectedCondition = {
        department: this.departmentSelected,
        group: this.groupSelected,
      };
      this.$emit("groupChanged", this.selectedCondition);
      this.openMenu = false;
    },

    selectAll() {
      this.checkAllMember = true;
      this.dropdownName = "全メンバー";
      this.$emit("selectedAll");
    },
  },
};
</script>
<style lang="scss" scoped>
.department {
  position: relative;
}
// .group {
//   width: 200px;
// }
.dropdown-btn-mobile {
  max-width: 100%;
}
.dropdown-btn {
  max-width: 300px;
}

.dropdown-btn ::v-deep span.v-btn__content,
.dropdown-btn-mobile ::v-deep span.v-btn__content {
  max-width: 100% !important;
}
.max-w-department {
  max-width: 225px;
}
</style>
