import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'

import './components'
import './plugins'
import MessageBox from './components/message-box/index'
import PromptBox from './components/prompt-box/index'
import Toast from './components/toast/index'
import { evalMixin } from './mixins/eval'

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = PromptBox.prompt
Vue.prototype.$toast = Toast.toast

Vue.mixin(evalMixin)
Vue.directive('blur', {
  inserted: function (el) {
    el.onfocus = (ev) => ev.target.blur()
  }
});
Vue.directive('longpress', {
  bind: function (el, binding, vNode) {
      
      // Make sure expression provided is a function
      if (typeof binding.value.function !== 'function') {
          // Fetch name of component
          const compName = vNode.context.name
          // pass warning to console
          let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be`
          if (compName) { warn += `Found in component '${compName}' ` }

          console.warn(warn)
      }

      // Define variable
      let pressTimer = null

      // Define funtion handlers
      // Create timeout ( run function after 1s )
      let start = (e) => {

          if (e.type === 'click' && e.button !== 0) {
              return;
          }

          if (pressTimer === null) {
              pressTimer = setTimeout(() => {
                  // Run function
                  handler(e)
              }, 10)
          }
      }

      // Cancel Timeout
      let cancel = () => {
          // Check if timer has a value or not
          if (pressTimer !== null) {
              clearTimeout(pressTimer)
              pressTimer = null
          }
      }
      // Run Function
      const handler = () => {
          binding.value.function(binding.value.item)
      }

      // Add Event listeners
      el.addEventListener("mousedown", start);
      el.addEventListener("touchstart", start);
      // Cancel timeouts if this events happen
      el.addEventListener("click", cancel);
      el.addEventListener("mouseout", cancel);
      el.addEventListener("touchend", cancel);
      el.addEventListener("touchcancel", cancel);
  }
});
const vue = new Vue({
  vuetify,
  i18n,
  store,
  router,
  ...App
})

vue.$mount('#app')
