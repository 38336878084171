<template>
  <v-menu open-on-click bottom offset-y>
    <template v-slot:activator="{ on }">
      <v-btn class="case" v-if="showMenu" :tile="tileType" :text="textType" depressed v-on="on" color="ligth">
        <span :class="$vuetify.breakpoint.mobile ? 'btn_mobile' : 'btn_pc'">{{ displayName }}</span>
        <v-icon large right dark>mdi-menu-down</v-icon>
      </v-btn>
      <v-btn class="case" v-else :tile="tileType" :text="textType" color="ligth">
        <span :class="$vuetify.breakpoint.mobile ? 'btn_mobile' : 'btn_pc'">{{ displayName }}</span>
        <v-icon large right dark>mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <v-list class="overflow-y-auto" max-height="50vh">
      <v-list-item-group>
        <v-list-item v-if="hasTotal">
          <v-list-item-content>
            <v-list-item-title> 全て</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="selected(item)" v-for="item in items" :key="item[id]">
          <v-list-item-content>
            <v-list-item-title v-text="item[display]"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'DropdownButton',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    name: String,
    display: String,
    id: String,
    type: {
      type: String,
      default: 'tile'
    },
    showMenu: {
      type: Boolean,
      default: false
    },
    hasTotal: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    selected(item) {
      this.displayName = item[this.display];
      this.$emit('dropdownSelected', item);
    }
  },
  data() {
    return {
      tileType: false,
      textType: false,
      displayName: this.name
    };
  },
  watch: {
    name: {
      immediate: true,
      handler(val) {
        this.displayName = val;
      }
    }
  },
  created() {
    switch (this.type) {
      case 'text':
        this.textType = true;
        break;
      case 'tile':
        this.tileType = true;
        break;
    }
  }
};
</script>

<style lang="scss" scoped>
.case {
  text-transform: none;
}
.case span {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

.case .btn_mobile {
  max-width: 110px;
}
.btn_pc {
  max-width: 300px;
}
</style>
