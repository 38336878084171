 <template>
  <WeeklyCalendar :isSchedule="false"></WeeklyCalendar>
</template>
<script>
import WeeklyCalendar from "../schedule/components/WeeklyCalendar";
export default {
  name: "WeeklyActuals",
  components: {
    WeeklyCalendar,
  },
  metaInfo() {
    return { title: this.$t("title.weekly-actuals") };
  },
};
</script>