var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fill-height":"","fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"md":"12"}},[_c('v-btn',{staticClass:"grey darken-1",attrs:{"dark":"","tile":"","large":""},on:{"click":function($event){return _vm.toggleDepartmentModal(true)}}},[_vm._v("部署を追加する")]),_c('v-btn',{staticClass:"grey darken-1 ml-2",attrs:{"dark":"","tile":"","large":""},on:{"click":function($event){return _vm.toggleGroupModal(true)}}},[_vm._v("Grを追加する")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"mx-auto height-custom",attrs:{"cols":"12"}},[_c('v-data-table',{ref:"dbTable",staticClass:"elevation-1 h-100",attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.departmentsList,"group-by":"department_id","disable-pagination":"","hide-default-footer":"","fixed-header":"","height":"100%"},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',[_c('th',{staticClass:"text-start ml-2"},[_vm._v("株式会社イトクロ")]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.totalMember))]),_c('th'),_c('th'),_c('th'),_c('th')])]},proxy:true},{key:"group.header",fn:function(ref){
var items = ref.items;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('th',{staticClass:"fz-m d-flex align-center",attrs:{"data-open":isOpen},on:{"click":toggle}},[(isOpen)?_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-chevron-down ")]):_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-chevron-right ")]),_c('span',{staticClass:"ml-8"},[_vm._v(_vm._s(items[0].department_name))])],1),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(items[0].department_count))]),_c('th',[_c('v-btn',{attrs:{"medium":""},on:{"click":function($event){return _vm.toggleDepartmentModal(true, 'modify', items[0])}}},[_vm._v("修正")])],1),_c('th',[_c('v-btn',{attrs:{"medium":""},on:{"click":function($event){return _vm.toggleDepartmentModal(true, 'delete', items[0])}}},[_vm._v("削除")])],1),_c('th',{staticStyle:{"with":"96px","min-width":"96px"}}),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(items[0].department_manager_name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(item.group_id)?_c('tr',{on:{"click":function($event){return _vm.gotoProfile($event, item)}}},[_c('td',[(_vm.$vuetify.breakpoint.mobile)?_c('a',{staticClass:"ml-16 d-inline-block black--text text-decoration-none",attrs:{"href":_vm.moveTo(item)}},[_vm._v(_vm._s(item.group_name))]):_c('span',{staticClass:"ml-16 d-inline-block"},[_vm._v(_vm._s(item.group_name))])]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.group_count)+" ")]),_c('td',[_c('v-btn',{attrs:{"medium":""},on:{"click":function($event){$event.stopPropagation();
                  _vm.toggleGroupModal(true, 'modify', item);}}},[_vm._v("修正")])],1),_c('td',[_c('v-btn',{attrs:{"medium":""},on:{"click":function($event){$event.stopPropagation();
                  _vm.toggleGroupModal(true, 'delete', item);}}},[_vm._v("削除")])],1),_c('td',[_c('v-btn',{attrs:{"medium":""},on:{"click":function($event){$event.stopPropagation();
                  _vm.toggleGroupModal(true, 'moveTo', item);}}},[_vm._v("移動")])],1),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.group_manager_name)+" ")])]):_vm._e()]}}],null,true)})],1)],1),_c('department-update',{attrs:{"show":_vm.departmentUpdateModal,"inputDepartment":_vm.departmentSeleted,"departments":_vm.departments},on:{"close":function($event){return _vm.toggleDepartmentModal(false)},"submit":function($event){return _vm.updateDepartment($event)}}}),_c('group-update',{attrs:{"show":_vm.groupUpdateModal,"departments":_vm.departments,"mode":_vm.groupUpdateModalMode,"group":_vm.groupSeleted},on:{"close":function($event){return _vm.toggleGroupModal(false)},"submit":function($event){return _vm.updateGroupModal($event)}}}),_c('group-move-to',{attrs:{"show":_vm.groupMoveToModal,"group":_vm.groupSeleted,"departments":_vm.departments},on:{"close":function($event){return _vm.toggleGroupModal(false, 'moveTo')},"submit":function($event){return _vm.moveToDepartment($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }