 <template>
  <WeeklyCalendar :isSchedule="true"></WeeklyCalendar>
</template>
<script>
import WeeklyCalendar from "./components/WeeklyCalendar";
export default {
  name: "WeeklySchedules",
  components: {
    WeeklyCalendar,
  },
  metaInfo() {
    return { title: this.$t("title.weekly-schedules") };
  },
};
</script>