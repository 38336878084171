<template>
  <v-menu
    v-model="openMenu"
    auto
    transition="scale-transition"
    bottom
    offset-y
    min-width="200"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn tile v-bind="attrs" v-on="on">
         <v-icon small>$tool</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="showExplainModal()">
        <v-icon class="mr-1">$information</v-icon>
        <v-list-item-content>
          <v-list-item-title> インフォメーション</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-icon class="mr-1">$indicatescutover</v-icon>
        <v-list-item-content>
          <v-menu
            v-model="openSlotMenu"
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"> 表示切替 </span>
            </template>
            <v-select
              v-model="slotDuration"
              :items="solotDurationOptions"
              hide-details
              solo
              @change="reloadSlotDuration"
            ></v-select>
          </v-menu>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isSelf">
        <v-icon class="mr-1">$memo</v-icon>
        <v-list-item-content>
          <daily-template
            @reload="reloadSchedule()"
            :planId="planId"
            :isDisable="isDisable"
            :isNoTask="isNoTask"
            :isHasSplitTask="isHasSplitTask"
          ></daily-template>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isSelf" @click="canSubmit && arrangeTask()">
        <v-icon class="mr-1">$alignment</v-icon>
        <v-list-item-content>
          <v-list-item-title> 整列</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import DailyTemplate from "./DailyTemplate";
export default {
  components: {
    DailyTemplate,
  },
  props: {
    slotDurationData: String,
    solotDurationOptionsData: Array,
    planId: String,
    isDisable: Boolean,
    isNoTask: Boolean,
    isSelf: Boolean,
    canSubmit: Boolean,
    isHasSplitTask: Boolean
  },

  data() {
    return {
      openMenu: false,
      openSlotMenu: false,
      slotDuration: this.slotDurationData,
      solotDurationOptions: this.solotDurationOptionsData,
    };
  },

  watch: {
    slotDurationData: {
      handler(value) {
        this.slotDuration = value;
      },
    },
  },

  methods: {
    close() {
      this.openExtensiveTask = false;
    },
    showExplainModal() {
      this.$emit("showExplainModal");
    },
    reloadSchedule() {
      this.$emit("reload");
    },
    reloadSlotDuration() {
      this.openSlotMenu = false;
      this.openMenu = false;
      this.$emit("reloadSlotDuration", this.slotDuration);
    },
    arrangeTask() {
      this.$emit("arrangeTask");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .border-dialog {
  border-radius: 30px;
  .v-card__text {
    padding-top: 10px;
  }
}
</style>
