<template>
  <v-dialog
    :value="openDialog"
    @input="$emit('input')"
    persistent
    max-width="500px"
    scrollable
  >
    <v-card>
      <v-card-title class="pb-3"> 日次実績 </v-card-title>
      <v-card-text>
        <v-card-subtitle class="ml-2">
          <v-icon small>$calendar</v-icon>
          <span> {{ date }}</span>
        </v-card-subtitle>
        <div v-if="hasUnCompletedTask()">
          <v-card-subtitle class="pb-2 ml-2 font-weight-bold">
            ▼ 未実施のタスク
          </v-card-subtitle>
          <div class="pb-4 ml-10">
            アーカイブするタスクを選択(※未完了タスクに保存されます)
          </div>
          <v-list-item
            v-for="(task, i) in listTask"
            :key="task.id"
            class="word-break"
          >
            <v-checkbox
              v-model="task.check"
            ></v-checkbox>
            {{ i + 1 }}. {{ task.title }}
          </v-list-item>
          <v-card-subtitle class="ml-2 font-weight-bold">
            ▼ 未実施の理由
          </v-card-subtitle>
          <v-container fluid>
            <v-textarea
              v-model="item.subComment"
              clearable
              auto-grow
              clear-icon="mdi-close"
              placeholder="コメントを追加"
              maxlength="512"
              counter
            ></v-textarea>
          </v-container>
        </div>
        <!-- start of add tasks !-->
        <div v-if="addTasks.length > 0">
          <v-card-subtitle class="pb-2 ml-2 font-weight-bold">
            ▼ 追加で実施したタスク
          </v-card-subtitle>
          <v-list-item
            v-for="(task, i) in addTasks"
            :key="task.id"
            class="word-break"
          >
            {{ i+1 }}. {{ task.title }} {{task.detail}}
          </v-list-item>
        </div>
        <!-- end of add tasks !-->
        <!-- start of List comment !-->
        <div v-if="showOldComments" class="my-0 py-0 overflow-hidden">
          <v-card-subtitle class="font-weight-bold ml-2">
            ▼コメント履歴
          </v-card-subtitle>
          <CommentList :notifications="notifications"></CommentList>
          <v-divider class="mx-3 mt-3 comments-divider"></v-divider>
        </div>
        <!-- end of List comment !-->
        <v-card-subtitle class="ml-4 font-weight-bold">
          ▼ 一言(感想・反省・改善・提案)
        </v-card-subtitle>
        <v-container fluid>
          <v-textarea
            v-model="item.comment"
            clearable
            auto-grow
            clear-icon="mdi-close"
            :placeholder="`コメントを追加 \n ※未実施以外の補足事項（タスクの予定時間から大幅な時間超過が発生した場合など）も記載`"
            maxlength="512"
            counter
          ></v-textarea>
        </v-container>
         <div>
         <v-checkbox 
         v-model="isChecked" 
         class="actual_cheak"
           label="日次実績について「事実に基づいて、タスクの回数やステータス、業務時間などを入力し、提出すること」に同意します。"
           value="on"
          />
          </div>
      </v-card-text>
      <v-card-actions :class="[{ 'responsive-button': $vuetify.breakpoint.mobile }]">
        <v-spacer></v-spacer>
        <v-btn depressed outlined color="ligth" @click="close()">{{
          $t("label.buttons.cancel")
        }}</v-btn>
        <v-btn
          depressed
          outlined
          @click="saveDraft()"
          class="font-weight-bold"
          >{{ $t("label.buttons.save_draft") }}</v-btn
        >
        <div
          style="position: relative"
          class="py-2 no-print submit-button"
          @mouseenter="isNoSubmmit() && submitValidation()"
          @mouseleave="isNoSubmmit() && hideValidation()"
        >
          <v-btn
          depressed
          outlined
          @click="submit"
          class="font-weight-bold"
          :class="[{ 'new-line': $vuetify.breakpoint.mobile }]"
          :disabled="!(isChecked && isEditing)"
          ><v-icon class="blue--text mr-2" small right>$submitorconfirm</v-icon
          > {{ $t("label.buttons.submission") }} </v-btn
        >
        <SnackError ref="snackErrorComponent"></SnackError>
        </div>
        
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { backMixin } from "../../../mixins/back";
import {
  formatDateTime,
  parseDateTime,
} from "../../../helpers/datetime-helpers";
import * as Role from "../../../constants/role";
import { yyyyMMddEEEEE3, yyyyMMdd2 } from "../../../constants/df";
import { debounce_leading, deepClone, isEmpty } from "../../../helpers/helpers";
import CommentList from "../../schedule/components/CommentList";
import SnackError from "../../../components/SnackError";
export default {
  middleware: Role.AUTH,
  components: {
    CommentList,
    SnackError,
  },
  props: {
    show: Boolean,
    inputComment: String,
    inputSubComment: String,
    selectedDate: String,
    tasks: {
      type: Array,
      default: () => [],
    },
    addTasks: {
      type: Array,
      default: () => [],
    },
    activeTask: Boolean,
    notifications: Array,
    showOldComments: Boolean,
  },
  mixins: [backMixin],
  data() {
    return {
      openDialog: this.show,
      item: {
        comment: "",
        subComment: "",
      },
      listTask: [],
      originalListTask: [],
      isEditing: true,
      isChecked: false,
    };
  },
  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },
  watch: {
    // comment: {
    //   handler(val) {
    //     this.$store.dispatch("save/setChangeFlag", !isEmpty(val));
    //   },
    // },
    async show() {
      this.openDialog = true;
      this.isEditing = true;
      this.isChecked=false;
      this.item.comment = this.inputComment;
      this.item.subComment = this.inputSubComment;
      if (this.hasUnCompletedTask()) {
        this.isEditing = isEmpty(this.item.subComment) || this.isPreValidation() ? false : true;

        this.listTask = [];
        this.listTask = this.tasks.map((x) => {
          return {
            id: x.id,
            title: x.detail ? x.name + " " + x.detail : x.name,
            check: x.check,
            check_old: this.activeTask,
            disabled: x.check,
          };
        });
        this.originalListTask = deepClone(this.listTask);
      } 
      else
      {
        this.isEditing = this.isPreValidation() ? false : true;
      }
    },
    item: {
      deep: true,
      handler() {
        if (this.hasUnCompletedTask()) {
          this.isEditing = isEmpty(this.item.subComment) || this.isPreValidation() ? false : true;
        }
        else {
          this.isEditing = this.isPreValidation() ? false : true;
        }
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
        // this.$store.dispatch("save/setChangeFlag", true);
      },
    },
    listTask: {
      deep: true,
      handler(val) {
        this.isChecking = false;
        val.map((element, i) => {
          if (
            JSON.stringify(element.check) !==
            JSON.stringify(this.originalListTask[i].check)
          ) {
            element.check_old = false;
            this.isChecking = true;
            return;
          }
        });
      },
    },
  },
  computed: {
    ...mapGetters("save", ["getChangeFlag"]),
    date() {
      return formatDateTime(
        parseDateTime(this.selectedDate, yyyyMMdd2),
        yyyyMMddEEEEE3
      );
    },
  },
  methods: {
    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.openDialog = false;
            this.$emit("input");
          }, 100);
        },
        () => {}
      );
    },
    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.openDialog = false;
          this.$emit("input");
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },
    submit: debounce_leading(function () {
      const unCompletedTasks = this.listTask;
      this.$store.dispatch("save/setChangeFlag", false);
      this.openDialog = false;
      this.$emit("submit", {
        comment: this.item.comment,
        sub_comment: this.item.subComment,
        unCompleted_ids: unCompletedTasks.map((a) => {
              return {
                id: a.id,
                is_checked: !!a.check
              };
            }),
      });
    }),
    saveDraft: debounce_leading(function () {
      const unCompletedTasks = this.listTask;
      this.openDialog = false;
      this.$emit("saveDraft", {
        comment: this.item.comment,
        sub_comment: this.item.subComment,
        unCompleted_ids: unCompletedTasks.map((a) => {
              return {
                id: a.id,
                is_checked: !!a.check
              };
            }),
      });
    }),
    
    hasUnCompletedTask() {
      return !isEmpty(this.tasks) && this.tasks.length > 0;
    },
    isPreValidation() {
      return this.$root.$refs.actualComponent.isPreValidation();
    },
    isNoSubmmit(){
      return this.$root.$refs.actualComponent.isNoSubmmit();
    },
    submitValidation(){
      let errors = this.$root.$refs.actualComponent.submitValidation();
      if (errors.length > 0) {
        this.$refs.snackErrorComponent.show(
          true,
          true,
          this.showDrawerRight,
          errors,
          50000
        );
      }
    },
    hideValidation(){
      this.$refs.snackErrorComponent.hide();
    }
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>
<style lang="scss" src="@/assets/scss/_comment.scss" scoped></style>
<style lang="scss">
.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.v-list-item {
  align-items: inherit;
}
.word-break {
  word-break: break-all;
}
.actual_cheak label{ 
   font-size: 20px !important;
}

.submit-button{
  margin-left: 7px !important;
}
</style>
