<template>
  <div class="icon-wrapper"></div>
</template>
<script>
export default { 
  name: "ExpertIcon" 
};
</script>
<style lang="scss" scoped>
.icon-wrapper {
  width: 24px;
  height: 24px;
  background-size: 31px 24px;
  background-position: -3px 0;
  background-image: url("~@/assets/images/expert.png");
}
</style>
