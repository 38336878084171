import { formatDateTime, parseDateTime } from "@/helpers/datetime-helpers";
import {
  replaceKanaHalfToFull,
  replaceKanaToHira,
  replaceAlphabetToFull,
} from "@/helpers/helpers";
import {
  yyyyMMdd3,
  yyyyMMddEEEEE3,
} from "@/constants/df";
export const taskMixin = {
  data: () => {
    return {
      labelType: 'タスクタイプ'
    }
  },
  methods: {
    formatSubject(subject) {
      let processNameArr = subject.split(" ");
      if (processNameArr.length > 0) {
        processNameArr[0] = formatDateTime(
          parseDateTime(processNameArr[0], yyyyMMdd3),
          yyyyMMddEEEEE3
        );
        return processNameArr.length === 2 ? `${processNameArr[0]} ${processNameArr[1]}` : processNameArr[0];
      }
      return "";
    },
    convertSearchKey(search) {
      if (!search) return null
      const searchArr = search.split("");
      if (searchArr.length === 0) return;
      let result = replaceKanaHalfToFull(search);
      result = replaceKanaToHira(result);
      result = replaceAlphabetToFull(result);
      return result;
    }
  }
}