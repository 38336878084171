import * as types from '../mutation-types'
/**
 * Store state
 */
export const state = {
  busy: false,
  loginInfo: localStorage.getItem('loginInfo') && localStorage.getItem('loginInfo') != "undefined" ? JSON.parse(localStorage.getItem('loginInfo')) : null,
  selectedMember: null,
  scheduleExpand: 0,
  actualExpand: 0,
  showDrawer: false,
};

/**
 * Store mutations
 */
export const mutations = {
  [types.IS_BUSY](state, payload) {
    state.busy = payload
  },
  [types.SAVE_LOGIN_INFO](state, {
    loginInfo
  }) {
    if (loginInfo){
      state.loginInfo = loginInfo
      localStorage.setItem('loginInfo',JSON.stringify(loginInfo))
    }
  },
  [types.REMOVE_LOGIN_INFO](state) {
    state.loginInfo = null
    localStorage.removeItem('loginInfo')
  },
  [types.SET_MEMBER](state, {
    selectedMember
  }) {
    state.selectedMember = selectedMember
    localStorage.removeItem('loginInfo')
  },
  [types.REMOVE_SELECTED_MEMBER](state) {
    state.selectedMember = null
  },
  [types.IS_SCHEDULE_EXPAND](state, payload) {
    state.scheduleExpand = payload
  },
  [types.IS_ACTUAL_EXPAND](state, payload) {
    state.actualExpand = payload
  },
  [types.IS_SHOW_DRAWER](state, payload) {
    state.showDrawer = payload
  }
};

/**
 * Store actions
 */
export const actions = {
  /**
   * Store current status of page
   *
   * @param {String} context IS_BUSY
   * @param {Boolean} busy True/False
   */
  isBusy(context, busy) {
    context.commit("IS_BUSY", busy);
  },

  saveLoginInfo({
    commit
  }, payload) {
    commit(types.SAVE_LOGIN_INFO, payload)
  },
  removeLoginInfo({
    commit
  }) {
    commit(types.REMOVE_LOGIN_INFO)
  },
  setMember({
    commit
  }, payload) {
    commit(types.SET_MEMBER, payload)
  },
  removeSelectMember({
    commit
  }) {
    commit(types.REMOVE_SELECTED_MEMBER)
  },
  isScheduleExpand({
    commit
  }, payload) {
    commit(types.IS_SCHEDULE_EXPAND, payload)
  },
  isActualExpand({
    commit
  }, payload) {
    commit(types.IS_ACTUAL_EXPAND, payload)
  },
  isShowDrawer({
    commit
  }, payload) {
    commit(types.IS_SHOW_DRAWER, payload)
  },
};

/**
 * State Getters
 */
export const getters = {
  getBusy: state => state.busy,
  loginInfo: state => state.loginInfo,
  selectedMember: state => state.selectedMember,
  isScheduleExpand: state => state.scheduleExpand,
  isActualExpand: state => state.actualExpand,
  isShowDrawer: state => state.showDrawer
}
