import {
  HHmmss1,
  HHmm2,
  HHmm1
} from '../constants/df';
import {
  formatTimeEx,
  formatDateTime
} from '../helpers/datetime-helpers';

export const initTimeRange = () => {
  var newTimes = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j = j + 15) {
      newTimes.push(`${('0' + i).slice(-2)}:${('0' + j).slice(-2)}`);
    }
  }
  return newTimes;
};

export const initBreakTimeRange = () => {
  var newTimes = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j = j + 15) {
      newTimes.push(`${('0' + i).slice(-2)}:${('0' + j).slice(-2)}`);
    }
  }
  return newTimes;
};

/**
 * Adjust min time
 * @param {*} v Time to format. etc: 151200, 163000 ...
 * @param {*} min Min value to set
 * @returns
 */
export const adjustMinEnd = (start, end, fmt, min = 5) => {
  try {
    let diff = diffMinutes(start, end, fmt);
    const add_minutes = function (dt, minutes) {
      return new Date(dt.getTime() + minutes * 60000);
    }
    let addMinutes = 0;

    if (Number(diff) < min && Number(diff) > 0) {
      addMinutes = min - Number(diff);
    }

    switch (fmt) {
      case HHmmss1: {
        let endDate = add_minutes(formatTimeEx(end, HHmmss1), addMinutes)
        return formatDateTime(endDate, HHmmss1);
      }
      case HHmm1: {
        let endDate = add_minutes(formatTimeEx(end, HHmm1), addMinutes)
        return formatDateTime(endDate, HHmm1);
      }
      case HHmm2: {
        let endDate = add_minutes(formatTimeEx(end, HHmm2), addMinutes)
        return formatDateTime(endDate, HHmm2);
      }
      default:
        throw `Format ${fmt} doesn't support!`;
    }
  } catch (error) {
    console.error(error)
    return end;
  }
};

/**
 * Adjust min time
 * @param {*} v Time to format. etc: 151200, 163000 ...
 * @param {*} min Min value to set
 * @returns
 */
 export const adjustMinStart = (start, end, fmt, min = 5) => {
  try {
    let diff = diffMinutes(start, end, fmt);
    const sub_minutes = function (dt, minutes) {
      return new Date(dt.getTime() - minutes * 60000);
    }
    let subMinutes = 0;

    if (Number(diff) < min && Number(diff) > 0) {
      subMinutes = min - Number(diff);
    }

    switch (fmt) {
      case HHmmss1: {
        let startDate = sub_minutes(formatTimeEx(start, HHmmss1), subMinutes)
        return formatDateTime(startDate, HHmmss1);
      }
      case HHmm1: {
        let startDate = sub_minutes(formatTimeEx(start, HHmm1), subMinutes)
        return formatDateTime(startDate, HHmm1);
      }
      case HHmm2: {
        let startDate = sub_minutes(formatTimeEx(start, HHmm2), subMinutes)
        return formatDateTime(startDate, HHmm2);
      }
      default:
        throw `Format ${fmt} doesn't support!`;
    }
  } catch (error) {
    console.error(error)
    return end;
  }
};

const diffMinutes = (start, end, fmt) => {
  let dt = new Date();
  let year = dt.getFullYear();
  let month = dt.getMonth() + 1;
  let date = dt.getDate();

  var startTime;
  var endTime;


  switch (fmt) {
    case HHmmss1:
      startTime = new Date(`${year}/${month}/${date} ${start[0]}${start[1]}:${start[2]}${start[3]}:${start[4]}${start[5]}`);
      endTime = new Date(`${year}/${month}/${date} ${end[0]}${end[1]}:${end[2]}${end[3]}:${end[4]}${end[5]}`);
      break;
    case HHmm1:
      startTime = new Date(`${year}/${month}/${date} ${start[0]}${start[1]}:${start[2]}${start[3]}`);
      endTime = new Date(`${year}/${month}/${date} ${end[0]}${end[1]}:${end[2]}${end[3]}`);
      break;
    case HHmm2:
      startTime = new Date(`${year}/${month}/${date} ${start}:00`);
      endTime = new Date(`${year}/${month}/${date} ${end}:00`);
      break;
    default:
      throw `Format ${fmt} doesn't support!`;
  }

  var difference = endTime.getTime() - startTime.getTime();
  var resultInMinutes = Math.round(difference / 60000);

  return resultInMinutes;
};
