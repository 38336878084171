<template>
  <v-navigation-drawer v-model="show" right width="auto">
    <v-list-item class="px-2 mt-4">
      <v-row>
        <v-col cols="10">
          <LargeCategory
            :selectetedObject="selectedCategory"
            :categories="largeCategories"
            @change="changePrimaryCategory"
          >
          </LargeCategory>
        </v-col>

        <v-col cols="2">
          <v-btn icon @click="close()">
            <v-icon small>$close</v-icon>
          </v-btn></v-col
        >
      </v-row>
    </v-list-item>
    <v-list-item class="px-2 mt-2">
      <v-row>
        <v-col cols="7">
          <v-text-field
            solo
            placeholder="検索"
            maxlength="32"
            @change="handleSearch"
            clearable
            v-model="search"
          >
            <template>
              <v-icon @click="handleSearch" small slot="prepend-inner">
                $search
              </v-icon>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="5">
          <FilterOption
            @reset="resetFilter"
            @submit="submitFilter"
            :items="filters"
          ></FilterOption>
        </v-col>
      </v-row>
    </v-list-item>

    <v-divider></v-divider>

    <div
      class="text-center"
      v-if="
        !(smallCategories && smallCategories.length > 0) &&
        !isEmptySearch(this.search)
      "
    >
      <p class="gray--text">検索条件に一致するタスクは</p>
      <p class="gray--text">見つかりませんでした。</p>
    </div>

    <div class="d-flex justify-center" v-if="isLoading">
      <svg
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        stroke="#000"
        class="sidebar-loader"
      >
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".25" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="0.8s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </div>
    <div class="content overflow-y-auto" v-else>
      <v-treeview
        v-if="smallCategories && smallCategories.length > 0"
        class="custom-tree"
        id="unfavoriteTree"
        ref="unfavoriteTree"
        :items="smallCategories"
        item-key="treeId"
        :open="filteredKeys"
        selected-color="indigo"
        open-on-click
        expand-icon="mdi-chevron-down"
      >
        <template v-slot:prepend="{ item, leaf }">
          <div @click="isFavorite(item)" v-if="leaf">
            <v-icon small v-if="item.is_favorite"> $favoriteactive </v-icon>
            <v-icon small v-else>$favoriteinactive</v-icon>
          </div>
        </template>
        <template v-slot:label="{ item, leaf }">
          <DoubleClick
            v-if="leaf"
            @double-click="isAllowDrag() && registerTask(item)"
            @single-click="view(item)"
            :class="[{ 'db-task': isAllowDrag() }]"
            :name="item.id"
          >
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="8">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-title v-bind="attrs" v-on="on">
                          <div class="d-flex align-center flex-wrap">
                            <div class="text-truncate mr-1">
                              {{ item.name }}
                            </div>
                            <BeginnerIcon v-if="isBeginnerSkill(item)" />
                            <ExpertIcon v-if="isExpertSkill(item)" />
                          </div>
                        </v-list-item-title>
                      </template>
                      <span>{{ item.name }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="4" class="text-right">
                    <span>{{ item.cost }}m/回</span></v-col
                  >
                </v-row>

                <v-row v-if="item.tags">
                  <v-col cols="12" class="text-right">
                    <v-chip
                      v-for="tag in item.tags.slice(0, 3)"
                      :key="tag.tag_id"
                      x-small
                      depressed
                    >
                      {{ getFiveCharacters(tag.tag_name) }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </DoubleClick>

          <div v-else>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{
                  item.category_secondary_name
                }}</span>
              </template>
              <span>{{ item.category_secondary_name }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-treeview>

      <DatabaseTask
        :show="showDatabaseTask"
        :standarId="standarId"
        @isFavorite="setFavorite($event)"
        @updateTag="updateTag($event)"
      ></DatabaseTask>
    </div>
  </v-navigation-drawer>
</template>

<script>
import LargeCategory from "./LargeCategory";
import FilterOption from "./FilterOption";
import DatabaseTask from "./DatabaseTask";
import { deepClone, isEmpty } from "../../../helpers/helpers";
import { apiErrorMessages } from "../../../helpers/messages";
import { GET_DB_TASKS_API, UPDATE_DB_TASKS_API } from "../../../constants/api";
import axios from "axios";
import { mapGetters } from "vuex";
import { FC708, FC709 } from "../../../constants/fc";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import DoubleClick from "../../../components/DoubleClick";
import BeginnerIcon from "@/components/icons/BeginnerIcon.vue";
import ExpertIcon from "@/components/icons/ExpertIcon.vue";
import * as systemAuth from "@/constants/role_code";
import { taskMixin } from "@/mixins/task.js";
export default {
  components: {
    LargeCategory,
    FilterOption,
    DatabaseTask,
    DoubleClick,
    BeginnerIcon,
    ExpertIcon,
  },
  mixins: [taskMixin],
  props: {
    value: Boolean,
  },
  watch: {
    value: {
      handler(val) {
        this.show = val;
      },
    },
    show: {
      async handler(val) {
        this.$emit("input", val);
      },
    },
    iselectedDate: {
      handler(val) {
        this.selectedDate = val;
      },
    },
  },
  methods: {
    registerTask(e) {
      this.$root.$refs.scheduleComponent.addEventFromDbList(e);
    },
    getFiveCharacters(name) {
      return !isEmpty(name) && name.length > 5
        ? name.substring(0, 5) + "..."
        : name;
    },
    updateTag(item) {
      let sc = this.smallCategories.filter(
        (x) => x.category_secondary_id == item.category_secondary_id
      )[0];
      if (sc) {
        let task = sc.children.filter((x) => x.id == item.id)[0];
        if (task) {
          task.tags = item.tags;
        }
      }
      this.reloadFilter();
    },
    reloadFilter() {
      let tags = [];
      this.smallCategories.forEach((x) => {
        x.children.forEach((y) => {
          tags = tags.concat(
            y.tags.map((tag) => {
              return tag.tag_name;
            })
          );
        });
      });
      this.filters = tags
        .filter((v, i, a) => a.indexOf(v) === i)
        .map((name) => {
          return { check: false, tag_name: name };
        });
    },
    setFavorite(item) {
      let smallCategory = this.smallCategories.filter(
        (x) => x.category_secondary_id === item.category_secondary_id
      )[0];
      if (smallCategory) {
        let children = smallCategory.children.map((x) => {
          return {
            ...x,
            ...{
              is_favorite: x.id === item.id ? item.is_favorite : x.is_favorite,
            },
          };
        });
        smallCategory.children = children;
      }

      let smallDBCategory = this.smallDbCategories.filter(
        (x) => x.category_secondary_id === item.category_secondary_id
      )[0];
      if (smallDBCategory) {
        let children = smallDBCategory.children.map((x) => {
          return {
            ...x,
            ...{
              is_favorite: x.id === item.id ? item.is_favorite : x.is_favorite,
            },
          };
        });
        smallDBCategory.children = children;
      }
    },
    async isFavorite(item) {
      try {
        await axios.put(UPDATE_DB_TASKS_API(this.user.user_id, item.id), {
          is_favorite: +!item.is_favorite,
        });
        item.is_favorite = !item.is_favorite;
        let smallCategory = this.smallCategories.filter(
          (x) => x.category_secondary_id === item.category_secondary_id
        )[0];
        if (smallCategory) {
          let children = smallCategory.children.map((x) => {
            return {
              ...x,
              ...{
                is_favorite:
                  x.id === item.id ? item.is_favorite : x.is_favorite,
              },
            };
          });
          smallCategory.children = children;
        }

        let smallDBCategory = this.smallDbCategories.filter(
          (x) => x.category_secondary_id === item.category_secondary_id
        )[0];
        if (smallDBCategory) {
          let children = smallDBCategory.children.map((x) => {
            return {
              ...x,
              ...{
                is_favorite:
                  x.id === item.id ? item.is_favorite : x.is_favorite,
              },
            };
          });
          smallDBCategory.children = children;
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC709, status);
      }
    },
    isBeginnerSkill(item) {
      return item.skill_level_id === systemAuth.SKILL_LEVELS_BEGIN;
    },
    isExpertSkill(item) {
      return item.skill_level_id === systemAuth.SKILL_LEVELS_EXPERT;
    },
    handleSearch() {
      const searchConvert = this.convertSearchKey(this.search);
      let categories =
        this.selectedCategory.category_primary_id === 0
          ? this.smallDbCategories
          : this.smallDbCategories.filter(
              (x) =>
                x.category_primary_id ===
                this.selectedCategory.category_primary_id
            );
      this.smallCategories = categories
        .map((x) => {
          let children = x.children.filter(
            (y) =>
              (y.name.includes(this.search) ||
              (y.tags && y.tags.some((z) => z.tag_name.includes(this.search))) ||
              isEmpty(this.search)) ||
              (this.convertSearchKey(y.name).includes(searchConvert) ||
              (y.tags && y.tags.some((z) => this.convertSearchKey(z.tag_name).includes(searchConvert))))
          );
          return {
            ...x,
            ...{ children: children },
          };
        })
        .filter((x) => x.children && x.children.length > 0);

      if (isEmpty(this.search)) {
        this.filteredKeys = [];
        this.filteredKey = [];
      } else {
        this.filteredKeys = this.smallCategories.map((top) => {
          return top.treeId;
        });
      }
    },
    view(item) {
      this.standarId = item.id;
      this.showDatabaseTask = !this.showDatabaseTask;
    },
    isEmptySearch() {
      return isEmpty(this.search);
    },
    changePrimaryCategory(item) {
      this.selectedCategory = item;
      this.smallCategories =
        item.category_primary_id === 0
          ? this.smallDbCategories
          : this.smallDbCategories.filter(
              (x) => x.category_primary_id === item.category_primary_id
            );

      let tags = [];
      this.smallCategories.forEach((x) => {
        x.children.forEach((y) => {
          tags = tags.concat(
            y.tags.map((tag) => {
              return tag.tag_name;
            })
          );
        });
      });
      this.filters = tags
        .filter((v, i, a) => a.indexOf(v) === i)
        .map((name) => {
          return { check: false, tag_name: name };
        });
    },
    resetFilter() {
      this.changePrimaryCategory(this.selectedCategory);
    },
    submitFilter(filters) {
      let categories =
        this.selectedCategory.category_primary_id === 0
          ? this.smallDbCategories
          : this.smallDbCategories.filter(
              (x) =>
                x.category_primary_id ===
                this.selectedCategory.category_primary_id
            );
      this.smallCategories = categories
        .map((x) => {
          let children = x.children.filter(
            (y) =>
              (y.tags &&
                y.tags.some((z) =>
                  filters.some((g) => g.check && g.tag_name === z.tag_name)
                )) ||
              !filters.some((g) => g.check)
          );
          return {
            ...x,
            ...{ children: children },
          };
        })
        .filter((x) => x.children && x.children.length > 0);

      if (!filters.some((g) => g.check)) {
        this.filteredKeys = [];
        this.filteredKey = [];
      } else {
        this.filteredKeys = this.smallCategories.map((top) => {
          return top.treeId;
        });
      }
    },

    getDatabaseList() {
      return this.dbTasks;
    },

    async getData() {
      //TODO reset search
      this.isLoading = true;
      this.search = "";

      try {
        const { data, status } = await axios.get(
          `${GET_DB_TASKS_API()}` + `?user_id=${this.user.user_id}`
        );
        this.dbTasks = status === STATUS_NO_CONTENT ? [] : data.result;
        let primaryCategories = [
          {
            category_primary_id: 0,
            category_primary_name: "すべてのカテゴリ",
          },
        ];
        let secondaryCategories = [];
        let index = 0;
        this.dbTasks.forEach((dbTask) => {
          if (
            !primaryCategories.some((x) => {
              return x.category_primary_id === dbTask.category_primary_id;
            })
          ) {
            primaryCategories.push({
              category_primary_id: dbTask.category_primary_id,
              category_primary_name: dbTask.category_primary_name,
            });
          }

          dbTask.treeId = index++;

          if (
            !secondaryCategories.some((x) => {
              return x.category_secondary_id === dbTask.category_secondary_id;
            })
          ) {
            secondaryCategories.push({
              category_primary_name: dbTask.category_primary_name,
              category_primary_id: dbTask.category_primary_id,
              category_secondary_name: dbTask.category_secondary_name,
              category_secondary_id: dbTask.category_secondary_id,
              treeId: index++,
              children: [dbTask],
            });
          } else {
            secondaryCategories
              .filter((x) => {
                return x.category_secondary_id === dbTask.category_secondary_id;
              })[0]
              .children.push(dbTask);
          }
        });
        this.smallCategories = secondaryCategories;
        this.smallDbCategories = deepClone(secondaryCategories);
        this.largeCategories = primaryCategories;
        this.selectedCategory = primaryCategories[0];
        this.changePrimaryCategory(this.selectedCategory);
        if (this.$refs.unfavoriteTree)
          this.$refs.unfavoriteTree.updateAll(false);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC708, status);
      } finally {
        this.isLoading = false;
      }
    },
    isAllowDrag() {
      return (
        this.$root.$refs.scheduleComponent.isSelf() &&
        this.$root.$refs.scheduleComponent.isNoSubmmit() &&
        this.$root.$refs.scheduleComponent.isCanSubmit()
      );
    },
    removeTag(tag) {
      try {
        this.smallCategories = this.smallCategories.map((x) => {
          let children = x.children.map((y) => {
            return {
              ...y,
              ...{ tags: y.tags.filter((z) => +z.tag_id !== +tag.id) },
            };
          });
          return {
            ...x,
            ...{
              children: children,
            },
          };
        });

        this.reloadFilter();
        this.smallDbCategories = this.smallDbCategories.map((x) => {
          let children = x.children.map((y) => {
            return {
              ...y,
              ...{ tags: y.tags.filter((z) => +z.tag_id !== +tag.id) },
            };
          });
          return {
            ...x,
            ...{
              children: children,
            },
          };
        });
      } catch {
        return;
      }
    },

    editTag(tag) {
      const addTag = function (y) {
        if (y.tags.some((z) => +z.tag_id === +tag.id)) {
          y.tags = y.tags.map((x) => {
            if (+tag.id === +x.tag_id) {
              tag.tag_name = tag.name;
              tag.tag_id = tag.id;
              return tag;
            } else {
              return x;
            }
          });
        }
        return y.tags;
      };

      try {
        this.smallCategories = this.smallCategories.map((x) => {
          let children = x.children.map((y) => {
            return {
              ...y,
              tags: addTag(y),
            };
          });
          return {
            ...x,
            ...{
              children: children,
            },
          };
        });

        this.reloadFilter();
        this.smallDbCategories = this.smallDbCategories.map((x) => {
          let children = x.children.map((y) => {
            return {
              ...y,
              tags: addTag(y),
            };
          });
          return {
            ...x,
            ...{
              children: children,
            },
          };
        });
      } catch {
        return;
      }
    },

    close() {
      this.show = false;
      this.$emit("close");
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      standarId: null,
      search: "",
      show: false,
      showDatabaseTask: false,
      largeCategories: [],
      filters: [],
      smallCategories: [],
      smallDbCategories: [],
      favoriteCategory: {
        category_secondary_name: "お気に入り",
        category_secondary_id: null,
        children: [],
      },
      selectedCategory: {},
      dbTasks: [],
      isLoading: false,
      favoriteChildren: [],
      filteredKeys: [],
      filteredKey: [],
    };
  },
  created() {
    this.$root.$refs.scheduleDbList = this;
  },
};
</script>
<style lang="scss" scoped>
.content {
  height: calc((var(--vh, 1vh) * 100) - 50px - 60px - 48px - 66px - 24px);
  overflow-x: hidden;
  ::v-deep.custom-tree {
    .v-treeview-node {
      position: relative;
      .v-treeview-node__children {
        .v-treeview-node__content {
          margin-left: -8%;
          padding-top: 5px !important;
          padding-bottom: 12px !important;
          border-bottom: solid 1px #e1e1e1;
          .v-treeview-node__label > div {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    .v-treeview-node__prepend {
      min-height: 24px;
    }
    .v-treeview-node__label {
      line-height: 24px;
    }
    > .v-treeview-node {
      > .v-treeview-node__root {
        border-bottom: solid 1px #e1e1e1;
        .v-treeview-node__content {
          border-bottom: unset;
          .v-treeview-node__label {
            margin-left: 4px;
          }
        }
      }
    }
  }
}

.sidebar-loader {
  margin-top: 10px;
}
</style>
