/**
 * Get message on errors
 * @param {*} e Vue constant
 * @param {*} screen screen code
 * @param {*} code http status code
 */
export const apiErrorMessages = (e, screen, code) => {
  let msg = messageErrors()[screen][`c${code}`];

  switch (msg) {
    case MSG_COMMON_TYPE_1:
      e.$alert('接続に失敗しました。再度試行してください。');
      break;
    case MSG_COMMON_TYPE_2:
      e.$toast('接続に失敗しました。再度試行してください。', 3000);
      break;
    case MSG_COMMON_TYPE_3:
      e.$alert('更新に失敗しました。再度試行してください。');
      break;
    case MSG_COMMON_TYPE_4:
      e.$alert('削除に失敗しました。再度試行してください。');
      break;
    case MSG_COMMON_TYPE_5:
      e.$toast('取得に失敗しました。再度試行してください。', 3000);
      break;
    case DIRECT_TO_LOGIN:
      e.$router.push({
        name: 'login'
      })
      break;
    case DIRECT_TO_UNAUTHORIZED:
      e.$router.push({
        name: 'unauthorized'
      })
      break;
    case DIRECT_TO_EXPIRED:
      e.$router.push({
        name: 'expired'
      })
      break;
    default:
      return msg;
  }

  return false;
};

const MSG_COMMON_TYPE_1 = 'MSG_COMMON_TYPE_1';
const MSG_COMMON_TYPE_2 = 'MSG_COMMON_TYPE_2';
const MSG_COMMON_TYPE_3 = 'MSG_COMMON_TYPE_3';
const MSG_COMMON_TYPE_4 = 'MSG_COMMON_TYPE_4';
const MSG_COMMON_TYPE_5 = 'MSG_COMMON_TYPE_5';

const DIRECT_TO_LOGIN = 'DIRECT_TO_LOGIN';
const DIRECT_TO_UNAUTHORIZED = 'DIRECT_TO_UNAUTHORIZED';
const DIRECT_TO_EXPIRED = 'DIRECT_TO_EXPIRED';

const messageErrors = () => {
  return {
    fc01: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: 'メールアドレスまたはパスワードが正しくありません。入力し直してください。',
      c402: '',
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc02: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: '',
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc03: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: 'ご指定のメールアドレスからはアカウントが検出されませんでした。',
      c402: '',
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc04: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: DIRECT_TO_EXPIRED,
      c402: '',
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc05: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: 'パスワードが正しくありません。入力し直してください。「パスワードをお忘れの場合」をクリックすると、再設定できます。',
      c402: '',
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc06: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: '大文字、小文字の英数字と記号を含めて8文字以上にしてください。',
      c402: '',
      c403: '',
      c404: DIRECT_TO_EXPIRED,
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc101: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc102: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c401_a: '大文字、小文字の英数字と記号を含めて8文字以上にしてください。', // UpdatePassword
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc103: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_4,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_4,
      c404: '', //TODO: ※APIのレスポンスボディ内メッセージを表示
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc104: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: '',
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc105: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc106: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: '',
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc107: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc108: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: '',
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc109: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    // TODO: Wating check API
    fc111: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc301: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc302: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc303: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc304: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_4,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc305: {
      c200: '',
      c201: '',
      c204: '',
      c400: MSG_COMMON_TYPE_1,
      c401: '', //TODO: ※表示内容については、IFから返却されるエラーメッセージ次第で変化
      c402: MSG_COMMON_TYPE_1,
      c403: MSG_COMMON_TYPE_1,
      c404: MSG_COMMON_TYPE_1,
      c405: MSG_COMMON_TYPE_1,
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc306: {
      c200: '',
      c201: '',
      c204: '',
      c400: MSG_COMMON_TYPE_1,
      c401: MSG_COMMON_TYPE_1,
      c402: MSG_COMMON_TYPE_1,
      c403: MSG_COMMON_TYPE_1,
      c404: MSG_COMMON_TYPE_1,
      c405: MSG_COMMON_TYPE_1,
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc501: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: '',
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc502: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc503: {
      c200: '',
      c201: '',
      c204: '',
      c400: MSG_COMMON_TYPE_2,
      c401: MSG_COMMON_TYPE_2,
      c402: MSG_COMMON_TYPE_2,
      c403: MSG_COMMON_TYPE_2,
      c404: MSG_COMMON_TYPE_2,
      c405: MSG_COMMON_TYPE_2,
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc504: {
      c200: '',
      c201: '',
      c204: '',
      c400: MSG_COMMON_TYPE_1,
      c401: MSG_COMMON_TYPE_1,
      c402: MSG_COMMON_TYPE_1,
      c403: MSG_COMMON_TYPE_1,
      c404: MSG_COMMON_TYPE_1,
      c405: MSG_COMMON_TYPE_1,
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc601: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: '',
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc701: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc702: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc703: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc704: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_4,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_4,
      c404: '', //TODO: ※APIのレスポンスボディ内メッセージを表示
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc705: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc706: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc707: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_4,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_4,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc708: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc709: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc710: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: '',
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc711: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_5,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc715: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: '',
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc716: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_5,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc720: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: '',
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: MSG_COMMON_TYPE_4,
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc721: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_5,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc722: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: '',
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc801: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: DIRECT_TO_UNAUTHORIZED,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc802: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc803: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_4,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_4,
      c404: MSG_COMMON_TYPE_4,
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc804: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: DIRECT_TO_UNAUTHORIZED,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc805: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc806: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_4,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_4,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc807: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc808: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc809: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_4,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc810: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc811: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: '', //※テンプレートが保存上限に達しているときに当ステータスコードが返却されるよう設計されているが、保存数が最大になった場合はフロント側でブロックをしAPIをコールしないつくりとする
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc812: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_4,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc813: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc814: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc815: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_4,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc816: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: DIRECT_TO_UNAUTHORIZED,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc817: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3, // ※日次予定提出条件がエラーの場合にも当ステータスコードが返却される設計だが、提出条件についてはフロント側で動的にチェックをしエラーの場合はボタン非活性となる
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: MSG_COMMON_TYPE_3,
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc818: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: DIRECT_TO_UNAUTHORIZED,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc819: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3, //※日次実績提出条件がエラーの場合にも当ステータスコードが返却されるが、提出条件についてはフロント側で動的にチェックをしエラーの場合はボタン非活性となる
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: MSG_COMMON_TYPE_3,
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc820: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_4,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_4,
      c404: MSG_COMMON_TYPE_4,
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc827: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: MSG_COMMON_TYPE_3,
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc828: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: MSG_COMMON_TYPE_3,
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc901: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc1001: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: MSG_COMMON_TYPE_5,
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc1002: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc1003: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_5,
      c404: MSG_COMMON_TYPE_5,
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc1004: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc1005: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_4,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_4,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc1201: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_5,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc1202: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: DIRECT_TO_UNAUTHORIZED,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc110: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc821: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc822: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc718: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: '',
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc823: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc824: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_3,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc825: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_4,
      c402: DIRECT_TO_LOGIN,
      c403: MSG_COMMON_TYPE_3,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_1,
      c500: MSG_COMMON_TYPE_1
    },
    fc829: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: DIRECT_TO_UNAUTHORIZED,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc830: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: DIRECT_TO_UNAUTHORIZED,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc904: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: DIRECT_TO_UNAUTHORIZED,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
    fc905: {
      c200: '',
      c201: '',
      c204: '',
      c400: '',
      c401: MSG_COMMON_TYPE_5,
      c402: DIRECT_TO_LOGIN,
      c403: DIRECT_TO_UNAUTHORIZED,
      c404: '',
      c405: '',
      c408: MSG_COMMON_TYPE_2,
      c500: MSG_COMMON_TYPE_2
    },
  };
};
