export const FC01 = 'fc01';
export const FC02 = 'fc02';
export const FC03 = 'fc03';
export const FC04 = 'fc04';
export const FC05 = 'fc05';
export const FC06 = 'fc06';

export const FC101 = 'fc101';
export const FC102 = 'fc102';
export const FC103 = 'fc103';
export const FC104 = 'fc104';
export const FC105 = 'fc105';
export const FC106 = 'fc106';
export const FC107 = 'fc107';
export const FC108 = 'fc108';
export const FC109 = 'fc109';
export const FC111 = 'fc111';

export const FC301 = 'fc301';
export const FC302 = 'fc302';
export const FC303 = 'fc303';
export const FC304 = 'fc304';
export const FC305 = 'fc305';
export const FC306 = 'fc306';

export const FC501 = 'fc501';
export const FC502 = 'fc502';
export const FC503 = 'fc503';
export const FC504 = 'fc504';

export const FC601 = 'fc601';

export const FC701 = 'fc701';
export const FC702 = 'fc702';
export const FC703 = 'fc703';
export const FC704 = 'fc704';
export const FC705 = 'fc705';
export const FC706 = 'fc706';
export const FC707 = 'fc707';
export const FC708 = 'fc708';
export const FC709 = 'fc709';
export const FC710 = 'fc710';
export const FC711 = 'fc711';
export const FC712 = 'fc712';
export const FC713 = 'fc713';
export const FC714 = 'fc714';
export const FC715 = 'fc715';
export const FC716 = 'fc716';
export const FC720 = 'fc720';
export const FC721 = 'fc721';
export const FC722 = 'fc722';

export const FC801 = 'fc801';
export const FC802 = 'fc802';
export const FC803 = 'fc803';
export const FC804 = 'fc804';
export const FC805 = 'fc805';
export const FC806 = 'fc806';
export const FC807 = 'fc807';
export const FC808 = 'fc808';
export const FC809 = 'fc809';
export const FC810 = 'fc810';
export const FC811 = 'fc811';
export const FC812 = 'fc812';
export const FC813 = 'fc813';
export const FC814 = 'fc814';
export const FC815 = 'fc815';
export const FC816 = 'fc816';
export const FC817 = 'fc817';
export const FC818 = 'fc818';
export const FC819 = 'fc819';
export const FC820 = 'fc820';
export const FC831 = 'fc831';
export const FC832 = 'fc832';
export const FC833 = 'fc833';

export const FC901 = 'fc901';
export const FC904 = 'fc904';
export const FC905 = 'fc905';

export const FC1001 = 'fc1001';
export const FC1002 = 'fc1002';
export const FC1003 = 'fc1003';
export const FC1004 = 'fc1004';
export const FC1005 = 'fc1005';

export const FC1201 = 'fc1201';
export const FC1202 = 'fc1202';

export const FC110 = 'fc110';
export const FC821 = 'fc821';
export const FC822 = 'fc822';
export const FC718 = 'fc718';
export const FC823 = 'fc823';
export const FC824 = 'fc824';
export const FC825 = 'fc825';
export const FC827 = 'fc827';
export const FC828 = 'fc828';
export const FC829 = 'fc829';
export const FC830 = 'fc830';
