<template>
  <v-menu v-model="openMenu" open-on-click bottom offset-y>
    <template v-slot:activator="{ on }">
      <v-combobox
        :items="items"
        v-model="dgName"
        :error-messages="dgNameErrors"
        v-on="on"
        @click:append="openMenu = true"
        readonly
        @click:clear="clearDepartment()"
        clearable
      >
      </v-combobox>
    </template>
    <v-list class="overflow-y-auto" max-height="50vh">
      <v-list-item v-for="item in items" :key="item.id">
        <v-menu
          open-on-click
          close-on-content-click
          offset-x
          v-if="item.group && item.group.length > 0"
        >
          <template v-slot:activator="{ on }">
            <div class="d-flex v-list-item" v-on="on" @click="toggle(item.id)">
              <v-list-item-content>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-bind="attrs" v-on="on">
                      {{ item.name }}
                    </v-list-item-title>
                  </template>
                  <span> {{ item.name }}</span>
                </v-tooltip>
              </v-list-item-content>
              <v-list-item-action v-if="item.group" @click.stop>
                <v-btn v-on="on" @click="toggle(item.id)" icon>
                  <v-icon>mdi-menu-right</v-icon>
                </v-btn>
              </v-list-item-action>
            </div>
          </template>
          <group-item
            class=""
            v-if="item.group && open && item.id === departmentSelected.id"
            :items="item.group"
            :parent="departmentSelected"
            @groupChanged="groupChanged($event)"
          ></group-item>
        </v-menu>
        <div class="d-flex v-list-item" v-else>
          <v-list-item-content>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title v-bind="attrs" v-on="on">
                  {{ item.name }}
                </v-list-item-title>
              </template>
              <span> {{ item.name }}</span>
            </v-tooltip>
          </v-list-item-content>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import GroupItem from "./GroupItem.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  components: { GroupItem },
  name: "Department",
  mixins: [validationMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    inputdgName: String,
  },
  data() {
    return {
      groups: [],
      open: false,
      openMenu: false,
      departmentSelected: {},
      dirty: false,
      groupSelected: {},
      dgName: this.inputdgName,
    };
  },
  validations: {
    dgName: { required },
  },
  watch: {
    inputdgName: {
      handler(val) {
        this.dgName = val;
      },
    },
  },
  computed: {
    dgNameErrors() {
      const errors = [];
      if (this.dirty) {
        !this.$v.dgName.required &&
          errors.push(this.$t("label.error.required"));
      }
      return errors;
    },
  },
  methods: {
    toggle(departmentId) {
      this.open =
        departmentId !== this.departmentSelected.id ? true : !this.open;
      this.departmentSelected = this.items.find((x) => x.id === departmentId);
    },

    groupChanged(value) {
      this.open = false;
      this.departmentSelected = value.department;
      this.groupSelected = value.group;
      this.dgName = ` ${this.departmentSelected.name} ＞ ${this.groupSelected.name}`;
      this.openMenu = false;
      this.$emit("selectedDepartment", value);
    },

    clearDepartment() {
      this.$emit("clearDepartment");
    },

    touch() {
      this.dirty = true;
      this.$v.$touch();
      return this.$v.dgName.$invalid;
    },

    reset() {
      this.dirty = false;
      this.$v.$reset();
    },
  },
};
</script>
<style lang="scss" scoped>
.department {
  position: relative;
}
.group {
  width: 200px;
}
</style>
