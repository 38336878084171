/*! Helpers.js
 * =================================================
 * JavaScript Helpers
 * =================================================
 */

/**
 * Creates a deep clone of an object.
 *
 * Example:
 *  const a = { foo: 'bar', obj: { a: 1, b: 2 } };
 *  const b = deepClone(a); // a !== b, a.obj !== b.obj
 *
 * @param {*} obj
 * @returns
 */
export const deepClone = obj => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    key => (clone[key] = typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  );
  return Array.isArray(obj) && obj.length ?
    (clone.length = obj.length) && Array.from(clone) :
    Array.isArray(obj) ?
      Array.from(obj) :
      clone;
};


/**
 * Returns the first key that satisfies the provided testing function. Otherwise undefined is returned.
 *
 * @param {*} obj
 * @param {*} fn
 */
export const findKey = (obj, fn) => Object.keys(obj).find(key => fn(obj[key], key, obj));

/**
 * Returns the last element for which the provided function returns a truthy value.
 *
 * @param {*} arr
 * @param {*} fn
 */
export const findLast = (arr, fn) => arr.filter(fn).pop();

export const convertStringToArray = (str, separate = ',', parseFn = (i) => i.trim()) => {
  var words = str.split(separate);
  if (Array.isArray(words)) {
    if (parseFn) {
      return words.map(i => parseFn(i))
    }
  } else {
    return []
  }
};


/**
 * Truncates a string up to a specified length.
 * Determine if the string's length is greater than num.
 * Return the string truncated to the desired length, with '...' appended to the end or the original string.
 * @param {*} str
 * @param {*} num
 */
export const truncateString = (str, num) => !isEmpty(str) && str.length > num ? str.slice(0, num > 3 ? num - 3 : num) + '...' : str;

/**
 * Returns true if the a value is an empty object,
 * collection, has no enumerable properties or is any type that is not considered a collection.
 * Check if the provided value is null or if its length is equal to 0.
 * EX:
 * isEmpty([]); // true
 * isEmpty({}); // true
 * isEmpty(''); // true
 * isEmpty([1, 2]); // false
 * isEmpty({ a: 1, b: 2 }); // false
 * isEmpty('text'); // false
 * isEmpty(123); // true - type is not considered a collection
 * isEmpty(true); // true - type is not considered a collection
 *
 * @param {*} val
 */
export const isEmpty = val => val === undefined || val === null || !(Object.keys(val) || val).length;

export const isEmptyNumber = val => val === undefined || val === null;


/**
 * Converts a string to camelcase.
 *
 * Break the string into words and combine them capitalizing the first letter of each word, using a regexp.
 * @param {*} str
 * @returns
 */
export const toCamelCase = str => {
  let s =
    str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
      .join('');
  return s.slice(0, 1).toLowerCase() + s.slice(1);
};

/**
 * Converts a string to kebab case.
 *
 * Break the string into words and combine them adding - as a separator, using a regexp.
 *
 * @param {*} str
 */
export const toKebabCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-');

/**
 * Converts a string to snake case.
 *
 * Break the string into words and combine them adding _ as a separator, using a regexp.
 *
 * @param {*} str
 */
export const toSnakeCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('_');

/**
 * Converts a string to title case.
 *
 * Break the string into words, using a regexp, and combine them capitalizing the first letter of each word and adding a whitespace between them.
 *
 * @param {*} str
 */
export const toTitleCase = str =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ');



/**
 * Copy a string to the clipboard. Only works as a result of user action (i.e. inside a click event listener).
 *
 * Create a new <textarea> element, fill it with the supplied data and add it to the HTML document.
 * Use Selection.getRangeAt()to store the selected range (if any).
 * Use Document.execCommand('copy') to copy to the clipboard.
 * Remove the <textarea> element from the HTML document.
 * Finally, use Selection().addRange() to recover the original selected range (if any).
 *
 * ⚠️ NOTICE: The same functionality can be easily implemented by using the new asynchronous Clipboard API,
 * which is still experimental but should be used in the future instead of this snippet.
 *
 * @param {*} str
 */
export const copyToClipboard = (str, elId = null) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  let parentEl = null;
  if (elId) {
    parentEl = document.getElementById(elId);
    parentEl.appendChild(el);
  } else {
    document.body.appendChild(el);
  }

  const selected =
    document.getSelection().rangeCount > 0 ?
      document.getSelection().getRangeAt(0) :
      false;
  el.select();
  document.execCommand('copy');

  if (elId && parentEl) {
    parentEl.removeChild(el);
  } else {
    document.body.removeChild(el);
  }

  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};
export const isSafari = () => {
  var ua = window.navigator.userAgent;
  var iOS = !!ua.match(/iP(ad|od|hone)/i);
  var hasSafariInUa = !!ua.match(/Safari/i);
  var noOtherBrowsersInUa = !ua.match(
    /Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox/i
  );
  var result = false;
  if (iOS) {
    //detecting Safari in IOS mobile browsers
    var webkit = !!ua.match(/WebKit/i);
    result = webkit && hasSafariInUa && noOtherBrowsersInUa;
  } else if (window.safari !== undefined) {
    //detecting Safari in Desktop Browsers
    result = true;
  } else {
    // detecting Safari in other platforms
    result = hasSafariInUa && noOtherBrowsersInUa;
  }
  return result;
}
export const saveFile = (data, headers, fileName) => {
  var contentType = headers['content-type'];
  const blob = new Blob([data], {
    type: contentType
  });
  //window.URL = window.URL || window.webkitURL
  const url = window.URL.createObjectURL(blob);

  if (contentType.startsWith('image/') || contentType.startsWith('text/')) {
    let tab = window.open();
    tab.location.href = url;

  } else {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = fileName;
    a.target = "_blank";
    a.click();
    a.remove();
  }
  window.URL.revokeObjectURL(url);

};

export const exportShiftJIS = (data, fileName) => {
  let blob = new Blob([data], {
    type: 'text/csv;charset=Shift_JIS'
  });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    let link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('charset', 'Shift_JIS');
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        (window.URL || window.webkitURL).revokeObjectURL(link.href);
      }, 100);
      document.body.removeChild(link);
    }
  }
}

export const isOverLap = (events) => {
  for (let index = 0; index < events.length; index++) {
    if (isEventOverLap(events, events[index])) {
      return true;
    }
  }
  return false;
}

export const isEventOverLap = (events, event) => {
  for (let index = 0; index < events.length; index++) {
    if (event.id === events[index].id) {
      continue;
    }

    if (!(+events[index].start_time >= +event.end_time || +events[index].end_time <= +event.start_time)) {
      return true;
    }

  }
  return false;
}
const jregex = /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/gi;
export const isJapanese = (value) => {
  return jregex.test(value);
}

export const jReplace = (value) => {
  if (isEmpty(value)) return;
  else
    return value.replace(jregex, '');
}
/**
 * Check the japanese is fullsize character
 *
 * @param character
 */
export const isFullSize = (character) => {
  // const HalfWidth = 'Half Width'
  // const FullWidth = 'Full Width'
  // REFERENCE UNICODE TABLES:
  // http://www.rikai.com/library/kanjitables/kanji_codes.unicode.shtml
  // http://www.tamasoft.co.jp/en/general-info/unicode.html
  //
  // TEST EDITOR:
  // http://www.gethifi.com/tools/regex
  //
  // UNICODE RANGE : DESCRIPTION
  //
  // 3000-303F : punctuation
  // 3040-309F : hiragana
  // 30A0-30FF : katakana
  // FF00-FFEF : Full-width roman + half-width katakana
  // 4E00-9FAF : Common and uncommon kanji
  //
  // Non-Japanese punctuation/formatting characters commonly used in Japanese text
  // 2605-2606 : Stars
  // 2190-2195 : Arrows
  // u203B     : Weird asterisk thing
  const regexTestIsJapanese = /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g
  const regexMatchFullWidth = /(^[０-９]+$)|(^[ー]?[０-９]+(．[０-９]+)?$)|[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\u4E00-\u9FAF]/g

  return character.match(regexMatchFullWidth) && character.match(regexTestIsJapanese);
}

export const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

/**
 * Sorts an array of objects, ordered by properties and orders.
 *
 * Uses Array.prototype.sort(), Array.prototype.reduce() on the props array with a default value of 0.
 * Use array destructuring to swap the properties position depending on the order supplied.
 * If no orders array is supplied, sort by 'asc' by default.
 *
 * @param {*} arr
 * @param {*} props
 * @param {*} orders
 * @returns
 */
export const orderBy = (arr, props, orders) => [...arr].sort((a, b) =>
  props.reduce((acc, prop, i) => {
    if (acc === 0) {
      const [p1, p2] =
        orders && orders[i] === 'desc' ? [b[prop], a[prop]] : [a[prop], b[prop]];
      acc = p1 > p2 ? 1 : p1 < p2 ? -1 : 0;
    }
    return acc;
  }, 0)
);

/**
 * To group an array by a specific key
 *
 * @param {*} array
 * @param {*} key
 * @returns
 */
export const groupBy = (array, key) => {
  // Return the reduced array
  return array.reduce((result, currentItem) => {
    // If an array already present for key, push it to the array. Otherwise create an array and push the object.
    (result[currentItem[key]] = result[currentItem[key]] || []).push(currentItem);
    // return the current iteration `result` value, this will be the next iteration's `result` value and accumulate
    return result;
  }, {}); // Empty object is the initial value for result object
};

export const getFileExtension = (filename) => {
  const ext = (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : undefined;
  return isEmpty(ext) ? '' : ext[0];
}


export const debounce_leading = (func, timeout = 1500) => {

  let isProcessing = false;

  return function () {
    const context = this;
    const args = arguments;
    if (!isProcessing) {
      isProcessing = true;
      func.apply(context, args);

      setTimeout(() => {
        isProcessing = false;
      }, timeout);
    }
  }
}

// 半角→全角(カタカナ)
export const replaceKanaHalfToFull = (value) => {
  var kanaMap = {
    'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
    'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
    'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
    'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
    'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
    'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
    'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
    'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
    'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
    'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
    'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
    'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
    'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
    'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
    'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
    'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
    'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
    'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
    '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
  };
  let reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
  return value.replace(reg, function(s){
    return kanaMap[s];
  }).replace(/ﾞ/g, '゛').replace(/ﾟ/g, '゜');
}

// カタカナ→ひらがな
export const replaceKanaToHira = (value) => {
  return value.replace(/[\u30a1-\u30f6]/g, function(s){
    return String.fromCharCode(s.charCodeAt(0) - 0x60);
  });
}

// 半角→全角(英数字)
export const replaceAlphabetToFull = (value) => {
  return value.toUpperCase().replace(/[A-Za-z0-9]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
  });
}
