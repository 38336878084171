<template>
  <v-dialog
    v-model="openFreeTask"
    persistent
    max-width="600px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
    content-class="aafreeTask"
    v-dialog-drag="{
      dialog: 'aafreeTask',
    }"
    overlay-color="transparent"
    overlay-opacity="1"
  >
    <v-card :id="parentId">
      <v-card-title class="card-header py-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="copyLink()"
              v-bind="attrs"
              v-on="on"
              class="ml-auto"
              icon
              color="gray"
            >
              <v-icon small>$link</v-icon>
            </v-btn>
          </template>
          <span>タスクのリンクをコピー</span>
        </v-tooltip>

        <v-tooltip bottom v-if="!isSupervisor() && canSplitCheck()">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="!isSupervisor() && archiveTask()"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$archive</v-icon>
            </v-btn>
          </template>
          <span>タスクをアーカイブ</span>
        </v-tooltip>

        <v-tooltip bottom v-if="!isSupervisor() && canSplitCheck()">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="!isSupervisor() && duplicateTask()"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$copy</v-icon>
            </v-btn>
          </template>
          <span>タスクを複製</span>
        </v-tooltip>

        <v-tooltip bottom v-if="!isSupervisor() && canSplitCheck()">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="!isSupervisor() && deleteTask()"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$delete</v-icon>
            </v-btn>
          </template>
          <span>タスクを削除</span>
        </v-tooltip>
        <v-btn @click="close()" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row class="py-0 my-0" v-if="isSpotTask && !isSupervisor()">
          <v-col cols="12" class="py-0 d-flex align-center">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <div @click="setFavorite(task)">
              <v-icon v-if="task.is_favorite" small>$favoriteactive</v-icon>
              <v-icon v-else small>$favoriteinactive</v-icon>
            </div>
            <v-text-field
              class="ml-2"
              maxlength="60"
              v-model="task.name"
              :error-messages="nameErrors"
              placeholder="タスク名を追加"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0 my-0">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="py-0 d-flex align-center">
            <v-icon small>$taskexplanation</v-icon>
            <v-textarea
              rows="1"
              class="ml-2"
              auto-grow
              maxlength="512"
              v-model="task.description"
              placeholder="説明を追加"
            ></v-textarea>
          </v-col>
          <v-col cols="12" class="py-0">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskcategory</v-icon>
            <dropdown-button
              :id="'id'"
              :display="'name'"
              :name="primaryName || '大カテゴリ名 '"
              :items="parentCategories"
              :type="'text'"
              :has-total="false"
              :showMenu="!isSupervisor()"
              @dropdownSelected="primaryCategoryChange($event)"
            ></dropdown-button>
            <div class="mt-2 ml-5 category">
              <dropdown-button
                :id="'id'"
                :display="'name'"
                :name="secondaryName || '小カテゴリ名 '"
                :items="childCategories"
                :type="'text'"
                :has-total="false"
                :showMenu="!isSupervisor()"
                @dropdownSelected="secondaryCategoryChange($event)"
                v-if="showSecondCategory"
              ></dropdown-button>
              <div
                v-if="
                  !this.$v.task.category_secondary_id.required &&
                  this.$v.task.category_secondary_id.$dirty
                "
                class="v-text-field__details"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ $t("label.error.required") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-else class="py-0">
          <v-col cols="12" class="pb-2 pt-0 mt-7 d-flex align-center">
            <div @click="setFavorite(task)">
              <v-icon v-if="task.is_favorite" small>$favoriteactive</v-icon>
              <v-icon v-else small>$favoriteinactive</v-icon>
            </div>
            <span class="ml-2 work-break">{{ task.name }}</span>
          </v-col>
          <v-col cols="12" class="py-0 my-0">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="py-6 d-flex align-center">
            <v-icon small>$taskexplanation</v-icon>
            <span class="ml-2 work-break">{{ task.description }}</span>
          </v-col>

          <v-col cols="12" class="py-2">
            <div class="header">
              <v-icon small>$taskcategory</v-icon>
              <span class="ml-2">{{ primaryName }}</span>
            </div>
            <div v-if="showSecondCategory" class="mt-2 ml-5 category">
              <span class="ml-2">{{ task.category_secondary_name }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="my-0" v-if="!isSupervisor()">
          <v-col class="py-0">
            <div class="d-flex align-center">
              <span style="margin-left: -16px" class="red--text mr-2">*</span>
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2">日次実績</span>
              <v-text-field
                class="w-130 ml-10"
                type="number"
                max="999"
                min="1"
                :error-messages="costErrors"
                :suffix="displayAsSplitTask ? 'm' : 'm/回'"
                :readonly="isSupervisor()"
                @keydown="checkMaxLength('cost', $event)"
                @input.native="updateCost"
                @input="changeCost()"
                v-model="task.cost"
              >
              </v-text-field>
              <span v-if="!displayAsSplitTask" class="mx-2 mt-5">×</span>
              <v-text-field v-if="!displayAsSplitTask"
                class="w-60 align-self-start"
                :error-messages="countErrors"
                type="number"
                suffix="回"
                max="999"
                min="1"
                :readonly="isSupervisor()"
                @input.native="updateCount"
                @keydown="checkMaxLength('count', $event)"
                @input="changeCount()"
                v-model="task.count"
              >
              </v-text-field>
              <span v-if="!displayAsSplitTask" class="mx-2 mt-5">=</span>
              <v-text-field v-if="!displayAsSplitTask"
                readonly
                type="number"
                class="w-95 align-self-start"
                :value="task.cost * task.count"
                suffix="m"
              ></v-text-field>
            </div>
            <div  v-if="displayAsSplitTask" class="d-flex">
              <span class="ml-7">累計実績</span>
              <span class="ml-1">{{ task.total_actual_cost || 0 }} m</span>
              <span class="ml-1 mr-1">/</span>
              <span class="">{{ task.total_cost || originalTask.cost }} m</span>
            </div>
          </v-col>
        </v-row>

        <v-row v-else class="py-2">
          <v-col class="py-0 d-flex align-center">
            <v-icon small>$taskworktime</v-icon>
            <span class="ml-2 w-120">日次実績</span>
            <div class="ml-2 d-flex align-center">
              <span>{{ task.cost }}{{displayAsSplitTask ? 'm' : 'm/回'}}</span>
              <span v-if="!displayAsSplitTask" class="mx-2">×</span>
              <span v-if="!displayAsSplitTask">{{ task.count }}回</span>
              <span v-if="!displayAsSplitTask" class="mx-2">=</span>
              <span v-if="!displayAsSplitTask">{{ task.cost * task.count }}m</span>
            </div>
            <div v-if="displayAsSplitTask" class="d-flex">
              <span class="ml-7">累計実績</span>
              <span class="ml-1">{{ task.total_actual_cost || 0 }} m</span>
              <span class="ml-1 mr-1">/</span>
              <span class="">{{ task.total_cost || originalTask.cost }} m</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="py-0" v-if="!isSupervisor()">
          <v-col class="py-0 d-flex align-center">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskstartendtime</v-icon>
            <div class="text-number ml-2 d-flex align-center">
              <v-text-field
                maxlength="5"
                :error-messages="startTimeErrors"
                v-model="task.start_time"
                :readonly="isSupervisor()"
                @input="!isSupervisor() && changeStartTime()"
                @blur="formatStartTime($event)"
                @keyup.enter.native="formatStartTime($event)"
                type="text"
              ></v-text-field>
              <span class="mx-2">~</span>
              <v-text-field
                class="align-self-start"
                maxlength="5"
                :error-messages="endTimeErrors"
                v-model="task.end_time"
                readonly
                type="text"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row v-else class="py-2">
          <v-col class="py-0 d-flex align-center">
            <v-icon small>$taskstartendtime</v-icon>
            <div class="text-number ml-2 d-flex align-center">
              <span>{{ task.start_time }}</span>
              <span class="mx-2">~</span>
              <span>{{ task.end_time }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0">
          <v-col class="d-flex align-center py-0">
            <v-radio-group
              :disabled="+task.count !== 1 || !canSplitCheck()"
              v-model="task.is_split_task_completed"
            >
              <v-radio
                v-bind:value="0"
                :label="'タスクを一部着手して後日継続予定'"
              >
              </v-radio>
              <span class="ml-8">
                ※チェックを付けて保存した場合、「未完了タスク」に登録されます
              </span>
              <br />
              <v-radio v-bind:value="1" :label="'タスクが全て完了'"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row class="py-2">
          <v-col class="d-flex align-center">
            <v-icon small class="mr-2">$tag</v-icon>
            <span v-if="!hasTag">無し</span>
            <v-container v-else justify="space-around">
              <v-sheet class="px-1">
                <v-chip-group mandatory>
                  <v-tooltip bottom v-for="tag in task.tags" :key="tag.id">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on">
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 100px"
                        >
                          {{ tag.name }}
                        </span>
                      </v-chip>
                    </template>
                    <span>{{ tag.name }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0">
          <v-col class="py-0" v-if="!showComment">
            <v-icon small>$comment</v-icon>
            <v-btn text color="ligth" @click="updateComment()">
              コメントを追加
            </v-btn>
            <v-list v-if="hasComment">
              <v-list-item v-for="item in task.comments" :key="item.id">
                <v-list-item-icon>
                  <v-icon small>$commonuser</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <p>
                    {{ item.user_name }}
                    <span v-if="!$vuetify.breakpoint.mobile" class="ml-5">{{
                      getCommentDate(item.updated_at)
                    }}</span>
                  </p>
                  <p v-if="$vuetify.breakpoint.mobile">
                    {{ getCommentDate(item.updated_at) }}
                  </p>
                  <p class="work-break">{{ item.comment }}
                  </p>
                </v-list-item-content>
                <v-list-item-action
                  style="align-self: flex-start"
                  @click="deleteComment(item)"
                >
                  <v-icon small>$delete</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col class="py-0 d-flex align-items-baseline" v-else>
            <v-icon small>$comment</v-icon>
            <v-textarea
              class="mt-0 ml-2"
              v-model="userComment"
              placeholder="コメントを追加"
              counter
              maxlength="512"
            ></v-textarea
          ></v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          :disabled="!isEditing"
          tile
          depressed
          class="ml-auto"
          color="primary"
          @click="updateTask()"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
    <PreviewFile :show="showPreview" :src="src" :text="text"> </PreviewFile>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { backMixin } from "../../../mixins/back";
import DropdownButton from "../../../components/DropdownButton.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import {
  DELETE_COMMENT_API,
  GET_OR_DEL_ACTUAL_TASK_API,
  ACTUAL_TASK_API,
  UPLOAD_ATTACH_FILE_API,
  ATTACH_FILE_API,
  CATEGORIES_API,
  TODO_TASK_API,
  FAVORITE_TASK_API,
  NOTIFICATION_API
} from "../../../constants/api";
import {
  isEmpty,
  copyToClipboard,
  saveFile,
  deepClone,
  isEmptyNumber,
  isSafari,
  debounce_leading,
  orderBy,
} from "../../../helpers/helpers";
import { required, requiredIf } from "vuelidate/lib/validators";
import {
  formatTime,
  formatDateTime,
  parseDateTime,
  toMinute,
  toHHmm,
  toMinuteForHHmm,
  toHHmmFormat,
} from "../../../helpers/datetime-helpers";
import {
  timeValidator,
  compareDateValidator,
  workTimeValidator,
} from "../../../helpers/validator";
import { apiErrorMessages } from "../../../helpers/messages";
import {
  FC1004,
  FC1005,
  FC701,
  FC804,
  FC805,
  FC806,
  FC808,
  FC820,
  FC1003,
  FC832,
  FC833,
  FC501,
  FC502
} from "../../../constants/fc";
import { yyyyMMddHHmm2, yyyyMMddHHmmss3 } from "../../../constants/df";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import DialogDragDirective from "../../../directives/dialogDrag";
import PreviewFile from "./PreviewFile.vue";
export default {
  props: {
    show: Boolean,
    actualId: String,
    actualTaskId: Number,
    planId: String,
    scheduleIdList: {
      type: Array,
      default: () => [],
    },
    scheduleComments: {
      type: Array,
      default: () => [],
    },
  },
  components: { DropdownButton, PreviewFile },
  directives: {
    "dialog-drag": DialogDragDirective,
  },
  mixins: [validationMixin, backMixin],
  validations: {
    task: {
      name: {
        required: requiredIf((task) => {
          if (isEmptyNumber(task.schedule_task_id)) {
            return true;
          } else {
            return !task.scheduleIdList.some((x) => x == task.schedule_task_id);
          }
        }),
      },
      output_detail: {
        required: requiredIf((task) => {
          if (isEmptyNumber(task.schedule_task_id)) {
            return !!task.is_need_output;
          } else {
            return (
              !!task.is_need_output &&
              !task.scheduleIdList.some((x) => x == task.schedule_task_id)
            );
          }
        }),
      },
      category_secondary_id: {
        required: requiredIf((task) => {
          if (isEmptyNumber(task.schedule_task_id)) {
            return false;
          } else {
            return (
              task.category_primary_id != null &&
              !task.scheduleIdList.some((x) => x == task.schedule_task_id)
            );
          }
        }),
      },
      cost: {
        required,
        workCompare: workTimeValidator("count"),
      },
      count: {
        required,
        workCompare: workTimeValidator("cost"),
      },
      start_time: { required, timeValidator },
      end_time: {
        required,
        timeValidator,
        compareDate: compareDateValidator("start_time"),
      },
    },
  },
  data() {
    return {
      parentId: "actionFreeTaskId",
      openFreeTask: false,
      originalTask: {},
      showComment: false,
      isEditing: false,
      isSelecting: false,
      userComment: "",
      comments: this.scheduleComments,
      parentCategories: [],
      childCategories: [],
      task: { name: "", files: [], tags: [], comments: [] },
      src: "",
      text: "",
      showPreview: false,
      canSplit: true,
      listNotification: [],
    };
  },
  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },
  watch: {
    async show() {
      this.openFreeTask = true;
      this.showComment = false;
      this.isEditing = false;
      this.userComment = "";
      this.$v.$reset();
      this.task = { name: "", files: [], tags: [], comments: [] };
      await this.getTask();
      await this.getNotification();
    },
    task: {
      deep: true,
      handler() {
        this.isEditing = this.compareValue();
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    userComment: {
      handler(val) {
        this.isEditing = !isEmpty(val);
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    scheduleComments: {
      handler(val) {
        this.comments = val;
      },
    },
  },
  computed: {
    ...mapGetters("common", ["loginInfo"]),
    ...mapGetters("save", ["getChangeFlag"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.task.name.$dirty) return errors;
      !this.$v.task.name.required &&
        errors.push(this.$t("label.error.required"));
      return errors;
    },
    endTimeErrors() {
      const errors = [];
      if (this.$v.task.end_time.$dirty) {
        !this.$v.task.end_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.end_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
        !this.$v.task.end_time.compareDate &&
          errors.push(this.$t("label.error.end_time"));
      }

      return errors;
    },
    startTimeErrors() {
      const errors = [];
      if (this.$v.task.start_time.$dirty) {
        !this.$v.task.start_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.start_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    costErrors() {
      const errors = [];
      if (this.$v.task.cost.$dirty) {
        !this.$v.task.cost.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.cost.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    countErrors() {
      const errors = [];
      if (this.$v.task.count.$dirty) {
        !this.$v.task.count.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.count.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    isSpotTask() {
      if (isEmptyNumber(this.task.schedule_task_id)) {
        return true;
      } else {
        return !this.scheduleIdList.some(
          (x) => x == this.task.schedule_task_id
        );
      }
    },
    primaryName() {
      let p = this.parentCategories.find(
        (x) => x.id === this.task.category_primary_id
      );
      return p ? p.name : "未分類";
    },
    secondaryName() {
      if (this.childCategories) {
        let s = this.childCategories.find(
          (x) => x.id === this.task.category_secondary_id
        );
        return s ? s.name : "";
      } else {
        return "";
      }
    },
    hasFile() {
      return this.task && this.task.files && this.task.files.length > 0;
    },
    hasTag() {
      return this.task && this.task.tags && this.task.tags.length > 0;
    },
    hasComment() {
      return this.task && this.task.comments && this.task.comments.length > 0;
    },
    outputDetailErrors() {
      const errors = [];
      if (this.$v.task.output_detail.$dirty) {
        !this.$v.task.output_detail.required &&
          this.task.is_need_output &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    showSecondCategory() {
      return !isEmptyNumber(this.task.category_primary_id);
    },

    // タスクは完了かどうか判断
    displayAsSplitTask(){
      // 分割ではないかつ「タスクが全て完了」チェック
      if (this.task.is_split_task === 0 && this.task.is_split_task_completed === 1){
        return false;
      }

      // 分割タスク→フリータスク
      if (this.task.is_split_task === 1 && this.task.is_split_task_completed === 1
        && this.originalTask.total_actual_cost - this.originalTask.cost === 0){
        return false;
      }

      return true;
    }
  },
  methods: {
    checkMaxLength(type, $event) {
      if (
        $event.keyCode === 8 ||
        $event.keyCode === 46 ||
        $event.keyCode === 38 ||
        $event.keyCode === 40 ||
        $event.keyCode === 37 ||
        $event.keyCode === 39
      )
        return true;
      if (type === "cost") {
        if (this.$v.task.cost.required) {
          if (this.task.cost.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      } else {
        if (this.$v.task.count.required) {
          if (this.task.count.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      }
    },

     /**
     * set task favorite
     * @return {void}
     */
     async setFavorite(item) {
        item.is_favorite = !item.is_favorite;
        // お気に入り追加
        if (item.is_favorite){
          try {
            const {data} = await axios.post(FAVORITE_TASK_API(), {
              name: item.name,
              description: item.description,
              category_secondary_id: item.category_secondary_id,
              cost: +item.cost,
              count: +item.count,
              is_need_output: +item.is_need_output,
              output_detail:
                +item.is_need_output === 0 ? null : item.output_detail,
              tags: item.tags,
              schedule_task_id: item.schedule_task_id,
              actual_task_id: item.id,
            });
            item.favorite_task_id = data.result.id;
          }
          catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC832, status);
          }
        }
        else{
          try {
            await axios.delete(FAVORITE_TASK_API(item.favorite_task_id));
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC833, status);
          }
        }

        this.$emit("setFavorite", item);
    },


    changeCount() {
      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        this.calculateTime();
      }
    },

    changeStartTime() {
      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        let hasColon = this.task.start_time.indexOf(":") >= 0;
        if (hasColon) this.calculateTime();
        else this.calculateTimeForHHmm();
      }
    },

    formatStartTime($event) {
      if (!this.$v.task.start_time.$invalid) {
        const value = $event.target.value;
        let hasColon = value.indexOf(":") >= 0;
        if (!hasColon) {
          this.task.start_time = toHHmmFormat(value);
        }
      }
    },

    changeCost() {
      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        this.calculateTime();
      }
    },
    updateCost(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.cost = value;
      } else {
        this.task.cost = value.slice(0, 3);
      }
      this.$forceUpdate();
    },
    updateCount(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.count = value;
      } else {
        this.task.count = value.slice(0, 3);
      }
      this.$forceUpdate();
    },
    getCommentDate(date) {
      return formatDateTime(
        parseDateTime(date, yyyyMMddHHmmss3),
        yyyyMMddHHmm2
      );
    },

    isSupervisor() {
      return this.$root.$refs.actualComponent.isSupervisor();
    },

    calculateTime() {
      let startTime = toMinute(this.task.start_time, ":");
      let endTime = toMinute(this.task.end_time, ":");
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },

    calculateTimeForHHmm() {
      let startTime = toMinuteForHHmm(this.task.start_time);
      let endTime = toMinuteForHHmm(this.task.end_time);
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },

    updateComment() {
      this.showComment = true;
    },

    deleteComment(item) {
      if (this.loginInfo.user_id !== item.user_id) return;
      this.$confirm(this.$t("label.confirm.delete_comment"), {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
        rtl: true,
      })
        .then(async () => {
          try {
            await axios.delete(DELETE_COMMENT_API(item.id));
            this.task.comments = this.task.comments.filter(
              (x) => x.id !== item.id
            );
            this.$emit("deleteComment", {
              comment_id: item.id,
              schedule_task_id: this.task.schedule_task_id,
            });
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC820, status);
          }
        })
        .catch(() => {});
    },

    async getTask() {
      try {
        const { data, status } = await axios.get(
          GET_OR_DEL_ACTUAL_TASK_API(this.actualId, this.actualTaskId)
        );
        if (status === STATUS_NO_CONTENT) {
          this.openFreeTask = false;
          this.$alert("タスクが見つかりません。");
        } else {
          let rdata = data.result[0];
          this.task = deepClone(rdata);
          this.originalTask = deepClone(rdata);
          this.originalTask.start_time = formatTime(rdata.start_time);
          this.task.start_time = formatTime(rdata.start_time);
          this.originalTask.end_time = formatTime(rdata.end_time);
          this.task.end_time = formatTime(rdata.end_time);
          await this.getCategories();
          if (this.isSpotTask) {
            this.task.scheduleIdList = this.scheduleIdList;
            this.parentCategories.push({
              id: null,
              name: "未分類",
              secondaries: [],
            });
            this.childCategories = this.parentCategories.find(
              (x) => x.id === this.task.category_primary_id
            )?.secondaries;
          } else {
            this.task.scheduleIdList = [];
          }
          let scheduleComments = this.comments.map((x) => {
            return {
              comment: x.comment,
              user_id: x.user_id,
              user_name: x.user_name,
              updated_at: x.updated_at,
              id: x.id,
            };
          });
          //Add schedule comment for task
          if (this.comments && this.comments.length > 0) {
            let comments = [];
            [...this.task.comments, ...scheduleComments].forEach((v) => {
              if (comments.some((x) => x.id === v.id)) {
                //
              } else {
                comments.push(v);
              }
            });
            this.task.comments = orderBy(comments, ["updated_at"], ["desc"]);
          }

          if (this.task.is_split_task !== 1) {
            this.task.is_split_task_completed = 1;
            this.originalTask.is_split_task_completed = 1;
          }

          if (!this.task.can_split && +this.task.can_split === 1){
            this.canSplit = true;
          }
          else{
            this.canSplit = false;
          }
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC804, status);
      }
    },

    updateTask: debounce_leading(async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        try {
          if (this.isSupervisor()) {
            if (!isEmpty(this.userComment)) {
              await axios.put(ACTUAL_TASK_API(this.actualId), {
                actual_tasks: [
                  {
                    id: this.task.id,
                    comments: [
                      {
                        user_id: this.loginInfo.user_id,
                        comment: this.userComment,
                      },
                    ],
                  },
                ],
              });
              this.$store.dispatch("save/setChangeFlag", false);
              this.openFreeTask = false;
            }
          } else {
            let params = [
              {
                ...this.task,
                start_time: (
                  this.task.start_time.replace(":", "") + "00"
                ).padStart(6, "0"),
                end_time: (this.task.end_time.replace(":", "") + "00").padStart(
                  6,
                  "0"
                ),
                cost: +this.task.cost,
                count: +this.task.count,
                category_secondary_id: this.task.category_secondary_id,
                is_favorite: +this.task.is_favorite,
                is_completed: 1,
                split_task_id:
                  +this.task.count === 1 &&
                  +this.task.is_split_task_completed === 0
                    ? null
                    : this.task.split_task_id,
                is_split_task_completed: this.task.is_split_task_completed,
                is_split_task: this.displayAsSplitTask
                    ? 1
                    : 0,
                comments:
                  !isEmpty(this.userComment) && this.showComment
                    ? [
                        {
                          user_id: this.loginInfo.user_id,
                          comment: this.userComment,
                        },
                      ]
                    : [],
              },
            ];
            const { data } = await axios.put(ACTUAL_TASK_API(this.actualId), {
              actual_tasks: params,
            });
            this.$store.dispatch("save/setChangeFlag", false);
            this.openFreeTask = false;
            data.result[0].start_time = formatTime(data.result[0].start_time);
            data.result[0].end_time = formatTime(data.result[0].end_time);
            this.$emit("updateTaskSuccess", data.result[0]);
          }
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC805, status);
        }
      }
    }, 1500),

    async deleteTask() {
      this.$confirm(this.$t("label.confirm.delete_task"), {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
        rtl: true,
      })
        .then(async () => {
          try {
            await axios.delete(
              GET_OR_DEL_ACTUAL_TASK_API(this.actualId, this.actualTaskId)
            );
            this.openFreeTask = false;
            this.$emit("deleteTask", this.task);
            this.openFreeTask = false;
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC806, status);
          }
        })
        .catch(() => {});
    },

    archiveTask: debounce_leading(async function () {
      try {
        let params = {
          name: this.task.name,
          description: this.task.description,
          cost: this.task.cost,
          count: this.task.count,
          category_secondary_id: this.task.category_secondary_id,
          is_need_output: this.task.is_need_output,
          output_detail: this.task.output_detail,
          is_favorite: this.task.is_favorite,
          tags: this.task.tags,
          comments: this.task.comments,
          actual_task_id: this.task.id,
        };
        const { data } = await axios.post(TODO_TASK_API(), {tasks: [params]});
        this.openFreeTask = false;
        this.task.todoTaskId = data.result.id[0];
        this.$emit("archiveTask", this.task);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC808, status);
      }
    }),

    async duplicateTask() {
      if (this.isEditing) {
        await this.updateTask();
      }
      this.openFreeTask = false;
      this.$emit("duplicateTask", this.task);
    },

    getNotification: debounce_leading(async function () {
      try {
        const { data, status } = await axios.get(NOTIFICATION_API(), {
          params: {
            is_verified: 0,
            sort: "new", // TODO:
          },
        });
        let list = status === STATUS_NO_CONTENT ? [] : data.result.notices;
        this.$store.dispatch(
          "notification/setCount",
          status === STATUS_NO_CONTENT ? 0 : data.result.total_count
        );
        this.$store.dispatch(
          "notification/setUnReadList",
          status === STATUS_NO_CONTENT ? [] : data.result.notices
        );
        // Add images to list
        for (let index = 0; index < list.length; index++) {
          const item = list[index];
          let comment = this.task.comments.find(
            x => +x.id === +item.comment_id && !!item.can_verified
          );
          if (comment){
            this.listNotification.push(item);
          }
          
        }
        if (this.listNotification.length > 0){
          await this.confirmed();
          this.$store.dispatch("notification/setCount", data.result.total_count - this.listNotification.length);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC501, status);
      }
    }),

    // Call Api comfirm notice
    async confirmed() {
      try {
        let params = [];
        this.listNotification.forEach(item => {
          let param = {
            status_notice_id: null,
            comment_notice_id: item.comment_notice_id,
            is_verified: 1,
          };
          params.push(param);
        });
        await axios.put(NOTIFICATION_API(), {
          notices: params,
        });
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC502, status);
      }
    },
    
    async viewAttachFile(item) {
      if (!item.isNew) {
        try {
          const { data, headers, status } = await axios.get(
            ATTACH_FILE_API(item.id),
            {
              responseType: "blob",
            }
          );

          if (status === STATUS_NO_CONTENT) return;
          const blob = new Blob([data], {
            type: contentType,
          });
          var contentType = headers["content-type"];
          if (
            contentType.startsWith("image/") ||
            contentType.startsWith("text/")
          ) {
            let that = this;
            if (isSafari() && typeof FileReader !== "undefined") {
              if (contentType.startsWith("image/")) {
                let reader = new FileReader();
                reader.onloadend = function () {
                  that.src = reader.result;
                  that.text = "";
                  that.showPreview = !that.showPreview;
                };
                reader.readAsDataURL(blob);
              } else {
                let reader = new FileReader();
                reader.onloadend = function () {
                  that.text = reader.result;
                  that.src = "";
                  that.showPreview = !that.showPreview;
                };
                reader.readAsText(blob);
              }
            } else {
              saveFile(data, headers, item.name);
            }
          } else {
            saveFile(data, headers, item.name);
          }
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC1003, status);
        }
      } else return;
    },

    copyLink() {
      copyToClipboard(
        window.location.origin +
          `/actual/${this.actualId}?taskId=${this.task.id}&sid=${this.planId}`,
        this.parentId
      );
      this.$toast(this.$t("label.toast.copied"), 3000);
    },

    attachFile() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },

    deleteAttachFile(item) {
      this.$confirm(this.$t("label.confirm.delete_attach"), {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
        rtl: true,
      })
        .then(async () => {
          if (!item.isNew) {
            try {
              await axios.delete(ATTACH_FILE_API(item.id));
              this.task.files = this.task.files.filter(
                (x) => x.name !== item.name
              );
              this.originalTask.files = this.originalTask.files.filter(
                (x) => x.name !== item.name
              );
              this.$emit("updateTaskSuccess", this.task);
            } catch (error) {
              const { status } = error.response;
              apiErrorMessages(this, FC1005, status);
            }
          } else {
            this.task.files = this.task.files.filter(
              (x) => x.name !== item.name || !x.isNew
            );
          }
        })
        .catch(() => {});
    },

    async uploadFile() {
      let formData = new FormData();
      for (var i = 0; i < this.task.files.length; i++) {
        if (!this.task.files[i].isNew) continue;
        else {
          formData = new FormData();
          formData.append("file", this.task.files[i].fileContent);
          try {
            const { data } = await axios.post(
              UPLOAD_ATTACH_FILE_API(),
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            this.task.files[i].id = data.result.file_id;
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC1004, status);
          }
        }
      }
    },

    async fileChanged(e) {
      let countFileError = 0;
      let selectedFiles = e.target.files;
      if (this.task.files.length + selectedFiles.length > 10) {
        this.$alert(this.$t("label.error.max_attach"), {
          width: "500px",
        });
      } else {
        for (var i = 0; i < selectedFiles.length; i++) {
          let fileSize = selectedFiles[i].size / 1024 / 1024;
          if (fileSize > 10) {
            countFileError = countFileError + 1;
          } else {
            this.task.files.push({
              fileContent: selectedFiles[i],
              name: selectedFiles[i].name,
              id: i,
              isNew: true,
            });
          }
        }
        if (countFileError > 0) {
          this.$toast(
            this.$t("label.error.max_byte", { 0: `${countFileError}` }),
            3000
          );
        }
      }
      this.$refs.uploader.value = null;
    },

    async getCategories() {
      try {
        const { data, status } = await axios.get(CATEGORIES_API());
        this.parentCategories = status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC701, status);
      }
    },

    primaryCategoryChange(primaryCategory) {
      this.childCategories = this.parentCategories.find(
        (x) => x.id === primaryCategory.id
      )?.secondaries;
      this.task.category_primary_id = primaryCategory.id;
      this.task.category_secondary_id = null;
    },

    secondaryCategoryChange(secondCategory) {
      this.task.category_secondary_id = secondCategory.id;
    },

    compareValue() {
      return (
        JSON.stringify({
          name: this.task.name,
          description: this.task.description,
          is_need_output: this.task.is_need_output,
          output_detail: this.task.output_detail,
          category_primary_id: this.task.category_primary_id,
          category_secondary_id: this.task.category_secondary_id,
          start_time: this.task.start_time,
          end_time: this.task.end_time,
          userComment: "",
          files: this.task.files,
          cost: this.task.cost,
          count: this.task.count,
          is_split_task_completed: this.task.is_split_task_completed,
        }) !==
        JSON.stringify({
          name: this.originalTask.name,
          description: this.originalTask.description,
          is_need_output: this.originalTask.is_need_output,
          output_detail: this.originalTask.output_detail,
          category_primary_id: this.originalTask.category_primary_id,
          category_secondary_id: this.originalTask.category_secondary_id,
          start_time: this.originalTask.start_time,
          end_time: this.originalTask.end_time,
          userComment: "",
          files: this.originalTask.files,
          cost: this.originalTask.cost,
          count: this.originalTask.count,
          is_split_task_completed: this.originalTask.is_split_task_completed,
        })
      );
    },

    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.openFreeTask = false;
          }, 100);
        },
        () => {}
      );
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.$v.$reset();
          this.openFreeTask = false;
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },

    canSplitCheck(){
      // 最後のタスクではない
      if (!this.task.can_split && +this.task.is_split_task === 1){
        return false;
      }
      return true;
    },
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}

.w-120 {
  width: 100px;
}
.w-90 {
  width: 90px;
}
.align-items-baseline {
  align-items: baseline;
}
.v-dialog.v-dialog--active .v-card__title {
  cursor: grab;
}
.w-60 {
  width: 60px;
}
.w-95 {
  width: 95px;
}
.w-95,
.w-60,
.w-90 {
  .v-text-field__details {
    width: 100%;
  }
}
.work-break {
  white-space: pre-wrap;
}
</style>
