<template>
  <div style="height: 4px">
    <!-- implement footer layout -->
  </div>
</template>
<script>
export default {
  name: "Footer",
  data: () => ({
    //data
  }),
};
</script>

<style>
</style>