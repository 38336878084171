import Vue from 'vue'
import MessageBoxComponent from './index'
import vuetify from '../../../plugins/vuetify'
import i18n from '../../../plugins/i18n'
const DEFAULT_OPTIONS = {
  buttons: {
    ok: {
      text: 'ok',
      type: 'primary',
    },
    cancel: {
      text: 'キャンセル',
      type: 'light',
    },
  },
  type: 'warning',
  rtl: false,
  width: '500px',
  wrapText: false,
  preWrapText: false,
  arrangeTask: "",
}

let instance

function initInstance() {
  const MessageBoxConstructor = Vue.extend(Object.assign({
    vuetify,
    i18n
  }, MessageBoxComponent))
  instance = new MessageBoxConstructor({
    el: document.createElement('div'),
  })
}

function showMessage(resolve, reject, dialogType, msg, options = {}) {
  const _options = {
    ...DEFAULT_OPTIONS,
    ...options
  }

  if (!instance) {
    initInstance()
  }
  instance.message = msg
  instance.dialogType = dialogType
  instance.rtl = _options.rtl
  instance.width = _options.width
  instance.width = _options.wrapText
  instance.arrangeTask = _options.arrangeTask
  try {
    const {
      ok,
      cancel
    } = _options.buttons
    if (ok) {
      instance.okBtnText = ok.text
      instance.okBtnType = ok.type
    }
    if (cancel) {
      instance.cancelBtnText = cancel.text
      instance.cancelBtnType = cancel.type
    }
    Object.keys(DEFAULT_OPTIONS).forEach((key) => {
      if (['buttons'].includes(key)) return
      if (_options[key]) {
        instance[key] = _options[key]
      }
    })
  } catch (e) {
    console.error(e)
  }

  instance.callback = function (btnType) {
    switch (btnType) {
      case 'ok':
        resolve()
        break
      case 'cancel':
        reject()
        break
    }
    instance.visible = false
  }

  document.querySelector('body').appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.visible = true
  })
}

const MessageBox = function () { }

MessageBox.alert = function (msg, options) {
  return new Promise((resolve, reject) => {
    showMessage(resolve, reject, 'alert', msg, options)
  })
}

MessageBox.confirm = function (msg, options) {
  return new Promise((resolve, reject) => {
    showMessage(resolve, reject, 'confirm', msg, options)
  })
}

export {
  MessageBox
}

export default MessageBox
