<template>
  <v-menu
    v-model="openMenu"
    auto
    transition="scale-transition"
    bottom
    offset-y
    :close-on-content-click="!(isDisable || !isCanSubmit())"
    min-width="360"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-blur
        v-if="!$vuetify.breakpoint.mobile"
        tile
        v-bind="attrs"
        v-on="on"
        >テンプレート</v-btn
      >
      <span v-else v-bind="attrs" v-on="on"> テンプレート </span>
    </template>
    <v-list>
      <child-template
        :templates="templates"
        :isDisable="isDisable || !isCanSubmit()"
        @selectedTemplate="!isDisable && selectTemplate($event)"
        @editedTemplate="editTemplate($event)"
        :name="'テンプレートの利用'"
        :isEdit="true"
      ></child-template>

      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-action>
          <v-menu
            v-model="sopenMenu"
            bottom
            right
            nudge-right="50"
            offset-x
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row class="v-center" v-bind="attrs" v-on="on">
                <v-icon class="mx-3" small>$savetemplate</v-icon>

                <v-list-item-content class="ml-7">
                  <v-list-item-title
                    >予定をテンプレートとして保存</v-list-item-title
                  >
                </v-list-item-content>
              </v-row>
            </template>
            <v-list :class="{ 'scroll-mobile': $vuetify.breakpoint.mobile }">
              <child-template
                :templates="templates"
                :name="'テンプレートの上書き'"
                @selectedTemplate="saveTemplate($event)"
              ></child-template>
              <v-divider></v-divider>
              <v-list-item @click="newTemplate">
                <v-list-item-content>
                  <v-list-item-title
                    >新しいテンプレートとして保存
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-action>
          <v-menu
            v-model="dopenMenu"
            bottom
            right
            offset-x
            nudge-right="120"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row class="v-center" v-bind="attrs" v-on="on">
                <v-icon class="mx-3" small>$delete</v-icon>
                <v-list-item-content class="ml-7">
                  <v-list-item-title>テンプレートを削除 </v-list-item-title>
                </v-list-item-content>
              </v-row>
            </template>
            <v-list>
              <child-template
                :templates="templates"
                :name="'削除するテンプレートを選んでください'"
                @selectedTemplate="deleteTemplate($event)"
              ></child-template>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import axios from "axios";
import {
  TEMPLATE_API,
  TASK_SCHEDULE_API,
  GET_SCHEDULE_API,
} from "../../../constants/api";
import ChildTemplate from "../../../components/ChildTemplate.vue";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import { apiErrorMessages } from "../../../helpers/messages";
import {
  FC802,
  FC803,
  FC810,
  FC811,
  FC812,
  FC817,
} from "../../../constants/fc";
import { mapGetters } from "vuex";
import { debounce_leading } from "@/helpers/helpers";
export default {
  components: { ChildTemplate },
  name: "DailyTemplate",
  props: {
    isNoTask: Boolean,
    planId: String,
    isDisable: Boolean,
    isHasSplitTask: Boolean,
  },
  computed: {
    ...mapGetters("common", ["loginInfo"]),
  },
  data() {
    return {
      openMenu: false,
      sopenMenu: false,
      dopenMenu: false,
      templates: [],
    };
  },
  watch: {
    openMenu: {
      handler(val) {
        if (val) {
          this.getTemplates();
        }
      },
    },
  },
  methods: {
    isBeforeTwelve() {
      return this.$root.$refs.scheduleComponent.isBeforeTwelve();
    },
    isCanSubmit() {
      return this.$root.$refs.scheduleComponent.isCanSubmit();
    },
    getTemplates: debounce_leading(async function () {
      try {
        const { data, status } = await axios.get(TEMPLATE_API());
        this.templates = status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC810, status);
      }
    }),
    saveTemplate(template) {
      let title = this.isHasSplitTask
        ? [
            "テンプレートを上書きしてもよろしいですか？\r\n分割したタスクはテンプレートとして保存されません。\r\nそれ以外のタスクをテンプレートとして保存します。",
          ]
        : ["テンプレートを上書きしてもよろしいですか？"];

      this.$confirm(title, {
        width: "700px",
      })
        .then(async () => {
          await this.saveRealTemplate(template.name, template.id, this.planId);
        })
        .catch(() => {
          return;
        });
    },
    selectTemplate(template) {
      if (!this.isNoTask) {
        this.$confirm(
          "テンプレートを挿入すると現在登録されているタスクが全て消去されます。\r\nタスクを上書きしてもよろしいですか？",
          {
            buttons: {
              ok: {
                text: "上書きする",
                type: "primary",
              },
            },
            width: "720px",
          }
        )
          .then(async () => {
            try {
              await axios.delete(TASK_SCHEDULE_API(this.planId));
              this.overideTemplate(template);
            } catch (error) {
              const { status } = error.response;
              apiErrorMessages(this, FC803, status);
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.overideTemplate(template);
      }
    },
    async overideTemplate(template) {
      try {
        let scheduleTasks = template.template_tasks.map((x) => {
          let task = {
            name: x.name,
            description: x.description,
            category_secondary_id: x.category_secondary_id,
            cost: +x.cost,
            count: +x.count,
            is_need_output: +x.is_need_output,
            is_favorite: 0,
            output_detail: x.output_detail,
            start_time: x.start_time,
            standard_task_id: x.standard_task_id,
            standard_task_revision: x.standard_task_revision,
            is_remand: 0,
            skill_level_id: x.skill_level_id || null,
            detail: x.detail,
          };
          return task;
        });
        if (scheduleTasks && scheduleTasks.length > 0) {
          await axios.post(TASK_SCHEDULE_API(this.planId), {
            schedule_tasks: scheduleTasks,
          });
        }
        try {
          await axios.put(GET_SCHEDULE_API(this.planId), {
            start_break_time: template.start_break_time,
            end_break_time: template.end_break_time,
          });
          this.$emit("reload");
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC817, status);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC802, status);
      }
    },
    deleteTemplate(template) {
      this.$confirm(["テンプレートを本当に削除しますか？"], {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
        },
      })
        .then(async () => {
          try {
            await axios.delete(TEMPLATE_API(template.id));
            this.templates = this.templates.filter((x) => x.id !== template.id);
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC812, status);
          }
        })
        .catch(() => {
          return;
        });
    },
    newTemplate() {
      if (this.isLimitTemplate()) {
        this.$alert(
          "テンプレートの保存件数上限に達しています。\r\n不要なテンプレートを削除してください。"
        );
        return;
      }

      if (this.isHasSplitTask) {
        this.$confirm(
          "分割したタスクはテンプレートとして保存されません。\r\nそれ以外のタスクをテンプレートとして保存します。",
          {
            width: "700px",
          }
        )
          .then(async () => {
            this.$prompt("新しいテンプレート名を入力", {
              maxlength: 64,
              validations: [(v) => !!v || this.$t("label.error.required")],
              dbData: this.templates,
              duplicatedError: this.$t("label.error.template_name_duplicated")
            })
              .then(async (x) => {
                await this.saveRealTemplate(x, null,this.planId);
              })
              .catch(() => {});
          })
          .catch(() => {
            return;
          });
      } else {
        this.$prompt("新しいテンプレート名を入力", {
          maxlength: 64,
          validations: [(v) => !!v || this.$t("label.error.required")],
          dbData: this.templates,
          duplicatedError: this.$t("label.error.template_name_duplicated")
        })
          .then(async (x) => {
            await this.saveRealTemplate(x, null,this.planId);
          })
          .catch(() => {});
      }
    },
    editTemplate(template) {
      this.$prompt("新しいテンプレート名を入力", {
        maxlength: 64,
        validations: [(v) => !!v || this.$t("label.error.required")],
        defaultValue: template.name,
        dbData: this.templates,
        duplicatedError: this.$t("label.error.template_name_duplicated")
      })
        .then(async (x) => {
          await this.saveRealTemplate(x, template.id,null);
        })
        .catch(() => {});
    },
    async saveRealTemplate(templateName, templateId, scheduleId) {
      try {
          if (templateId) {
            await axios.put(TEMPLATE_API(templateId), {
              name: templateName,
              schedule_id: scheduleId,
            });
          } else {
            await axios.post(TEMPLATE_API(), {
              name: templateName,
              schedule_id: scheduleId,
            });
          }
          await this.getTemplates();
      } catch (error) {
        const { status } = error.response;
        if (status === 404) {
          this.$alert(error.response.data.message);
        } else apiErrorMessages(this, FC811, status);
      }
    },

    isLimitTemplate() {
      return this.templates?.length == 10;
    },
  },
};
</script>
<style>
.scroll-mobile {
  max-height: 430px;
  overflow-y: auto;
}
</style>