import store from '../store'

export default async (to, from, next) => {
  if (!store.getters[ 'auth/check' ]) {
    next({ name: 'login', query : {
      back_url: location.href
    } })
  } else {
    next()
  }
}
