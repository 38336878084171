<template>
  <v-dialog
    v-model="openNotification"
    persistent
    max-width="600px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-card-title class="card-header py-2">
        <span> {{ dateT }} {{ "日次予定" }}</span>
        <v-btn @click="close()" class="ml-auto" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div v-for="notification in results" :key="notification.id">
          <v-list-item three-line>
            <v-list-item-avatar>
              <v-img :src="notification.userImage"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <div>
                {{ notification.comment_user_name }}さん
              </div>
              <div class="mb-1">
                {{
                  formatDate(notification.comment_date, "yyyy/MM/dd(EEEEE) HH:mm")
                }}
              </div>
              <div>
                <v-btn
                  class="mt-1 mb-5"
                  :class="{
                    'reject-text': isRejectText(notification),
                    disabledAction: true,
                  }"
                >
                  {{ notification.phase_name }}
                </v-btn>
              </div>
              <ul class="mb-5 word-break" v-if="isRejectText(notification) && notification.issue_tasks && notification.issue_tasks.length > 0">
                <li 
                  v-for="(issueTask, i) in notification.issue_tasks" :key="issueTask.id" 
                  :class="{'mb-2' : i !== notification.issue_tasks.length - 1}"
                >
                  {{ displayIssueTask(issueTask) }} 
                </li>
              </ul>
              <pre class="notification--comment v-application">{{
                notification.comment
              }}</pre>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { STATUS_NO_CONTENT } from "@/constants/status";
import {
  formatDateTime,
  parseDateTime,
} from "../../../helpers/datetime-helpers";
import { blobToBase64, deepClone, isEmpty } from "@/helpers/helpers";
import axios from "axios";
import * as SystemConstant from "../../../constants/system";
import { HHmm2, yyyyMMdd1, yyyyMMdd2, yyyyMMddEEEEE3 } from "@/constants/df";
const DEFAULT_IMAGE = "/assets/images/account_for_no_image.png";
export default {
  props: {
    show: Boolean,
    notifications: {
      type: Array,
      default: () => [],
    },
    date: String,
  },
  watch: {
    show: {
      handler(val) {
        this.openNotification = val;
      },
    },
    notifications: {
      async handler(val) {
        for (let index = 0; index < val.length; index++) {
          const item = val[index];
          let exisitem = val.find(
            (x) =>
              x.user_file_api_path === item.user_file_api_path &&
              x.id !== item.id
          );
          if (exisitem && exisitem.userImage) {
            item.userImage = exisitem.userImage;
          } else {
            item.userImage = await this.getImageByPath(
              item.user_file_api_path
            );
          }
        }
        this.results = deepClone(val);
        this.results.sort(
          (a, b) => Number(a.comment_date) - Number(b.comment_date)
        );
      },
    },
  },
  data() {
    return {
      openNotification: this.show,
      results: this.notifications,
    };
  },
  methods: {
    close() {
      this.openNotification = false;
      this.$emit('closeNotification')
    },
    formatDate(value, format) {
      let date = parseDateTime(value, "yyyyMMddHHmmss");
      return formatDateTime(
        date,
        formatDateTime(date, yyyyMMdd1) ===
          formatDateTime(new Date(), yyyyMMdd1)
          ? HHmm2
          : format
      );
    },
    async getImageByPath(imagePath) {
      let userImage = DEFAULT_IMAGE;

      try {
        if (!isEmpty(imagePath)) {
          const { data, status } = await axios.get(imagePath, {
            responseType: "blob",
          });
          userImage =
            status === STATUS_NO_CONTENT
              ? DEFAULT_IMAGE
              : await blobToBase64(data);
        }
      } catch (error) {
        // const { status } = error.response;
        // apiErrorMessages(this, FC1001, status);
      }

      return userImage;
    },
    isRejectText(item) {
      return (
        item.phase_name === SystemConstant.PHASE_ID_PRIMARY_REJECT_TEXT ||
        item.phase_name === SystemConstant.PHASE_ID_2_NEXT_REJECT_TEXT
      );
    },
    displayIssueTask(issueTask) {
      return `${issueTask?.name} ${issueTask?.detail || ''}`
    }
  },
  computed: {
    dateT() {
      return formatDateTime(parseDateTime(this.date, yyyyMMdd2), yyyyMMddEEEEE3);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.notification--comment {
  white-space: pre-wrap;
  overflow: visible;
  word-break: break-word;
}
.reject-text {
  color: #dc143c !important;
}
.disabledAction {
  pointer-events: none;
}
.word-break {
  word-break: break-all;
}
</style>
