function setListeners(div, threeDot) {
  var startY, startHeight;

  var start = function (e) {
    startY = e.touches[0].pageY
  };
  var resize = function (e) {
    e.preventDefault();
    var orig = e;
    e.target.style.cursor = 'row-resize';
    e.target.style.userSelect = 'none';
    let height = (orig.changedTouches[0].pageY - startY);
    if (height < 41) height = 41
    div.style.height = height + 'px';
    if ((height - 41) > 10) {
      for (let index = 0; index < threeDot.length; index++) {
        const element = threeDot[index];
        div.getElementsByTagName('td')[element].children[0].classList.remove('three-dot')

      }

    }
  };

  div.addEventListener("touchstart", start)
  div.addEventListener("touchmove", resize)
  div.addEventListener("touchend", function () {
    startY = undefined;
    startHeight = undefined;
    //e.preventDefault();
  })

  div.addEventListener('mousedown', function (e) {
    startY = e.clientY;
    startHeight = parseInt(document.defaultView.getComputedStyle(div).height, 10);
    e.preventDefault();
  });

  div.addEventListener('mouseover', function (e) {
    e.target.style.cursor = 'row-resize';
    e.target.style.userSelect = 'none';
  })

  div.addEventListener('mouseout', function (e) {
    e.preventDefault();
  })

  document.addEventListener('mousemove', function (e) {
    let height = (startHeight + e.clientY - startY);
    if (height < 41) height = 41
    div.style.height = height + 'px';
    if ((height - 41) > 10) {
      for (let index = 0; index < threeDot.length; index++) {
        const element = threeDot[index];
        div.getElementsByTagName('td')[element].children[0].classList.remove('three-dot')

      }

    }
  });

  document.addEventListener('mouseup', function (e) {
    startY = undefined;
    startHeight = undefined;
    e.preventDefault();
  });
}

function handler(el, binding) {
  if (binding.value.allowChangeGap) {
    let nodeName = el.nodeName;
    if (['TABLE', 'THEAD'].indexOf(nodeName) < 0) {
      el = el.querySelector('table'); // looking for the closest table
      if (!el) return;
      nodeName = 'TABLE';
    }

    const table = nodeName === 'TABLE' ? el : el.parentElement;
    var rows = table.getElementsByTagName('tr');
    if (!rows) return;
    table.style.overflow = 'hidden';
    for (var i = 1; i < rows.length; i++) {
      setListeners(rows[i], binding.value.threeDot);
    }
  }
}

const ResizeTableRowDirective = {
  update: (el, binding) => {
    handler(el, binding)
  },
  inserted: (e, b) => {
    handler(e, b)
  },
};

export default ResizeTableRowDirective;
