import {
  withParams
} from "vuelidate/lib/validators/common";
// eslint-disable-next-line no-useless-escape
export const passwordValidator = (value) => /^[a-zA-Z\d!"#$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~]{8,}$/.test(value)

export const compareDateValidator = (equalTo) =>
  withParams({
    type: "sameAs",
    eq: equalTo
  }, function (value, parentVm) {
    if (!equalTo) return true;
    let start = parentVm[equalTo]
    if (!value || !start) return true;
    let end = value.toString().replace(":", "");
    start = start.toString().replace(":", "");
    return parseInt(end) >= parseInt(start);
  });

export const timeValidator = (value) => /^([0-9]|0[0-9]|1[0-9]|2[0-3]):?[0-5][0-9]$/.test(value)

export const workTimeValidator = (equalTo) =>
  withParams({
    type: "sameAs",
    eq: equalTo
  }, function (value, parentVm) {
    if (!equalTo) return true;
    let cost = parentVm[equalTo]
    if (!value || !cost) return true;
    let workTime = parseInt(cost) * parseInt(value)
    return workTime <= 1439 && workTime > 0;
  });
export const alphabetValidatior = (value) => /^[A-Za-z0-9_.@]+$/.test(value)


export const fromValidator = (equalFrom) =>
  withParams({
    type: 'sameAs',
    eq: equalFrom
  }, function (value, parentVm) {
    if (!equalFrom || !value) return true
    const from = parentVm[equalFrom]
    if (!from) return true
    return parseInt(value) >= parseInt(from)
  })
export const toValidator = (equalTo) =>
  withParams({
    type: 'sameAs',
    eq: equalTo
  }, function (value, parentVm) {
    if (!equalTo || !value) return true
    const to = parentVm[equalTo]
    if (!to) return true
    return parseInt(to) >= parseInt(value)
  })

export const fiveDivisibility = (value) => /^[0-9:]*[0,5]$/.test(value) && +value !== 0

export const costBeginnerFieldValidator = (value, siblings) => {
  if(!siblings?.is_target_beginner) return true
  
  const costStandard = siblings?.cost_standard || false
  if (!costStandard || !value) return true
  return parseInt(costStandard) <= parseInt(value)
}

export const costExpertFieldValidator = (value, siblings) => {
  if(!siblings?.is_target_expert) return true

  const costStandard = siblings?.cost_standard || false
  if (!costStandard || !value) return true
  return parseInt(costStandard) >= parseInt(value)
}

export const startEndTogetherValidator = (equalTo) =>
  ( function (value, parentVm) {
    if (parentVm.position_id != 100 && parentVm.position_id !=200) return true;
    if (!equalTo) return true;
    let start = parentVm[equalTo]
    if (!value && !start) return true;
    if (value && start) return true;
    return false;
  });