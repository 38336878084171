<template>
  <v-list class="overflow-y-auto" max-height="50vh">
    <v-list-item-group>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="groupChanged()" v-model="checkAll"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>全て</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-for="item in listItem" :key="item[id]">
        <v-list-item-action>
          <v-checkbox
            @change="groupChanged()"
            v-model="item[check]"
          ></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-title v-bind="attrs" v-on="on">
                {{ item[display] }}
              </v-list-item-title>
            </template>
            <span> {{ item[display] }}</span>
          </v-tooltip>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {
  name: "GroupItemCheckList",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    parent: {},
    display: String,
    check: String,
    id: String,
    checkAllDepartment: Boolean,
  },
  data() {
    return {
      listItem: [],
    };
  },
  watch: {
    items: {
      immediate: true,
      handler(val) {
        this.listItem = val;
      },
    },
  },
  computed: {
    checkAll: {
      // getter
      get: function () {
        return !this.listItem.some((x) => !x[this.check]);
      },
      // setter
      set: function (newValue) {
        this.listItem = this.listItem.map((x) => {
          return {
            ...x,
            [this.check]: newValue,
          };
        });
      },
    },
  },
  methods: {
    groupChanged() {
      let commitedValue = {
        department: {
          id: this.parent.id,
          name: this.parent.name,
        },
        groups: this.listItem,
      };
      this.$emit("groupChanged", commitedValue);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
