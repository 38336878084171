<template>
  <button :type="nativeType" :disabled="loading || disabled" :class="{
    [`btn-${type}`]: true,
    'btn-block': block,
    'btn-lg': large,
    'is-loading': loading
  }" class="btn"
  @click="click"
  >
    <slot />
  </button>
</template>


<script>
/**
 * Button Sample
 */
export default {
  name: 'VButton',

  props: {
    type: {
      type: String,
      default: 'primary'
    },

    nativeType: {
      type: String,
      default: 'submit'
    },

    loading: {
      type: Boolean,
      default: false
    },

    block: {
      type: Boolean,
      default: false
    },

    large: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    click: {
      type: Function,
      default: () => { return false;}
    }
  }
}
</script>
