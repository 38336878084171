export const COLOR_GRAY = "gray";
// export const COLOR_FREE_TASK = "#FFDDB3"
// export const COLOR_FREE_TASK_NOT_COMPLETE = "#FF8C00"
export const COLOR_FREE_TASK = "#F8CBAD"
export const COLOR_FREE_TASK_COMPLETE = "#F8CBAD"
export const COLOR_FREE_TASK_NOT_COMPLETE = "#F8CBAD"
// export const COLOR_DB_TASK = "#FFFFFF"
// export const COLOR_DB_TASK_COMPLETE = "#FFFFFF"
// export const COLOR_DB_TASK_NOT_COMPLETE = "#FFFFFF"
export const COLOR_DB_TASK = "#BDD7EE"
export const COLOR_DB_TASK_COMPLETE = "#BDD7EE"
export const COLOR_DB_TASK_NOT_COMPLETE = "#BDD7EE"
export const COLOR_REMAND = "#002060"
export const COLOR_PINK = "#F37D93"
export const COLOR_RED = "#DC143C"
export const COLOR_DEFAULT = ""

export const COLOR_FREE_TASK_WITHIN_1_2 = "#FAB4CA"
export const COLOR_FREE_TASK_GREATER_1_2 = "#FF0000"

export const COLOR_DB_TASK_WITHIN_1_2 = "#92BEE6"
export const COLOR_DB_TASK_GREATER_1_2 = "#2E75B6"

export const BORDER_COLOR_TASK_NOT_COMPLETE = '#FF0000'
export const BORDER_COLOR_TASK_NOT_PLAN = '#2E75B6'

export const MARK_OUPUT_COMPLETE = "output-task-completed"
export const MARK_OUPUT_NOT_COMPLETE = "output-task"
export const MARK_OUPUT_DEFAULT = ""
export const TIME_TEXT_ZERO= "0m"

export const COLOR_SELECT_TASK = "#E6E6E6"
