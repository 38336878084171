<template>
  <v-dialog v-model="visible" persistent :width="width" :content-class="arrangeTask">
    <v-card>
      <v-card-title class="pt-10" >
        <p class="v-application" v-for="(p, i) in content" :key="i">
          <pre class="v-application" v-bind:class="{'warp-text-mobile' : $vuetify.breakpoint.mobile || wrapText ,
        'pre-wrap': preWrapText
        }">{{ p }}</pre></p>
      </v-card-title>

      <template v-if="rtl">
        <v-card-actions class="py-2">
          <v-spacer></v-spacer>
          <v-btn
            :color="cancelBtnType"
            depressed
            outlined
            @click="handleClick('cancel')"
            v-if="dialogType === 'confirm'"
          >
            {{ cancelBtnText }}
          </v-btn>
          <v-btn
            class="font-weight-bold"
            @click="handleClick('ok')"
            :color="okBtnType"
          >
            {{ okBtnText }}
          </v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-actions class="py-2">
          <v-spacer></v-spacer>

          <v-btn
            :color="cancelBtnType"
            depressed
            outlined
            @click="handleClick('cancel')"
            v-if="dialogType === 'confirm'"
          >
            {{ cancelBtnText }}
          </v-btn>

          <v-btn  class="font-weight" @click="handleClick('ok')" :color="okBtnType">
            {{ okBtnText }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "MessageBox",
  props: {},
  data() {
    return {
      visible: false,
      message: "",
      dialogType: "alert",
      type: "warning",
      okBtnText: "ok",
      okBtnType: "primary",
      cancelBtnText: this.$t("label.buttons.cancel"),
      cancelBtnType: "light",
      width: "500px",
      rtl: false,
      wrapText: false,
      preWrapText: false,
      arrangeTask: "",
    };
  },
  computed: {
    content() {
      if (!Array.isArray(this.message)) {
        return [this.message];
      }
      return this.message;
    },
  },
  methods: {
    handleClick(type) {
      this.callback(type);
    },
  },
};
</script>
<style lang="scss">
.custom-dialog {
  margin-left: 10px;
  margin-right: 10px;
}

.custom-dialog > .v-card > .v-card__title {
  padding-left: 10px;
  padding-right: 10px;
}
.custom-dialog > .v-card > .v-card__title .warp-text-mobile {
  white-space: pre-wrap !important;
}
</style>
<style scoped>
p:not(:first-child) {
  width: 100%;
  font-size: 12px;
}
.warp-text-mobile {
  white-space: normal;
}
.pre-wrap {
  white-space: pre-wrap;
}
</style>
