import Vue from 'vue'
import PromptBoxComponent from './index'
import vuetify from '../../../plugins/vuetify'
import i18n from '../../../plugins/i18n'


const DEFAULT_OPTIONS = {
  buttons: {
    ok: {
      text: '保存',
      type: 'primary',
    },
    cancel: {
      text: 'キャンセル',
      type: 'default',
    },
  },
  type: 'prompt',
  maxlength: 20,
  validations: [],
  defaultValue: ''
}

let instance

function initInstance() {
  const MessageBoxConstructor = Vue.extend(Object.assign({ vuetify, i18n }, PromptBoxComponent))
  instance = new MessageBoxConstructor({
    el: document.createElement('div'),
  })
}

function showMessage(resolve, reject, dialogType, msg, options = {}) {
  const _options = { ...DEFAULT_OPTIONS, ...options }

  if (!instance) {
    initInstance()
  }
  instance.message = msg
  instance.dialogType = dialogType
  instance.maxlength = _options.maxlength
  instance.value = _options.defaultValue
  instance.originalValue = _options.defaultValue
  instance.validations = _options.validations
  instance.dbData = _options.dbData
  instance.duplicatedError = _options.duplicatedError
  try {
    const { ok, cancel } = _options.buttons
    if (ok) {
      instance.okBtnText = ok.text
      instance.okBtnType = ok.type
    }
    if (cancel) {
      instance.cancelBtnText = cancel.text
      instance.cancelBtnType = cancel.type
    }
    Object.keys(DEFAULT_OPTIONS).forEach((key) => {
      if (['buttons'].includes(key)) return
      if (_options[key]) {
        instance[key] = _options[key]
      }
    })
  } catch (e) {
    console.error(e)
  }
  instance.callback = function (btnType, value, found) {
    let visible = false;
    switch (btnType) {
      case 'ok':
        if(!found) {
          resolve(value)
        }
        visible = found;
        break
      case 'cancel':
        visible = false;
        reject()
        break
    }
    instance.visible = visible;
  }

  document.querySelector('body').appendChild(instance.$el)

  Vue.nextTick(() => {
    instance.visible = true
  })
}

const PromptBox = function () { }

PromptBox.prompt = function (msg, options) {
  return new Promise((resolve, reject) => {
    showMessage(resolve, reject, 'prompt', msg, options)
  })
}
export { PromptBox }

export default PromptBox
