<template>
  <v-dialog
    v-model="openExplain"
    persistent
    content-class="border-dialog"
    max-width="600px"
    scrollable
    v-dialog-drag="{
      dialog: 'border-dialog',
    }"
    overlay-color="transparent"
    overlay-opacity="1"
  >
    <v-card>
      <v-card-title class="card-header py-2">
        <v-btn @click="close()" class="ml-auto" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <h2 class="h2-mobile">
          タスク色とアイコンは以下のように設定されています。
        </h2>
        <v-list class="explain">
          <v-list-item>
            <v-list-item-content
              :style="{
                backgroundColor: COLOR_DB_TASK,
                maxWidth: '30%',
                marginRight: '10%',
              }"
            >
              <v-list-item-title class="ml-2">タスク</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content> 標準工数タスク </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :style="{
                backgroundColor: COLOR_FREE_TASK,
                maxWidth: '30%',
                marginRight: '10%',
              }"
            >
              <v-list-item-title class="ml-2">タスク</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content> 標準工数以外タスク </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :style="{
                backgroundColor: COLOR_GRAY,
                maxWidth: '30%',
                marginRight: '10%',
                color: 'white',
              }"
            >
              <v-list-item-title class="ml-2">タスク</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content
              >休憩・イレギュラー勤怠（遅刻・半休など）</v-list-item-content
            >
          </v-list-item>
        </v-list>
        <div class="divider mt-1">
          <v-divider style="border-top: 1px solid gray"></v-divider>
        </div>
        <!-- <h2 class="h2-mobile">アイコンは以下のように設定されています。</h2> -->
        <v-list class="explain">
          <v-list-item>
            <v-list-item-content
              :style="{ maxWidth: '30%', marginRight: '10%' }"
            >
              <v-icon>$remandtask</v-icon>
            </v-list-item-content>
            <v-list-item-content> 差し戻しタスク </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogDragDirective from "../../../directives/dialogDrag";
import {
  COLOR_FREE_TASK,
  COLOR_DB_TASK,
  COLOR_GRAY,
} from "../../../constants/color";
export default {
  directives: {
    "dialog-drag": DialogDragDirective,
  },
  props: {
    show: Boolean,
  },
  watch: {
    show: {
      handler() {
        this.openExplain = true;
      },
    },
  },
  data() {
    return {
      openExplain: this.show,
      COLOR_DB_TASK: COLOR_DB_TASK,
      COLOR_FREE_TASK: COLOR_FREE_TASK,
      COLOR_GRAY: COLOR_GRAY,
    };
  },
  methods: {
    close() {
      this.openExplain = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .border-dialog {
  // border-radius: 30px;
  .v-card__text {
    padding-top: 10px;
    text-align: center;
    white-space: nowrap;
    .v-list-item {
      padding: 4px 5px !important;
    }
    .v-list-item__content {
      text-align: left;
      padding: 8px 0 !important;
    }
    .explain {
      padding-left: 5px;
      width: 85%;
      margin: 0 auto;
    }
    .divider {
      padding: 0 9%;
    }
  }

  @media only screen and (max-width: 600px) {
    min-width: 340px;
    .v-card__text {
      padding-left: 20px;
      padding-right: 5px;
      .h2-mobile {
        font-size: medium;
        word-break: break-all;
        white-space: normal;
      }
      .v-list-item__content {
        word-break: break-all;
        white-space: normal;
        margin-right: 8% !important;
      }
      .explain {
        padding-left: 0px;
        width: 100%;
        margin: 0 auto;
      }
      .divider {
        padding: 0 5% 0 2%;
      }
    }
  }
}
</style>
<style scoped>
.v-dialog.v-dialog--active .v-card__title {
  cursor: grab;
}
</style>