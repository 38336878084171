<template>
  <v-list class="overflow-y-auto" max-height="50vh">
    <v-list-item-group>
      <v-list-item
        @click="groupChanged(item)"
        v-for="item in items"
        :key="item.id"
      >
        <v-list-item-content class="text-truncate max-w-group">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-title v-bind="attrs" v-on="on">
                {{ item.name }}
              </v-list-item-title>
            </template>
            <span class="text-truncate max-w-group" > {{ item.name }}</span>
          </v-tooltip>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {
  name: "GroupItem",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    parent: {},
  },
  methods: {
    groupChanged(item) {
      let value = {
        department: {
          id: this.parent.id,
          name: this.parent.name,
        },
        group: item,
      };
      this.$emit("groupChanged", value);
    },
  },
};
</script>
<style lang="scss" scoped>
.max-w-group {
  max-width: 275px;
}
</style>
