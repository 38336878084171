<template>
  <v-dialog
    v-model="openDbTask"
    persistent
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.mobile"
    scrollable
    content-class="atualndbTask"
    v-dialog-drag="{
      dialog: 'atualndbTask',
    }"
    overlay-color="transparent"
    overlay-opacity="1"
  >
    <v-card>
      <v-card-title class="card-header py-2">
        <v-btn @click="close()" class="ml-auto" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="card-content">
        <v-row class="py-2">
          <v-col cols="12" class="py-0 d-flex align-center">
            <v-icon v-if="task.is_favorite" small>$favoriteactive</v-icon>
            <v-icon v-else small>$favoriteinactive</v-icon>
            <span class="py-2 ml-2 work-break">{{ task.name }}</span>
            <div class="detailCopyActualText">
              <v-text-field
                maxlength="40"
                v-model="task.detail"
                placeholder="タスク詳細を追加"
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="12" class="py-0 my-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row class="py-0 my-2">
          <v-col class="d-flex align-center">
            <v-icon small class="yellow--text">$taskexplanation</v-icon>
            <span class="ml-2 work-break">{{ task.description }}</span>
          </v-col>
        </v-row>

        <v-row class="py-0">
          <v-col cols="12">
            <v-icon small>$taskcategory</v-icon>
            <span class="ml-2">{{ task.category_primary_name }}</span>
          </v-col>
          <v-col cols="12">
            <span class="ml-7">{{ task.category_secondary_name }}</span>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col class="d-flex align-center">
            <v-icon small>$taskType</v-icon>
            <span class="ml-2 work-break"
              >{{ labelType }} : {{ task.task_type_name || "" }}</span
            >
          </v-col>
        </v-row>

        <v-row
          class="py-0 my-0 align-baseline"
          v-if="
            (task.is_split_task === 0 && task.is_split_task_completed === 1) ||
            (task.is_split_task === 1 &&
              task.is_split_task_completed === 1 &&
              task.total_actual_cost === 0)
          "
        >
          <v-col cols="3" class="py-0 align-self-start mt-5">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskworktime</v-icon>
            <span class="ml-2">日次実績</span></v-col
          >
          <v-col cols="9" class="py-0 d-flex align-start align-self-start">
            <v-text-field
              type="number"
              max="999"
              min="1"
              :error-messages="costErrors"
              suffix="m/回"
              @keydown="checkMaxLength('cost', $event)"
              @input.native="updateCost"
              @input="changeCost()"
              v-model="task.cost"
              class="w-90"
            >
            </v-text-field>
            <span class="mx-2 mt-5">×</span>
            <v-text-field
              :error-messages="countErrors"
              type="number"
              suffix="回"
              max="999"
              min="1"
              @input.native="updateCount"
              @keydown="checkMaxLength('count', $event)"
              @input="changeCount()"
              v-model="task.count"
              class="w-60 align-self-start"
            >
            </v-text-field>
            <span class="mx-2 mt-5">=</span>
            <v-text-field
              readonly
              type="number"
              class="w-90 align-self-start"
              :value="task.cost * task.count"
              suffix="m"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0 my-0">
            <div class="ml-7">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="work-break">{{ task.unit || '' }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="py-0 my-0" v-else>
          <v-col>
            <div class="d-flex align-center">
              <span style="margin-left: -16px" class="red--text mr-2">*</span>
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2">日次実績</span>
              <span class="ml-10 align-baseline">
                <v-text-field
                  type="number"
                  class="w-95"
                  max="999"
                  min="1"
                  :error-messages="costErrors"
                  suffix="m"
                  @keydown="checkMaxLength('cost', $event)"
                  @input.native="updateCost"
                  @input="changeCost()"
                  v-model="task.cost"
                ></v-text-field>
              </span>
            </div>
            <div class="d-flex">
              <span class="ml-7">累計実績</span>
              <span class="ml-1">{{ task.cost }} m</span>
              <span class="ml-1 mr-1">/</span>
              <span class="">{{ task.total_cost }} m</span>
            </div>
            <div class="ml-7">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="work-break">{{ task.unit || '' }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0">
          <v-col class="py-0 d-flex align-center">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskstartendtime</v-icon>
            <div class="ml-2 d-flex align-center">
              <v-text-field
                v-model="task.start_time"
                :error-messages="startTimeErrors"
                maxlength="5"
                @input="changeStartTime()"
                @blur="formatStartTime($event)"
                @keyup.enter.native="formatStartTime($event)"
                type="text"
              ></v-text-field>
              <span class="mx-2">~</span>
              <v-text-field
                class="align-self-start"
                v-model="task.end_time"
                readonly
                :error-messages="endTimeErrors"
                maxlength="5"
                type="text"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center py-0">
            <v-radio-group
              v-model="task.is_split_task_completed"
              :disabled="+task.count !== 1"
            >
              <v-radio
                v-bind:value="0"
                :label="'タスクを一部着手して後日継続予定'"
              >
              </v-radio>
              <span class="ml-8">
                ※チェックを付けて保存した場合、「未完了タスク」に登録されます
              </span>
              <br />
              <v-radio v-bind:value="1" :label="'タスクが全て完了'"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <!-- <v-row class="py-0 my-0">
          <v-col class="py-0">
            <div class="d-flex align-center">
              <v-icon small>$output</v-icon>
              <span class="mx-2">アウトプット</span>
              <v-checkbox
                readonly
                v-model="task.is_need_output"
                :label="!!task.is_need_output ? '必要' : '不要'"
              ></v-checkbox>
            </div>
            <div class="ml-2 py-0" v-if="task.is_need_output">
              アウトプット内容： {{ task.output_detail }}
            </div>
          </v-col>
        </v-row> -->

        <v-row class="py-0">
          <v-col class="d-flex align-center">
            <v-icon small class="mr-2">$tag</v-icon>
            <span v-if="!hasTag">無し</span>
            <v-container v-else justify="space-around">
              <v-sheet class="px-1">
                <v-chip-group mandatory>
                  <v-tooltip bottom v-for="tag in task.tags" :key="tag.id">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on">
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 100px"
                        >
                          {{ tag.name }}
                        </span>
                      </v-chip>
                    </template>
                    <span>{{ tag.name }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>

        <!-- <v-row class="py-0">
          <v-col cols="12" class="d-flex align-center">
            <v-icon small>$outputsignoncalendar</v-icon>
            <v-btn
              @click="attachFile()"
              :loading="isSelecting"
              text
              color="ligth"
            >
              添付ファイルを追加
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              multiple
              @change="fileChanged"
            />
            <span
              >添付ファイル
              <span class="ml-5">{{ task.files.length }}件</span></span
            >
          </v-col>

          <v-col cols="12" class="py-0" v-if="hasFile">
            <v-virtual-scroll :items="task.files" :item-height="40" height="80">
              <template v-slot:default="{ item }">
                <v-list-item class="ml-4">
                  <v-list-item-icon>
                    <v-icon small>$attachedfile</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action @click="deleteAttachFile(item)">
                    <v-icon small>$delete</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-col>
        </v-row> -->

        <v-row class="py-0 my-0">
          <v-col v-if="!showComment">
            <v-icon small>$comment</v-icon>
            <v-btn text color="ligth" @click="updateComment()">
              コメントを追加
            </v-btn>
          </v-col>

          <v-col v-else class="py-0">
            <v-icon small>$comment</v-icon>
            <v-textarea
              class="ml-2 mt-0"
              v-model="userComment"
              placeholder="コメントを追加"
              counter
              maxlength="512"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          tile
          class="ml-auto"
          depressed
          color="primary"
          @click="createTask()"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { backMixin } from "../../../mixins/back";
import { taskMixin } from '@/mixins/task.js';
import { mapGetters } from "vuex";
import {
  UPLOAD_ATTACH_FILE_API,
  ACTUAL_TASK_API,
} from "../../../constants/api";
import {
  timeValidator,
  compareDateValidator,
  workTimeValidator,
} from "../../../helpers/validator";
import { debounce_leading, isEmpty } from "../../../helpers/helpers";
import {
  toMinute,
  toHHmm,
  toMinuteForHHmm,
  toHHmmFormat,
  formatDateTime,
  parseDateTime,
} from "../../../helpers/datetime-helpers";
import axios from "axios";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC1004, FC805 } from "../../../constants/fc";
import { yyyyMMddHHmm2, yyyyMMddHHmmss3 } from "../../../constants/df";
import DialogDragDirective from "../../../directives/dialogDrag";
export default {
  props: {
    show: Boolean,
    iactualId: String,
    inputTask: {},
  },
  mixins: [validationMixin, backMixin, taskMixin],
  directives: {
    "dialog-drag": DialogDragDirective,
  },
  validations: {
    task: {
      cost: {
        required,
        workCompare: workTimeValidator("count"),
      },
      count: {
        required,
        workCompare: workTimeValidator("cost"),
      },
      start_time: { required, timeValidator },
      end_time: {
        required,
        timeValidator,
        compareDate: compareDateValidator("start_time"),
      },
    },
  },
  data() {
    return {
      isSelecting: false,
      openDbTask: this.show,
      isEditing: false,
      showComment: false,
      isDivided: 0,
      userComment: "",
      actualId: this.iactualId,
      task: {
        category_secondary_id: null,
        is_favorite: false,
        is_need_output: false,
        start_time: this.startTime,
        end_time: this.endTime,
        files: [],
        tags: [],
        comments: [],
      },
    };
  },
  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },
  watch: {
    show() {
      this.openDbTask = true;
      this.isEditing = false;
      this.userComment = "";
      this.task = {
        ...this.task,
        ...this.inputTask,
        ...{
          is_favorite: false,
          comments: [],
          files: [],
          tags: [],
          //start_time: "",
          //end_time: "",
          total_actual_cost: 0,
        },
      };
      this.$v.$reset();
      this.showComment = false;
      this.actualId = this.iactualId;
    },

    iactualId: {
      handler(val) {
        this.actualId = val;
      },
    },

    task: {
      handler() {
        this.isEditing = true;
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
  },
  computed: {
    ...mapGetters("common", ["loginInfo"]),
    ...mapGetters("save", ["getChangeFlag"]),
    countErrors() {
      const errors = [];
      if (this.$v.task.count.$dirty) {
        !this.$v.task.count.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.count.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    endTimeErrors() {
      const errors = [];
      if (this.$v.task.end_time.$dirty) {
        !this.$v.task.end_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.end_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
        !this.$v.task.end_time.compareDate &&
          errors.push(this.$t("label.error.end_time"));
      }

      return errors;
    },
    startTimeErrors() {
      const errors = [];
      if (this.$v.task.start_time.$dirty) {
        !this.$v.task.start_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.start_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    costErrors() {
      const errors = [];
      if (this.$v.task.cost.$dirty) {
        !this.$v.task.cost.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.cost.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    hasFile() {
      return this.task && this.task.files && this.task.files.length > 0;
    },
    hasTag() {
      return this.task && this.task.tags && this.task.tags.length > 0;
    },
  },
  methods: {
    checkMaxLength(type, $event) {
      if (
        $event.keyCode === 8 ||
        $event.keyCode === 46 ||
        $event.keyCode === 38 ||
        $event.keyCode === 40 ||
        $event.keyCode === 37 ||
        $event.keyCode === 39
      )
        return true;
      if (type === "cost") {
        if (this.$v.task.cost.required) {
          if (this.task.cost.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      } else {
        if (this.$v.task.count.required) {
          if (this.task.count.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      }
    },

    changeCount() {
      if (!this.$v.task.count.$invalid && !this.$v.task.start_time.$invalid) {
        this.calculateTime();
      }
    },

    changeCost() {
      if (!this.$v.task.count.$invalid && !this.$v.task.start_time.$invalid) {
        this.calculateTime();
      }
    },
    updateCost(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.cost = value;
      } else {
        this.task.cost = value.slice(0, 3);
      }
      this.$forceUpdate();
    },
    updateCount(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.count = value;
      } else {
        this.task.count = value.slice(0, 3);
      }
      this.$forceUpdate();
    },
    changeStartTime() {
      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        let hasColon = this.task.start_time.indexOf(":") >= 0;
        if (hasColon) this.calculateTime();
        else this.calculateTimeForHHmm();
      }
    },

    formatStartTime($event) {
      if (!this.$v.task.start_time.$invalid) {
        const value = $event.target.value;
        let hasColon = value.indexOf(":") >= 0;
        if (!hasColon) {
          this.task.start_time = toHHmmFormat(value);
        }
      }
    },

    getCommentDate(date) {
      return formatDateTime(
        parseDateTime(date, yyyyMMddHHmmss3),
        yyyyMMddHHmm2
      );
    },

    updateComment() {
      this.showComment = true;
    },

    createTask: debounce_leading(async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        await this.uploadFile();

        let params = [
          {
            ...this.task,
            start_time: (this.task.start_time.replace(":", "") + "00").padStart(
              6,
              "0"
            ),
            end_time: (this.task.end_time.replace(":", "") + "00").padStart(
              6,
              "0"
            ),
            files: this.task.files.map((x) => {
              return { id: x.id };
            }),
            is_completed: 0,
            count: +this.task.count,
            cost: +this.task.cost,
            is_remand: 0,
            comments:
              !isEmpty(this.userComment) && this.showComment
                ? [
                    {
                      user_id: this.loginInfo.user_id,
                      comment: this.userComment,
                    },
                  ]
                : [],
            is_favorite: +this.task.is_favorite,
            is_need_output: !!this.task.is_need_output === true ? 1 : 0,
            output_detail: this.task.is_need_output
              ? this.task.output_detail
              : null,
            detail: this.task.detail,
            split_task_id:
              +this.task.count === 1 && +this.task.is_split_task_completed === 0
                ? null
                : null,
            is_split_task_completed: this.task.is_split_task_completed,
            is_split_task:
              +this.task.count === 1 && +this.task.is_split_task_completed === 0
                ? 1
                : 0,
          },
        ];
        try {
          const { data } = await axios.post(ACTUAL_TASK_API(this.actualId), {
            actual_tasks: params,
          });
          this.$store.dispatch("save/setChangeFlag", false);
          this.openDbTask = false;
          this.task.id = data.result[0].id;
          this.task.standard_task_id = data.result[0].standard_task_id;
          this.task.standard_task_revision =
            data.result[0].standard_task_revision;
          this.task.split_task_source_id = data.result[0].split_task_source_id;
          this.task.is_split_task =
            this.task.count === 1 && this.task.is_split_task_completed === 0
              ? 1
              : 0;
          this.$emit("createTaskSuccess", this.task);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC805, status);
        }
      }
    }),

    calculateTime() {
      let startTime = toMinute(this.task.start_time, ":");
      let endTime = toMinute(this.task.end_time, ":");
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },

    calculateTimeForHHmm() {
      let startTime = toMinuteForHHmm(this.task.start_time);
      let endTime = toMinuteForHHmm(this.task.end_time);
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },

    deleteAttachFile(item) {
      this.$confirm(this.$t("label.confirm.delete_attach"), {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
        rtl: true,
      })
        .then(() => {
          this.task.files = this.task.files.filter((x) => x.name !== item.name);
        })
        .catch(() => {});
    },

    attachFile() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },

    async uploadFile() {
      let formData = new FormData();
      for (var i = 0; i < this.task.files.length; i++) {
        formData = new FormData();
        formData.append("file", this.task.files[i].fileContent);
        try {
          const { data } = await axios.post(
            UPLOAD_ATTACH_FILE_API(),
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          this.task.files[i].id = data.result.file_id;
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC1004, status);
        }
      }
    },

    async fileChanged(e) {
      let countFileError = 0;
      let selectedFiles = e.target.files;
      if (this.task.files.length + selectedFiles.length > 10) {
        this.$alert(this.$t("label.error.max_attach"), {
          width: "500px",
        });
      } else {
        for (var i = 0; i < selectedFiles.length; i++) {
          let fileSize = selectedFiles[i].size / 1024 / 1024;
          if (fileSize > 10) {
            countFileError = countFileError + 1;
          } else {
            this.task.files.push({
              fileContent: selectedFiles[i],
              name: selectedFiles[i].name,
              id: i,
            });
          }
        }
        if (countFileError > 0) {
          this.$toast(
            this.$t("label.error.max_byte", { 0: `${countFileError}` }),
            3000
          );
        }
      }
      this.$refs.uploader.value = null;
    },

    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.openDbTask = false;
          }, 100);
        },
        () => {}
      );
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.$v.$reset();
          this.openDbTask = false;
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}

.align-items-baseline {
  align-items: baseline;
}

.w-120 {
  width: 100px;
}
.w-90 {
  width: 100px;
}
.v-dialog.v-dialog--active .v-card__title {
  cursor: grab;
}
.w-60 {
  width: 60px;
}
.w-95 {
  width: 95px;
}
.w-95,
.w-60,
.w-90 {
  .v-text-field__details {
    width: 100%;
  }
}

.detailCopyActualText {
  margin-left: auto;
  margin-right: 0;
  min-width: 200px;
  max-width: 250px;
}
.work-break {
  word-break: break-all;
}
</style>
