<template>
  <v-list-item-group>
    <v-list-item @click.stop>
      <v-list-item-content>
        {{ name }}
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      :disabled="isDisable"
      @click="selectTemplate(item)"
      v-for="item in templates"
      :key="item.id"
    >
      <v-list-item-action> </v-list-item-action>
      <v-list-item-content>
        <span>
          {{ item.name }}
          <v-btn v-show="isEdit" icon @click="editTemplate($event, item)">
            <v-icon small>$edit</v-icon>
          </v-btn>
        </span>
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
export default {
  name: "ChildTemplate",
  props: {
    templates: {
      type: Array,
      default: () => [],
    },
    name: String,
    isDisable: Boolean,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectTemplate(item) {
      this.$emit("selectedTemplate", item);
    },
    editTemplate($event, item) {
      $event.stopPropagation();
      this.$emit("editedTemplate", item);
    },
  },
};
</script>

<style>
</style>
