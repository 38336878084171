<template>
  <v-container class="container--fluid" :style="cssProps">
    <div class="content-row is-panel-collapsed">
      <div class="my-column-full">
        <v-row v-if="$vuetify.breakpoint.mobile">
          <v-col>
            <div class="d-flex align-center">
              <span class="mr-2 text-right __label">対象</span>
              <v-combobox
                v-if="expand === 0"
                class="ml-2"
                item-text="name"
                item-value="id"
                v-model="selectUser"
                hide-details
                solo
                :items="members"
                :menu-props="{
                  allowOverflow: true,
                }"
                @change="membersChange($event)"
              >
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-content>
                      <span>{{ item.name }}</span>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </div>
            <div class="py-2 d-flex align-center" v-if="expand === 0">
              <span class="mr-2 text-right __label">期間</span>
              <v-icon dark @click="selectRangeDate()" class="ml-2"
                >$calendar</v-icon
              >
            </div>
            <div class="pt-0 pb-2 d-flex align-center" v-if="expand === 0">
              <span class="mr-2 __label">表示切替</span>
              <v-select
                width="10"
                class="ml-2 select"
                :value="calendarOptions.slotDuration"
                :items="slotDurationItems"
                @change="reloadSlotDuration"
                hide-details
                solo
              ></v-select>
            </div>
            <div class="py-1 btn-width">
              <v-btn class="mr-2" @click="moveToSunday()">
                <v-icon color="#7F7F8F">mdi-chevron-double-left</v-icon>
              </v-btn>
              <v-btn class="mr-2" @click="moveToDay()">
                <v-icon color="#7F7F8F">mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn class="mr-2" @click="toDay()"> 今日 </v-btn>
              <v-btn class="mr-2" @click="moveToDay(true)">
                <v-icon color="#7F7F8F">mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn @click="moveToSunday(true)">
                <v-icon color="#7F7F8F">mdi-chevron-double-right</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-else
          class="header-items"
          :class="{ 'hide-header-items': expand !== 0 }"
        >
          <v-col v-show="expand === 0" class="pt-0 pb-5 my-0">
            <div class="d-flex flex-wrap align-header no-padding-print">
              <div>
                <label class="font-weight-bold" for="fname">対象</label><br />
                <v-combobox
                  item-text="name"
                  item-value="id"
                  v-model="selectUser"
                  hide-details
                  solo
                  :items="members"
                  @change="membersChange($event)"
                  id="fname"
                ></v-combobox>
              </div>
              <div class="pl-3">
                <label class="ml-2 font-weight-bold" for="fRange">期間</label
                ><br />
                <v-btn
                  outlined
                  text
                  color="light"
                  @click="selectRangeDate()"
                  class="ml-2"
                  id="fRange"
                >
                  {{ formatStartDate }} ~ {{ formatEndDate }}
                </v-btn>
              </div>
              <div class="pa-2 py-0 no-print">
                <div class="v-center d-lex ml-3">
                  <span class="mr-2">表示切替</span>
                  <v-select
                    width="10"
                    class="mr-2 select"
                    :value="calendarOptions.slotDuration"
                    :items="slotDurationItems"
                    @change="reloadSlotDuration"
                    hide-details
                    solo
                  ></v-select>
                </div>
              </div>
              <div class="pa-2 py-0 ml-auto no-print">
                <v-btn class="mr-2" @click="moveToSunday()">
                  <v-icon color="#7F7F8F">mdi-chevron-double-left</v-icon>
                </v-btn>
                <v-btn class="mr-2" @click="moveToDay()">
                  <v-icon color="#7F7F8F">mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn class="mr-2" @click="toDay()"> 今日 </v-btn>
                <v-btn class="mr-2" @click="moveToDay(true)">
                  <v-icon color="#7F7F8F">mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn @click="moveToSunday(true)">
                  <v-icon color="#7F7F8F">mdi-chevron-double-right</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <v-row>
          <v-col
            class="print-calendar"
            :class="{
              'fullcalendar-itokuro-expand':
                !$vuetify.breakpoint.mobile && expand !== 0,
              'fullcalendar-itokuro': !$vuetify.breakpoint.mobile,
              'fullcalendar-itokuro-mobile-expand':
                $vuetify.breakpoint.mobile && expand !== 0,
              'fullcalendar-itokuro-mobile': $vuetify.breakpoint.mobile,
              'fullcalendar-itokuro--five-min':
                calendarOptions.slotDuration === `00:05:00`,
              'fullcalendar-itokuro--thirty-min':
                calendarOptions.slotDuration === `00:30:00`,
              'fullcalendar-itokuro--fifteen-min':
                calendarOptions.slotDuration === `00:15:00`,
              'fullcalendar-itokuro--sixty-min':
                calendarOptions.slotDuration === `00:60:00`,
            }"
          >
            <FullCalendar
              ref="fullCalendar"
              :options="calendarOptions"
              class="viewCalendar"
            >
              <template v-slot:eventContent="arg">
                <v-row>
                  <v-col
                    class="__task-content d-flex"
                    :class="[
                      {
                        normalText:
                          (isDbTask(arg.event.extendedProps) &&
                            isDbTaskByType(arg.event.extendedProps)) ||
                          isFreeTask(arg.event.extendedProps),
                      },
                      {
                        'flex-column': isBreakLineTaskContent(
                          arg.event.extendedProps.cost,
                          arg.event.extendedProps.count
                        ),
                      },
                    ]"
                  >
                    <div
                      class="__task-info d-flex align-center"
                      :class="{
                        'w-100': isBreakLineTaskContent(
                          arg.event.extendedProps.cost,
                          arg.event.extendedProps.count
                        ),
                      }"
                    >
                      <span
                        v-if="
                          arg.event.extendedProps.is_remand_previous === 1 ||
                          arg.event.extendedProps.is_remand === 1
                        "
                        class="__remand"
                      >
                        <v-icon class="remandTaskIcon" small>
                          $remandtask
                        </v-icon>
                      </span>
                      <!-- ./Remand -->
                      <span class="name-detail text-truncate">
                        {{ arg.event.title }}
                        {{ arg.event.extendedProps.detail }}
                      </span>
                    </div>
                    <div
                      :class="{
                        'align-self-center':
                          arg.event.extendedProps.cost *
                            arg.event.extendedProps.count <
                          30,
                      }"
                    >
                      <span
                        v-if="arg.event.extendedProps.count === 1"
                        class="d-flex align-center"
                      >
                        <span
                          class="task-time text-truncate"
                          :class="{
                            'time-text-margin':
                              arg.event.extendedProps.cost *
                                arg.event.extendedProps.count >=
                              30,
                          }"
                        >
                          {{ arg.event.extendedProps.cost }}m
                        </span>
                        <span v-if="isDbTask(arg.event.extendedProps)">
                          <span v-if="isBeginnerSkill(arg.event.extendedProps)">
                            <v-icon
                              class="beginnerTaskIcon"
                              :class="[
                                {
                                  'position-inside':
                                    calendarOptions.slotDuration ===
                                      '00:60:00' &&
                                    arg.event.extendedProps.cost *
                                      arg.event.extendedProps.count ===
                                      30,
                                },
                              ]"
                            >
                              $beginnertask
                            </v-icon>
                          </span>
                          <span v-if="isExpertSkill(arg.event.extendedProps)">
                            <v-icon
                              class="expertTaskIcon"
                              :class="[
                                {
                                  'position-inside':
                                    calendarOptions.slotDuration ===
                                      '00:60:00' &&
                                    arg.event.extendedProps.cost *
                                      arg.event.extendedProps.count ===
                                      30,
                                },
                              ]"
                            >
                              $experttask
                            </v-icon>
                          </span>
                        </span>
                      </span>
                      <span v-else class="d-flex align-center">
                        <span
                          :class="[
                            (calendarOptions.slotDuration === '00:60:00' ||
                              calendarOptions.slotDuration === '00:30:00') &&
                            arg.event.extendedProps.cost *
                              arg.event.extendedProps.count <=
                              30
                              ? arg.event.extendedProps.cost *
                                  arg.event.extendedProps.count ===
                                30
                                ? 'time-text-truncate time-text-margin'
                                : 'time-text-truncate'
                              : 'time-break task-time text-truncate',
                          ]"
                        >
                          {{
                            arg.event.extendedProps.cost *
                            arg.event.extendedProps.count
                          }}m({{ arg.event.extendedProps.cost }}m×{{
                            arg.event.extendedProps.count
                          }})
                        </span>
                        <span v-if="isDbTask(arg.event.extendedProps)">
                          <span v-if="isBeginnerSkill(arg.event.extendedProps)">
                            <v-icon
                              class="beginnerTaskIcon"
                              :class="[
                                {
                                  'position-inside':
                                    calendarOptions.slotDuration ===
                                      '00:60:00' &&
                                    arg.event.extendedProps.cost *
                                      arg.event.extendedProps.count ===
                                      30,
                                },
                              ]"
                            >
                              $beginnertask
                            </v-icon>
                          </span>
                          <span v-if="isExpertSkill(arg.event.extendedProps)">
                            <v-icon
                              class="expertTaskIcon"
                              :class="[
                                {
                                  'position-inside':
                                    calendarOptions.slotDuration ===
                                      '00:60:00' &&
                                    arg.event.extendedProps.cost *
                                      arg.event.extendedProps.count ===
                                      30,
                                },
                              ]"
                            >
                              $experttask
                            </v-icon>
                          </span>
                        </span>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </template>
            </FullCalendar>

            <FullCalendar
              ref="printCalendar"
              :options="printCalendarOptions"
              class="printCalendar"
              v-if="!isMobile"
            >
              <template v-slot:eventContent="arg">
                <v-row>
                  <v-col
                    class="__task-content d-flex mt-0"
                    :class="[
                      {
                        normalText:
                          (isDbTask(arg.event.extendedProps) &&
                            isDbTaskByType(arg.event.extendedProps)) ||
                          isFreeTask(arg.event.extendedProps),
                      },
                      {
                        'flex-column': isBreakLineTaskContent(
                          arg.event.extendedProps.cost,
                          arg.event.extendedProps.count
                        ),
                      },
                    ]"
                  >
                    <div
                      class="__task-info d-flex align-center"
                      :class="{
                        'w-100': isBreakLineTaskContent(
                          arg.event.extendedProps.cost,
                          arg.event.extendedProps.count
                        ),
                      }"
                    >
                      <span
                        v-if="
                          arg.event.extendedProps.is_remand_previous === 1 ||
                          arg.event.extendedProps.is_remand === 1
                        "
                        class="__remand"
                      >
                        <v-icon class="remandTaskIcon" small>
                          $remandtask
                        </v-icon>
                      </span>
                      <!-- ./Remand -->
                      <span class="name-detail text-truncate">
                        {{ arg.event.title }}
                        {{ arg.event.extendedProps.detail }}
                      </span>
                    </div>
                    <div>
                      <span
                        v-if="arg.event.extendedProps.count === 1"
                        class="d-flex align-center"
                      >
                        <span class="task-time text-truncate">
                          {{ arg.event.extendedProps.cost }}m
                        </span>
                        <span v-if="isDbTask(arg.event.extendedProps)">
                          <span v-if="isBeginnerSkill(arg.event.extendedProps)">
                            <v-icon class="beginnerTaskIcon">
                              $beginnertask
                            </v-icon>
                          </span>
                          <span v-if="isExpertSkill(arg.event.extendedProps)">
                            <v-icon class="expertTaskIcon">
                              $experttask
                            </v-icon>
                          </span>
                        </span>
                      </span>
                      <span v-else class="d-flex align-center">
                        <span
                          :class="[
                            (calendarOptions.slotDuration === '00:60:00' ||
                              calendarOptions.slotDuration === '00:30:00') &&
                            arg.event.extendedProps.cost *
                              arg.event.extendedProps.count <=
                              30
                              ? 'time-text-truncate'
                              : 'time-break task-time text-truncate',
                          ]"
                        >
                          {{
                            arg.event.extendedProps.cost *
                            arg.event.extendedProps.count
                          }}m({{ arg.event.extendedProps.cost }}m×{{
                            arg.event.extendedProps.count
                          }})
                        </span>
                        <span v-if="isDbTask(arg.event.extendedProps)">
                          <span v-if="isBeginnerSkill(arg.event.extendedProps)">
                            <v-icon class="beginnerTaskIcon">
                              $beginnertask
                            </v-icon>
                          </span>
                          <span v-if="isExpertSkill(arg.event.extendedProps)">
                            <v-icon class="expertTaskIcon">
                              $experttask
                            </v-icon>
                          </span>
                        </span>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </template>
            </FullCalendar>
          </v-col>
        </v-row>
      </div>
    </div>
    <RangeSevenDate
      @change="changeDate($event)"
      v-model="rangeDate"
      :show="openRangeDate"
    ></RangeSevenDate>
    <ViewPlanTask
      :planId="planId"
      :show="showViewPlanTask"
      :taskId="taskId"
      :viewType="''"
    ></ViewPlanTask>
    <ViewActualTask
      :show="showViewActualTask"
      :actualId="actualId"
      :actualTaskId="actualTaskId"
      :viewType="''"
    >
    </ViewActualTask>
    <BreakTime
      :can-view="true"
      :show="showBreakTime"
      :start="startBreakTime"
      :end="endBreakTime"
      :isView="true"
    ></BreakTime>
  </v-container>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import RangeSevenDate from "../../../components/RangeSevenDate";
import ViewPlanTask from "../modal/ViewTask";
import BreakTime from "../modal/BreakTime";
import ViewActualTask from "../../actual/modal/ViewTask";
import {
  formatDateTime,
  parseDateTime,
} from "../../../helpers/datetime-helpers";
import { deepClone, isEmpty, isEmptyNumber } from "../../../helpers/helpers";
import {
  GET_WEEKLY_SCHEDULE_API,
  GET_WEEKLY_ACTUAL_API,
  GET_MEMBERS_API,
} from "../../../constants/api";
import { mapGetters } from "vuex";
import * as Role from "../../../constants/role";
import axios from "axios";
import * as SystemConstant from "../../../constants/system";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC108, FC829, FC830 } from "../../../constants/fc";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import {
  yyyyMMdd1,
  yyyyMMdd2,
  yyyyMMdd3,
  MMddEEEEE2,
  yyyyMMddEEEEE3,
  yyyyMMddHHmm1,
  yyyyMMddHHmmss1,
  yyyyMMddHHmmss2,
  HHmmss1,
  HHmmss2,
  HHmm2,
} from "../../../constants/df";
import {
  COLOR_GRAY,
  COLOR_FREE_TASK,
  COLOR_DB_TASK,
} from "../../../constants/color";
import { adjustMinEnd } from "../../../helpers/system";
import Cookies from "js-cookie";
import {
  CALENDAR_PRINT_LENGHT_MM,
  DEFAULT_SCALE,
  SLOT_30_SCALE,
  SLOT_60_SCALE,
} from "../../../constants/calendar";
import { calendarMixin } from "@/mixins/calendar";
import { queryRouterMixin } from "@/mixins/queryRouter.js";
export default {
  layout: "default",
  middleware: Role.AUTH,
  props: {
    isSchedule: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    FullCalendar,
    RangeSevenDate,
    ViewPlanTask,
    ViewActualTask,
    BreakTime,
  },
  mixins: [calendarMixin, queryRouterMixin],
  data() {
    return {
      COLOR_GRAY: COLOR_GRAY,
      slotDurationItems: [
        { value: "00:05:00", text: "5分" },
        { value: "00:15:00", text: "15分" },
        { value: "00:30:00", text: "30分" },
        { value: "00:60:00", text: "60分" },
      ],
      scrollToTime: "",
      expand: 0,
      selectUser: "",
      menuDate: false,
      members: [],
      selectedDate: formatDateTime(new Date()),
      userId: "",
      calendarId: "",
      breadcrumbs: [
        {
          text: "",
          href: "",
          disabled: true,
        },
        {
          text: "週次予定",
          href: "",
          disabled: true,
        },
      ],
      showViewPlanTask: false,
      taskId: null,
      planId: "",
      showViewActualTask: false,
      actualTaskId: null,
      actualId: "",
      showBreakTime: false,
      startBreakTime: "",
      endBreakTime: "",
      dateData: [],
      events: [],
      calendarOptions: {
        slotMinTime: "00:00:00",
        slotMaxTime: "22:00:00",
        slotDuration: "00:15:00",
        scrollTime: false,
        loading: this.setLoaded,
        // contentHeight: "auto",
        height: "parent",
        locale: "es",
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: false,
        //timeZone: "UTC",
        allDaySlot: false,
        // initialView: "timeGridDay",
        initialView: "timeGridWeek",
        firstDay: new Date().getDay(),
        editable: false,
        selectable: false,
        selectMirror: false,
        dayMaxEvents: true,
        weekends: true,
        dayHeaders: true,
        eventClick: this.handleEventClick,
        dayHeaderContent: this.handleHeaderContent,
        initialEvents: [],
        events: [],
        droppable: true, // this allows things to be dropped onto the calendar
        slotLabelFormat: {
          hour: "2-digit",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: false,
        },
        eventOrder: "start_time,id",
        eventResize: function (eventResizeInfo) {
          eventResizeInfo.revert();
        },
      },
      scrollTop: null,
      preSlotDuration: "00:15:00",
      min: 5,
      timeGridLength: 0,
      rangeDate: {},
      queryCalendar: {
        user_id: "",
        start_date: null,
        end_date: null,
      },
      openRangeDate: false,
      queryData: {},
      startWorkTime: "",
      endWorkTime: "",
    };
  },
  methods: {
    /**
     * Handle header content
     * @param {object} [arg]
     * @return {void}
     */
    handleHeaderContent(arg) {
      let hasDate;
      if(this.dateData.length > 0){
        hasDate = this.dateData.filter(x => x.date === formatDateTime(arg.date, yyyyMMdd1));
      }
      let italicEl = document.createElement("a");
      italicEl.className =
        "d-flex align-center text-decoration-none text-truncate";
      italicEl.innerHTML = formatDateTime(arg.date, MMddEEEEE2);
      let redirectDate = formatDateTime(arg.date, yyyyMMdd2);
      if (this.isSchedule) {
        if (!isEmpty(hasDate)) {
          italicEl.href = `/schedule?userId=${
            this.$route.query.userId || this.userId
          }&date=${redirectDate}`;
        }
        else {
          italicEl.style.pointerEvents = "none !important";
          italicEl.style.cursor = "default";
        }
      } else {
        if (!isEmpty(hasDate)) {
          italicEl.href = `/actual?uid=${
            this.$route.query.userId || this.userId
          }&date=${redirectDate}`;
        }
        else {
          italicEl.style.pointerEvents = "none !important";
          italicEl.style.cursor = "default";
        }
      }
      var clockImg = document.createElement("img");
      clockImg.className = "ml-1";
      clockImg.src = "/img/task_start_end_time.e8e598b6.png";
      clockImg.style = "font-size: 16px; height: 16px; width: 16px;";
      italicEl.appendChild(clockImg);
      let arrayOfDomNodes = [italicEl];
      return { domNodes: arrayOfDomNodes };
    },

    /**
     * Handle calendar event click
     * @param {object} [value]
     * @return {void}
     */
    handleEventClick(e) {
      const isBreaktime =
        e.event.backgroundColor === COLOR_GRAY && e.event.id === "";
      if (!isBreaktime) {
        if (this.isSchedule) {
          this.taskId = +e.event.id;
          this.planId = e.event.extendedProps.plan_id;
          this.showViewPlanTask = !this.showViewPlanTask;
        } else {
          this.actualTaskId = +e.event.id;
          this.actualId = e.event.extendedProps.actual_id;
          this.showViewActualTask = !this.showViewActualTask;
        }
      } else {
        this.showBreakTime = !this.showBreakTime;
        this.startBreakTime = formatDateTime(e.event.start, HHmm2);
        this.endBreakTime = formatDateTime(
          e.event.extendedProps.end_real,
          HHmm2
        );
        return;
      }
    },

    /**
     * When switching time display
     * @param {string} [value]
     * @return scroll, set time slot duration to browser
     */
    reloadSlotDuration(e) {
      if (e === "00:30:00") {
        this.min = 15;
      } else if (e === "00:60:00") {
        this.min = 18;
      } else {
        this.min = 5;
      }
      this.calendarOptions.events.map((x) => {
        x.start = x.start_real;
        x.end = formatDateTime(
          parseDateTime(
            x.date +
              " " +
              adjustMinEnd(x.start_time, x.end_time, HHmmss1, this.min), // adjustMinEnd HHmmss1
            yyyyMMddHHmmss1
          ),
          yyyyMMddHHmmss2
        );
      });
      let scrollTopPosition =
        document.getElementsByClassName("fc-scroller")[0].scrollTop;
      switch (true) {
        case this.preSlotDuration === "00:30:00":
          switch (true) {
            case e === "00:05:00":
              this.scrollTop = scrollTopPosition * 6;
              break;
            case e === "00:15:00":
              this.scrollTop = scrollTopPosition * 3;
              break;
            // e === "00:60:00"
            default:
              this.scrollTop = scrollTopPosition / 1.1;
              break;
          }
          break;
        case this.preSlotDuration === "00:05:00":
          switch (true) {
            case e === "00:30:00":
              this.scrollTop = scrollTopPosition / 6;
              break;
            case e === "00:15:00":
              this.scrollTop = scrollTopPosition / 2;
              break;
            // e === "00:60:00"
            default:
              this.scrollTop = scrollTopPosition / 6.5;
              break;
          }
          break;
        case this.preSlotDuration === "00:15:00":
          switch (true) {
            case e === "00:30:00":
              this.scrollTop = scrollTopPosition / 3;
              break;
            case e === "00:05:00":
              this.scrollTop = scrollTopPosition * 2;
              break;
            // e === "00:60:00"
            default:
              this.scrollTop = scrollTopPosition / 3.2;
              break;
          }
          break;
        // this.preSlotDuration === "00:60:00"
        default:
          switch (true) {
            case e === "00:30:00":
              this.scrollTop = scrollTopPosition * 1.1;
              break;
            case e === "00:15:00":
              this.scrollTop = scrollTopPosition * 3.2;
              break;
            // e === "00:05:00"
            default:
              this.scrollTop = scrollTopPosition * 6.5;
              break;
          }
          break;
      }
      this.calendarOptions.slotDuration = e;
      this.preSlotDuration = e;
      if (!isEmptyNumber(this.scrollTop)) {
        setTimeout(() => {
          const fcScroller = document.getElementsByClassName("fc-scroller")[0];
          if (!isEmptyNumber(this.scrollTop)) {
            fcScroller.scrollTop = this.scrollTop;
          }

          this.scrollTop = null;
        }, 0.0005);
      }
      Cookies.set("slotDurationItem" + this.loginInfo?.user_id, e, {
        expires: 365,
      });

      // change slot min max time
      let events = this.calendarOptions.events;
      this.adjustTasks(events, this.min, "24:00:00");
      this.changeSlotMinMax(this.calendarOptions, events, this.startWorkTime, this.endWorkTime);
    },

    /**
     * set loaded
     * @param {boolean} [value]
     * @return {void}
     */
    setLoaded(isLoading) {
      if (!isLoading && !isEmptyNumber(this.scrollTop)) {
        setTimeout(() => {
          const fcScroller = document.getElementsByClassName("fc-scroller")[0];
          if (!isEmptyNumber(this.scrollTop)) {
            fcScroller.scrollTop = this.scrollTop;
          }
          this.scrollTop = null;
        }, 0.0005);
      }
    },

    /**
     * Go to selected date
     * @return {void}
     */
    gotoDate(date) {
      this.$nextTick(() => {
        let calendarApi = this.$refs.fullCalendar.getApi();
        calendarApi.gotoDate(date);
        this.calendarOptions.firstDay = new Date(`${date}`).getDay();
        if (!this.isMobile) {
          let printCalendarApi = this.$refs.printCalendar.getApi();
          printCalendarApi.gotoDate(date);
          this.printCalendarOptions.firstDay = new Date(`${date}`).getDay();
        }
        const scrollTime = this.getTimeForScroll(
          this.calendarOptions,
          this.startWorkTime
        );
        calendarApi.scrollToTime(scrollTime);
      });
    },

    /**
     * Get data from API FC829
     * @return {void}
     */
    async getWeeklySchedules() {
      try {
        const qs = (params) =>
          Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join("&");

        //TODO CREATE API LINK
        let apiUrl = "";

        apiUrl = `${GET_WEEKLY_SCHEDULE_API()}?${qs({
          user_id: this.userId,
          start_date: +formatDateTime(this.queryCalendar.start_date, yyyyMMdd1),
          end_date: +formatDateTime(this.queryCalendar.end_date, yyyyMMdd1),
        })}`;

        let weeklySchedules = await axios.get(apiUrl);
        let data = weeklySchedules.data;
        let status = weeklySchedules.status;
        // Handle data
        if (status === STATUS_NO_CONTENT) {
          this.calendarOptions.events = [];
          this.gotoDate(this.queryCalendar.start_date);
          return;
        }

        // SET DATA
        this.startWorkTime = null;
        this.endWorkTime = null;
        let startTime;
        let endTime;
        let start;
        let startReal;
        let end;
        let endReal;
        let cost;
        let calendarId;
        this.calendarOptions.events = [];
        this.userId = data.result[0].user_id;
        this.selectUser = this.members.find((x) => x.id === this.userId);
        this.dateData = data.result;
        data.result.map((item) => {
          let calendar = item;
          let date = formatDateTime(
            parseDateTime(calendar.date, yyyyMMdd1),
            yyyyMMdd2
          );
          let planId = calendar.id;
          calendar.schedule_tasks.map((x) => {
            let event = {
              ...x,
              title: x.name,
              plan_id: planId,
              date: date,
              start: formatDateTime(
                parseDateTime(date + " " + x.start_time, yyyyMMddHHmmss1),
                yyyyMMddHHmmss2
              ),
              end: formatDateTime(
                parseDateTime(
                  date +
                    " " +
                    adjustMinEnd(x.start_time, x.end_time, HHmmss1, this.min), // adjustMinEnd HHmmss1
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmmss2
              ),
              end_real: formatDateTime(
                parseDateTime(date + " " + x.end_time, yyyyMMddHHmmss1),
                yyyyMMddHHmmss2
              ),
              start_real: formatDateTime(
                parseDateTime(date + " " + x.start_time, yyyyMMddHHmmss1),
                yyyyMMddHHmmss2
              ),
              check: !!x.is_remand,
              className:
                this.isDbTask(x) && !this.isSplitTask(x) ? "dbTask" : "",
              color: this.getColorSchedule(x),
            };
            this.calendarOptions.events.push(event);
          });
          calendarId = calendar.id;
          this.calendarId = calendarId;

          if (calendar.start_break_time && calendar.end_break_time) {
            startTime = calendar.start_break_time;
            endTime = calendar.end_break_time;
            start = formatDateTime(
              parseDateTime(
                date + " " + calendar.start_break_time,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            );
            startReal = formatDateTime(
              parseDateTime(
                date + " " + calendar.start_break_time,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            );
            end = formatDateTime(
              parseDateTime(
                date +
                  " " +
                  adjustMinEnd(
                    calendar.start_break_time,
                    calendar.end_break_time,
                    HHmmss1,
                    this.min
                  ), // adjustMinEnd HHmmss1
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            );
            endReal = formatDateTime(
              parseDateTime(
                date + " " + calendar.end_break_time,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            );
            cost = this.diffTime(
              calendar.start_break_time,
              calendar.end_break_time
            );
          

            // Break task
            let breakTask = {
              title: "休憩",
              date: date,
              start_time: startTime,
              end_time: endTime,
              start: start,
              start_real: startReal,
              end: end,
              end_real: endReal,
              color: COLOR_GRAY,
              check: false,
              count: 1,
              cost: cost,
            };

            if (+breakTask.cost * +breakTask.count !== 0)
              this.calendarOptions.events.push(breakTask);
          }
          // 開始時間
          if (!this.startWorkTime || this.startWorkTime > item.start_time){
              this.startWorkTime = item.start_time;
          }

          // 終了時間
          if (!this.endWorkTime || this.endWorkTime < item.end_time){
            this.endWorkTime = item.end_time;
          }
        });

        this.gotoDate(this.queryCalendar.start_date);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC829, status);
      } // END-TRY-CATCH
    },

    /**
     * Get data from API FC829
     * @return {void}
     */
    async getWeeklyActuals() {
      try {
        const qs = (params) =>
          Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join("&");

        //TODO CREATE API LINK
        let apiUrl = "";

        apiUrl = `${GET_WEEKLY_ACTUAL_API()}?${qs({
          user_id: this.userId,
          start_date: +formatDateTime(this.queryCalendar.start_date, yyyyMMdd1),
          end_date: +formatDateTime(this.queryCalendar.end_date, yyyyMMdd1),
        })}`;

        let weeklyActuals = await axios.get(apiUrl);
        let data = weeklyActuals.data;
        let status = weeklyActuals.status;
        // Handle data
        if (status === STATUS_NO_CONTENT) {
          this.calendarOptions.events = [];
          this.gotoDate(this.queryCalendar.start_date);
          return;
        }

        // SET DATA
        this.startWorkTime = null;
        this.endWorkTime = null;
        let startTime;
        let endTime;
        let start;
        let startReal;
        let end;
        let endReal;
        let cost;
        let calendarId;
        this.calendarOptions.events = [];
        this.userId = data.result[0].user_id;
        this.selectUser = this.members.find((x) => x.id === this.userId);
        this.dateData = data.result;
        data.result.map((item) => {
          let calendar = item;
          let actualId = calendar.id;
          let date = formatDateTime(
            parseDateTime(calendar.date, yyyyMMdd1),
            yyyyMMdd2
          );
          calendar.actual_tasks.map((x) => {
            let event = {
              ...x,
              title: x.name,
              actual_id: actualId,
              date: date,
              start: formatDateTime(
                parseDateTime(date + " " + x.start_time, yyyyMMddHHmmss1),
                yyyyMMddHHmmss2
              ),
              end: formatDateTime(
                parseDateTime(
                  date +
                    " " +
                    adjustMinEnd(x.start_time, x.end_time, HHmmss1, this.min), // adjustMinEnd HHmmss1
                  yyyyMMddHHmmss1
                ),
                yyyyMMddHHmmss2
              ),
              end_real: formatDateTime(
                parseDateTime(date + " " + x.end_time, yyyyMMddHHmmss1),
                yyyyMMddHHmmss2
              ),
              start_real: formatDateTime(
                parseDateTime(date + " " + x.start_time, yyyyMMddHHmmss1),
                yyyyMMddHHmmss2
              ),
              check: !!x.is_remand,
              className:
                this.isDbTask(x) && !this.isSplitTask(x) ? "dbTask" : "",
              color: this.getColorActual(x),
            };
            this.calendarOptions.events.push(event);
          });
          calendarId = calendar.id;
          this.calendarId = calendarId;

          if (calendar.start_break_time && calendar.end_break_time) {
            startTime = calendar.start_break_time;
            endTime = calendar.end_break_time;
            start = formatDateTime(
              parseDateTime(
                date + " " + calendar.start_break_time,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            );
            startReal = formatDateTime(
              parseDateTime(
                date + " " + calendar.start_break_time,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            );
            end = formatDateTime(
              parseDateTime(
                date +
                  " " +
                  adjustMinEnd(
                    calendar.start_break_time,
                    calendar.end_break_time,
                    HHmmss1,
                    this.min
                  ), // adjustMinEnd HHmmss1
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            );
            endReal = formatDateTime(
              parseDateTime(
                date + " " + calendar.end_break_time,
                yyyyMMddHHmmss1
              ),
              yyyyMMddHHmmss2
            );
            cost = this.diffTime(
              calendar.start_break_time,
              calendar.end_break_time
            );
          

            // Break task
            let breakTask = {
              title: "休憩",
              date: date,
              start_time: startTime,
              end_time: endTime,
              start: start,
              start_real: startReal,
              end: end,
              end_real: endReal,
              color: COLOR_GRAY,
              check: false,
              count: 1,
              cost: cost,
            };

            if (+breakTask.cost * +breakTask.count !== 0)
              this.calendarOptions.events.push(breakTask);
          }
          // 開始時間
          if (!this.startWorkTime || this.startWorkTime > item.start_time){
            this.startWorkTime = item.start_time;
          }

          // 終了時間
          if (!this.endWorkTime || this.endWorkTime < item.end_time){
            this.endWorkTime = item.end_time;
          }

        });

        this.gotoDate(this.queryCalendar.start_date);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC830, status);
      } // END-TRY-CATCH
    },

    /**
     * Get data from API FC108
     * @return {void}
     */
    async getMembers() {
      try {
        const { data, status } = await axios.get(GET_MEMBERS_API());
        this.members = status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC108, status);
      } // END-TRY-CATCH
    },

    /**
     * open modal range date
     * @return {void}
     */
    selectRangeDate() {
      this.rangeDate = Object.assign({}, this.rangeDate);
      this.openRangeDate = !this.openRangeDate;
    },

    /**
     * change date
     * @param {event} e
     * @return {void}
     */
    changeDate(e) {
      this.queryCalendar.start_date = e.start_date;
      this.queryCalendar.end_date = e.end_date;
      this.queryCalendar.real_start_date = e.start_date;
      this.queryCalendar.real_end_date = e.end_date;

      this.setQueryRangeDate();
    },

    /**
     * set query router
     */
    setQueryRouterSchedule() {
      this.$router.replace({
        path: "/weekly-schedules",
        query: this.queryData,
      });
    },

    /**
     * set query router
     */
    setQueryRouterActual() {
      this.$router.replace({
        path: "/weekly-actuals",
        query: this.queryData,
      });
    },

    /**
     * assign range date to query
     * @param {date} day
     * @return {void}
     */
    async assignRangeDate(day) {
      this.queryCalendar.start_date = formatDateTime(day, yyyyMMdd2);
      this.queryCalendar.real_start_date = formatDateTime(day, yyyyMMdd2);
      let endDay = formatDateTime(day.setDate(day.getDate() + 6), yyyyMMdd2);
      this.queryCalendar.end_date = formatDateTime(endDay, yyyyMMdd2);
      this.queryCalendar.real_end_date = formatDateTime(endDay, yyyyMMdd2);
      this.rangeDate = {
        ...this.rangeDate,
        ...this.queryCalendar,
      };

      this.setQueryRangeDate();
    },

    setQueryRangeDate() {
      const setQueryStartDate = this.checkExistQueryString(
        this.$route.query.startDate,
        this.queryCalendar.start_date
      );
      if (setQueryStartDate) {
        this.queryData["startDate"] = this.queryCalendar.start_date;
      }

      const setQueryEndDate = this.checkExistQueryString(
        this.$route.query.endDate,
        this.queryCalendar.end_date
      );
      if (setQueryEndDate) {
        this.queryData["endDate"] = this.queryCalendar.end_date;
      }
      if (this.isSchedule) {
        this.getWeeklySchedules();
        if (setQueryStartDate || setQueryEndDate) this.setQueryRouterSchedule();
      } else {
        this.getWeeklyActuals();
        if (setQueryStartDate || setQueryEndDate) this.setQueryRouterActual();
      }
    },

    /**
     * move to previous or next sunday
     * @param {boolean} isNext
     * @return {void}
     */
    async moveToSunday(isNext = false) {
      var sunday = parseDateTime(`${this.queryCalendar.start_date}`, yyyyMMdd2);
      sunday.setDate(
        isNext
          ? sunday.getDate() +
              (sunday.getDay() > 0 ? (7 + (7 - sunday.getDay())) % 7 : 7)
          : sunday.getDate() - (sunday.getDay() > 0 ? sunday.getDay() + 7 : 7)
      );
      await this.assignRangeDate(sunday);
      if (this.isSchedule) {
        await this.getWeeklySchedules();
      } else {
        await this.getWeeklyActuals();
      }
    },

    /**
     * move to previous or next day
     * @param {boolean} isNext
     * @return {void}
     */
    async moveToDay(isNext = false) {
      const startDate = parseDateTime(
        `${this.queryCalendar.start_date}`,
        yyyyMMdd2
      );
      let y = startDate.getFullYear(),
        m = startDate.getMonth(),
        d = isNext ? startDate.getDate() + 1 : startDate.getDate() - 1;
      const day = new Date(y, m, d);
      await this.assignRangeDate(day);
      if (this.isSchedule) {
        await this.getWeeklySchedules();
      } else {
        await this.getWeeklyActuals();
      }
    },

    /**
     * move today
     * @return {void}
     */
    async toDay() {
      const day = new Date();
      await this.assignRangeDate(day);
      if (this.isSchedule) {
        await this.getWeeklySchedules();
      } else {
        await this.getWeeklyActuals();
      }
    },

    /**
     * Check if item is DB task
     * @param {object} item
     * @return {boolean}
     */
    isDbTask(item) {
      if (this.isSchedule) {
        return (
          isEmptyNumber(item.free_task_id) &&
          isEmptyNumber(item.actual_free_task_id) &&
          !isEmptyNumber(item.standard_task_id) &&
          !isEmptyNumber(item.standard_task_revision) &&
          this.isDbTaskByType(item)
        );
      } else
        return (
          isEmptyNumber(item.free_task_id) &&
          isEmptyNumber(item.actual_free_task_id) &&
          !isEmptyNumber(item.standard_task_id) &&
          !isEmptyNumber(item.standard_task_revision) &&
          this.isDbTaskByType(item)
        );
    },

    /**
     * Check if item is Free task
     * @param {object} item
     * @return {boolean}
     */
    isFreeTask(item) {
      return !isEmptyNumber(item.free_task_id) || !isEmptyNumber(item.actual_free_task_id);
    },

    /**
     * Check if item is DB task by type
     * @param {object} item
     * @return {boolean}
     */
    isDbTaskByType(item) {
      return (
        !item.task_type_id ||
        SystemConstant.DB_TASK_TYPE_LIST.includes(item.task_type_id)
      );
    },

    /**
     * Check if item is Beginner level DB task
     * @param {object} item
     * @return {boolean}
     */
    isBeginnerSkill(item) {
      return item.skill_level_id ? item.skill_level_id === 10 : null;
    },

    /**
     * Check if item is Expert level DB task
     * @param {object} item
     * @return {boolean}
     */
    isExpertSkill(item) {
      return item.skill_level_id ? item.skill_level_id == 30 : null;
    },

    /**
     * Check if item is Split task
     * @param {object} item
     * @return {boolean}
     */
    isSplitTask(item) {
      return (
        !isEmptyNumber(item.split_task_id) &&
        !isEmptyNumber(item.standard_task_id) &&
        !isEmptyNumber(item.standard_task_revision)
      );
    },

    /**
     * Get color for task on schedule calendar
     * @param {object} item
     * @return {string}
     */
    getColorSchedule(item) {
      switch (true) {
        case !isEmptyNumber(item.free_task_id) || !isEmptyNumber(item.actual_free_task_id):
          return COLOR_FREE_TASK;
        case isEmptyNumber(item.free_task_id) && isEmptyNumber(item.actual_free_task_id) &&
          !isEmptyNumber(item.standard_task_id) &&
          !isEmptyNumber(item.standard_task_revision):
          if (!this.isDbTaskByType(item)) {
            return COLOR_GRAY;
          } else {
            return COLOR_DB_TASK;
          }
        default:
          throw "Color is undefined";
      } // END-SWITCH
    },

    /**
     * Get color for task on actual calendar
     * @param {object} item
     * @return {string}
     */
    getColorActual(item) {
      switch (true) {
        case !isEmptyNumber(item.free_task_id) || !isEmptyNumber(item.actual_free_task_id):
          return COLOR_FREE_TASK;
        case isEmptyNumber(item.free_task_id) && isEmptyNumber(item.actual_free_task_id) &&
          !isEmptyNumber(item.standard_task_id) &&
          !isEmptyNumber(item.standard_task_revision):
          if (!this.isDbTaskByType(item)) {
            return COLOR_GRAY;
          } else {
            return COLOR_DB_TASK;
          }
        default:
          throw "Color is undefined";
      } // END-SWITCH
    },

    /**
     * Handle change members event
     * @param {object} e
     * @return {void}
     */
    membersChange(e) {
      if (e.id && this.userId !== e.id) {
        this.$store.dispatch("common/setMember", { selectedMember: e });
        this.userId = e.id;
        this.calendarId = "";
        const setQueryMember = this.checkExistQueryString(
          this.$route.query.userId,
          this.userId
        );
        if (setQueryMember) {
          this.queryData["userId"] = e.id;
          if (this.isSchedule) {
            this.setQueryRouterSchedule();

            this.getWeeklySchedules();
          } else {
            this.setQueryRouterActual();

            this.getWeeklyActuals();
          }
        }
      }
    },

    /**
     * Get duration time
     * @param {string} e
     * @param {object} e
     */
    diffTime(sTime, eTime) {
      let sHour = sTime.substr(0, 2);
      let sMinute = sTime.substr(2, 2);
      let eHour = eTime.substr(0, 2);
      let eMinute = eTime.substr(2, 2);
      return eHour * 60 + parseInt(eMinute) - (sHour * 60 + parseInt(sMinute));
    },

    /**
     * adjust tasks
     * @param {array<object>} events
     * @param {int} minSlot
     * @param {string} slotMaxTime
     */
    adjustTasks(events, minSlot, slotMaxTime = "24:00:00") {
      const getAdjustStart = (end, min, slotMaxTime = "24:00:00") => {
        const endDate = formatDateTime(
          parseDateTime(end, yyyyMMddHHmmss2),
          yyyyMMdd2
        );
        const startTime =
          slotMaxTime === "24:00:00"
            ? `23:${this.timeToStrTime(Math.floor(60 - min))}:00`
            : formatDateTime(
                new Date(
                  new Date(`2020-01-01 ${slotMaxTime}`).getTime() -
                    min * 60 * 1000
                ),
                HHmmss2
              );
        return `${endDate} ${startTime}`;
      };
      const getFormat = (dateTimeString) => {
        switch (dateTimeString.length) {
          case 19:
            return yyyyMMddHHmmss2;
          case 16:
            return yyyyMMddHHmm1;
          default:
            throw `format errors ${dateTimeString}`;
        }
      };
      events.forEach((item) => {
        let endRealDate = parseDateTime(
          item.end_real,
          getFormat(item.end_real)
        );

        // item.start_real = formatDateTime(
        //   parseDateTime(item.date + " " + item.start_time, yyyyMMddHHmmss1),
        //   yyyyMMddHHmmss2
        // );
        // check min
        const min = item.cost * item.count < 30 ? minSlot : minSlot * 1.65;
        const tomorrowTime =
          Date.parse(`${formatDateTime(endRealDate, yyyyMMdd2)}T00:00:00`) +
          24 * 60 * 60 * 1000;
        if (!item.start_real) return;
        let compareStartRealTime =
          parseDateTime(item.start_real, yyyyMMddHHmmss2).getTime() +
          min * 60 * 1000;
        const endRealTime = endRealDate.getTime();
        const isAdjustEnd = compareStartRealTime > tomorrowTime;
        const compareStartHHmmss2 = formatDateTime(
          new Date(compareStartRealTime),
          HHmmss2
        );

        if (
          isAdjustEnd &&
          item.end_time > 230000 &&
          slotMaxTime === "24:00:00"
        ) {
          item.end = `${formatDateTime(endRealDate, yyyyMMdd2)} 23:59:59`;
          item.start = getAdjustStart(item.end, min, slotMaxTime);
        } else if (
          compareStartRealTime > endRealTime &&
          compareStartHHmmss2 > slotMaxTime
        ) {
          item.end = `${formatDateTime(endRealDate, yyyyMMdd2)} ${slotMaxTime}`;
          item.start = getAdjustStart(item.end, min, slotMaxTime);
        } else if (compareStartRealTime > endRealTime) {
          compareStartRealTime =
            compareStartRealTime >= tomorrowTime
              ? tomorrowTime - 1000
              : compareStartRealTime;
          item.end = formatDateTime(compareStartRealTime, yyyyMMddHHmmss2);
        }
      });
    },

    /**
     * Set time grid length
     * @return {void}
     */
    setTimeGridLength(slotDuration, slotMinTime, slotMaxTime) {
      this.timeGridLength = this.getTimeGridSlotByDuration(
        slotDuration,
        slotMinTime,
        slotMaxTime
      );
    },
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["loginInfo", "isScheduleExpand"]),
    /**
     * format css print page
     * @return {object}
     */
    cssProps() {
      if (this.timeGridLength === 0) return;

      const unitPrint = "mm";
      const heightFcTimegridSlotDuration30 =
        (CALENDAR_PRINT_LENGHT_MM + SLOT_30_SCALE[this.timeGridLength]) /
          this.timeGridLength +
        unitPrint;
      const heightFcTimegridSlotDuration60 =
        (CALENDAR_PRINT_LENGHT_MM + SLOT_60_SCALE[this.timeGridLength]) /
          this.timeGridLength +
        unitPrint;
      let heightFcTimegridSlotDurationDefault =
        CALENDAR_PRINT_LENGHT_MM / (13 * 4) + unitPrint;

      if (
        (this.calendarOptions.slotDuration === "00:05:00" ||
          this.calendarOptions.slotDuration === "00:15:00") &&
        this.timeGridLength > 13 * 4
      ) {
        heightFcTimegridSlotDurationDefault =
          (CALENDAR_PRINT_LENGHT_MM * 2 +
            DEFAULT_SCALE[this.timeGridLength / 4]) /
            this.timeGridLength +
          unitPrint;
      }

      return {
        "--height-fc-timegrid-slot-duration-default":
          heightFcTimegridSlotDurationDefault,
        "--height-fc-timegrid-slot-duration-30": heightFcTimegridSlotDuration30,
        "--height-fc-timegrid-slot-duration-60": heightFcTimegridSlotDuration60,
      };
    },

    /**
     * format date
     * @return {string} yyyy年MM月dd日
     */
    formatSelectedDate() {
      return formatDateTime(
        parseDateTime(this.selectedDate, yyyyMMdd2),
        yyyyMMddEEEEE3
      );
    },

    isMobile() {
      return ["xs", "sm", "md"].includes(this.$vuetify.breakpoint.name);
    },

    /**
     * Set option print Calendar
     * @return {object}
     */
    printCalendarOptions() {
      if (this.isMobile) {
        return {};
      }
      let printCalendarOptions = deepClone(this.calendarOptions);
      this.changeSlotMinMaxModePrint(printCalendarOptions);
      this.setTimeGridLength(
        printCalendarOptions.slotDuration,
        printCalendarOptions.slotMinTime,
        printCalendarOptions.slotMaxTime
      );

      const minSlot = this.getMinSlotByDurationModePrint(
        printCalendarOptions.slotDuration,
        this.timeGridLength
      );
      this.adjustTasks(
        printCalendarOptions.events,
        minSlot,
        printCalendarOptions.slotMaxTime
      );

      return {
        ...printCalendarOptions,
        ...{
          slotDuration: this.getSlotDuration(printCalendarOptions.slotDuration),
        },
      };
    },

    /**
     * format start date yyyy年MM月dd日
     * @return {string}
     */
    formatStartDate() {
      return formatDateTime(this.queryCalendar.real_start_date, yyyyMMdd3);
    },

    /**
     * format end date yyyy年MM月dd日
     * @return {string}
     */
    formatEndDate() {
      return formatDateTime(this.queryCalendar.real_end_date, yyyyMMdd3);
    },
  },

  watch: {
    isScheduleExpand(val) {
      this.expand = val;
    },
    "calendarOptions.events": {
      deep: true,
      handler(val) {
        this.adjustTasks(val, this.min, "24:00:00");
        this.changeSlotMinMax(this.calendarOptions, val, this.startWorkTime, this.endWorkTime);
      },
    },
    rangeDate: {
      deep: true,
      handler() {
        this.queryCalendar = {
          ...this.queryCalendar,
          ...this.rangeDate,
        };
      },
    },
  },

  created() {
    this.$root.$refs.calendarComponent = this;
    const date = new Date();
    const lastDay = this.isSchedule
      ? new Date(date.setDate(date.getDate() + 6))
      : new Date();
    const firstDay = this.isSchedule
      ? new Date()
      : new Date(date.setDate(date.getDate() - 6));
    this.rangeDate = {
      start_date:
        this.$route.query.startDate || formatDateTime(firstDay, yyyyMMdd2),
      end_date: this.$route.query.endDate || formatDateTime(lastDay, yyyyMMdd2),
      real_start_date:
        this.$route.query.startDate || formatDateTime(firstDay, yyyyMMdd2),
      real_end_date:
        this.$route.query.endDate || formatDateTime(lastDay, yyyyMMdd2),
    };

    this.queryData = this.setQuery(this.$route.query);
    this.queryCalendar = {
      ...this.queryCalendar,
      ...this.rangeDate,
    };
  },

  async mounted() {
    let fullCalendarScrollX = document.querySelector('.fc-view-harness') || null
    fullCalendarScrollX && (fullCalendarScrollX.style.overflowX = "auto")
    let fullCalendarTable = document.querySelector('.fc-view-harness .fc-view > table') || null
    fullCalendarTable && (fullCalendarTable.style.minWidth = '1200px')
    
    this.userId = this.$route.query.userId || this.user?.user_id;
    this.calendarId = this.$route.params.id || null;

    this.calendarOptions.slotDuration = Cookies.get(
      "slotDurationItem" + this.user?.user_id
    )
      ? Cookies.get("slotDurationItem" + this.user?.user_id)
      : this.slotDurationItems[1].value;
    this.preSlotDuration = this.calendarOptions.slotDuration;
    //this.min = (this.calendarOptions.slotDuration === "00:30:00" || this.calendarOptions.slotDuration === "00:60:00") ? 15 : 5;
    if (this.calendarOptions.slotDuration === "00:30:00") {
      this.min = 15;
    } else if (this.calendarOptions.slotDuration === "00:60:00") {
      this.min = 18;
    } else {
      this.min = 5;
    }
    await this.getMembers();
    if (this.isSchedule) {
      await this.getWeeklySchedules();
    } else {
      await this.getWeeklyActuals();
    }
    this.selectUser = this.members.find((x) => x.id === this.userId);
    const userStore = { id: this.userId };
    this.$store.dispatch("common/setMember", { selectedMember: userStore });
  },

  destroyed() {
    this.$store.dispatch("common/isScheduleExpand", 0);
    let fullCalendarScrollX = document.querySelector('.fc-view-harness') || null
    fullCalendarScrollX && (fullCalendarScrollX.style.overflowX = "unset")
    let fullCalendarTable = document.querySelector('.fc-view-harness .fc-view > table') || null
    fullCalendarTable && (fullCalendarTable.style.minWidth = 'unset')
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_calendar.scss";
.time-break {
  word-break: break-all;
}
.time-text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.dbText {
  color: #1e90ff;
}
.normalText {
  color: #222222;
}
.whiteText {
  color: white;
}
.select {
  width: 100px;
}
.v-text-field.v-text-field--solo {
  .v-input__control {
    min-height: 36px !important;
  }
}

.v-center {
  display: flex;
  align-items: center;
}

.sub-hours {
  font-size: 0.8rem;
}

.content-row {
  display: flex;
}

.content-calendar {
  width: 70%;
  padding-left: 0;
  .v-breadcrumbs {
    padding-left: 0;
  }
  .v-breadcrumbs li:nth-child(2n) {
    padding: 0;
  }
}
.my-column-full {
  width: 100% !important;
}
.content-db-list {
  width: 30%;
  border-left: 1px solid #e1e1e1;
}
.content-db-list > .row {
  height: 100%;
  margin: 0;
  padding-bottom: 0;
}
.content-db-list #dragElements {
  padding: 0;
}
.is-panel-collapsed {
  .content-calendar {
    width: 97%;
  }

  .content-db-list {
    width: 3%;
  }
}
.flow-root {
  display: flow-root !important;
}

.outputTask:before {
  background-color: #222222;
  display: block;
  content: " ";
  position: absolute;
  height: 100%;
  width: 8px;
  border-radius: 3px 0 0 3px;
  top: 0;
  left: -1px;
}
.outputTask {
  .fc-event-main {
    margin-left: 8px;
  }
}
.v-tabs {
  a {
    font-weight: 700;
  }
}
.text-large {
  font-size: 1rem;
  font-weight: 900;
}
.reject-text {
  color: #dc143c !important;
}

.header-items button {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
}
.header-items button:disabled {
  box-shadow: none;
}
.viewCalendar {
  display: flex;
  .viewCalendar {
    .fc-view-harness {
      .fc-event-main {
        .row {
          .col {
            padding-bottom: 0 !important;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" src="@/assets/scss/_calendar.scss" scoped></style>
<style lang="scss" scoped>
::v-deep .v-input__append-outer {
  margin-top: 0 !important;
}
.flatbtn {
  box-shadow: none !important;
  background-color: unset !important;
}

.name-detail {
  word-break: break-word;
}

.time-text-margin {
  margin-top: -0.2rem;
}

.print-calendar {
  ::v-deep.fc-timegrid-event-harness {
    .fc-timegrid-event {
      overflow: hidden;
      min-width: 20px;
    }
  }
  ::v-deep .fc-timegrid-event-harness:last-child {
    .fc-timegrid-event {
      min-width: unset !important;
    }
  }
  .__task-content {
    padding-bottom: 0px;
    .__task-info {
      min-width: 10px;
    }
    .__remand {
      margin-right: 2px;
      height: 28px;
    }
    .__checkbox {
      height: 28px;
    }
    .task-time {
      min-width: 20px;
      overflow: hidden;
      flex-shrink: 1;
      // line-height: 28px;
    }
  }
}
// Config print
$--font-size-default: 0.7em;
$--font-size-duration-30: 0.7em;
$--font-size-duration-60: 0.7em;

.printCalendar {
  height: 100%;
}

// Print for duration 5', 15'
.fullcalendar-itokuro--five-min,
.fullcalendar-itokuro--fifteen-min {
  ::v-deep .printCalendar {
    .fc-timegrid-event {
      font-size: $--font-size-default;
    }
    .fc-scroller-harness.fc-scroller-harness-liquid {
      .fc-timegrid-slot,
      .fc-timegrid-slot-lane,
      .fc-scrollgrid-shrink,
      .fc-timegrid-slot-label,
      .fc-timegrid-slot-minor {
        font-size: $--font-size-default;
        height: var(--height-fc-timegrid-slot-duration-default);
      }
    }
  }
}

// Print for duration 30'
.fullcalendar-itokuro--thirty-min {
  ::v-deep .printCalendar {
    .fc-timegrid-event {
      font-size: $--font-size-duration-30;
    }
    .fc-scroller-harness,
    .fc-scroller-harness-liquid {
      .fc-timegrid-slot,
      .fc-timegrid-slot-lane,
      .fc-scrollgrid-shrink,
      .fc-timegrid-slot-label,
      .fc-timegrid-slot-minor {
        font-size: $--font-size-duration-30;
        height: var(--height-fc-timegrid-slot-duration-30);
      }
    }
  }
}

// Print for duration 60'
.fullcalendar-itokuro--sixty-min {
  ::v-deep .printCalendar {
    .fc-timegrid-event {
      font-size: $--font-size-duration-60;
    }
    .fc-scroller-harness.fc-scroller-harness-liquid {
      .fc-timegrid-slot,
      .fc-timegrid-slot-lane,
      .fc-scrollgrid-shrink,
      .fc-timegrid-slot-label,
      .fc-timegrid-slot-minor {
        font-size: $--font-size-duration-60;
        height: var(--height-fc-timegrid-slot-duration-60);
      }
    }
  }
}
</style>
