var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px","scrollable":""},model:{value:(_vm.openCategory),callback:function ($$v) {_vm.openCategory=$$v},expression:"openCategory"}},[_c('v-card',[_c('v-card-text',{staticClass:"card-content"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"4"}},[_vm._v("大カテゴリ"),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('v-col',{staticClass:"text-left",attrs:{"cols":"8"}},[_c('v-combobox',{ref:"myComboBox",attrs:{"item-text":"name","item-value":"id","no-data-text":"空欄","error-messages":_vm.primaryCategoryErrors,"items":_vm.parentCategories,"clearable":"","menu-props":{
              maxWidth: 360,
            }},on:{"change":function($event){return _vm.primaryChange($event)},"update:search-input":_vm.searchInput},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item',[_c('v-list-item-content',_vm._g(_vm._b({},'v-list-item-content',attrs,false),on),[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v(_vm._s(item.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])],1),_c('v-list-item-action',[_c('v-menu',{attrs:{"right":"","offset-x":"","bottom":"","nudge-width":100},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"grey--text"},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editPrimaryCategory(item)}}},[_c('v-list-item-content',[_vm._v(_vm._s(((item.name) + "を編集")))])],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_vm._v("他の小カテゴリと紐づくこの大カテゴリの名前も変更されます。")])],1)],1)],1)],1)],1)]}}]),model:{value:(_vm.primaryCategory),callback:function ($$v) {_vm.primaryCategory=$$v},expression:"primaryCategory"}})],1)],1),_c('v-divider'),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"4"}},[_vm._v("小カテゴリ"),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('v-col',{staticClass:"text-left",attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"maxlength":"64","error-messages":_vm.secondaryNameErrors,"clearable":""},model:{value:(_vm.category.category_secondary_name),callback:function ($$v) {_vm.$set(_vm.category, "category_secondary_name", $$v)},expression:"category.category_secondary_name"}})],1)],1),_c('v-divider'),_c('v-row',{staticClass:"pt-2",attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"4"}},[_vm._v("紐づき部署・Gr編集")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"open-on-click":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":""}},on),[_vm._v(" 編集")])]}}]),model:{value:(_vm.openMenu),callback:function ($$v) {_vm.openMenu=$$v},expression:"openMenu"}},[_c('DepartmentCheckList',{attrs:{"items":_vm.departments,"display":"name","id":"id","check":"check"},model:{value:(_vm.selectedDepartments),callback:function ($$v) {_vm.selectedDepartments=$$v},expression:"selectedDepartments"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"outlined":"","depressed":"","color":"light"},on:{"click":_vm.close}},[_vm._v(" キャンセル ")]),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" 変更 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }