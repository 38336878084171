<template>
  <div class="icon-wrapper"></div>
</template>
<script>
export default { 
  name: "BeginnerIcon" 
};
</script>
<style lang="scss" scoped>
.icon-wrapper {
  width: 24px;
  height: 24px;
  background-image: url("~@/assets/images/beginner.png");
  background-size: 42px 29.5px;
  background-position: -8px -3px;
}
</style>
