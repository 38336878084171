<template>
  <div>
    <v-menu
      v-if="!$vuetify.breakpoint.mobile"
      v-model="openFilterMenu"
      :close-on-content-click="false"
      offset-y
      bottom
      left
      min-width="auto"
    >
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" color="ligth" class="btn-filter">
          フィルター
          <v-icon left dark>mdi-filter-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item-group>
            <v-list-item>
              <v-btn text :disabled="noCheck" @click="reset()">
                フィルタを解除</v-btn
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-action>
                <v-checkbox v-model="checkAll"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>全て</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="item in listItem" :key="item.id">
              <v-list-item-action>
                <v-checkbox v-model="item.check"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                {{ item.tag_name }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile color="ligth darken-1" @click="openFilterMenu = false">{{
            $t("label.buttons.cancel")
          }}</v-btn>
          <v-btn tile color="primary darken-1" @click="submit()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <v-dialog v-else v-model="openFilterMenu" hide-overlay>
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" color="ligth">
          フィルター
          <v-icon left dark>mdi-filter-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item-group>
            <v-list-item>
              <v-btn text :disabled="noCheck" @click="reset()">
                フィルタを解除</v-btn
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-action>
                <v-checkbox v-model="checkAll"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>全て</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="item in listItem" :key="item.id">
              <v-list-item-action>
                <v-checkbox v-model="item.check"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                {{ item.tag_name }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile color="ligth darken-1" @click="openFilterMenu = false">{{
            $t("label.buttons.cancel")
          }}</v-btn>
          <v-btn tile color="primary darken-1" @click="submit()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      openFilterMenu: false,
      listItem: this.items,
    };
  },
  watch: {
    items: {
      handler(val) {
        this.listItem = val;
      },
    },
  },
  methods: {
    submit() {
      this.openFilterMenu = false;
      this.$emit("submit", this.listItem);
    },
    reset() {
      this.openFilterMenu = false;
      this.$emit("reset");
      this.checkAll = false;
    },
  },
  computed: {
    checkAll: {
      // getter
      get: function () {
        return !this.listItem.some((x) => !x.check);
      },
      // setter
      set: function (newValue) {
        this.listItem = this.listItem.map((x) => {
          return {
            tag_name: x.tag_name,
            id: x.id,
            check: newValue,
          };
        });
      },
    },
    noCheck() {
      return !this.listItem.some((x) => x.check);
    },
  },
  mounted() {
    this.listItem = this.items;
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1450px) and (min-width: 1350px) {
  .btn-filter {
    padding: 0 7px !important;
  }
}
@media screen and (max-width: 1350px) and (min-width: 1250px) {
  .btn-filter {
    padding: 0 !important;
  }
}
</style>
