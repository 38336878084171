<template>
  <v-dialog v-model="openFavoriteTask" persistent max-width="600px" scrollable :fullscreen="$vuetify.breakpoint.mobile">
    <v-card class="hidden">
      <v-card-title class="card-header py-2">
        <v-tooltip v-if="taskId !== null" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="deleteTask" v-bind="attrs" v-on="on" class="ml-auto" icon color="gray">
              <v-icon small>$delete</v-icon>
            </v-btn>
          </template>
          <span>タスクを削除</span>
        </v-tooltip>
        <v-btn @click="close()" v-bind:class="[taskId !== null ? '' : 'ml-auto']" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="py-0 my-0">
          <v-col class="py-0 d-flex align-center">
            <div v-if="isFavorite">
              <span style="margin-left: -16px" class="red--text mr-2">*</span>
              <v-icon small>$favoriteactive</v-icon>
            </div>
            <div v-else @click="task.is_favorite = !task.is_favorite">
              <span style="margin-left: -16px" class="red--text mr-2">*</span>
              <v-icon v-if="task.is_favorite" small>$favoriteactive</v-icon>
              <v-icon v-else small>$favoriteinactive</v-icon>
            </div>
            <v-text-field class="ml-2" maxlength="60" v-model="task.name" :error-messages="nameErrors"
              placeholder="タスク名を追加"></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="py-0 my-0">
          <v-col cols="12" class="d-flex align-center">
            <v-icon small>$taskexplanation</v-icon>
            <v-textarea rows="1" class="ml-2" auto-grow maxlength="512" v-model="task.description" placeholder="説明を追加">
            </v-textarea>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0">
          <v-col class="py-0">
            <div class="header">
              <span style="margin-left: -16px" class="red--text mr-2">*</span>
              <v-icon small>$taskcategory</v-icon>
              <dropdown-button :id="'id'" :display="'name'" :name="primaryName || '大カテゴリ名'" :items="parentCategories"
                :type="'text'" :has-total="false" :showMenu="true" @dropdownSelected="primaryCategoryChange($event)">
              </dropdown-button>
            </div>
            <div class="mt-2 ml-5 category">
              <dropdown-button :id="'id'" :display="'name'" :name="secondaryName || '小カテゴリ名'" :items="childCategories"
                :type="'text'" :has-total="false" :showMenu="true" @dropdownSelected="secondaryCategoryChange($event)"
                v-if="showSecondCategory"></dropdown-button>
              <div v-if="
                !this.$v.task.category_secondary_id.required &&
                this.$v.task.category_secondary_id.$dirty
              " class="v-text-field__details">
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ $t("label.error.required") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0 align-baseline">
          <v-col cols="3" class="py-0 align-self-start mt-5">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskworktime</v-icon>
            <span class="ml-2 ">工数</span>
          </v-col>
          <v-col cols="9" class="py-0 d-flex align-start align-self-start"
            :class="[$vuetify.breakpoint.mobile ? 'pl-0' : '']">
            <v-text-field type="number" max="999" min="1" @keydown="checkMaxLength('cost', $event)"
              @input.native="updateCost" :error-messages="costErrors" v-model="task.cost" suffix="m/回" class="w-90">
            </v-text-field>
            <span class="mx-2 mt-5">×</span>
            <v-text-field type="number" max="999" min="1" @keydown="checkMaxLength('count', $event)"
              @input.native="updateCount" :error-messages="countErrors" suffix="回" v-model="task.count"
              class="w-60 align-self-start">
            </v-text-field>
            <span class="mx-2 mt-5">=</span>
            <v-text-field readonly min="1" :value="task.cost * task.count" suffix="m" class="w-95 align-self-start">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0">
          <v-col class="py-0">
            <v-icon small class="mr-2">$tag</v-icon>
            <v-btn tile color="ligth" @click="createTag()">タグを追加 </v-btn>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0">
          <v-col class="py-0">
            <v-container justify="space-around">
              <v-sheet class="px-1">
                <v-chip-group mandatory>
                  <v-tooltip bottom v-for="tag in task.tags" :key="tag.id">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" close @click:close="deleteTag(tag)" close-icon="mdi-close">
                        <span class="d-inline-block text-truncate" style="max-width: 100px">
                          {{ tag.name }}
                        </span>
                      </v-chip>
                    </template>
                    <span>{{ tag.name }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          tile
          :disabled="!isEditing"
          class="ml-auto"
          color="primary"
          @click="updateTask()"
        >
          保存
        </v-btn>
        <v-btn
          v-if="$vuetify.breakpoint.mobile && isAllowDrag() && !isAddFavorite"
          tile
          depressed
          color="primary"
          @click="showFreeTaskModal()"
        >
          カレンダーに配置
        </v-btn>
      </v-card-actions>
    </v-card>
    <tag-list :items="tagList" :existItems="existTag" @selectTag="selectTagModal" @updateTag="updateTagModal"
      @deleteTag="deleteTagModal" v-model="openTagDialog"></tag-list>
    <FreeTask
      :planId="planId"
      :show="showFreeTask"
      :copyFreeData="copyFreeData"
      :isAddedFromFavorite=true
      :isPlan="isPlan"
      @addToCalendar="addToCalendar($event)"
    ></FreeTask>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import DropdownButton from "../../../components/DropdownButton.vue";
import TagList from "./TagList.vue";
import { CATEGORIES_API, TAG_API, FAVORITE_TASK_API } from "../../../constants/api";
import axios from "axios";
import { mapGetters } from "vuex";
import { workTimeValidator } from "../../../helpers/validator";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC701, FC813, FC831, FC832, FC833,FC815 } from "../../../constants/fc";
import { backMixin } from "../../../mixins/back";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import FreeTask from "./FreeTask";
import {
  debounce_leading,
  isEmptyNumber,
  deepClone,
} from "../../../helpers/helpers";
export default {
  components: { DropdownButton, TagList, FreeTask },
  props: {
    show: Boolean,
    taskId: { type: Number, default: null },
    isFavorite: { type: Boolean, default: false },
    planId: String,
    isPlan: {
      type: Boolean,
      default: true,
    },
    isAddFavorite: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [validationMixin, backMixin],
  validations: {
    task: {
      name: { required },
      output_detail: {
        required: requiredIf((task) => {
          return task.is_need_output;
        }),
      },
      category_secondary_id: {
        required: requiredIf((task) => {
          return task.category_primary_id !== null;
        }),
      },
      cost: {
        required,
        workCompare: workTimeValidator("count"),
      },
      count: {
        required,
        workCompare: workTimeValidator("cost"),
      },
    },
  },
  data() {
    return {
      openFavoriteTask: false,
      openTagDialog: false,
      showComment: false,
      parentCategories: [],
      childCategories: [],
      primaryCategory: {},
      userComment: "",
      task: {},
      originalTask: {},
      tagList: [],
      existTag: [],
      isEditing: false,
      showFreeTask: false,
      copyFreeData: {},
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["loginInfo"]),
    ...mapGetters("save", ["getChangeFlag"]),
    nameErrors() {
      const errors = [];
      if (this.$v.task.name.$dirty) {
        !this.$v.task.name.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    outputDetailErrors() {
      const errors = [];
      if (this.$v.task.output_detail.$dirty) {
        !this.$v.task.output_detail.required &&
          this.task.is_need_output &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    costErrors() {
      const errors = [];
      if (this.$v.task.cost.$dirty) {
        !this.$v.task.cost.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.cost.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    countErrors() {
      const errors = [];
      if (this.$v.task.count.$dirty) {
        !this.$v.task.count.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.count.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    primaryName() {
      let p = this.parentCategories.find(
        (x) => x.id === this.task.category_primary_id
      );
      return p ? p.name : "未分類";
    },
    secondaryName() {
      if (this.childCategories) {
        let s = this.childCategories.find(
          (x) => x.id === this.task.category_secondary_id
        );
        return s ? s.name : "";
      } else {
        return "";
      }
    },
    showSecondCategory() {
      return !isEmptyNumber(this.task.category_primary_id);
    },
  },
  watch: {
    async show() {
      this.openFavoriteTask = true;
      this.isEditing = false;
      await this.getData();
      this.$v.$reset();
      this.$store.dispatch("save/setChangeFlag", false);
      this.userComment = "";
      this.showComment = false;
    },
    task: {
      deep: true,
      handler() {
        this.isEditing = this.compareValue();
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
  },
  methods: {
    registerTask(e) {
      if (this.isPlan){
        this.$root.$refs.scheduleComponent.addEventFromDbList(e);
      }
      else{
        this.$root.$refs.actualComponent.addEventFromDbList(e);
      }
    },

    isAllowDrag() {
      if (this.isPlan){
        return (
          this.$root.$refs.scheduleComponent.isSelf() &&
          this.$root.$refs.scheduleComponent.isNoSubmmit() &&
          this.$root.$refs.scheduleComponent.isCanSubmit()
        );
      }
      return (
        this.$root.$refs.actualComponent.isNoSubmmit() &&
        this.$root.$refs.actualComponent.isCanSubmit()
      );
    },

    updateCost(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.cost = value;
      } else {
        this.task.cost = value.slice(0, 3);
      }
      this.$forceUpdate();
    },
    updateCount(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.count = value;
      } else {
        this.task.count = value.slice(0, 3);
      }
      this.$forceUpdate();
    },
    checkMaxLength(type, $event) {
      if (
        $event.keyCode === 8 ||
        $event.keyCode === 46 ||
        $event.keyCode === 38 ||
        $event.keyCode === 40 ||
        $event.keyCode === 37 ||
        $event.keyCode === 39
      )
        return true;
      if (type === "cost") {
        if (this.$v.task.cost.required) {
          if (this.task.cost.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      } else {
        if (this.$v.task.count.required) {
          if (this.task.count.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      }
    },

    deleteTagModal(tag) {
      this.task.tags = this.task.tags.filter((x) => x.id !== tag.id);
      this.originalTask.tags = this.originalTask.tags.filter(
        (x) => x.id !== tag.id
      );
    },

    updateTagModal(tag) {
      let t = this.task.tags.filter((x) => x.id === tag.id)[0];
      if (t) t.name = tag.name;
      let ot = this.originalTask.tags.filter((x) => x.id === tag.id)[0];
      if (ot) ot.name = tag.name;
    },

    async deleteTag(item) {
      try {
        let param = { favorite_task_id: this.taskId };

        await axios.delete(TAG_API(item.id), {
          data: param,
        });
        
        this.task.tags = this.task.tags.filter((x) => +x.id !== +item.id);
        this.originalTask.tags = this.originalTask.tags.filter(
          (x) => +x.id !== +item.id
        );
        this.$emit("updateTag", this.task);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC815, status);
      }
    },

    async getCategories() {
      try {
        const { data } = await axios.get(CATEGORIES_API());

        this.parentCategories = status === STATUS_NO_CONTENT ? [] : data.result;
        this.parentCategories.push({
          id: null,
          name: "未分類",
          secondaries: [],
        });
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC701, status);
      }
    },

    async getData() {
      try {

        if (this.taskId !== null) {
          const qs = (params) =>
            Object.keys(params)
              .map((key) => `${key}=${params[key]}`)
              .join("&");
          const apiUrl = `${FAVORITE_TASK_API()}?${qs({
            favorite_task_id: this.taskId,
          })}`;

          const { data, status } = await axios.get(apiUrl);
          this.task =
            status === STATUS_NO_CONTENT ? {} : deepClone(data.result[0]);
          this.originalTask =
            status === STATUS_NO_CONTENT ? {} : deepClone(data.result[0]);
        }
        else {
          let blankTask = {
            name: "",
            description: "",
            cost: null,
            count: "",
            is_need_output: false,
            output_detail: "",
            category_secondary_id: null,
            category_primary_id: null,
            is_favorite: false,
            tags: [],
          };
          this.task = deepClone(blankTask);
          this.originalTask = deepClone(blankTask);
        }

        await this.getCategories();

        this.childCategories = this.parentCategories.find(
          (x) => x.id === this.originalTask.category_primary_id
        )?.secondaries;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC831, status);
      }
    },

    /**
     * Delete FavoriteTask
     */
    async deleteTask() {
      this.$confirm(
        this.isSplit
          ? `${this.$t(
            "label.confirm.delete_task"
          )} \r\nこのタスクは中断となり、生産性が評価できません。`
          : this.$t("label.confirm.delete_task"),
        {
          buttons: {
            ok: {
              text: this.$t("label.buttons.delete"),
              type: "primary",
            },
            cancel: {
              text: this.$t("label.buttons.cancel"),
              type: "ligth",
            },
          },
          width: "600px",
          rtl: true,
        }
      )
        .then(async () => {
          try {
            await axios.delete(FAVORITE_TASK_API(this.taskId));
            this.openFavoriteTask = false;
            this.$emit("deleteFavoriteTask", this.task);
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC833, status);
          }
        })
        .catch(() => { });
    },

    primaryCategoryChange(e) {
      this.childCategories = this.parentCategories.find(
        (x) => x.id === e.id
      )?.secondaries;
      this.primaryCategory = e;
      this.task.category_primary_id = e.id;
      this.task.category_secondary_id = null;
    },

    secondaryCategoryChange(e) {
      this.task.category_secondary_id = e.id;
    },

    createTag: debounce_leading(async function () {
      try {
        const { data, status } = await axios.get(
          `${TAG_API()}` + `?user_id=${this.user.user_id}`
        );
        this.tagList =
          status === STATUS_NO_CONTENT
            ? []
            : data.result.map((x) => {
              return { ...x, ...{ check: false } };
            });
        this.existTag = this.task.tags.map((x) => {
          return {
            tag_id: x.id,
            tag_name: x.name,
          };
        });

        this.openTagDialog = !this.openTagDialog;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC813, status);
      }
    }),

    updateComment() {
      this.showComment = true;
    },

    updateTask: debounce_leading(async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        try {
          let data = {};
          if (this.taskId === null) {
            let response = await axios.post(FAVORITE_TASK_API(), {
              name: this.task.name,
              description: this.task.description,
              category_secondary_id: this.task.category_secondary_id,
              cost: +this.task.cost,
              count: +this.task.count,
              is_need_output: +this.task.is_need_output,
              output_detail:
                +this.task.is_need_output === 0 ? null : this.task.output_detail,
              tags: this.task.tags,
            });
            data = response.data;
          }
          else {
            let response = await axios.put(FAVORITE_TASK_API(this.taskId), {
              name: this.task.name,
              description: this.task.description,
              category_secondary_id: this.task.category_secondary_id,
              cost: +this.task.cost,
              count: +this.task.count,
              is_need_output: +this.task.is_need_output,
              output_detail:
                +this.task.is_need_output === 0 ? null : this.task.output_detail,
              tags: this.task.tags,
            });
            data = response.data;
          }

          this.openFavoriteTask = false;
          this.task.favorite_task_id = data.result.id;
          this.task.is_favorite = 1;
          this.$store.dispatch("save/setChangeFlag", false);
          this.$emit("registerFavoriteTask", this.task);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC832, status);
        }
        this.openDbTask = false;
      }
    }),

    selectTagModal(items) {
      this.task.tags = this.task.tags.concat(items);
      this.openTagDialog = false;
    },

    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.openFavoriteTask = false;
          }, 100);
        },
        () => { }
      );
    },

    compareValue() {
      return (
        JSON.stringify({
          name: this.task.name,
          description: this.task.description,
          cost: this.task.cost,
          count: this.task.count,
          is_need_output: this.task.is_need_output,
          output_detail: this.task.output_detail,
          category_secondary_id: this.task.category_secondary_id,
          category_primary_id: this.task.category_primary_id,
          tags: this.task.tags,
        }) !==
        JSON.stringify({
          name: this.originalTask.name,
          description: this.originalTask.description,
          cost: this.originalTask.cost,
          count: this.originalTask.count,
          is_need_output: this.originalTask.is_need_output,
          output_detail: this.originalTask.output_detail,
          category_secondary_id: this.originalTask.category_secondary_id,
          category_primary_id: this.originalTask.category_primary_id,
          tags: this.originalTask.tags,
        })
      );
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.$v.$reset();
          this.openFavoriteTask = false;
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },

    showFreeTaskModal(){
      this.copyFreeData = {
        name: this.task.name,
        description: this.task.description,
        cost: this.task.cost,
        count: this.task.count,
        is_need_output: this.task.is_need_output,
        output_detail: this.task.output_detail,
        category_secondary_id: this.task.category_secondary_id,
        category_primary_id: this.task.category_primary_id,
        tags: this.task.tags,
        start_time: "",
        end_time: "",
        favorite_task_id: this.task.favorite_task_id,
        is_favorite: 1,
      };
      this.showFreeTask = !this.showFreeTask;
    },

    addToCalendar(item){
      this.openFavoriteTask = false;
      this.$emit("addToCalendar", item);
    }
  },
  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}

.w-120 {
  width: 120px;
}

.w-90 {
  width: 90px;
}

.align-items-baseline {
  align-items: baseline;
}

.w-60 {
  width: 60px;
}

.w-95 {
  width: 95px;
}

.w-95,
.w-60,
.w-90 {
  .v-text-field__details {
    width: 100%;
  }
}
</style>
