<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    left
    min-width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-if="!mobile" text v-on="on" color="ligth" rounded outlined>
        <v-icon left dark>mdi-filter-outline</v-icon>
        フィルター
      </v-btn>
      <v-btn v-else text v-on="on">
        <v-icon left dark>mdi-filter-outline</v-icon>
        フィルター
      </v-btn>
    </template>
    <v-list>
      <v-list-item class="justify-center" @click="deleteFilter()"
        >フィルタを解除</v-list-item
      >
      <v-divider></v-divider>
      <v-list-item>
        <v-date-picker
          no-title
          scrollable
          locale="ja"
          :day-format="(date) => new Date(date).getDate()"
          v-model="selectedDate"
        >
          <v-spacer></v-spacer>
          <v-btn outlined color="ligth" @click="menu = false">
            キャンセル
          </v-btn>
          <v-btn
            color="primary"
            @click="
              $refs.menu.save(selectedDate);
              changeDate();
            "
            depressed
            class="btn-ok"
          >
            OK
          </v-btn></v-date-picker
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { yyyyMMdd2 } from "../../../constants/df";
import { formatDateTime } from "../../../helpers/datetime-helpers";
export default {
  name: "DropdownCalendar",
  props: {
    inputDate: String,
  },
  data() {
    return {
      menu: false,
      selectedDate: "",
    };
  },
  created() {
    this.selectedDate = this.inputDate
      ? formatDateTime(this.inputDate, yyyyMMdd2)
      : "";
  },
  computed: {
    mobile() {
      return ["sm", "xs"].includes(this.$vuetify.breakpoint.name);
    },
  },
  watch: {
    inputDate: {
      handler(val) {
        if (val) this.selectedDate = formatDateTime(val, yyyyMMdd2);
        else this.selectedDate = "";
      },
    },
  },
  methods: {
    changeDate() {
      this.menu = false;
      this.$emit("changeDate", this.selectedDate);
    },
    deleteFilter() {
      this.menu = false;
      this.$emit("deleteFilter");
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-ok {
  width: 110px;
}
.v-picker .v-picker__actions {
  align-self: center;
}
</style>
