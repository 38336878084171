import Vue from 'vue';
import Vuetify from 'vuetify/lib'
import AccountForNoImageIcon from '../components/icons/AccountForNoImageIcon'
import AddTodolistIcon from '../components/icons/AddTodolistIcon'
import ArchiveIcon from '../components/icons/ArchiveIcon'
import AttachedFileIcon from '../components/icons/AttachedFileIcon'
import BradcrumbTrailIcon from '../components/icons/BradcrumbTrailIcon'
import CalendarIcon from '../components/icons/CalendarIcon'
import CloseIcon from '../components/icons/CloseIcon'
import CommentIcon from '../components/icons/CommentIcon'
import CommonUserIcon from '../components/icons/CommonUserIcon'
import CopyIcon from '../components/icons/CopyIcon'
import CsvExportIcon from '../components/icons/CsvExportIcon'
import CsvImportIcon from '../components/icons/CsvImportIcon'
import DbTaskListIcon from '../components/icons/DbTaskListIcon'
import DeleteIcon from '../components/icons/DeleteIcon'
import ErrorIcon from '../components/icons/ErrorIcon'
import FavoriteActiveIcon from '../components/icons/FavoriteActiveIcon'
import FavoriteInactiveIcon from '../components/icons/FavoriteInactiveIcon'
import FilterIcon from '../components/icons/FilterIcon'
import HamburgerMenuIcon from '../components/icons/HamburgerMenuIcon'
import LinkIcon from '../components/icons/LinkIcon'
import NotificationIcon from '../components/icons/NotificationIcon'
import OutputIcon from '../components/icons/OutputIcon'
import OutputSignOnCalendarIcon from '../components/icons/OutputSignOnCalendarIcon'
import PasswordInvisibleIcon from '../components/icons/PasswordInvisibleIcon'
import PasswordVisibleIcon from '../components/icons/PasswordVisibleIcon'
import PulldownIcon from '../components/icons/PulldownIcon'
import ReorderIcon from '../components/icons/ReorderIcon'
import ReturnIcon from '../components/icons/ReturnIcon'
import ReviewCommentIcon from '../components/icons/ReviewCommentIcon'
import SaveTemplateIcon from '../components/icons/SaveTemplateIcon'
import SearchIcon from '../components/icons/SearchIcon'
import SettingsIcon from '../components/icons/SettingsIcon'
import SubmitOrConfirmIcon from '../components/icons/SubmitOrConfirmIcon'
import TagIcon from '../components/icons/TagIcon'
import TaskCategoryIcon from '../components/icons/TaskCategoryIcon'
import TaskExplanationIcon from '../components/icons/TaskExplanationIcon'
import TaskStartEndTimeIcon from '../components/icons/TaskStartEndTimeIcon'
import TaskWorktimeIcon from '../components/icons/TaskWorktimeIcon'
import TodoListIcon from '../components/icons/TodoListIcon'
import ToolIcon from '../components/icons/ToolIcon'
import TaskVisibleStatusIcon from "../components/icons/TaskVisibleStatusIcon"
import EditIcon from "../components/icons/EditIcon"
import MemoIcon from "../components/icons/MemoIcon"
import SpotTaskIcon from "../components/icons/SpotTaskIcon"
import RemandTaskIcon from "../components/icons/RemandTaskIcon"
import InformationIcon from "../components/icons/InformationIcon"
import AlignmentIcon from "../components/icons/AlignmentIcon"
import IndicatesCutoverIcon from "../components/icons/IndicatesCutoverIcon"
import TaskTypeIcon from "../components/icons/TaskTypeIcon"
import BeginnerTaskIcon from "../components/icons/BeginnerTaskIcon"
import ExpertTaskIcon from "../components/icons/ExpertTaskIcon"
import ScrollTopIcon from "../components/icons/ScrollTopIcon"
import ja from 'vuetify/es5/locale/ja.js'
Vue.use(Vuetify);

const opts = {
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1264,
      lg: 1904,
    },
    scrollBarWidth: 24,
  },
  icons: {
    values: {
      taskType : {
        component: TaskTypeIcon,
      },
      pulldown: {
        component: PulldownIcon,
      },
      accountfornoimage: {
        component: AccountForNoImageIcon,
      },
      addtodolist: {
        component: AddTodolistIcon
      },
      archive: {
        component: ArchiveIcon
      },
      attachedfile: {
        component: AttachedFileIcon
      },
      bradcrumbtrail: {
        component: BradcrumbTrailIcon
      },
      calendar: {
        component: CalendarIcon
      },
      close: {
        component: CloseIcon
      },
      comment: {
        component: CommentIcon
      },
      commonuser: {
        component: CommonUserIcon
      },
      copy: {
        component: CopyIcon
      },
      csvexport: {
        component: CsvExportIcon
      },
      csvimport: {
        component: CsvImportIcon
      },
      dbtasklist: {
        component: DbTaskListIcon
      },
      delete: {
        component: DeleteIcon
      },
      error: {
        component: ErrorIcon
      },
      favoriteactive: {
        component: FavoriteActiveIcon
      },
      favoriteinactive: {
        component: FavoriteInactiveIcon
      },
      filter: {
        component: FilterIcon
      },
      hamburgermenu: {
        component: HamburgerMenuIcon
      },
      link: {
        component: LinkIcon
      },
      notification: {
        component: NotificationIcon
      },
      output: {
        component: OutputIcon
      },
      outputsignoncalendar: {
        component: OutputSignOnCalendarIcon
      },
      passwordinvisible: {
        component: PasswordInvisibleIcon
      },
      passwordvisible: {
        component: PasswordVisibleIcon
      },
      reorder: {
        component: ReorderIcon
      },
      return: {
        component: ReturnIcon
      },
      reviewcomment: {
        component: ReviewCommentIcon
      },
      savetemplate: {
        component: SaveTemplateIcon
      },
      search: {
        component: SearchIcon
      },
      settings: {
        component: SettingsIcon
      },
      submitorconfirm: {
        component: SubmitOrConfirmIcon
      },
      tag: {
        component: TagIcon
      },
      taskcategory: {
        component: TaskCategoryIcon
      },
      taskexplanation: {
        component: TaskExplanationIcon
      },
      taskstartendtime: {
        component: TaskStartEndTimeIcon
      },
      taskworktime: {
        component: TaskWorktimeIcon
      },
      todolist: {
        component: TodoListIcon
      },
      tool: {
        component: ToolIcon
      },
      taskvisiblestatus: {
        component: TaskVisibleStatusIcon
      },
      edit: {
        component: EditIcon
      },
      memo: {
        component: MemoIcon
      },
      spottask: {
        component: SpotTaskIcon
      },
      remandtask: {
        component: RemandTaskIcon
      },
      information: {
        component: InformationIcon
      },
      indicatescutover: {
        component: IndicatesCutoverIcon
      },
      alignment: {
        component: AlignmentIcon
      },
      beginnertask: {
        component: BeginnerTaskIcon
      },
      experttask: {
        component: ExpertTaskIcon
      },
      scrolltop: {
        component: ScrollTopIcon
      }
    },
  },
  lang: {
    locales: {
      ja
    },
    current: 'ja'
  },
}

export default new Vuetify(opts);
