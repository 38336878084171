import store from '../store'
// import i18n from '../plugins/i18n'
import { MANAGER } from '../constants/role_code'
import { MASTER } from '../constants/role_code'
export default async (to, from, next) => {
  let user = store.getters['auth/user']
  if (user && (MANAGER === user.system_auth_id || MASTER ===  user.system_auth_id)) {
    next() // Allow access
  } else {
     // let msg = i18n.t('errors.permissions')
    // alert(msg)
    //TODO: Show alert and more...
    next({ name: 'home' }) // direct to page if denied
  }
}
