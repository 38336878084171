export const state = () => ({
  count: 0,
  unReadList: []
})

export const mutations = {
  SET_COUNT(state, payload) {
    state.count = payload
  },
  SET_UNREAD_LIST(state, payload) {
    state.unReadList = payload
  },
}

export const actions = {
  setCount({ commit }, count) {
    commit('SET_COUNT', count)
  },
  setUnReadList({ commit }, list) {
    commit('SET_UNREAD_LIST', list)
  },
}

export const getters = {
  getCount(state) {
    return state.count
  },
  getUnReadList(state) {
    return state.unReadList
  },
}
