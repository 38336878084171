<template>
  <v-dialog
    :value="openDialog"
    @input="$emit('input')"
    persistent
    max-width="500px"
    scrollable
  >
    <v-card>
      <v-card-title class="pb-3"> 日次予定を提出しますか？ </v-card-title>
      <v-card-subtitle class="mt-5 ml-4">
        <v-icon small>$calendar</v-icon>
        <span> {{ date }} </span>
      </v-card-subtitle>

      <!-- start of List comment !-->
      <v-card-text v-if="showOldComments" class="my-0 py-0 overflow-hidden">
        <v-card-subtitle class="font-weight-bold">
          ▼コメント履歴
        </v-card-subtitle>
        <CommentList :notifications="notifications"></CommentList>
        
      </v-card-text>
      <!-- end of List comment !-->
      <v-card-text class="my-0 py-0" :class="[
          $vuetify.breakpoint.mobile ? 'comment-height' : 'overflow-hidden',
        ]">
        <v-divider v-if="showOldComments" class="mx-3 mt-3 comments-divider"></v-divider>
        <v-card-subtitle v-if="showOldComments" class="pb-0 font-weight-bold">
          ▼コメント欄
        </v-card-subtitle>
        <v-container fluid>
          <v-textarea
            v-model="comment"
            clearable
            clear-icon="mdi-close"
            placeholder=""
            maxlength="512"
            counter
          >
          </v-textarea>
          <div class="__placeholder ml-3" v-if="!comment">
            <div>コメントを追加</div>
            <br />
            <div class="__line-2">
              ※提出期限12時以降に提出する場合は、必ず遅延した理由を記載の上、提出をしてください。
            </div>
            <div class="__line-3">
              (例)昨日早退し、本日午前休をいただいたため遅延しました。
            </div>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions :class="[{ 'responsive-button': $vuetify.breakpoint.mobile }]">
        <v-spacer></v-spacer>
        <v-btn depressed outlined @click="close()">{{
          $t("label.buttons.cancel")
        }}</v-btn>
        <v-btn depressed outlined @click="saveDraft()" class="font-weight-bold">
          {{ $t("label.buttons.save_draft") }}</v-btn
        >
        <div
          class="mr-2 btn-box submit-button"
          @mouseenter="isNoSubmmit() && submitValidation()"
          @mouseleave="isNoSubmmit() && hideValidation()"
        >
          <v-btn
            depressed
            outlined
            @click="isCanSubmit() && submit()"
            class="font-weight-bold"
            :class="[{ 'new-line': $vuetify.breakpoint.mobile }]"
            :disabled="!isEditing"
            ><v-icon class="blue--text mr-2" small right>$submitorconfirm</v-icon
            > {{ $t("label.buttons.submission") }} </v-btn
          >
          <SnackError ref="snackErrorComponent"></SnackError>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { backMixin } from "../../../mixins/back";
import {
  formatDateTime,
  parseDateTime,
} from "../../../helpers/datetime-helpers";
import * as Role from "../../../constants/role";
import { yyyyMMddEEEEE3, yyyyMMdd2 } from "../../../constants/df";
import { debounce_leading, isEmpty } from "../../../helpers/helpers";
import CommentList from "../components/CommentList";
import SnackError from "../../../components/SnackError";
export default {
  middleware: Role.AUTH,
  components: {
    CommentList,
    SnackError,
  },
  props: {
    show: Boolean,
    inputComment: String,
    selectedDate: String,
    notifications: Array,
    showOldComments: Boolean,
  },
  mixins: [backMixin],
  data() {
    return {
      openDialog: this.show,
      comment: "",
      isEditing: true,
    };
  },
  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },
  watch: {
    comment: {
      handler(val) {
        if (this.isAfterTwelveCurrentDay()) {
          this.isEditing = val && !isEmpty(val);
        }
        if (!isEmpty(this.inputComment) && !isEmpty(val) && val !== this.inputComment)
          this.$store.dispatch("save/setChangeFlag", true);
        if (isEmpty(this.inputComment) && !isEmpty(val))
          this.$store.dispatch("save/setChangeFlag", true);
      },
    },
    show: {
      handler() {
        this.openDialog = true;
        this.comment = this.inputComment;
        this.isEditing =
          !this.isAfterTwelveCurrentDay() && !this.isPreValidation();
          this.$store.dispatch("save/setChangeFlag", false);
      },
    },
  },
  computed: {
    ...mapGetters("save", ["getChangeFlag"]),
    date() {
      return formatDateTime(
        parseDateTime(this.selectedDate, yyyyMMdd2),
        yyyyMMddEEEEE3
      );
    },
  },
  methods: {
    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.openDialog = false;
            this.$emit("input");
          }, 100);
        },
        () => {}
      );
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.openDialog = false;
          this.$emit("input");
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },

    submit: debounce_leading(function () {
      this.openDialog = false;
      this.$store.dispatch("save/setChangeFlag", false);
      this.$emit("submit", this.comment);
    }),

    saveDraft: debounce_leading(function () {
      this.openDialog = false;
      this.$emit("saveDraft", this.comment);
    }),

    isCanSubmit() {
      return this.$root.$refs.scheduleComponent.isCanSubmit();
    },
    isAfterTwelveCurrentDay() {
      return this.$root.$refs.scheduleComponent.isAfterTwelveCurrentDay();
    },
    isPreValidation() {
      return this.$root.$refs.scheduleComponent.isPreValidation();
    },
    isNoSubmmit(){
      return this.$root.$refs.scheduleComponent.isNoSubmmit();
    },
    submitValidation(){
      let errors = this.$root.$refs.scheduleComponent.submitValidation();
      if (errors && errors.length > 0) {
        this.$refs.snackErrorComponent.show(
          true,
          true,
          this.showDrawerRight,
          errors,
          50000
        );
      }
    },
    hideValidation(){
      this.$refs.snackErrorComponent.hide();
    }
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>
<style lang="scss" src="@/assets/scss/_comment.scss" scoped></style>
<style scoped lang="scss">
.container {
  position: relative;
}
.__placeholder {
  position: absolute;
  left: 0;
  height: 100%;
  width: calc(100% - 60px);
  align-self: stretch;
  flex: 1 1 auto;
  color: rgb(133, 133, 133) !important;
  font-size: 1rem;
  top: 18px;
  div:first-child {
    margin-top: 14px;
  }
  .__line-2 {
    margin-top: 8px;
  }
  .__line-3 {
    margin-top: 8px;
  }
}
::v-deep .v-text-field__slot {
  textarea {
    z-index: 2;
    min-height: 170px;
  }
}

@media (max-width: 500px) {
  ::v-deep .v-text-field__slot {
    textarea {
      min-height: 190px;
    }
  }
}
@media (max-width: 382px) {
  ::v-deep .v-text-field__slot {
    textarea {
      min-height: 230px;
    }
  }
}
.submit-button{
  margin-left: 7px !important;
}
</style>

