<template>
  <v-container fill-height fluid class="pa-5">
    <v-card class="pa-3 mx-auto card-custom">
      <div>新しいパスワード</div>
      <div class="px-8">
        <v-text-field
          v-model="form.password"
          :error-messages="passwordErrors"
          :type="show1 ? 'text' : 'password'"
          name="password"
          maxlength="64"
          @input.native="allowHalfWidth($event, 'password')"
        >
          <template v-slot:append>
            <v-icon @click="show1 = !show1" v-show="show1"
              >$passwordvisible</v-icon
            >
            <v-icon @click="show1 = !show1" v-show="!show1"
              >$passwordinvisible</v-icon
            >
          </template>
          <template v-slot:message="{ message }">
            <v-icon small class="mr-2">$error</v-icon>
            <span>{{ message }}</span>
          </template>
        </v-text-field>
      </div>

      <div class="pl-8" v-if="passwordErrors.length <= 0">
        <p :class="[$vuetify.breakpoint.mobile ? 'f-14' : '']">大文字、小文字の英数字と記号を含めて</p>
        <p :class="[$vuetify.breakpoint.mobile ? 'f-14' : '']">8文字以上にしてください。</p>
      </div>

      <div class="mt-4">新しいパスワード（確認用）</div>

      <div class="px-8">
        <v-text-field
          v-model="form.confirmPassword"
          :error-messages="confirmPasswordErrors"
          :type="show2 ? 'text' : 'password'"
          name="confirmPasword"
          maxlength="64"
          @input.native="allowHalfWidth($event, 'confirmPassword')"
        >
          <template v-slot:append>
            <v-icon @click="show2 = !show2" v-show="show2"
              >$passwordvisible</v-icon
            >
            <v-icon @click="show2 = !show2" v-show="!show2"
              >$passwordinvisible</v-icon
            >
          </template>
          <template v-slot:message="{ message }">
            <v-icon small class="mr-2">$error</v-icon>
            <span>{{ message }}</span>
          </template>
        </v-text-field>
      </div>

      <div>
        <span class="error--text" v-show="passwordErrorsApi">
          <v-icon small>$error</v-icon>
          {{ passwordErrorsApi }}
        </span>
      </div>

      <div align="center" justify="center" class="mt-3">
        <v-btn tile @click="update" color="primary" depressed
          >パスワードを変更する</v-btn
        >
      </div>
    </v-card>
  </v-container>
</template>

<script>
import * as Role from "../../constants/role";
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import axios from "axios";
import { UPDATE_PASSWORD } from "../../constants/api";
import { mapGetters } from "vuex";
import { apiErrorMessages } from "../../helpers/messages";
import { debounce_leading, isJapanese, jReplace } from "../../helpers/helpers";
import { FC06 } from "../../constants/fc";
export default {
  middleware: Role.GUEST,
  layout: "blank",
  metaInfo() {
    return { title: this.$t("title.updatepassword") };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      password: { required, minLength: minLength(8) },
      confirmPassword: { sameAsPassword: sameAs("password") },
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    passwordErrors() {
      const errors = [];
      if (this.$v.form.password.$dirty) {
        !this.$v.form.password.required &&
          errors.push(this.$t("label.error.new_pwd"));
        !this.$v.form.password.minLength &&
          errors.push(this.$t("label.error.pwd_length"));
      }

      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (this.$v.form.confirmPassword.$dirty) {
        !this.$v.form.confirmPassword.sameAsPassword &&
          errors.push(this.$t("label.error.pwd_match"));
      }

      return errors;
    },
  },
  data: () => ({
    form: {
      password: "",
      confirmPassword: "",
    },
    passwordErrorsApi: "",
    show1: false,
    show2: false,
  }),
  methods: {
    update: debounce_leading(async function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        /// call api
        try {
          let comparisonKey = this.$route.query.comparison_key || false;
          await axios.put(UPDATE_PASSWORD(), {
            comparison_key: comparisonKey,
            password: this.form.password,
          });
          this.$router.push("login");
        } catch (error) {
          const { status } = error.response;
          let errorMsg = apiErrorMessages(this, FC06, status);
          if (errorMsg) {
            this.passwordErrorsApi = errorMsg;
          }
        }
      }
    }),

    allowHalfWidth(e, field) {
      if (isJapanese(e.target.value)) {
        this.form[field] = jReplace(e.target.value);
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-custom {
  width: 500px;
}
.f-14{
  font-size: 14px;
}
@media screen and (max-width: 524px) {
  .card-custom {
    margin: 0 12px;
  }
}
</style>
