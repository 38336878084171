<template>
  <v-dialog
    v-model="openCategory"
    persistent
    max-width="500px"
    scrollable
  >
    <v-card>
      <v-card-text v-if="!category.openChildCategory" class="card-content">
        <v-row align="center">
          <v-col class="text-left" cols="5" sm="4">大カテゴリ名<span class="red--text">*</span></v-col>
          <v-col class="text-left" cols="7" sm="8">
            <v-text-field
              v-model="category.category_primary_name"
              :error-messages="primaryNameErrors"
              maxlength="64"
              placeholder="入力してください"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn icon @click="category.openChildCategory = true">
              <v-icon class="mr-1">mdi-menu-down</v-icon>
            </v-btn>
            小カテゴリを作成
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row align="center">
          <v-col class="text-left" cols="5" sm="4">大カテゴリ名<span class="red--text">*</span></v-col>
          <v-col class="text-left" cols="7" sm="8">
            <v-combobox
              item-text="name"
              item-value="id"
              no-data-text="空欄"
              :items="parentCategories"
              :error-messages="primaryIdErrors"
              @change="primaryChange($event)"
              v-model="category.primaryCategory"
              clearable
              :menu-props="{
                maxWidth: 300,
                allowOverflow:true
              }"
            >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-content>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-title v-bind="attrs" v-on="on">{{
                          item.name
                        }}</v-list-item-title>
                      </template>
                      <span>{{ item.name }}</span>
                    </v-tooltip>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="text-left" cols="5" sm="4">小カテゴリ名<span class="red--text">*</span></v-col>
          <v-col class="text-left" cols="7" sm="8">
            <v-text-field
              v-model="category.category_secondary_name"
              :error-messages="secondaryNameErrors"
              maxlength="64"
              placeholder="入力してください"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn icon @click="category.openChildCategory = false">
              <v-icon class="mr-1">mdi-menu-up</v-icon>
            </v-btn>
            大カテゴリを作成
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="ml-auto">
        <v-btn @click="close" outlined depressed color="light">
          キャンセル
        </v-btn>
        <v-btn depressed @click="createCategory()" color="primary">登録</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import { backMixin } from "../../../mixins/back";
import { mapGetters } from "vuex";
import axios from "axios";
import {
  PRIMARY_CATEGORY_API,
  SECONDARY_CATEGORY_API,
  CATEGORIES_API,
} from "../../../constants/api";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC702, FC703, FC701 } from "../../../constants/fc";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import { debounce_leading, isEmptyNumber } from "@/helpers/helpers";
export default {
  mixins: [validationMixin, backMixin],
  validations: {
    category: {
      category_primary_name: {
        required: requiredIf((category) => {
          return !category.openChildCategory;
        }),
      },
      category_secondary_name: {
        required: requiredIf((category) => {
          return category.openChildCategory;
        }),
      },
      primaryCategory: {
        required: requiredIf((category) => {
          return category.openChildCategory;
        }),
      },
      category_primary_id: {
        required: requiredIf((category) => {
          return category.openChildCategory;
        }),
      },
    },
  },
  props: {
    show: Boolean,
  },
  watch: {
    show: {
      async handler() {
        this.openCategory = true;
        this.isEditing = false;
        this.$v.$reset();
        this.category = { openChildCategory: false, primaryCategory: null };
        await this.getCategories();
      },
    },
    category: {
      deep: true,
      handler() {
        this.isEditing = this.compareValue();
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
  },
  data() {
    return {
      category: { openChildCategory: false },
      openCategory: false,
      parentCategories: [],
      childCategories: [],
      departments: [],
      isEditing: false,
      primaryCategory: null,
    };
  },
  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },
  computed: {
    ...mapGetters("save", ["getChangeFlag"]),
    ...mapGetters("common", ["loginInfo"]),

    primaryNameErrors() {
      const errors = [];
      if (this.$v.category.category_primary_name.$dirty) {
        !this.$v.category.category_primary_name.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },

    secondaryNameErrors() {
      const errors = [];
      if (this.$v.category.category_secondary_name.$dirty) {
        !this.$v.category.category_secondary_name.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },

    primaryIdErrors() {
      const errors = [];
      if (this.$v.category.primaryCategory.$dirty) {
        !this.$v.category.primaryCategory.required &&
          errors.push(this.$t("label.error.required"));
        isEmptyNumber(this.category.category_primary_id) &&
          errors.push("登録されている大カテゴリのみ選択できます。");
      }

      return errors;
    },
  },
  methods: {
    primaryChange(e) {
      this.category.category_primary_id = e ? e.id : null;
    },
    compareValue() {
      if (this.category.openChildCategory) {
        return (
          !(
            this.category.category_primary_id == undefined ||
            this.category.category_primary_id == null
          ) ||
          !(
            this.category.category_secondary_name == undefined ||
            this.category.category_secondary_name == ""
          )
        );
      } else {
        return !(
          this.category.category_primary_name == undefined ||
          this.category.category_primary_name == ""
        );
      }
    },

    createCategory : debounce_leading(async function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        if (this.category.openChildCategory) {
          await this.createSubCategory();
        } else {
          await this.createMainCategory();
        }
      }
    }),

    async getCategories() {
      try {
        const { data, status } = await axios.get(CATEGORIES_API());
        this.parentCategories = status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC701, status);
      }
    },

    async createMainCategory() {
      try {
        let params = {
          name: this.category.category_primary_name,
        };
        const { data } = await axios.post(PRIMARY_CATEGORY_API(), params);
        this.category.category_primary_id = data.result.id;
        this.$store.dispatch("save/setChangeFlag", false);
        this.$toast(
          `${this.category.category_primary_name}を追加しました。`,
          3000
        );
        this.$emit("primaryCategoryCreated", this.category);
        this.openCategory = false;
      } catch (error) {
        const { status } = error.response;
        if (status === 404) {
          this.$alert(error.response.data.message);
        } else {
          apiErrorMessages(this, FC702, status);
        }
      }
    },

    async createSubCategory() {
      try {
        let params = {
          name: this.category.category_secondary_name,
          primary_id: this.category.category_primary_id,
        };
        const { data } = await axios.post(SECONDARY_CATEGORY_API(), params);
        this.category.category_primary_name = this.parentCategories.find(
          (x) => x.id === this.category.category_primary_id
        )?.name;
        this.category.category_secondary_id = data.result.id;
        this.$store.dispatch("save/setChangeFlag", false);
        this.$toast(
          `${this.category.category_secondary_name}を追加しました。`,
          3000
        );
        this.$emit("secondaryCategoryCreated", this.category);
        this.openCategory = false;
      } catch (error) {
        const { status } = error.response;
        if (status === 404) {
          this.$alert(error.response.data.message);
        } else {
          apiErrorMessages(this, FC703, status);
        }
      }
    },

    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.openCategory = false;
          }, 100);
        },
        () => {}
      );
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.$v.$reset();
          this.openTask = false;
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>

<style>
</style>
