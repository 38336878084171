<template>
  <v-dialog scrollable :value="show" max-width="500px" persistent>
    <v-card>
      <v-card-title class="font-weight-bold">
        <span v-if="mode == 'add'">新規Gr.追加</span>
        <span v-else>Gr.情報編集</span>
      </v-card-title>
      <v-card-text>
        <v-row
          class="form-item"
          align="center"
          justify="center"
          v-if="mode == 'add'"
        >
          <v-col md="4">部署名<span class="red--text">*</span></v-col>
          <v-col md="8">
            <v-select
              v-model="localGroup.department_id"
              :items="departments"
              item-text="name"
              item-value="id"
              clearable
              :error-messages="departmentErrors"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="form-item" align="center" justify="center">
          <v-col md="4">Gr.名<span class="red--text">*</span></v-col>
          <v-col md="8">
            <v-text-field
              v-model="localGroup.group_name"
              :error-messages="groupNameErrors"
              maxlength="64"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="form-item" align="center" justify="center">
          <v-col md="4">GM</v-col>
          <v-col md="8">
            <v-autocomplete
              :items="items"
              v-model="localGroup.group_manager_id"
              append-icon="$search"
              item-text="name"
              item-value="id"
              clearable
              open-on-clear
              :search-input.sync="search"
            >
              <template v-slot:no-data>
                <div>候補者がいません</div>
              </template>
              <v-row class="mb-2" slot="prepend-item" v-if="searchData.length > 0">
                <v-col class="pl-6">
                  {{ this.searchData.length }}
                  {{ mode == "add" ? "件の検索候補" : "件の検索候補" }}
                </v-col>
              </v-row>
              <template v-slot:item="{ item }">
                <div class="d-block mb-2">
                  <v-row>
                    <v-col cols="12">
                      <strong>{{ item.name }}</strong>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex">
                    <v-col>
                      <span>{{ item.department_name }}</span>
                      <span class="ml-2"> {{ item.position_name }}</span>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="form-item mt-2">
          <v-col md="12" align="center" justify="center">
            <v-btn
              color="primary"
              width="90px"
              class="grey darken-1"
              @click="submit()"
              >{{
                this.mode == "add" ? $t("label.buttons.register") : "保存"
              }}</v-btn
            >
            <v-btn width="90px" class="ml-2" @click="close()">{{
              $t("label.buttons.cancel")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { BELONG_GROUP, GET_GROUP_HEAD } from "@/constants/api";
import { debounce_leading, deepClone, isEmpty } from "../../../helpers/helpers";
import axios from "axios";
import { STATUS_NO_CONTENT } from "@/constants/status";
import { apiErrorMessages } from "@/helpers/messages";
import { FC111, FC303 } from "@/constants/fc";
import { validationMixin } from "vuelidate";
import { backMixin } from "@/mixins/back";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search: null,
      groupHeads: [],
      isEditing: false,
      originalGroup: {},
      localGroup: {},
      entries: [],
      searchData: [],
    };
  },
  props: {
    show: Boolean,
    departments: Array,
    group: Object,
    mode: String,
  },
  watch: {
    localGroup: {
      deep: true,
      handler() {
        this.search = "";
        this.isEditing = this.compareValue();
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    group(val) {
      this.originalGroup = deepClone(this.group);
      this.localGroup = deepClone(val);
    },
    show(val) {
      if (val) this.getGroupHeads();
      this.$v.$reset();
      this.isEditing = false;
    },
    search(val) {
      // Items have already been requested
      this.searchData = isEmpty(val)
        ? this.groupHeads
        : this.groupHeads.filter(
            (x) =>
              isEmpty(x.name) ||
              x.name.toLocaleLowerCase().indexOf(val.toLocaleLowerCase()) > -1
          ) || [];
    },
  },
  computed: {
    ...mapGetters("save", ["getChangeFlag"]),
    groupNameErrors() {
      const errors = [];
      if (this.$v.localGroup.group_name.$dirty) {
        !this.$v.localGroup.group_name.required &&
          errors.push(this.$t("label.error.required"));
      }
      return errors;
    },
    departmentErrors() {
      const errors = [];
      if (this.$v.localGroup.department_id.$dirty) {
        !this.$v.localGroup.department_id.required &&
          errors.push(this.$t("label.error.required"));
      }
      return errors;
    },
    items() {
      return this.entries.map((entry) => {
        return Object.assign({}, entry);
      });
    },
  },
  mixins: [validationMixin, backMixin],
  validations: {
    localGroup: {
      group_name: { required },
      department_id: { required },
    },
  },
  methods: {
    compareValue() {
      return (
        JSON.stringify({
          group_name: this.localGroup.group_name,
          group_manager_id: this.localGroup.group_manager_id,
          department_id: this.localGroup.department_id,
        }) !==
        JSON.stringify({
          group_name: this.originalGroup.group_name,
          group_manager_id: this.originalGroup.group_manager_id,
          department_id: this.originalGroup.department_id,
        })
      );
    },
    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.$emit("close");
          }, 100);
        },
        () => {}
      );
    },

    submit: debounce_leading(async function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.mode == "add") await this.addGroup();
        else await this.modifyGroup();

        this.$emit("submit", this.localGroup);
      }
    }),
    isEmpty(val) {
      return isEmpty(val);
    },

    getGroupHeads: debounce_leading(async function () {
      try {
        const { data, status } = await axios.get(GET_GROUP_HEAD());
        this.groupHeads = status === STATUS_NO_CONTENT ? [] : data.result;
        this.entries = deepClone(this.groupHeads);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC111, status);
      }
    }),

    async addGroup() {
      try {
        const { data } = await axios.post(BELONG_GROUP(), {
          name: this.localGroup.group_name,
          manager_id:
            this.localGroup.group_manager_id == null
              ? ""
              : this.localGroup.group_manager_id,
          department_id: this.localGroup.department_id,
        });

        // Setting for merge to list
        this.localGroup.department_name = this.departments.filter(
          (item) => item.id == this.localGroup.department_id
        )[0].name;
        this.localGroup.group_manager_name = "";
        if (!isEmpty(this.localGroup.group_manager_id)) {
          this.localGroup.group_manager_name = this.groupHeads.filter(
            (item) => item.id == this.localGroup.group_manager_id
          )[0].name;
        }
        this.localGroup.group_count = 0;
        this.localGroup.group_id = data.result.id;
        this.$toast(`${this.localGroup.group_name}を追加しました。`, 3000);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC303, status);
      }
    },

    async modifyGroup() {
      try {
        await axios.put(BELONG_GROUP(this.localGroup.group_id), {
          department_id: this.localGroup.department_id,
          name: this.localGroup.group_name,
          manager_id:
            this.localGroup.group_manager_id == null
              ? ""
              : this.localGroup.group_manager_id,
        });

        // Setting for merge to list
        this.localGroup.group_manager_name = "";
        if (!isEmpty(this.localGroup.group_manager_id)) {
          this.localGroup.group_manager_name = this.groupHeads.filter(
            (item) => item.id == this.localGroup.group_manager_id
          )[0].name;
        }
        this.$toast(`${this.localGroup.group_name}を編集しました。`, 3000);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC303, status);
      }
    },
  },
};
</script>
