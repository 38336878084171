<template>
  <v-dialog v-model="visible" persistent width="500px">
    <v-form @submit.prevent="handleClick('ok')" ref="form" v-model="valid">
      <v-card>
        <v-card-title class="pt-10 px-10">
          <p class="v-application" v-for="(p, i) in content" :key="i">
            {{ p }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12 d-flex align-center">
                <span class="red--text mr-2">*</span>
                <v-text-field
                  v-model="value"
                  :rules="validations"
                  type="text"
                  :maxlength="maxlength"
                  @input.native="change()"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="py-2">
          <v-spacer></v-spacer>
          <v-btn :color="cancelBtnType" tile @click="handleClick('cancel')">
            {{ cancelBtnText }}
          </v-btn>

          <div class="ml-2" @mouseenter="$refs.form.validate()">
            <v-btn
              tile
              :disabled="!valid"
              @click="handleClick('ok')"
              :color="okBtnType"
            >
              {{ okBtnText }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { validationMixin } from "vuelidate";
import { isEmpty } from "@/helpers/helpers"
export default {
  name: "PromptBox",
  mixins: [validationMixin],
  props: {},
  data() {
    return {
      visible: false,
      message: "",
      dialogType: "prompt",
      type: "warning",
      okBtnText: "保存",
      okBtnType: "primary",
      cancelBtnText: this.$t("label.buttons.cancel"),
      cancelBtnType: "ligth",
      maxlength: 20,
      value: "",
      originalValue: "",
      valid: false,
      validations: [],
      dbData: [],
      duplicatedError: ""
    };
  },
  computed: {
    content() {
      if (!Array.isArray(this.message)) {
        return [this.message];
      }
      return this.message;
    },
  },
  watch: {
    visible() {
      if (this.$refs.form) this.$refs.form.resetValidation();
    },
  },
  methods: {
    handleClick(type) {
      if (type === "cancel") {
        if (this.originalValue !== this.value) {
          this.$confirm(this.$t("label.confirm.unsave"), {
            buttons: {
              ok: {
                text: this.$t("label.buttons.discard"),
                type: "primary",
              },
            },
            rtl: true,
          })
            .then(() => {
              this.callback(type, this.value);
            })
            .catch(() => {
              return;
            });
        } else {
          this.callback(type, this.value);
        }
      } else if (!this.$refs.form.validate()) return;
      else {
        let found = false;
        if (!isEmpty(this.dbData)) {
          found = this.dbData.some((x) => x.name === this.value);
          this.validations = [
            !found || this.duplicatedError,
          ];
        }
        this.callback(type, this.value, found);
      }
    },
    change() {
      if (!isEmpty(this.dbData)) {
        this.validations = [!!this.value || this.$t("label.error.required")];
      }
    },
  },
};
</script>
<style scoped>
p:not(:first-child) {
  font-size: 12px;
}
</style>
