<template>
  <v-dialog
    v-model="openDbTask"
    persistent
    max-width="500px"
    :fullscreen="$vuetify.breakpoint.mobile"
    scrollable
  >
    <v-card class="hidden">
      <v-card-title class="card-header py-2">
        <v-btn @click="openDbTask = false" class="ml-auto" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row class="py-4">
          <v-col class="d-flex align-center">
            <div @click="isFavorite(item)">
              <v-icon v-if="item.is_favorite" small class="yellow--text"
                >$favoriteactive</v-icon
              >
              <v-icon v-else small class="yellow--text"
                >$favoriteinactive</v-icon
              >
            </div>
            <span class="ml-2 work-break">{{ item.name }}</span>
            <span :class="[{ detail: !isDetailEmpty() }]">{{
              item.detail
            }}</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="py-2">
          <v-col class="d-flex align-center">
            <v-icon small>$taskexplanation</v-icon>
            <span class="ml-2 work-break">{{ item.description }}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="header">
              <v-icon small>$taskcategory</v-icon>
              <span class="ml-2"> {{ item.category_primary_name }}</span>
            </div>
            <div class="mt-2 ml-5 category">
              <span class="ml-2"> {{ item.category_secondary_name }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex align-center">
            <v-icon small>$taskType</v-icon>
            <span class="ml-2 work-break"
              >{{ labelType }} : {{ item.task_type_name || "" }}</span
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="align-center">
            <div class="d-flex">
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2">標準工数</span>
              <span class="mx-2">{{ item.cost }}m/回</span>
            </div>
            <div class="mt-2 ml-7">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="work-break">{{ item.unit || "" }}</span>
            </div>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col class="py-0">
            <div class="header d-flex align-center">
              <v-icon small>$output</v-icon>
              <span class="ml-2">アウトプット</span>
              <v-checkbox
                v-model="item.is_need_output"
                class="ml-3"
                :label="!!item.is_need_output ? '必要' : '不要'"
                readonly
              ></v-checkbox>
            </div>
            <div class="mt-2 ml-2 category" v-if="!!item.is_need_output">
              アウトプット内容： {{ item.output_detail }}
            </div>
          </v-col>
        </v-row> -->

        <v-row class="py-2">
          <v-col cols="12">
            <v-icon small class="mr-2">$tag</v-icon>
            <v-btn tile color="ligth" @click="createTag()"> タグを追加 </v-btn>
          </v-col>

          <v-col cols="12" class="py-0">
            <v-container justify="space-around">
              <v-sheet class="px-1">
                <v-chip-group mandatory>
                  <v-tooltip bottom v-for="tag in item.tags" :key="tag.tag_id">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        close
                        @click:close="deleteTag(tag, item)"
                        close-icon="mdi-close"
                      >
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 100px"
                        >
                          {{ tag.tag_name }}
                        </span>
                      </v-chip>
                    </template>
                    <span>{{ tag.tag_name }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>
        <v-card-actions v-if="$vuetify.breakpoint.mobile && isAllowDrag()">
          <v-btn
            tile
            depressed
            class="ml-auto"
            color="primary"
            @click="showDatabaseEditTask = !showDatabaseEditTask"
          >
            カレンダーに配置
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <tag-list
      v-model="openTagDialog"
      :items="tagList"
      :existItems="existItems"
      @selectTag="selectTagModal"
      @deleteTag="deleteTagModal"
      @updateTag="updateTagModal"
    ></tag-list>
    <DatabaseEditTask
      :show="showDatabaseEditTask"
      :standarId="standarId"
      @addToCalendar="addToCalendar($event)"
    ></DatabaseEditTask>
  </v-dialog>
</template>

<script>
import {
  GET_DB_TASKS_API,
  UPDATE_DB_TASKS_API,
  TAG_API,
} from "../../../constants/api";
import axios from "axios";
import TagList from "./TagList.vue";
import { mapGetters } from "vuex";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC708, FC709, FC813, FC815 } from "../../../constants/fc";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import DatabaseEditTask from "./DatabaseEditTask";
import { debounce_leading, isEmpty } from "@/helpers/helpers";
import { taskMixin } from '@/mixins/task.js';
export default {
  components: { TagList, DatabaseEditTask },
  name: "DatabaseTask",
  props: {
    show: Boolean,
    standarId: Number,
    isPlan: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [taskMixin],
  data() {
    return {
      item: {},
      openTagDialog: false,
      openDbTask: false,
      showDatabaseEditTask: false,
      tagList: [],
      existItems: [],
    };
  },
  watch: {
    async show() {
      this.openDbTask = true;
      await this.getData();
    },
  },

  methods: {
    isDetailEmpty() {
      return isEmpty(this.item.detail);
    },
    isAllowDrag() {
      if (this.isPlan){
        return (
          this.$root.$refs.scheduleComponent.isSelf() &&
          this.$root.$refs.scheduleComponent.isNoSubmmit() &&
          this.$root.$refs.scheduleComponent.isCanSubmit()
        );
      }
      return (
        this.$root.$refs.actualComponent.isNoSubmmit() &&
        this.$root.$refs.actualComponent.isCanSubmit()
      );
    },
    addToCalendar(item) {
      this.openDbTask = false;
      this.$emit("addToCalendar", item);
    },
    createTag: debounce_leading(async function () {
      try {
        const { data, status } = await axios.get(
          `${TAG_API()}` + `?user_id=${this.user.user_id}`
        );
        this.tagList =
          status === STATUS_NO_CONTENT
            ? []
            : data.result.map((x) => {
                let tag = { ...x, ...{ check: false } };
                return tag;
              });
        this.existItems = this.item.tags;
        this.openTagDialog = true;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC813, status);
      }
    }),
    async getData() {
      try {
        const { data, status } = await axios.get(
          `${GET_DB_TASKS_API()}` +
            `?user_id=${this.user.user_id}&standard_task_id=${this.standarId}`
        );
        let dbTask = status === STATUS_NO_CONTENT ? [] : data.result[0];
        this.item = dbTask;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC708, status);
      }
    },
    async isFavorite(item) {
      try {
        item.is_favorite = !item.is_favorite;
        await axios.put(
          UPDATE_DB_TASKS_API(this.user.user_id, this.standarId),
          {
            is_favorite: +item.is_favorite,
          }
        );

        this.$emit("isFavorite", item);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC709, status);
      }
    },
    async deleteTag(tag, item) {
      try {
        await axios.delete(TAG_API(tag.tag_id), {
          data: {
            standard_task_id: item.id,
          },
        });
        item.tags = item.tags.filter((x) => x.tag_id !== tag.tag_id);
        this.$emit("updateTag", item);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC815, status);
      }
    },
    deleteTagModal(tag) {
      this.item.tags = this.item.tags.filter((x) => x.tag_id !== tag.id);
    },
    updateTagModal(tag) {
      let t = this.item.tags.filter((x) => x.tag_id === tag.id)[0];
      if (t) t.tag_name = tag.name;
    },
    async selectTagModal(items) {
      try {
        await axios.put(UPDATE_DB_TASKS_API(this.user.user_id, this.item.id), {
          tags: items.map((x) => {
            return {
              id: x.id,
              name: x.name,
            };
          }),
        });

        this.item.tags = this.item.tags.concat(
          items.map((x) => {
            return {
              tag_id: x.id,
              tag_name: x.name,
            };
          })
        );
        this.$emit("updateTag", this.item);
        this.openTagDialog = false;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC709, status);
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}
.detail {
  margin-left: auto;
  margin-right: 0;
  min-width: 200px;
  max-width: 250px;
}
.work-break {
  word-break: break-all;
}
</style>
