<template>
  <v-menu bottom offset-y left :close-on-content-click="false" v-model="show">
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        v-show="getCount > 0"
        class="mr-4"
        :content="getCount"
        color="orange"
        overlap
      >
        <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="getNotification()"
          >$notification</v-icon
        >
      </v-badge>
      <v-icon
        v-show="getCount <= 0"
        class="mr-4"
        v-bind="attrs"
        v-on="on"
        @click="getNotification()"
        >$notification</v-icon
      >
    </template>

    <v-card class="notification--card">
      <v-card-title class="grey lighten-2 notification-title">
        <span>お知らせ</span>
        <v-icon small @click="close()">$close</v-icon>
      </v-card-title>

      <v-list
        class="notification--list"
        v-if="!isEmpty(notificationUnconfirmList)"
      >
        <v-list-item
          :key="item.id"
          v-for="item in notificationUnconfirmList"
          link
          :href="moveTo(item)"
          class="notification-item"
        >
          <v-list-item-avatar color="grey" size="40" class="action">
            <v-img :src="item.userImage"></v-img>
          </v-list-item-avatar>

            
              <v-list-item-content>
                <v-list-item-subtitle v-text="item.title"></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-text="item.subject_format"
                ></v-list-item-subtitle>
                <v-list-item-subtitle class="mt-1 ml-4" v-if="isShowTasks(item)">
                  <li
                    class="text--primary mb-1 ml-1"
                    :key="index"
                    v-for="(task, index) in item.tasks"
                    v-text="displayRejectTask(task)"
                  ></li>
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  class="text--primary"
                  v-text="item.comment"
                ></v-list-item-subtitle>
              </v-list-item-content>

          <v-list-item-action class="action">
            <v-list-item-action-text
              v-text="item.updated_at_format"
            ></v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-card-text v-else class="mt-5 text-center">
        未確認のお知らせはありません。
      </v-card-text>

      <v-card-actions class="notification--footer">
        <a href="/notification">お知らせ一覧</a>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import axios from "axios";
import { debounce_leading, deepClone, isEmpty } from "../helpers/helpers";
import { formatDistanceToNowStrict } from "date-fns";
import { ja } from "date-fns/locale";
import { parseDateTime } from "../helpers/datetime-helpers";
import { yyyyMMddHHmmss3 } from "../constants/df";
import { NOTIFICATION_API } from "../constants/api";
import { FC501, FC502 } from "../constants/fc";
import { blobToBase64 } from "../helpers/helpers";
import { STATUS_NO_CONTENT } from "../constants/status";
import { apiErrorMessages } from "../helpers/messages";
import * as SystemConstant from "@/constants/system";
import { mapGetters } from "vuex";
import { taskMixin } from "@/mixins/task.js";
import { queryRouterMixin } from "@/mixins/queryRouter.js";

const DEFAULT_IMAGE = "/assets/images/account_for_no_image.png";
export default {
  mixins: [taskMixin, queryRouterMixin],
  data() {
    return {
      notificationUnconfirmList: [],
      show: false,
    };
  },
  computed: {
    ...mapGetters("common", ["loginInfo"]),
    ...mapGetters("notification", ["getCount"]),
  },
  methods: {
    async redirectTo(event, item) {
      this.moveTo(event, item);

      // Confirm notice
      await this.confirmed(item);

      this.show = false;
    },

    moveTo(item) {

      // Redicrect
      const statusNotice = !item.comment_notice_id;
      let query = {};
      if (this.isReadable(item)) {
        query["is_verified"] = "1";
        if (item.status_notice_id) {
          query["status_notice_id"] = item.status_notice_id;
        }
        if (item.comment_notice_id) {
          query["comment_notice_id"] = item.comment_notice_id;
        }
      }

      let router = null;
      switch (true) {
        case statusNotice && isEmpty(item.actual_id):
          query["fromNotice"] = 1;
          router = this.$router.resolve({
            path: `/schedule/${item.schedule_id}`,
            query: this.setQuery(query),
          });
          break;

        case statusNotice:
          query["sid"] = item.schedule_id;
          query["fromNotice"] = 1;
          router = this.$router.resolve({
            path: `/actual/${item.actual_id}`,
            query: this.setQuery(query),
          });
          break;

        case !statusNotice && isEmpty(item.actual_id):
          query["taskId"] = item.schedule_task_id;
          router = this.$router.resolve({
            path: `/schedule/${item.schedule_id}`,
            query: this.setQuery(query),
          });
          break;

        case !statusNotice:
          query["sid"] = item.schedule_id;
          query["taskId"] = item.actual_task_id;
          router = this.$router.resolve({
            path: `/actual/${item.actual_id}`,
            query: this.setQuery(query),
          });
          break;

        default:
          break;
      }
      if (router) {
        return router.href;
      }
      return router;
    },

    isEmpty(val) {
      return isEmpty(val);
    },

    getNotification: debounce_leading(async function () {
      try {
        const { data, status } = await axios.get(NOTIFICATION_API(), {
          params: {
            is_verified: 0,
            sort: "new", // TODO:
          },
        });
        let list = status === STATUS_NO_CONTENT ? [] : data.result.notices;
        list = await this.customerList(list);
        this.$store.dispatch(
          "notification/setCount",
          status === STATUS_NO_CONTENT ? 0 : data.result.total_count
        );
        this.$store.dispatch(
          "notification/setUnReadList",
          status === STATUS_NO_CONTENT ? [] : data.result.notices
        );
        // Add images to list
        for (let index = 0; index < list.length; index++) {
          const item = list[index];
          let existItem = list.find(
            (x) =>
              x.process_user_image === item.process_user_image &&
              x.id !== item.id
          );

          if (existItem && existItem.userImage) {
            item.userImage = existItem.userImage;
          } else {
            item.userImage = await this.getImageByPath(item.process_user_image);
          }
        }
        this.notificationUnconfirmList = deepClone(list);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC501, status);
      }
    }),

    //Customer data for list
    async customerList(list) {
      list.map((item, key) => {
        item.id = key;
        item.updated_at_format = this.customerFormatDateTime(item.updated_at); // Add format time
        item.title = item.process_user_name + "さんが" + item.process_name;
        item.subject_format = item.subject
          ? this.formatSubject(item.subject)
          : "";
      });

      return list;
    },

    //Format time (dateTime : yyyyMMddHHmmss)
    customerFormatDateTime(dateTime) {
      return formatDistanceToNowStrict(
        parseDateTime(dateTime, yyyyMMddHHmmss3),
        {
          addSuffix: true,
          roundingMethod: "round",
          locale: ja,
        }
      );
    },

    // get image
    async getImageByPath(imagePath) {
      let userImage = DEFAULT_IMAGE;

      try {
        if (!isEmpty(imagePath)) {
          const { data, status } = await axios.get(imagePath, {
            responseType: "blob",
          });
          userImage =
            status === STATUS_NO_CONTENT
              ? DEFAULT_IMAGE
              : await blobToBase64(data);
        }
      } catch (error) {
        // const { status } = error.response;
        // apiErrorMessages(this, FC1001, status);
      }

      return userImage;
    },

    // Call Api comfirm notice
    async confirmed(notice) {
      if (!this.isReadable(notice)) return;

      try {
        await axios.put(NOTIFICATION_API(), {
          notices: [
            {
              status_notice_id: notice.status_notice_id || null,
              comment_notice_id: notice.comment_notice_id || null,
              is_verified: 1,
            },
          ],
        });
        this.$store.dispatch("notification/setCount", this.getCount - 1);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC502, status);
      }
    },

    // Check mask as read
    isReadable(item) {
      return !!item.can_verified;
    },

    close() {
      this.show = false;
    },

    // displayRejectTask(task, item) {
    //   if (item.comment_notice_id) {
    //     return `${task?.name}`
    //   }
    //   return `${task?.name} ${task?.detail}`
    // },

    displayRejectTask(task) {
      return `${task?.name} ${task?.detail}`;
    },

    isShowTasks(task) {
      return (
        task.comment_notice_id ||
        +task.phase_id === SystemConstant.PHASE_ID_PRIMARY_REJECT ||
        +task.phase_id === SystemConstant.PHASE_ID_2_NEXT_REJECT ||
        (+task.phase_id === SystemConstant.PHASE_ID_VERIFIED && task.tasks.length > 0)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.notification--footer {
  text-align: center;
  display: block;
  a {
    text-decoration: none;
  }
}
.notification--card {
  width: 500px;
  max-height: 80vh;
  .notification-title {
    display: flex;
    justify-content: space-between;
  }
  .notification--list {
    max-height: calc(80vh - 64px - 40px);
    overflow-y: auto;
    .action {
      align-self: start;
    }
    .notification-item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      .v-list-item__title,
      .v-list-item__subtitle {
        white-space: unset;
      }
    }
  }
}
</style>
