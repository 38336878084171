import Login from '../views/auth/Login'
import ForgotPasword from '../views/auth/ForgotPasword'
import ExpiredToken from '../views/auth/ExpiredToken'
import UpdatePassword from '../views/auth/UpdatePassword'
import Profile from '../views/account/Profile'
import AccountList from '../views/account/AccountList'
import DailyPlan from '../views/schedule/index'
import DailyActual from '../views/actual/index'
import ReupdatePassword from '../views/account/password/ReupdatePassword'
import Unauthorized from '../views/Unauthorized'
import Notification from '../views/notification'
import DepartmentGroup from '../views/department'
import Mangagement from "../views/dbmaster/Mangagement"
import EvaluationManagement from "../views/evaluation/EvaluationManagement"
import CategoryManagement from "../views/category/CategoryManagement"
import StatusLogsManagement from "../views/status-logs/StatusLogsManagement"
import UserSkillsManagement from "../views/user-skills/UserSkillsManagement"
import WeeklySchedules from '../views/schedule/WeeklySchedules'
import WeeklyActuals from '../views/actual/WeeklyActuals'
import StatusSchedules from '../views/status/StatusSchedules'
import StatusActuals from '../views/status/StatusActuals'

export default [
  {
    path: '/',
    name: 'home',
    component: Login
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/reset-password',
    name: 'forgotpassword',
    component: ForgotPasword
  },
  {
    path: '/expired',
    name: 'expired',
    component: ExpiredToken
  },
  {
    path: '/update-password',
    name: 'updatepassword',
    component: UpdatePassword
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/profiles',
    name: 'profiles',
    component: AccountList
  },
  {
    path: '/schedule/:id',
    name: 'plan',
    component: DailyPlan
  },
  {
    path: '/schedule',
    name: 'my-plan',
    component: DailyPlan
  },
  {
    path: '/actual/:id',
    name: 'actual',
    component: DailyActual,
  },
  {
    path: '/actual',
    name: 'my-actual',
    component: DailyActual,
  },
  {
    path: '/reupdate-password/:id',
    name: 'reupdate-password',
    component: ReupdatePassword,
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: Mangagement,
  },
  {
    path: '/productivity',
    name: 'productivity',
    component: EvaluationManagement,
  },
  {
    path: '/categories',
    name: 'categories',
    component: CategoryManagement,
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: Unauthorized,
  },
  {
    path: '/notification',
    name: 'notification',
    component: Notification,
  },
  {
    path: '/belong',
    name: 'department',
    component: DepartmentGroup,
  },
  {
    path: '/status-logs',
    name: 'status-logs',
    component: StatusLogsManagement,
  },
  {
    path: '/user-skills',
    name: 'user-skills',
    component: UserSkillsManagement,
  },
  {
    path: '/weekly-schedules',
    name: 'weekly-schedules',
    component: WeeklySchedules,
  },
  {
    path: '/weekly-actuals',
    name: 'weekly-actuals',
    component: WeeklyActuals,
  },
  {
    path: '/status-schedules',
    name: 'status-schedules',
    component: StatusSchedules,
  },
  {
    path: '/status-actuals',
    name: 'status-actuals',
    component: StatusActuals,
  },
]
