<template>
  <v-dialog
    v-model="openTodoTask"
    persistent
    max-width="600px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
    content-class="svTask"
    v-dialog-drag="{
      dialog: 'svTask',
    }"
    overlay-color="transparent"
    overlay-opacity="1"
  >
    <v-card class="hidden" :id="parentId">
      <v-card-title class="card-header py-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="isEditComment()"
              @click="copyLink()"
              class="ml-auto"
              v-bind="attrs"
              v-on="on"
              icon
              color="gray"
            >
              <v-icon small>$link</v-icon>
            </v-btn>
          </template>
          <span>タスクのリンクをコピー</span>
        </v-tooltip>
        <v-btn
          @click="close()"
          :class="{ 'ml-auto': !isEditComment() }"
          icon
          color="gray"
        >
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row class="py-0 my-0">
          <!-- <v-col class="d-flex align-center"> -->
          <v-col cols="12" class="py-4 d-flex align-center">
            <v-icon v-if="task.is_favorite" small>$favoriteactive</v-icon>
            <v-icon v-else small>$favoriteinactive</v-icon>
            <span class="ml-2 work-break">{{ task.name }}</span>
            <span :class="[{ detail: !isDetailEmpty() }]">{{
              task.detail
            }}</span>
          </v-col>
          <!-- </v-row> -->
          <!-- <v-divider></v-divider> -->
          <v-col cols="12" class="py-0 my-0">
            <v-divider></v-divider>
          </v-col>

          <!-- <v-row class="py-0 my-0"> 
          <v-col class="d-flex align-center"> -->
          <v-col cols="12" class="py-4 d-flex align-center">
            <v-icon small>$taskexplanation</v-icon>
            <span class="ml-2 work-break">{{ task.description }}</span>
          </v-col>
          <!-- </v-row> -->

          <!-- <v-row class="py-0 my-0"> -->
          <!-- <v-col cols="12" class="pb-0"> -->
          <v-col cols="12" class="pt-4 pb-0">
            <v-icon small>$taskcategory</v-icon>
            <span class="ml-2"> {{ primaryCategoryName }}</span>
          </v-col>
          <v-col
            v-if="task.category_secondary_id !== null"
            cols="12"
            class="mt-0 pb-0"
          >
            <span class="ml-7">
              {{ task.category_secondary_name }}
            </span>
          </v-col>
        </v-row>

        <v-row class="py-1 mt-2" v-if="task.task_type_id">
          <v-col class="d-flex align-center">
            <v-icon small>$taskType</v-icon>
            <span class="ml-2 work-break"
              >{{ labelType }} : {{ task.task_type_name || "" }}</span
            >
          </v-col>
        </v-row>

        <v-row class="py-1 my-2">
          <v-col class="align-center pb-0" v-if="!task.is_split_task">
            <div class="d-flex">
              <v-icon small class="yellow--text">$taskworktime</v-icon>
              <span class="ml-2">日次予定</span>
              <div class="text-number ml-2 d-flex align-center">
                <span>{{ task.cost }} m/回</span>
                <span class="mx-2">×</span>
                <span>{{ task.count }} 回</span>
                <span class="mx-2">=</span>
                <span>{{ task.cost * task.count }} m</span>
              </div>
            </div>
          </v-col>
          <v-col class="align-center py-2" v-else>
            <div class="d-flex">
              <v-icon small>$taskworktime</v-icon>
              <span class="ml-2">日次予定</span>
              <div class="text-number ml-7">
                <span>{{ task.cost * task.count }} m</span>
              </div>
            </div>
            <div class="d-flex mt-2">
              <span class="ml-7">累計実績</span>
              <span class="ml-1">{{ task.total_actual_cost }} m</span>
              <span class="ml-1">/</span>
              <span class="ml-1">{{ task.total_cost }} m</span>
            </div>
          </v-col>
          <v-col cols="12" class="align-center py-0" v-if="!isFreeTask(task)">
            <div class="mt-2 ml-7">
              <span>※標準工数/回の業務量定義（件数など）：</span>
              <span class="work-break">{{ task.unit || "" }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="my-0 py-0">
          <v-col class="d-flex align-center">
            <v-icon small>$taskstartendtime</v-icon>
            <div class="ml-2 d-flex align-center">
              <span>{{ task.start_time }}</span>
              <span class="mx-3">~</span>
              <span>{{ task.end_time }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="my-0 py-0" v-if="!isFreeTask(task)">
          <v-col>
            <div class="d-flex align-center">
              <v-checkbox
                class="mt-0"
                hide-details
                disabled
                v-model="task.is_split_task"
              >
              </v-checkbox>
              <span class="mx-2"> タスクを分割実施（一部のみ実施） </span>
            </div>
            <div class="d-flex align-center">
              <span class="mx-2 ml-10"
                >※標準工数60分のうち30分作業する場合など</span
              >
            </div>
          </v-col>
        </v-row>
        <!-- <v-row class="my-0 py-0">
          <v-col class="py-0">
            <div class="d-flex align-center">
              <v-icon small>$output</v-icon>
              <span class="mx-2">アウトプット</span>
              <v-checkbox
                v-model="task.is_need_output"
                readonly
                :label="!!task.is_need_output ? '必要' : '不要'"
              ></v-checkbox>
            </div>
            <div class="ml-2" v-if="!!task.is_need_output">
              アウトプット内容： {{ task.output_detail }}
            </div>
          </v-col>
        </v-row> -->

        <v-row class="my-0 py-0" :class="isFreeTask(task) ? 'pt-3' : ''">
          <v-col class="d-flex align-center py-0">
            <v-icon small class="mr-2">$tag</v-icon>
            <span v-if="task.tags.length === 0">無し</span>
            <v-container v-else justify="space-around">
              <v-sheet class="px-1">
                <v-chip-group mandatory>
                  <v-tooltip bottom v-for="tag in task.tags" :key="tag.id">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on">
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 100px"
                        >
                          {{ tag.name }}
                        </span>
                      </v-chip>
                    </template>
                    <span>{{ tag.name }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>

        <v-row v-if="isEditComment()" class="py-0 my-0">
          <v-col v-if="!showComment" class="py-2">
            <v-icon small>$comment</v-icon>
            <v-btn text color="ligth" @click="updateComment()">
              コメントを追加
            </v-btn>
            <v-list v-if="hasComment">
              <v-list-item v-for="item in task.comments" :key="item.id">
                <v-list-item-icon>
                  <v-icon small>$commonuser</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <p>
                    {{ item.user_name }}
                    <span v-if="!$vuetify.breakpoint.mobile" class="ml-5">{{
                      getCommentDate(item.updated_at)
                    }}</span>
                  </p>
                  <p v-if="$vuetify.breakpoint.mobile">
                    {{ getCommentDate(item.updated_at) }}
                  </p>
                  <p class="work-break">{{ item.comment }}
                  </p>
                </v-list-item-content>
                <v-list-item-action
                  style="align-self: flex-start"
                  @click="deleteComment(item)"
                >
                  <v-icon small>$delete</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col class="py-0 d-flex align-items-baseline" v-else>
            <v-icon small>$comment</v-icon>
            <v-textarea
              class="mt-0 ml-2"
              v-model="userComment"
              placeholder="コメントを追加"
              counter
              maxlength="512"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row v-else class="py-4 my-0">
          <v-col v-if="canViewComment" class="py-0">
            <v-list v-if="hasComment">
              <v-list-item v-for="item in task.comments" :key="item.id">
                <v-list-item-icon>
                  <v-icon small>$commonuser</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <p>
                    {{ item.user_name }}
                    <span v-if="!$vuetify.breakpoint.mobile" class="ml-5">{{
                      getCommentDate(item.updated_at)
                    }}</span>
                  </p>
                  <p v-if="$vuetify.breakpoint.mobile">
                    {{ getCommentDate(item.updated_at) }}
                  </p>
                  <p class="work-break">{{ item.comment }}
                  </p>
                </v-list-item-content>
                <v-list-item-action @click="deleteComment(item)">
                  <v-icon small>$delete</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="isEditComment()"
          :disabled="!isEditing"
          tile
          class="ml-auto"
          color="primary"
          @click="updateTask()"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  GET_SCHEDULE_TASK_API,
  DELETE_COMMENT_API,
  NOTIFICATION_API
} from "../../../constants/api";
import axios from "axios";
import { mapGetters } from "vuex";
import {
  formatTime,
  formatDateTime,
  parseDateTime,
} from "../../../helpers/datetime-helpers";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC801, FC820, FC802, FC501, FC502 } from "../../../constants/fc";
import { yyyyMMddHHmm2, yyyyMMddHHmmss3 } from "../../../constants/df";
import {
  isEmpty,
  isEmptyNumber,
  copyToClipboard,
  debounce_leading,
} from "../../../helpers/helpers";
import { backMixin } from "../../../mixins/back";
import { taskMixin } from '@/mixins/task.js';
import { STATUS_NO_CONTENT } from "../../../constants/status";
import DialogDragDirective from "../../../directives/dialogDrag";

export default {
  name: "ViewTask",
  props: {
    show: Boolean,
    planId: String,
    taskId: Number,
    canViewComment: {
      type: Boolean,
      default: false,
    },
    viewType: String,
  },
  directives: {
    "dialog-drag": DialogDragDirective,
  },

  mixins: [backMixin, taskMixin],

  data() {
    return {
      parentId: "ViewTaskId",
      openTodoTask: false,
      showComment: false,
      userComment: "",
      isEditing: false,
      task: {
        name: "",
        description: "",
        cost: "",
        count: "",
        category_secondary_id: null,
        category_secondary_name: "",
        category_primary_name: "",
        is_need_output: false,
        output_detail: "",
        start_time: "",
        end_time: "",
        is_favorite: false,
        tags: [],
        comments: [],
      },
      listNotification: [],
    };
  },

  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["loginInfo"]),
    ...mapGetters("save", ["getChangeFlag"]),
    primaryCategoryName() {
      return !isEmptyNumber(this.task.category_secondary_id)
        ? this.task.category_primary_name
        : "未分類";
    },
    hasComment() {
      return this.task && this.task.comments && this.task.comments.length > 0;
    },
  },

  watch: {
    async show() {
      this.task = {
        name: "",
        description: "",
        cost: "",
        count: "",
        category_secondary_id: null,
        category_secondary_name: "",
        category_primary_name: "",
        is_need_output: false,
        output_detail: "",
        start_time: "",
        end_time: "",
        is_favorite: false,
        tags: [],
        comments: [],
      };
      this.openTodoTask = true;
      this.showComment = false;
      this.isEditing = false;
      this.userComment = "";
      this.$store.dispatch("save/setChangeFlag", this.isEditing);
      await this.getTask();
      await this.getNotification();
    },
    userComment: {
      handler: function (value) {
        this.isEditing = !isEmpty(value);
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    canViewComment: {
      handler: function () {
        this.isEditing = false;
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
  },

  methods: {
    isDetailEmpty() {
      return isEmpty(this.task.detail);
    },
    isFreeTask() {
      return !isEmptyNumber(this.task.free_task_id);
    },
    copyLink() {
      copyToClipboard(
        window.location.origin +
          `/schedule/${this.planId}?taskId=${this.task.id}`,
        this.parentId
      );
      this.$toast(this.$t("label.toast.copied"), 3000);
    },
    getCommentDate(date) {
      return formatDateTime(
        parseDateTime(date, yyyyMMddHHmmss3),
        yyyyMMddHHmm2
      );
    },

    async getTask() {
      try {
        const { data, status } = await axios.get(
          GET_SCHEDULE_TASK_API(this.planId, this.taskId)
        );
        if (status === STATUS_NO_CONTENT) {
          this.openTodoTask = false;
          this.$alert("タスクが見つかりません。");
        } else {
          this.task = data.result[0];
          this.task.is_split_task == !!data.result[0].is_split_task;
          this.task.start_time = formatTime(data.result[0].start_time);
          this.task.end_time = formatTime(data.result[0].end_time);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC801, status);
      }
    },

    getNotification: debounce_leading(async function () {
      try {
        const { data, status } = await axios.get(NOTIFICATION_API(), {
          params: {
            is_verified: 0,
            sort: "new", // TODO:
          },
        });
        let list = status === STATUS_NO_CONTENT ? [] : data.result.notices;
        this.$store.dispatch(
          "notification/setCount",
          status === STATUS_NO_CONTENT ? 0 : data.result.total_count
        );
        this.$store.dispatch(
          "notification/setUnReadList",
          status === STATUS_NO_CONTENT ? [] : data.result.notices
        );
        // Add images to list
        for (let index = 0; index < list.length; index++) {
          const item = list[index];
          let comment = this.task.comments.find(
            x => +x.id === +item.comment_id && !!item.can_verified
          );
          if (comment){
            this.listNotification.push(item);
          }
          
        }
        if (this.listNotification.length > 0){
          await this.confirmed();
          this.$store.dispatch("notification/setCount", data.result.total_count - this.listNotification.length);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC501, status);
      }
    }),

    // Call Api comfirm notice
    async confirmed() {
      try {
        let params = [];
        this.listNotification.forEach(item => {
          let param = {
            status_notice_id: null,
            comment_notice_id: item.comment_notice_id,
            is_verified: 1,
          };
          params.push(param);
        });
        await axios.put(NOTIFICATION_API(), {
          notices: params,
        });
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC502, status);
      }
    },
    
    isBeforeTwelve() {
      return this.$root.$refs.scheduleComponent.isBeforeTwelve();
    },
    updateComment() {
      this.showComment = true;
    },
    updateTask: debounce_leading(async function () {
      let params = [
        {
          id: this.task.id,
          comments:
            !isEmpty(this.userComment) && this.showComment
              ? [
                  {
                    user_id: this.loginInfo.user_id,
                    comment: this.userComment,
                  },
                ]
              : [],
        },
      ];
      try {
        await axios.put(GET_SCHEDULE_TASK_API(this.planId), {
          schedule_tasks: params,
        });
        this.$store.dispatch("save/setChangeFlag", false);
        this.openTodoTask = false;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC802, status);
      }
    }),

    isEditComment() {
      if (this.viewType === "PLAN") {
        return (
          this.$root.$refs.scheduleComponent.isSelf() ||
          this.$root.$refs.scheduleComponent.isSupervisor()
        );
      } else {
        return false;
      }
    },

    deleteComment(item) {
      if (item.user_id !== this.loginInfo.user_id) return;
      this.$confirm(this.$t("label.confirm.delete_comment"), {
        buttons: {
          ok: {
            text: this.$t("label.buttons.delete"),
            type: "primary",
          },
          cancel: {
            text: this.$t("label.buttons.cancel"),
            type: "ligth",
          },
        },
        rtl: true,
      })
        .then(async () => {
          try {
            await axios.delete(DELETE_COMMENT_API(item.id));
            this.task.comments = this.task.comments.filter(
              (x) => x.id !== item.id
            );
            // this.originalTask.comments = this.originalTask.comments.filter(
            //   (x) => x.id !== item.id
            // );
            this.$emit("deleteComment", {
              comment_id: item.id,
              id: this.task.id,
            });
          } catch (error) {
            const { status } = error.response;
            apiErrorMessages(this, FC820, status);
          }
        })
        .catch(() => {});
    },

    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.openTodoTask = false;
          }, 100);
        },
        () => {}
      );
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.openTodoTask = false;
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },
  },

  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}
.text-number {
  width: 200px;
}
.align-items-baseline {
  align-items: baseline;
}
.v-dialog.v-dialog--active .v-card__title {
  cursor: grab;
}
.detail {
  margin-left: auto;
  margin-right: 0;
  min-width: 200px;
  max-width: 250px;
}
.work-break {
  white-space: pre-wrap;
}
</style>
