<template>
  <v-dialog
    v-model="openTask"
    persistent
    max-width="650px"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-card-title class="card-header py-2">
        <v-btn @click="close()" class="ml-auto" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="card-content">
        <v-row class="py-0 my-0">
          <v-col class="py-0 d-flex align-center">
            <p class="error-text" v-if="errorApiMessage">
              <v-icon small class="mr-2">$error</v-icon>{{ errorApiMessage }}
            </p>
          </v-col>
        </v-row>
        <v-row class="py-0 my-0">
          <v-col class="py-0 d-flex align-center">
            <span class="red--text mr-2">*</span>
            <v-icon small>$filter</v-icon>
            <v-text-field
              class="ml-2"
              maxlength="60"
              v-model="task.name"
              placeholder="タスク名を追加"
              :error-messages="nameErrors"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="py-0 my-0">
          <v-col class="py-0 d-flex align-center">
            <v-icon small>$taskexplanation</v-icon>
            <v-textarea
              rows="1"
              class="ml-2"
              auto-grow
              maxlength="512"
              v-model="task.description"
              placeholder="説明を追加"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0" cols="12">
            <span class="red--text mr-2">*</span>
            <v-icon small>$taskcategory</v-icon>
            <DropdownButton
              :id="'id'"
              :display="'name'"
              :name="primaryName || '大カテゴリ名 '"
              :items="parentCategories"
              :type="'text'"
              :hasTotal="false"
              :showMenu="true"
              @dropdownSelected="primaryCategoryChange($event)"
            ></DropdownButton>
            <div class="mt-2 ml-5 category">
              <DropdownButton
                :id="'id'"
                :display="'name'"
                :name="secondaryName || '小カテゴリ名 '"
                :items="childCategories"
                :type="'text'"
                :hasTotal="false"
                :showMenu="true"
                v-if="showSecondCategory"
                @dropdownSelected="secondaryCategoryChange($event)"
              ></DropdownButton>
            </div>
            <div
              v-if="
                !this.$v.task.category_secondary_id.required &&
                this.$v.task.category_secondary_id.$dirty
              "
              class="v-text-field__details"
            >
              <div class="v-messages theme--light error--text" role="alert">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">
                    {{ $t("label.error.required") }}
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col class="py-0 d-flex align-center" cols="12">
            <span class="red--text mr-2">*</span>
            <v-icon small class="mr-2">$taskType</v-icon>
            <span class="mr-8">{{ labelType }}</span>
            <v-select
              no-data-text="空欄"
              :items="taskTypeItems"
              :error-messages="taskTypeErrors"
              v-model="task.task_type_id"
              @change="resetSkillLevel()"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="py-0 d-flex align-center">
            <span class="red--text mr-2">*</span>
            <v-icon small class="mr-2">$taskworktime</v-icon>
            <span class="mr-2">標準工数</span>
            <v-text-field
              v-model="task.cost"
              @keydown="checkMaxLength('cost', $event)"
              @input.native="handleInput($event, 'cost')"
              min="1"
              max="999"
              type="number"
              :error-messages="costErrors"
              suffix="m/回"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="pt-0 d-flex align-center">
            <span class="ml-2">標準工数/回の業務量定義（件数など）</span>
            <v-text-field
              class="ml-2"
              maxlength="60"
              v-model="task.unit"
            ></v-text-field>
          </v-col>
          <v-col cols="12 d-flex" class="py-0 my-0">
            <v-checkbox
              label="ビギナー設定あり"
              v-model="task.is_target_beginner"
              :disabled="disableSkillLevelCheckbox"
            >
            </v-checkbox>
            <v-checkbox class="ml-3"
              label="エキスパート設定あり"
              v-model="task.is_target_expert"
              :disabled="disableSkillLevelCheckbox"
            >
            </v-checkbox>
          </v-col>
          <v-col class="pl-6 pr-6 pt-0 pb-6 my-0" cols="12">
            <div class="d-flex align-baseline" v-if="!!task.is_target_beginner">
              <span class="mr-2">ビギナー</span>
              <v-text-field
                v-model="task.cost_beginner"
                :error-messages="costBeginnerErrors"
                min="1"
                max="999"
                type="number"
                suffix="m"
                @input.native="handleInput($event, 'cost_beginner')"
                @keydown="preventKeyCode($event)"
              >
              </v-text-field>
            </div>
            <div
              v-if="!!task.is_target_expert || !!task.is_target_beginner"
              class="d-flex align-baseline"
            >
              <span class="mr-2">スタンダード</span>
              <v-text-field
                v-model="task.cost_standard"
                :error-messages="costStandardErrors"
                min="1"
                max="999"
                type="number"
                suffix="m"
                @input.native="handleInput($event, 'cost_standard')"
                @keydown="preventKeyCode($event)"
              >
              </v-text-field>
            </div>
            <div v-if="!!task.is_target_expert" class="d-flex align-baseline">
              <span class="mr-2">エキスパート</span>
              <v-text-field
                v-model="task.cost_expert"
                :error-messages="costExpertErrors"
                min="1"
                max="999"
                type="number"
                suffix="m"
                @input.native="handleInput($event, 'cost_expert')"
                @keydown="preventKeyCode($event)"
              >
              </v-text-field>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="!$vuetify.breakpoint.mobile" class="py-0 my-0">
          <v-col class="py-0 d-flex align-center" cols="12">
            <v-icon small class="mr-2">$calendar</v-icon>
            <span class="mr-2 w-300"> 実施タイミング</span>
            <v-select
              class="mr-1"
              no-data-text="月"
              placeholder="月"
              :items="implementationMonths"
              :disabled="disableMonth"
              @change="changeMonth()"
              v-model="task.implementation_month"
              clearable
            >
            </v-select>
            <v-select
              class="mr-1"
              no-data-text="週"
              placeholder="週"
              @change="changeWeek()"
              :disabled="disableWeek"
              :items="implementationWeeks"
              v-model="task.implementation_week"
              clearable
            >
            </v-select>
            <v-select
              placeholder="曜日/日"
              no-data-text="曜日/日"
              :disabled="disableDay"
              :items="implementationDays"
              v-model="task.implementation_day"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="py-0 d-flex align-center" cols="12">
            <v-icon small class="mr-2">$calendar</v-icon>
            <span class="mr-2 w-300"> 実施タイミング</span>
          </v-col>
          <v-col class="py-0 align-center" cols="12">
            <v-select
              no-data-text="月"
              placeholder="月"
              :items="implementationMonths"
              :disabled="disableMonth"
              @change="changeMonth()"
              v-model="task.implementation_month"
              clearable
            >
            </v-select>
            <v-select
              no-data-text="週"
              placeholder="週"
              @change="changeWeek()"
              :disabled="disableWeek"
              :items="implementationWeeks"
              v-model="task.implementation_week"
              clearable
            >
            </v-select>
            <v-select
              placeholder="曜日/日"
              no-data-text="曜日/日"
              :disabled="disableDay"
              :items="implementationDays"
              v-model="task.implementation_day"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0 d-flex align-center" cols="12">
            <span class="red--text mr-2">*</span>
            <v-icon small class="mr-2">$taskvisiblestatus</v-icon>
            <span class="mr-2"> ステータス</span>
            <v-select
              item-text="name"
              item-value="id"
              no-data-text="空欄"
              :items="statusList"
              :error-messages="statusVisibleErrors"
              v-model="task.is_visible"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="ml-auto">
        <v-btn outlined depressed @click="close()" color="light"
          >キャンセル</v-btn
        >
        <v-btn
          depressed
          color="primary"
          :disabled="!isEditing"
          @click="updateTask()"
          >保存</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { backMixin } from "../../../mixins/back";
import { taskMixin } from '@/mixins/task.js';
import { dbMasterMixin } from "@/mixins/dbMaster.js";
import DropdownButton from "../../../components/DropdownButton.vue";
import {
  debounce_leading,
  deepClone,
  isEmpty,
  isEmptyNumber,
} from "../../../helpers/helpers";
import { required, requiredIf, between } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import {
  MONTHLY,
  ANY_MONTH,
  MONTHS,
  WEEKLY,
  ANY_WEEK,
  WEEKS,
  DAYINMONTHS,
  WORKINGDAYINMONTHS,
  DAYOFWEEKS,
  TASK_TYPE
} from "../../../constants/system";

import { DB_STANDARD_API, CATEGORIES_API } from "../../../constants/api";
import { FC706, FC701 } from "../../../constants/fc";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import { apiErrorMessages } from "../../../helpers/messages";
import * as systemAuth from "../../../constants/role_code";
import axios from "axios";
import { costBeginnerFieldValidator, costExpertFieldValidator } from "@/helpers/validator";
export default {
  mixins: [validationMixin, backMixin, taskMixin, dbMasterMixin],
  components: { DropdownButton },
  validations: {
    task: {
      name: { required },
      task_type_id : { required },
      category_secondary_id: {
        required,
      },
      output_detail: {
        required: requiredIf((task) => {
          return task.is_need_output;
        }),
      },
      cost: { 
        required,
        between: between(1, 999)
        },
      is_visible: { required },
      cost_beginner: {
        required: requiredIf((task) => {
          let required = task.is_target_beginner;
          return required;
        }),
        between: between(1, 999),
        compare: costBeginnerFieldValidator,
      },
      cost_standard: {
        required: requiredIf((task) => {
          let required = task.is_target_beginner || task.is_target_expert;
          return required;
        }),
        between: between(1, 999)
      },
      cost_expert: {
        required: requiredIf((task) => {
          let required = task.is_target_expert;
          return required;
        }),
        between: between(1, 999),
        compare: costExpertFieldValidator,
      },
    },
  },
  props: {
    show: Boolean,
    selectedTask: Object,
    taskTypeItems : Array,
  },
  watch: {
    openTask: {
      async handler() {
        // this.openTask = true;
        this.isEditing = false;
        this.disableDay = true;
        this.disableMonth = false;
        this.disableWeek = false;
        this.$v.$reset();
        this.errorApiMessage = "";
      },
    },
    selectedTask: {
      async handler(val) {
        this.task.implementation_month = val.implementation_month;
        this.task.implementation_week = val.implementation_week;
        if (val.implementation_month) {
          this.changeMonth();
        }
        if (val.implementation_week) {
          this.changeWeek();
        }
        val.is_target_beginner = !!val.is_target_beginner;
        val.is_target_expert = !!val.is_target_expert;
        this.task = deepClone(val);
        this.orginalTask = deepClone(val);
        await this.getCategories();
        this.childCategories = this.parentCategories.find(
          (x) => x.id === val.category_primary_id
        )?.secondaries;
        this.resetSkillLevel();
      },
    },
    task: {
      deep: true,
      handler(val) {
        this.isEditing = this.jsonCompare(val);
        this.showSecondCategory = !isEmptyNumber(val.category_primary_id);
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
  },

  data() {
    return {
      task: {
        name: "",
        category_secondary_id: null,
        category_primary_id: null,
        task_type_id: null,
        implementation_month: "",
        implementation_week: "",
        category_primary_name: "",
        category_secondary_name: "",
      },
      orginalTask: {
        name: "",
        category_secondary_id: null,
        category_primary_id: null,
        task_type_id: null,
        implementation_month: "",
        implementation_week: "",
        category_primary_name: "",
        category_secondary_name: "",
      },
      openTask: false,
      parentCategories: [],
      childCategories: [],
      implementationMonths: [],
      implementationWeeks: [],
      implementationDays: [],
      outputs: [
        { id: 1, name: "要" },
        { id: 0, name: "不要" },
      ],
      dependents: [
        { id: 1, name: " 対象" },
        { id: 0, name: " 非対象" },
      ],
      statusList: [
        { id: 1, name: "表示" },
        { id: 0, name: "非表示" },
      ],
      disableWeek: false,
      disableMonth: false,
      disableDay: true,
      showSecondCategory: false,
      isEditing: false,
      errorApiMessage: "",
    };
  },

  computed: {
    ...mapGetters("save", ["getChangeFlag"]),
    ...mapGetters("common", ["loginInfo"]),

    nameErrors() {
      const errors = [];
      if (this.$v.task.name.$dirty) {
        !this.$v.task.name.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },

    taskTypeErrors() {
      const errors = [];
      if (this.$v.task.task_type_id.$dirty) {
        !this.$v.task.task_type_id.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },

    costErrors() {
      const errors = [];
      if (this.$v.task.cost.$dirty) {
        !this.$v.task.cost.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.cost.between &&
          errors.push(this.$t("label.error.cost_range_1_999"));
      }

      return errors;
    },

    outputDetailErrors() {
      const errors = [];
      if (this.$v.task.output_detail.$dirty) {
        !this.$v.task.output_detail.required &&
          this.task.is_need_output &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },

    dependentErrors() {
      const errors = [];
      if (this.$v.task.is_target_skill_level.$dirty) {
        !this.$v.task.is_target_skill_level.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },

    statusVisibleErrors() {
      const errors = [];
      if (this.$v.task.is_visible.$dirty) {
        !this.$v.task.is_visible.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },

    countErrors() {
      const errors = [];
      if (this.$v.task.count.$dirty) {
        !this.$v.task.count.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.count.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },

    costBeginnerErrors() {
      const errors = [];
      if (this.$v.task.cost_beginner.$dirty) {
        !this.$v.task.cost_beginner.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.cost_beginner.between &&
          errors.push(this.$t("label.error.cost_range_1_999"));
        !this.$v.task.cost_beginner.compare &&
          errors.push("スタンダード工数以上の数字を入力してください。");
      }
      return errors;
    },
    costStandardErrors() {
      const errors = [];
      if (this.$v.task.cost_standard.$dirty) {
        !this.$v.task.cost_standard.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.cost_standard.between &&
          errors.push(this.$t("label.error.cost_range_1_999"));
      }
      return errors;
    },
    costExpertErrors() {
      const errors = [];
      if (this.$v.task.cost_expert.$dirty) {
        !this.$v.task.cost_expert.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.cost_expert.between &&
          errors.push(this.$t("label.error.cost_range_1_999"));
        !this.$v.task.cost_expert.compare &&
          errors.push("スタンダード工数以下の数字を入力してください。");
      }
      return errors;
    },

    secondaryName() {
      if (!isEmpty(this.childCategories)) {
        let s = this.childCategories.find(
          (x) => x.id === this.task.category_secondary_id
        );
        return s ? s.name : "";
      } else {
        return "";
      }
    },

    primaryName() {
      let p = this.parentCategories.find(
        (x) => x.id === this.task.category_primary_id
      );
      return p ? p.name : "";
    },

    disableSkillLevelCheckbox() {
      return this.task.task_type_id !== TASK_TYPE.NORMAL_BUSINESS;
    },

    isMaster() {
      if (isEmpty(this.loginInfo)) return false;
      else return this.loginInfo.system_auth_id === systemAuth.MASTER;
    },
  },

  mounted() {
    let months = deepClone(MONTHS);
    months.push(MONTHLY);
    months.push(ANY_MONTH);
    this.implementationMonths = this.implementationMonths.concat(months);

    let weeks = deepClone(WEEKS);
    weeks.push(ANY_WEEK);
    weeks.push(WEEKLY);
    this.implementationWeeks = this.implementationWeeks.concat(weeks);

    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },

  methods: {
    showDialog(value) {
      // this.selectedTask = selectedTask;
      this.openTask = value;
    },
    checkMaxLength(type, $event) {
      this.preventKeyCode($event)
      if ($event.keyCode === 8 || $event.keyCode === 46) return true;
      if (type === "cost") {
        if (this.$v.task.cost.required) {
          if (this.task.cost.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      } else {
        if (this.$v.task.count.required) {
          if (this.task.count.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      }
    },
    updateTask: debounce_leading(async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        try {
          if (!this.task.is_need_output) this.task.output_detail = "";
          const payload = {
            name: this.task.name,
            description: this.task.description,
            category_secondary_id: this.task.category_secondary_id,
            cost: +this.task.cost,
            skill_levels: this.getPayloadSkillCost(this.task),
            task_type_id: this.task.task_type_id,
            unit: this.task.unit,
            is_need_output: this.task.is_need_output ? +this.task.is_need_output : 0,
            output_detail: this.task.output_detail,
            implementation_month: this.task.implementation_month,
            implementation_week: this.task.implementation_week,
            implementation_day: this.task.implementation_day,
            is_visible: +this.task.is_visible
          }
          this.assignValueTask()

          const { data } = await axios.put(
            DB_STANDARD_API(this.task.id),
            payload
          );
          this.$store.dispatch("save/setChangeFlag", false);
          this.task.id = data.result.id;
          this.openTask = false;
          this.$emit("taskUpdated", this.task);
          this.$toast(`${this.task.name}を編集しました。`, 3000);
        } catch (error) {
          const { status } = error.response;
          if (status === 401) {
            this.errorApiMessage = error.response.data.message;
          } else apiErrorMessages(this, FC706, status);
        }
      }
    }),

    primaryCategoryChange(primaryCategory) {
      this.childCategories = this.parentCategories.find(
        (x) => x.id === primaryCategory.id
      )?.secondaries;

      if (!isEmptyNumber(primaryCategory.id)) {
        this.parentCategories = this.parentCategories.filter(
          (x) => !isEmptyNumber(x.id)
        );
      }
      this.task.category_primary_id = primaryCategory.id;
      this.task.category_secondary_id = null;
    },

    secondaryCategoryChange(secondCategory) {
      this.task.category_secondary_id = secondCategory.id;
    },

    changeMonth() {
      this.task.implementation_week = "";
      switch (true) {
        case this.task.implementation_month === ANY_MONTH:
          this.disableWeek = true;
          this.disableDay = true;
          this.implementationDays = [];
          this.task.implementation_day = "";
          break;
        case this.task.implementation_month === MONTHLY:
          this.disableWeek = true;
          this.disableDay = false;
          this.implementationDays = [];
          this.implementationDays = this.implementationDays
            .concat(DAYINMONTHS)
            .concat(WORKINGDAYINMONTHS);
          break;
        case isEmpty(this.task.implementation_month):
          this.disableWeek = false;
          this.disableDay = true;
          this.task.implementation_day = "";
          break;
        default:
          {
            this.disableWeek = true;
            this.disableDay = false;
            this.implementationDays = [];
            let month = this.task.implementation_month.replace("月", "");
            let numberOfDay = this.daysInMonth(+month);
            let newDays = deepClone(DAYINMONTHS);
            newDays.length = numberOfDay;
            this.implementationDays = newDays;
          }
          break;
      }
    },

    daysInMonth(month) {
      if (+month === 2) return 29;
      return new Date(new Date().getFullYear(), month, 0).getDate();
    },

    changeWeek() {
      this.disableMonth = !isEmpty(this.task.implementation_week);
      this.disableDay = isEmpty(this.task.implementation_week);
      this.task.implementation_month = "";
      this.task.implementation_day = isEmpty(this.task.implementation_week)
        ? ""
        : this.task.implementation_day;
      this.implementationDays = [];
      this.implementationDays = this.implementationDays.concat(DAYOFWEEKS);
    },

    async getCategories() {
      try {
        const { data, status } = await axios.get(CATEGORIES_API());
        this.parentCategories = status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC701, status);
      }
    },

    jsonCompare(val) {
      let newValue = {
        name: val.name,
        description: val.description,
        category_secondary_id: val.category_secondary_id,
        cost: +val.cost,
        count: +val.count,
        unit: val.unit,
        is_need_output: +val.is_need_output,
        output_detail: val.output_detail,
        implementation_month: val.implementation_month,
        implementation_week: val.implementation_week,
        implementation_day: val.implementation_day,
        is_target_beginner: +val.is_target_beginner,
        is_target_expert: +val.is_target_expert,
        is_visible: +val.is_visible,
        task_type_id : val.task_type_id,
        cost_beginner: +val.cost_beginner,
        cost_standard: +val.cost_standard,
        cost_expert: +val.cost_expert
      };
      let oldValue = {
        name: this.orginalTask.name,
        description: this.orginalTask.description,
        category_secondary_id: this.orginalTask.category_secondary_id,
        cost: +this.orginalTask.cost,
        count: +this.orginalTask.count,
        unit: this.orginalTask.unit,
        is_need_output: +this.orginalTask.is_need_output,
        output_detail: this.orginalTask.output_detail,
        implementation_month: this.orginalTask.implementation_month,
        implementation_week: this.orginalTask.implementation_week,
        implementation_day: this.orginalTask.implementation_day,
        is_target_beginner: +this.orginalTask.is_target_beginner,
        is_target_expert: +this.orginalTask.is_target_expert,
        is_visible: +this.orginalTask.is_visible,
        task_type_id : this.orginalTask.task_type_id,
        cost_beginner: +this.orginalTask.cost_beginner,
        cost_standard: +this.orginalTask.cost_standard,
        cost_expert: +this.orginalTask.cost_expert
      };
      return JSON.stringify(newValue) !== JSON.stringify(oldValue);
    },

    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.openTask = false;
          }, 100);
        },
        () => {}
      );
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.$v.$reset();
          this.openTask = false;
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },
  },

  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>

<style scoped lang="scss">
.error-text {
  width: calc(50% - 12px);
  text-align: left;
  font-size: 75%;
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}
.w-180 {
  width: 208px;
}
.w-100 {
  width: 100px;
}
::v-deep .v-input__slot .v-input--selection-controls__input {
  margin-right: 5px;
}
</style>
