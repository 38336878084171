import {
  Sortable
} from "sortablejs";

function watchClass(targetNode, classToWatch) {
  let lastClassState = targetNode.classList.contains(classToWatch);
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const currentClassState = mutation.target.classList.contains(classToWatch);
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState;
          if (!currentClassState) {
            mutation.target.classList.add('sortHandle');
          }
        }
      }
    }
  });
  observer.observe(targetNode, {
    attributes: true
  });
}

function handler(el, binding) {
  if (binding.value.allowChangeOrder) {
    el.querySelectorAll('th').forEach((draggableEl, index) => {
      // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
      if ((index + 1) !== +binding.value.length) {
        watchClass(draggableEl, 'sortHandle');
        draggableEl.classList.add('sortHandle');
      }

    });
    Sortable.create(el.querySelector('tr'), binding.value ? {
      ...binding.value,
      handle: '.sortHandle'
    } : {});
  }
}

const SortTableHeaderDirective = {
  inserted: (e, b) => {
    handler(e, b)
  },
  update: (el, binding) => {
    handler(el, binding)
  },
};

export default SortTableHeaderDirective;
