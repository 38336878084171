<template>
  <v-container fill-height fluid class="pa-3">
    <v-row>
      <!-- Side bar -->
      <v-col
        cols="12"
        md="2"
        class="d-flex d-md-block justify-space-between align-center py-0"
        :class="{
          'side-bar': !isMobile,
          '__sub-menu-bar': !isMobile,
        }"
      >
        <v-list class="d-flex d-md-block" rounded>
          <v-list-item-group
            class="d-flex d-md-block"
            v-model="calendarTypeChosen"
            color="primary"
          >
            <v-list-item
              v-for="item in calendarType"
              :key="item.value"
              :value="item.value"
              @click="changeList(item.value)"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="v-list-item--active"
                  v-text="item.text"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <!-- .Side bar -->
      <!-- Main -->
      <v-col
        cols="12"
        md="10"
        :class="{
          __main: !isMobile,
        }"
      >
        <v-row>
          <v-col cols="12" class="d-flex align-center">
            <span class="ml-4 mr-2">対象</span>
            <v-icon dark v-if="isMobile" @click="selectRangeDate()" class="ml-2"
              >$calendar</v-icon
            >
            <v-btn
              outlined
              text
              color="light"
              @click="selectRangeDate()"
              v-else
              class="ml-2"
            >
              {{ formatStartDate }} ~ {{ formatEndDate }}
            </v-btn>
            <v-btn @click="exportCsv()" outlined class="ml-2" v-if="!isMobile">
              <v-icon small dark class="mr-2">$csvexport</v-icon>
              CSVエクスポート
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            :class="isMobile ? 'height-custom' : 'height-custom-pc'"
          >
            <v-data-table
              class="elevation-1 h-100 contentTable"
              height="100%"
              fixed-header
              item-key="status_id"
              :headers="dataTableConfig.headers"
              :items-per-page="dataTableConfig.options.itemsPerPage"
              :items="statusLogs"
              :no-data-text="dataTableConfig.noDataText"
              :options.sync="dataTableConfig.options"
              :server-items-length="dataTableConfig.total"
              :footer-props="{
                'items-per-page-options': dataTableConfig.itemsPerPageOptions,
              }"
              mobile-breakpoint="0"
              @update:options="optionsChanged"
            >
              <template v-slot:[`item.name`]="{ item }">
                {{ item.process_user_name }}
              </template>
              <template v-slot:[`item.dept`]="{ item }">
                <td class="dt-comment">
                  <!-- {{ item.department_name }} ＞ {{ item.group_name }} -->
                  {{ getDept(item.department_name, item.group_name) }}
                </td>
              </template>
              <template v-slot:[`item.position`]="{ item }">
                {{ item.position_name }}
              </template>
              <template v-slot:[`item.subject`]="{ item }">
                {{ item.subject_format }}
              </template>
              <template v-slot:[`item.processName`]="{ item }">
                {{ getProcessName(item.phase_id) }}
              </template>
              <template v-slot:[`item.updated_at`]="{ item }">
                {{ item.updated_at_format }}
              </template>
              <template v-slot:[`item.comment`]="{ item }">
                <td class="dt-comment">
                  {{ item.comment }}
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <!-- .Main -->
    </v-row>

    <RangeDate
      @change="changeDate($event)"
      v-model="rangeDate"
      :show="openRangeDate"
    ></RangeDate>
    <Export :show="openExport" :queryStatusLogs="queryStatusLogs"></Export>
  </v-container>
</template>
<script>
import RangeDate from "./components/RangeDate";
import Export from "./components/Export";
import axios from "axios";
import { STATUS_LOGS_API } from "../../constants/api";
import { FC501 } from "../../constants/fc";
import { apiErrorMessages } from "../../helpers/messages";
import { STATUS_NO_CONTENT } from "../../constants/status";
import { mapGetters } from "vuex";
import { debounce_leading } from "@/helpers/helpers";
import { formatDateTime, parseDateTime } from "@/helpers/datetime-helpers";
import {
  yyyyMMdd1,
  yyyyMMdd2,
  yyyyMMdd3,
  yyyyMMddHHmmEEEEE2,
  yyyyMMddHHmmss3,
} from "@/constants/df";
import * as Role from "../../constants/role";
import {
  PHASE_ID_2_NEXT_CONFIRMATION_PENDING,
  PHASE_ID_PRIMARY_CONFIRMATION_PENDING,
  PHASE_ID_VERIFIED,
} from "@/constants/system";
import { queryRouterMixin } from "@/mixins/queryRouter.js";
import { taskMixin } from "@/mixins/task.js";
export default {
  middleware: Role.MASTER,
  components: {
    RangeDate,
    Export,
  },
  mixins: [queryRouterMixin, taskMixin],
  metaInfo() {
    return { title: this.$t("title.status-logs") };
  },
  data: () => ({
    calendarType: [
      { text: "日次予定", value: "schedule" },
      { text: "日次実績", value: "actual" },
    ],
    calendarTypeChosen: "schedule",
    dataTableConfig: {
      noDataText: "データはありません。",
      headers: [
        {
          text: "名前",
          value: "name",
          sortable: false,
          width: "140px",
          cellClass: "break-all",
        },
        {
          text: "所属部署",
          value: "dept",
          sortable: false,
          width: "140px",
          cellClass: "break-all",
        },
        {
          text: "役職",
          value: "position",
          sortable: false,
          width: "140px",
        },
        {
          text: "対象",
          value: "subject",
          sortable: false,
          width: "280px",
          cellClass: "break-all",
        },
        {
          text: "",
          value: "processName",
          sortable: false,
          width: "60px",
        },
        {
          text: "日時",
          value: "updated_at",
          sortable: false,
          width: "220px",
        },
        {
          text: "コメント",
          value: "comment",
          sortable: false,
          class: "dt-comment",
        },
      ],
      itemsPerPageOptions: [50],
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        pageStart: 1,
      },
    },
    statusLogs: [],
    rangeDate: {},
    queryStatusLogs: {
      calendar_type: "schedule",
      start_date: null,
      end_date: null,
    },
    openRangeDate: false,
    openExport: false,
    queryData: {},
  }),

  async created() {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    this.rangeDate = {
      start_date:
        this.$route.query.startDate || formatDateTime(firstDay, yyyyMMdd2),
      end_date: this.$route.query.endDate || formatDateTime(lastDay, yyyyMMdd2),
      real_start_date:
        this.$route.query.startDate || formatDateTime(firstDay, yyyyMMdd2),
      real_end_date:
        this.$route.query.endDate || formatDateTime(lastDay, yyyyMMdd2),
    };
    this.queryData = this.setQuery(this.$route.query);
    this.calendarTypeChosen = this.$route.query.type || "schedule";
    this.dataTableConfig.options.page = 1;
    this.queryStatusLogs = {
      ...this.queryStatusLogs,
      ...this.rangeDate,
    };
  },

  computed: {
    ...mapGetters("common", ["loginInfo"]),
    isMobile() {
      return ["sm", "xs"].includes(this.$vuetify.breakpoint.name);
    },
    toggleColor() {
      if (!this.isSelectItem) return "#1976d2";
      else return "gray";
    },
    formatStartDate() {
      return this.queryStatusLogs.real_start_date
        ? formatDateTime(this.queryStatusLogs.real_start_date, yyyyMMdd3)
        : "";
    },
    formatEndDate() {
      return this.queryStatusLogs.real_end_date
        ? formatDateTime(this.queryStatusLogs.real_end_date, yyyyMMdd3)
        : "";
    },
  },

  watch: {
    rangeDate: {
      deep: true,
      handler() {
        this.queryStatusLogs = {
          ...this.queryStatusLogs,
          ...this.rangeDate,
        };
      },
    },
  },

  methods: {
    changeList: debounce_leading(function (value) {
      this.calendarTypeChosen = value;
      this.queryStatusLogs.calendar_type = value;
      if (this.dataTableConfig.options.page === 1) {
        this.getStatusLogs();
      } else {
        this.dataTableConfig.options.page = 1;
      }

      const setQueryType = this.checkExistQueryString(
        this.$route.query.type,
        this.queryStatusLogs.calendar_type
      );
      if (setQueryType) {
        this.queryData["type"] = this.queryStatusLogs.calendar_type;
        this.setQueryRouter();
      }
    }, 500),

    // Call Api notification
    async getStatusLogs() {
      try {
        const query = JSON.parse(
          JSON.stringify({
            calendar_type: this.calendarTypeChosen,
            start_date: formatDateTime(
              this.queryStatusLogs.start_date,
              yyyyMMdd1
            ),
            end_date: formatDateTime(this.queryStatusLogs.end_date, yyyyMMdd1),
            offset:
              (this.dataTableConfig.options.page - 1) *
              this.dataTableConfig.options.itemsPerPage,
            limit: this.dataTableConfig.options.itemsPerPage,
          })
        );
        // Fetch API
        const { data, status } = await axios.get(STATUS_LOGS_API(query));
        if (status === STATUS_NO_CONTENT) {
          this.statusLogs = [];
          this.dataTableConfig.total = 0;
        } else {
          this.statusLogs = this.statusLogsList(data.result.logs);
          this.dataTableConfig.total = data.result.total_count;
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC501, status);
      } finally {
        document.getElementsByClassName(
          "v-data-table__wrapper"
        )[0].scrollTop = 0;
        document.getElementsByClassName(
          "v-data-table__wrapper"
        )[0].scrollLeft = 0;
      }
    },

    // Customize data for list
    statusLogsList(list) {
      list.map((item) => {
        item.updated_at_format = formatDateTime(
          parseDateTime(item.updated_at, yyyyMMddHHmmss3),
          yyyyMMddHHmmEEEEE2
        );
        item.subject_format = item.subject
          ? this.formatSubject(item.subject)
          : "";
      });
      return list;
    },

    changeDate(e) {
      this.queryStatusLogs.start_date = e.start_date;
      this.queryStatusLogs.end_date = e.end_date;
      this.queryStatusLogs.real_start_date = e.start_date;
      this.queryStatusLogs.real_end_date = e.end_date;
      if (this.dataTableConfig.options.page === 1) {
        this.getStatusLogs();
      } else {
        this.dataTableConfig.options.page = 1;
      }

      const setQueryStartDate = this.checkExistQueryString(
        this.$route.query.startDate,
        this.queryStatusLogs.start_date
      );
      if (setQueryStartDate) {
        this.queryData["startDate"] = this.queryStatusLogs.start_date;
      }

      const setQueryEndDate = this.checkExistQueryString(
        this.$route.query.endDate,
        this.queryStatusLogs.end_date
      );
      if (setQueryEndDate) {
        this.queryData["endDate"] = this.queryStatusLogs.end_date;
      }
      if (setQueryStartDate || setQueryEndDate) this.setQueryRouter();
    },

    selectRangeDate() {
      this.rangeDate = Object.assign({}, this.rangeDate);
      this.openRangeDate = !this.openRangeDate;
    },

    setQueryRouter() {
      this.$router.replace({
        path: "/status-logs",
        query: this.queryData,
      });
    },

    exportCsv() {
      this.openExport = !this.openExport;
    },

    getPreWeekday(date) {
      var yesterday = new Date(date.setDate(date.getDate() - 1));
      return yesterday.getDay() % 6 ? yesterday : this.getPreWeekday(yesterday);
    },

    getProcessName(phaseId) {
      if (
        [
          PHASE_ID_PRIMARY_CONFIRMATION_PENDING,
          PHASE_ID_2_NEXT_CONFIRMATION_PENDING,
        ].includes(phaseId)
      ) {
        return "提出";
      } else if (PHASE_ID_VERIFIED === phaseId) {
        return "承認";
      }

      return "";
    },
    getDept(departmentName, groupName) {
      if (departmentName && groupName) {
        return departmentName + " ＞ " + groupName;
      }
      return "-";
    },
    optionsChanged() {
      this.getStatusLogs();
    },
  },
};
</script>
<style lang="scss" scoped>
.theme--light.v-data-table .v-data-footer {
  display: none;
}
.v-list--nav .v-list-item:not(:last-child):not(:only-child),
.v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 0;
}

::v-deep .contentTable {
  .v-data-table-header {
    font-weight: bold !important;
  }
}

::v-deep .v-input--selection-controls__input {
  margin-right: 0;
}

::v-deep .dt-comment {
  min-width: 200px;
  word-break: break-all;
}

.height-custom {
  height: calc((var(--vh, 1vh) * 100) - 56px - 72px - 64px - 12px);
}
.height-custom-pc {
  height: calc((var(--vh, 1vh) * 100) - 72px - 64px);
}
.h-100 {
  height: calc(100% - 36px);
}
.side-bar {
  margin-top: 64px;
}
.__sub-menu-bar {
  max-width: 145px;
}
.__main {
  flex: unset;
  max-width: calc(100% - 145px);
}
::v-deep .break-all {
  word-break: break-all;
}
</style>
