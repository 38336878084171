<template>
  <v-dialog
    scrollable
    :value="value"
    @input="$emit('input')"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="600px"
    persistent
  >
    <v-card>
      <v-card-title>
        <v-row class="mt-2" align="center" justify="center">
          <v-col class="text-center" md="6">
            <h3 v-if="modalType === 'new'">ユーザー追加画面</h3>
            <h3 v-else>ユーザー編集画面</h3>
            <p class="error-text" v-if="errorApiMessage">
              <v-icon small class="mr-2">$error</v-icon>{{ errorApiMessage }}
            </p>
          </v-col>
          <v-col class="text-right profile-avatar" md="6">
            <AvatarUpload
              ref="avatar"
              :iscreenType="'ACCOUNT'"
              :fileId="userInfo.file_id"
              @imageChanged="changeAvatar($event)"
            ></AvatarUpload>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="3">名前 <span class="red--text">*</span></v-col>
          <v-col class="text-left" md="9">
            <v-text-field
              v-model="userInfo.name"
              maxlength="64"
              clearable
              :error-messages="nameErrors"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="3">ナマエ<span class="red--text">*</span></v-col>
          <v-col class="text-left" md="9">
            <v-text-field
              v-model="userInfo.name_kana"
              maxlength="64"
              clearable
              :error-messages="nameKanaErrors"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="3">雇用形態<span class="red--text">*</span></v-col>
          <v-col class="text-left" md="9">
            <v-select
              :items="employeeTypes"
              item-text="name"
              item-value="id"
              no-data-text="空欄"
              v-model="userInfo.employee_type_id"
              :error-messages="employeeTypeErrors"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="3">就業時間<span class="red--text" v-if="!isInternOrPartTime()">*</span></v-col>
          <v-col class="text-left" md="4">
            <v-select
              v-model="userInfo.start_time"
              :items="times"
              :error-messages="wstartTimeErrors"
              clearable
            >
            </v-select>
          </v-col>
          <v-col class="text-center" md="1">～</v-col>
          <v-col class="text-left" md="4">
            <v-select
              v-model="userInfo.end_time"
              :error-messages="wendTimeErrors"
              :items="times"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="4">所属部署＞所属Gr<span class="red--text">*</span></v-col>
          <v-col class="text-left" md="8">
            <department
              ref="departmentGroup"
              :inputdgName="displayDepartment"
              :items="departments"
              @selectedDepartment="changeDepartment($event)"
              @clearDepartment="clearDepartment()"
            ></department>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="3">役職<span class="red--text">*</span></v-col>
          <v-col class="text-left" md="9">
            <v-select
              :items="positions"
              item-text="name"
              item-value="id"
              no-data-text="空欄"
              v-model="userInfo.position_id"
              :error-messages="positionErrors"
              @change="changPosition()"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="3">システム権限<span class="red--text">*</span></v-col>
          <v-col class="text-left" md="9">
            <v-select
              v-model="userInfo.system_auth_id"
              :items="systemAuthorities"
              item-text="name"
              item-value="id"
              no-data-text="空欄"
              :error-messages="systemAuthErrors"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="3">承認権限<span class="red--text">*</span></v-col>
          <v-col class="text-left" md="9">
            <v-select
              v-model="userInfo.approval_auth_id"
              item-text="name"
              item-value="id"
              no-data-text="空欄"
              :items="approvalAuthorities"
              :error-messages="approvalAuthErrors"
              clearable
              @change="changeApproval()"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="3">直属上長<span class="red--text">*</span></v-col>
          <v-col class="text-left __managers-group" md="9">
            <div class="__manager-text d-flex align-center" v-if="!isInitExistManager">
              <div>
                <span>{{ userInfo.manage_name }}</span>
              </div>
            </div>
            <v-select
              class="__managers-select"
              v-model="userInfo.manage_id"
              :items="managers"
              :disabled="deactiveManage"
              :item-text="'manageName'"
              :item-value="'manageId'"
              no-data-text="空欄"
              :error-messages="manageErrors"
              clearable
              @click="changeIsInitExistManager"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="3">入社日<span class="red--text">*</span></v-col>
          <v-col class="text-left" md="9">
            <v-dialog
              ref="dialog"
              v-model="openDatepicker"
              :return-value.sync="userInfo.joining_date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="userInfo.joining_date"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  ref="joinDate"
                  :error-messages="joinDateErrors"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                :day-format="(date) => new Date(date).getDate()"
                v-model="userInfo.joining_date"
                scrollable
                locale="ja"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="resetJoiningDate()">
                  {{ $t("label.buttons.cancel") }}
                </v-btn>
                <v-btn text color="primary" @click="setJoiningDate()">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="3">日次予定の提出<span class="red--text">*</span></v-col>
          <v-col class="text-left" md="9">
            <v-select
              v-model="userInfo.is_need_submit"
              :error-messages="isNeedSubmitErrors"
              item-text="name"
              item-value="id"
              :items="needTypes"
              no-data-text="空欄"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="3">メールアドレス<span class="red--text">*</span></v-col>
          <v-col class="text-left" md="9">
            <v-text-field
              v-model="userInfo.mail"
              clearable
              :error-messages="emailErrors"
              maxlength="256"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn tile color="primary" @click="submit()">保存</v-btn>
        <v-btn tile outlined color="ligth" @click="close()">{{
          $t("label.buttons.cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { backMixin } from "../../../mixins/back";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import { initTimeRange } from "../../../helpers/system";
import { compareDateValidator } from "../../../helpers/validator";
import { startEndTogetherValidator } from "../../../helpers/validator";
import {
  formatDateTime,
  formatTime,
  parseDateTime,
} from "../../../helpers/datetime-helpers";
import Department from "../../../components/Department.vue";
import AvatarUpload from "../../../components/AvatarUpload.vue";
import { mapGetters } from "vuex";
import axios from "axios";
import {
  GET_TEMPLATE_TYPES_API,
  GET_TEMPLATE_MANAGERS_API,
  UPDATE_PROFILE_API,
  UPLOAD_AVATAR_API,
} from "../../../constants/api";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC102, FC106, FC107, FC1002 } from "../../../constants/fc";
import { yyyyMMdd1, yyyyMMdd2 } from "../../../constants/df";
import {
  debounce_leading,
  deepClone,
  isEmpty,
  isEmptyNumber,
} from "../../../helpers/helpers";
import { STATUS_NO_CONTENT } from "../../../constants/status";
export default {
  name : 'AccountDialog',
  components: { Department, AvatarUpload },
  mixins: [validationMixin, backMixin],
  props: {
    value: Boolean,
    inputUserInfo: {
      type: Object,
      default: () => {},
    },
    inputModalType: {
      type: String,
      default: "new",
    },
  },
  data() {
    return {
      userInfo: { file_id: "", manage_id: "" },
      iuserInfo: { file_id: "", manage_id: "" },
      modalType: "",
      selectedFile: null,
      isSelecting: false,
      openDatepicker: false,
      departments: [],
      displayDepartment: "",
      times: [],
      employeeTypes: [],
      positions: [],
      systemAuthorities: [],
      approvalAuthorities: [],
      managers: [],
      needTypes: [
        { id: 1, name: "要" },
        { id: 0, name: "不要" },
      ],
      errorApiMessage: "",
      isInitExistManager : false,
      prevManage : null,
    };
  },
  async mounted() {
    this.times = initTimeRange();
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },
  watch: {
    inputUserInfo: {
      async handler(val) {
        if (isEmpty(val)) return;
        // this.iuserInfo = deepClone(val);
        this.userInfo = this.inputModalType === "edit" ? val : {};
        
        this.userInfo.start_time = formatTime(val.start_time);
        this.userInfo.end_time = formatTime(val.end_time);
        this.userInfo.joining_date = this.userInfo.ojoining_date =
          formatDateTime(
            parseDateTime(this.userInfo.joining_date, yyyyMMdd1),
            yyyyMMdd2
          );
        this.displayDepartment = `${this.userInfo.department_name}＞${this.userInfo.group_name}`;
        this.iuserInfo = deepClone(val);
        if (this.modalType !== "new") {
          await this.getManager();
          this.checkExistManager(val)
        }
      },
    },
    inputModalType: {
      handler(val) {
        this.modalType = val;
        if (this.modalType === "new") {
          this.userInfo = { file_id: "" };
          this.iuserInfo = { file_id: "" };
        }
      },
    },
    userInfo: {
      deep: true,
      handler(val) {
        this.$store.dispatch(
          "save/setChangeFlag",
          JSON.stringify(val) !== JSON.stringify(this.iuserInfo)
        );
      },
    },
    value: {
      async handler(val) {
        if (val) {
          this.$v.$reset();
          this.errorApiMessage = "";
          this.selectedFile = null;
          await this.getTemplate();
          if (this.modalType === "new") {
            this.removeCheckExistManager();
            this.displayDepartment = "";
          }
        } else return;
      },
    },
  },
  validations: {
    userInfo: {
      name: { required },
      name_kana: { required },
      employee_type_id: { required },
      position_id: { required },
      manage_id: {
        required: requiredIf((userInfo) => {
          let required =
            (userInfo.position_id === 630 && userInfo.approval_auth_id === 30) ||
            userInfo.position_id === 640 ||
            userInfo.position_id === 650 ||
            userInfo.position_id === 660 ||
            userInfo.position_id === 670;
          return !required;
        }),
      },
      joining_date: { required },
      is_need_submit: { required },
      system_auth_id: { required },
      approval_auth_id: { required },
      mail: { required, email },
      start_time: { 
        required: requiredIf((userInfo) => {
          let required = userInfo.position_id !== 100 && userInfo.position_id !== 200;
          return required;
        }),
      },
      end_time: {
        required: requiredIf((userInfo) => {
          let required = userInfo.position_id !== 100 && userInfo.position_id !== 200;
          return required;
        }),
        compareDate: compareDateValidator("start_time"),
        togetherInput: startEndTogetherValidator("start_time"),
      },
    },
  },
  computed: {
    ...mapGetters("save", ["getChangeFlag"]),
    ...mapGetters("common", ["loginInfo"]),
    nameErrors() {
      const errors = [];
      if (this.$v.userInfo.name.$dirty) {
        !this.$v.userInfo.name.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    nameKanaErrors() {
      const errors = [];
      if (this.$v.userInfo.name_kana.$dirty) {
        !this.$v.userInfo.name_kana.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    employeeTypeErrors() {
      const errors = [];
      if (this.$v.userInfo.employee_type_id.$dirty) {
        !this.$v.userInfo.employee_type_id.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    positionErrors() {
      const errors = [];
      if (this.$v.userInfo.position_id.$dirty) {
        !this.$v.userInfo.position_id.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    manageErrors() {
      const errors = [];
      if (this.$v.userInfo.manage_id.$dirty) {
        !this.$v.userInfo.manage_id.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    joinDateErrors() {
      const errors = [];
      if (this.$v.userInfo.joining_date.$dirty) {
        !this.$v.userInfo.joining_date.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    isNeedSubmitErrors() {
      const errors = [];
      if (this.$v.userInfo.is_need_submit.$dirty) {
        !this.$v.userInfo.is_need_submit.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    emailErrors() {
      const errors = [];
      if (this.$v.userInfo.mail.$dirty) {
        !this.$v.userInfo.mail.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.userInfo.mail.email &&
          errors.push(this.$t("label.error.invalid_email_address"));
      }

      return errors;
    },
    systemAuthErrors() {
      const errors = [];
      if (this.$v.userInfo.system_auth_id.$dirty) {
        !this.$v.userInfo.system_auth_id.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    approvalAuthErrors() {
      const errors = [];
      if (this.$v.userInfo.approval_auth_id.$dirty) {
        !this.$v.userInfo.approval_auth_id.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    wendTimeErrors() {
      const errors = [];
      if (this.$v.userInfo.end_time.$dirty) {
        !this.$v.userInfo.end_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.userInfo.end_time.compareDate &&
          errors.push(this.$t("label.error.end_time"));
        !this.$v.userInfo.end_time.togetherInput &&
          errors.push(this.$t("label.error.start_end_together"));
      }

      return errors;
    },
    wstartTimeErrors() {
      const errors = [];
      if (this.$v.userInfo.start_time.$dirty) {
        !this.$v.userInfo.start_time.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    deactiveManage() {
      return (
        (this.userInfo.position_id === 630 && this.userInfo.approval_auth_id === 30) ||
        this.userInfo.position_id === 640 ||
        this.userInfo.position_id === 650 ||
        this.userInfo.position_id === 660 ||
        this.userInfo.position_id === 670
      );
    },
  },
  methods: {
    submit: debounce_leading(async function () {
      this.removeCheckExistManager();
      let childInvalid = this.$refs.departmentGroup.touch();
      this.$v.$touch();
      if (this.$v.$invalid || childInvalid) {
        ///
      } else {
        if (this.selectedFile !== null) await this.saveAvatar();

        try {
          let params = {
            ...this.userInfo,
            joining_date: formatDateTime(this.userInfo.joining_date, yyyyMMdd1),
          };

          if (this.userInfo.start_time && this.userInfo.end_time){
            params.start_time = `${this.userInfo.start_time.replace(":", "")}00`;
            params.end_time = `${this.userInfo.end_time.replace(":", "")}00`;
          }
          else{
            params.start_time = null;
            params.end_time = null;
          }

          if (this.modalType === "new") {
            const { data } = await axios.post(UPDATE_PROFILE_API(), params);
            params.user_id = data.result.user_id;
            this.$emit("createAccount", this.setUser(params));
          } else {
            await axios.put(UPDATE_PROFILE_API(this.userInfo.user_id), params);
            this.$emit("updateAccount", this.setUser(params));

            //TODO Update self information
            if (this.loginInfo.user_id === this.userInfo.user_id)
              this.$store.dispatch("common/saveLoginInfo", {
                loginInfo: this.setUser(params),
              });
          }
          this.$store.dispatch("save/setChangeFlag", false);
        } catch (error) {
          const { status } = error.response;
          if (status === 404) {
            this.errorApiMessage = error.response.data.message;
          } else apiErrorMessages(this, FC102, status);
        }
        this.$refs.departmentGroup.reset();
      }
    }),

    async getTemplate() {
      try {
        const { data } = await axios.get(GET_TEMPLATE_TYPES_API());
        this.employeeTypes = data.result.employee_type;
        this.departments = data.result.department;
        this.positions = data.result.position;
        this.systemAuthorities = deepClone(data.result.system_authority);
        this.approvalAuthorities = deepClone(data.result.approval_authority);
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC106, status);
      }
    },

    async getManager() {
      try {
        const { data, status } = await axios.get(
          GET_TEMPLATE_MANAGERS_API(
            this.userInfo.approval_auth_id,
            this.userInfo.group_id
          )
        );
        if (status === STATUS_NO_CONTENT) {
          this.managers = [];
        } else {
          let manageData = isEmpty(this.userInfo.user_id)
            ? data.result
            : data.result.filter((x) => x.id !== this.userInfo.user_id);
          this.managers = manageData.map((x) => {
            return {
              manageName: x.name,
              manageId: x.id,
            };
          });
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC107, status);
      }
    },

    async changeDepartment(event) {
      this.userInfo.department_id = event.department.id;
      this.userInfo.department_name = event.department.name;
      this.userInfo.group_id = event.group.id;
      this.userInfo.group_name = event.group.name;
      this.displayDepartment = `${event.department.name}＞${event.group.name}`;
      await this.detectManager();
    },

    async detectManager() {
      if (
        !isEmptyNumber(this.userInfo.group_id) &&
        !isEmptyNumber(this.userInfo.approval_auth_id)
      ) {
        this.removeCheckExistManager();
        await this.getManager();
        if (!this.managers.some((x) => x.manageId === this.userInfo.manage_id))
          this.userInfo = { ...this.userInfo, manage_id: null };
      }
    },

    clearDepartment() {
      this.userInfo.department_id = null;
      this.userInfo.department_name = "";
      this.userInfo.group_id = null;
      this.userInfo.group_name = "";
      this.displayDepartment = "";
      this.userInfo = { ...this.userInfo, manage_id: null };
    },

    async changeApproval() {
      await this.detectManager();
    },

    changPosition() {
      if (this.deactiveManage) {
        this.userInfo = { ...this.userInfo, manage_id: null };
      }
    },

    changeAvatar(file) {
      this.selectedFile = file;
    },

    async saveAvatar() {
      try {
        let formData = new FormData();
        formData.append("file", this.selectedFile);
        const { data } = await axios.post(UPLOAD_AVATAR_API(), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.userInfo.file_id = data.result.file_id;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC1002, status);
      }
    },

    setUser(user) {
      user.system_auth_name = this.systemAuthorities.find(
        (x) => x.id === user.system_auth_id
      )?.name;
      user.position_name = this.positions.find(
        (x) => x.id === user.position_id
      )?.name;
      user.employee_type_name = this.employeeTypes.find(
        (x) => x.id === user.employee_type_id
      )?.name;
      // set Manager Name 
      if (this.prevManage && this.prevManage.id === user.manage_id) {
        user.manage_name = this.prevManage.name
      } else {
        user.manage_name = isEmpty(user.manage_id)
          ? ""
          : this.managers.find((x) => x.manageId === user.manage_id)?.manageName;
      }
      user.approval_auth_name = this.approvalAuthorities.find(
        (x) => x.id === user.approval_auth_id
      )?.name;
      return user;
    },

    resetJoiningDate() {
      this.userInfo.joining_date = this.userInfo.ojoining_date;
      this.openDatepicker = false;
    },

    setJoiningDate() {
      this.$refs.dialog.save(this.userInfo.joining_date);
      this.userInfo.ojoining_date = this.userInfo.joining_date;
    },
    changeIsInitExistManager() {
      this.removeCheckExistManager()
      this.userInfo.manage_id = null;
    },
    isInternOrPartTime(){
      return this.userInfo.position_id === 100 || this.userInfo.position_id === 200;
    },
    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$refs.avatar.clearImage();
            this.$refs.departmentGroup.reset();
            this.userInfo = {
              file_id: null,
            };
            this.$v.$reset();
            this.$emit("input");
            this.$emit("closeAccountDialog");
          }, 100);
        },
        () => {}
      );
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.$refs.avatar.clearImage();
          this.$refs.departmentGroup.reset();
          this.userInfo = {
            file_id: null,
          };
          this.$v.$reset();
          this.$emit("input");
          this.$emit("closeAccountDialog");
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },

    checkExistManager(userInfo){
      this.isInitExistManager = !!this.managers.find(manager => manager.manageId === userInfo.manage_id)
      if (!this.isInitExistManager) {
        this.prevManage = {
          id : userInfo.manage_id,
          name : userInfo.manage_name
        }
      }
    },

    removeCheckExistManager() {
      this.isInitExistManager = true;
    }
  },

  beforeDestroy() {
    this.removeCheckExistManager()
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>

<style scoped lang="scss">
.__managers-group {
  position: relative;
  .__manager-text {
    position: absolute;
    height: 70px;
    div {
      padding-bottom: 3px;
      width: 100%;
      span {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
}
.error-text {
  width: calc(50% - 12px);
  text-align: left;
  position: absolute;
  top: 165px;
  left: 20px;
  font-size: 75%;
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}
</style>
