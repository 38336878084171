<template>
  <v-snackbar
    :class="[
      { 'position-custom': hasClass },
      { collapse: collapse },
      { uncollapse: !collapse },
      { 'work-break': true },
    ]"
    v-model="visible"
    :multi-line="true"
    :timeout="timeout"
  >
    <p class="v-application" v-for="(p, i) in content" :key="i">{{ p }}</p>
  </v-snackbar>
</template>
<script>
export default {
  name: "Toast",
  props: {},
  data() {
    return {
      visible: false,
      hasClass: false,
      collapse: false,
      message: "",
      timeout: 3000,
    };
  },
  computed: {
    content() {
      if (!Array.isArray(this.message)) {
        return [this.message];
      }
      return this.message;
    },
  },
  methods: {},
};
</script>
<style  lang="scss">
.position-custom.uncollapse {
  .v-snack__wrapper {
    position: absolute;
    top: 180px;
    right: 100px !important;
  }
}
.position-custom.collapse {
  .v-snack__wrapper {
    position: absolute;
    top: 180px;
    right: 500px !important;
  }
}
.work-break {
  word-break: break-all;
}
</style>
