<template>
  <v-dialog v-model="openDialog" hide-overlay fullscreen>
    <v-card>
      <v-card-title class="card-header">
        <v-btn @click="openDialog = false" class="ml-auto" icon
          ><v-icon small>$close</v-icon></v-btn
        >
      </v-card-title>
      <v-row>
        <v-col class="overflow-auto">
          <img v-if="!isEmpty(src)" :src="src" alt="Image" />
          <pre class="overflow-auto" v-if="!isEmpty(text)">
          {{ text }}
        </pre
          >
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { isEmpty } from "@/helpers/helpers";

export default {
  metaInfo() {
    return { title: "PreviewFile" };
  },

  props: {
    show: Boolean,
    src: String,
    text: String,
  },
  watch: {
    show: {
      handler() {
        this.openDialog = true;
      },
    },
  },
  data() {
    return {
      openDialog: false,
    };
  },
  methods: {
    isEmpty(val) {
      return isEmpty(val);
    },
  },
};
</script>