var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-5 scroll-comments"},_vm._l((_vm.results),function(notification){return _c('div',{key:notification.id},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":notification.userImage}})],1),_c('v-list-item-content',[_c('div',[_vm._v(_vm._s(notification.comment_user_name)+"さん")]),_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.formatDate(notification.comment_date, "yyyy/MM/dd(EEEEE) HH:mm"))+" ")]),_c('div',[_c('v-btn',{staticClass:"mt-1 mb-5",class:{
              'reject-text': _vm.isRejectText(notification),
              disabledAction: true,
            }},[_vm._v(" "+_vm._s(notification.phase_name)+" ")])],1),(
            _vm.isRejectText(notification) &&
            notification.issue_tasks &&
            notification.issue_tasks.length > 0
          )?_c('ul',{staticClass:"mb-5 word-break"},_vm._l((notification.issue_tasks),function(issueTask,i){return _c('li',{key:issueTask.id,class:{ 'mb-2': i !== notification.issue_tasks.length - 1 }},[_vm._v(" "+_vm._s(_vm.displayIssueTask(issueTask))+" ")])}),0):_vm._e(),_c('pre',{staticClass:"notification--comment v-application"},[_vm._v(_vm._s(notification.comment))])])],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }