<template>
  <v-container fill-height fluid>
    <v-breadcrumbs large :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon small>$bradcrumbtrail</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <a class="font-weight-bold black--text text-decoration-none" :href="item.href" :disabled="item.disabled">
          {{ item.text }}
        </a>
      </template>
    </v-breadcrumbs>
    <v-row v-if="notVerifyPassword">
      <v-col class="mx-auto" md="4">
        <v-card class="pa-5">
          <v-row>
            <v-col md="12" cols="12" class="text-center text-h5">
              パスワードを変更する
            </v-col>
            <v-col md="12" cols="12" class="text-center">{{ userName }} </v-col>
            <v-col md="12" cols="12" class="text-center ">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="input-email text-truncate">{{ email }}</div>
                </template>
                <span> {{ email }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" class="text-center">
              元のパスワードを入力してください
            </v-col>
          </v-row>
          <v-row align="baseline" justify="center">
            <v-col md="3" cols="5" align="center"> パスワード </v-col>
            <v-col md="9" cols="7" class="text-left">
              <v-text-field
                v-model="oldPassword"
                autocomplete="new-password"
                :error-messages="oldPasswordErrors"
                :type="showOldPassword ? 'text' : 'password'"
                maxlength="64"
                @input.native="allowHalfWidth($event, 'oldPassword')"
              >
                <template v-slot:append>
                  <v-icon @click="showOldPassword = !showOldPassword" v-show="showOldPassword">$passwordvisible</v-icon>
                  <v-icon @click="showOldPassword = !showOldPassword" v-show="!showOldPassword">$passwordinvisible</v-icon>
                </template>

                <template v-slot:message="{ message }">
                  <v-icon small class="mr-2">$error</v-icon>
                  <span>{{ message }}</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="mx-auto text-center" md="10">
              <a class="black--text" href="/reset-password">※パスワードを忘れた場合</a>
              <v-btn @click="verify()" tile color="primary" class="ml-3" depressed>次へ </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <UpdatePassword :userId="userId" @cancelClick="cancel()" v-else></UpdatePassword>
  </v-container>
</template>

<script>
import { passwordValidator } from '../../../helpers/validator';
import { validationMixin } from 'vuelidate';
import { requiredIf, minLength } from 'vuelidate/lib/validators';
import UpdatePassword from './UpdatePassword.vue';
import axios from 'axios';
import { UPDATE_PASSWORD } from '../../../constants/api';
import { apiErrorMessages } from '../../../helpers/messages';
import { debounce_leading, isEmpty, isJapanese, jReplace } from '../../../helpers/helpers';
import { FC05 } from '../../../constants/fc';
export default {
  layout: 'default',
  components: { UpdatePassword },
  metaInfo() {
    return { title: this.$t('title.updatepassword') };
  },
  mixins: [validationMixin],
  validations: {
    oldPassword: {
      required: requiredIf(notVerifyPassword => {
        return notVerifyPassword;
      }),
      minLength: minLength(8),
      passwordValidator
    }
  },
  computed: {
    oldPasswordErrors() {
      const errors = [];
      if (this.$v.oldPassword.$dirty) {
        !this.$v.oldPassword.required && errors.push(this.$t('label.error.no_data_input'));

        !this.$v.oldPassword.minLength && errors.push(this.$t('label.error.pwd_length'));

        !this.$v.oldPassword.passwordValidator && errors.push(this.$t('label.error.pwd_valid'));

        !isEmpty(this.passwordErrorsApi) && errors.push(this.passwordErrorsApi);
      }
      return errors;
    }
  },
  data: () => ({
    notVerifyPassword: true,
    oldPassword: '',
    email: '',
    userId: '',
    userName: '',
    passwordErrorsApi: '',
    showOldPassword: false,
    breadcrumbs: [
      {
        text: '',
        disabled: true,
        href: ''
      },
      {
        text: 'パスワードを変更',
        disabled: true,
        href: ''
      }
    ]
  }),
  methods: {
    verify: debounce_leading (async function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        try {
          let params = {
            user_id: this.userId,
            password: this.oldPassword
          };
          const { data } = await axios.post(UPDATE_PASSWORD(), params);
          if (data.result.is_invalid === 0) {
            this.notVerifyPassword = false;
            this.$v.$reset();
          } else {
            this.passwordErrorsApi = apiErrorMessages(this, FC05, 401);
          }
        } catch (error) {
          const { status } = error.response;
          let errorMsg = apiErrorMessages(this, FC05, status);
          if (errorMsg) {
            this.passwordErrorsApi = errorMsg;
          }
        }
      }
    }),

    cancel() {
      this.notVerifyPassword = true;
    },

    allowHalfWidth(e, field) {
      if (isJapanese(e.target.value)) {
        this[field] = jReplace(e.target.value);
      }
      return true;
    }
  },
  created() {
    this.userId = this.$route.params.id;
    this.email = this.$route.query.m;
    this.userName = this.$route.query.n;

    this.breadcrumbs = [
      {
        text: '',
        disabled: true,
        href: ''
      },
      {
        text: 'パスワードを変更',
        disabled: false,
        href: `${this.userId}?m=${this.email}&n=${this.userName}`
      }
    ];
  }
};
</script>

<style>
.input-email {
  border: 1px solid gray;
  padding: 8px 16px;
  border-radius: 6px;
}
</style>
