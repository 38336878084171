<template>
  <v-dialog :value="openDialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title class="card-header pb-2">
        {{ date }}<span class="px-2">日次予定</span
        ><strong class="px-2" v-if="loginInfo">
          {{ ownerName }}
        </strong>
      </v-card-title>
      <!-- start of List comment !-->
      <v-card-text v-if="showOldComments" class="my-0 py-0 overflow-hidden">
        <v-card-subtitle class="font-weight-bold">
          ▼コメント履歴
        </v-card-subtitle>
        <CommentList :notifications="notifications"></CommentList>
        <v-divider class="mx-3 mt-3 comments-divider"></v-divider>
        <v-card-subtitle class="pb-0 font-weight-bold">
          ▼コメント欄
        </v-card-subtitle>
      </v-card-text>
      <!-- end of List comment !-->
      <v-card-text
        class="my-0 py-0"
        :class="[
          $vuetify.breakpoint.mobile ? 'comment-height' : 'overflow-hidden',
        ]"
      >
        <v-container fluid>
          <v-textarea
            v-model="comment"
            clearable
            clear-icon="mdi-close"
            placeholder="コメントを追加"
            maxlength="512"
            counter
          ></v-textarea> </v-container
      ></v-card-text>

      <v-card-actions :class="[{ 'responsive-button': $vuetify.breakpoint.mobile }]">
        <v-spacer></v-spacer>
        <v-btn depressed outlined @click="close()">{{
          $t("label.buttons.cancel")
        }}</v-btn>
        <v-btn depressed outlined @click="saveDraft()" class="font-weight-bold">
          {{ $t("label.buttons.save_draft") }}</v-btn
        >
        <v-btn
          class="font-weight-bold"
          :class="[{ 'new-line': $vuetify.breakpoint.mobile }]"
          depressed
          outlined
          @click="confirm()"
        >
          <v-icon class="blue--text mr-2" small right>$submitorconfirm</v-icon
          > {{ $t("label.buttons.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {
  formatDateTime,
  parseDateTime,
} from "../../../helpers/datetime-helpers";
import { backMixin } from "../../../mixins/back";
import * as Role from "../../../constants/role";
import { yyyyMMddEEEEE3, yyyyMMdd2 } from "../../../constants/df";
import { debounce_leading, isEmpty } from "../../../helpers/helpers";
import CommentList from "../components/CommentList";
export default {
  middleware: Role.AUTH,
  components: {
    CommentList,
  },
  props: {
    show: Boolean,
    inputComment: String,
    ownerName: String,
    selectedDate: String,
    notifications: Array,
    showOldComments: Boolean,
  },
  mixins: [backMixin],
  data() {
    return {
      openDialog: this.show,
      comment: this.inputComment,
    };
  },
  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },
  watch: {
    comment: {
      handler(val) {
        this.$store.dispatch("save/setChangeFlag", !isEmpty(val));
      },
    },
    show() {
      this.openDialog = true;
      this.comment = this.inputComment;
    },
  },
  computed: {
    ...mapGetters("save", ["getChangeFlag"]),
    ...mapGetters("common", ["loginInfo"]),
    date() {
      return formatDateTime(
        parseDateTime(this.selectedDate, yyyyMMdd2),
        yyyyMMddEEEEE3
      );
    },
  },
  methods: {
    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.openDialog = false;
            this.$emit("input");
          }, 100);
        },
        () => {}
      );
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.openDialog = false;
          this.$emit("input");
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },

    confirm: debounce_leading(function () {
      this.$store.dispatch("save/setChangeFlag", false);
      this.openDialog = false;
      this.$emit("confirm", this.comment);
    }),

    saveDraft: debounce_leading(function () {
      this.openDialog = false;
      this.$emit("saveDraft", this.comment);
    }),
  },

  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>
<style lang="scss" src="@/assets/scss/_comment.scss" scoped></style>
<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}
.new-line {
  margin-top: 5px;
  margin-left: 17px !important;
}
</style>
