import {
  isEmptyNumber
} from "../helpers/helpers"
import {
  queryString
} from "../helpers/qs"

export const BASE_URL = "https://itokuro-rms.com/server"
/* LOGIN */
export const LOGIN_API = () => `${BASE_URL}/api/login`

/* LOGOUT */
export const LOGOUT_API = () => `${BASE_URL}/api/logout`

/* RESET_PASSWORD */
export const RESET_PASSWORD_API = () => `${BASE_URL}/api/password/mail`

/* EXPIRATION_PASSWORD */
export const EXPIRATION_PASSWORD = () => `${BASE_URL}/api/password/expiration`

/* UPDATE_PASSWORD */
export const UPDATE_PASSWORD = () => `${BASE_URL}/api/password`

/*UPLOAD AVATAR */
export const UPLOAD_AVATAR_API = () => `${BASE_URL}/api/files/profile`

/*GET PROFILE IMAGE*/
export const GET_PROFILE_API = () => `${BASE_URL}/api/profile-self`

/*GET PROFILE */
export const GET_PROFILE_IMAGE_API = (fileId) => `${BASE_URL}/api/files/profile/${fileId}`

/*UPDATE PROFILE*/
export const UPDATE_PROFILE_API = (userId) => {
  if (isEmptyNumber(userId)) return `${BASE_URL}/api/profiles/`;
  else return `${BASE_URL}/api/profiles/${userId}`
}

/*GET ALL PROFILE*/
export const GET_ALL_PROFILE_API = (department_id = '', groupId = '') => {
  let params = [];
  if (groupId && groupId !== 'plan' && groupId !== 'actual') {
    params.push(`group_id=${groupId}`);
  }
  if (department_id) {
    params.push(`department_id=${department_id}`);
  }
  params = params.join('&');
  if (params) {
    return `${BASE_URL}/api/profiles` + `?` + params;
  } else {
    return `${BASE_URL}/api/profiles`;
  }
}

/*GET_ALL_MEMNUS*/
export const GET_ALL_MEMNUS_API = () => `${BASE_URL}/api/menus`

/*IMPORT ACCOUNT CSV */
export const IMPORT_ACCOUNT_CSV_API = () => `${BASE_URL}/api/profiles/csv/import`

/*IMPORT ACCOUNT CSV */
export const EXPORT_ACCOUNT_CSV_API = (type, condition) => {
  if (type == 1) {
    return `${BASE_URL}/api/profiles/csv/export`;
  } else {
    const qs = queryString.stringify(queryString.clean(condition));
    let url = `${BASE_URL}/api/profiles/csv/export?${qs}`;
    return url;
  }
}

/*GET TEMPLATE TYPES */
export const GET_TEMPLATE_TYPES_API = () => `${BASE_URL}/api/profiles-templates`

/*GET TEMPLATE MANAGERS */
export const GET_TEMPLATE_MANAGERS_API = (authority_id, group_id) => {
  switch (true) {
    case !isEmptyNumber(authority_id) && isEmptyNumber(group_id):
      return `${BASE_URL}/api/profiles-templates/managers?authority_id=${authority_id}`
    case !isEmptyNumber(group_id) && !isEmptyNumber(authority_id):
      return `${BASE_URL}/api/profiles-templates/managers?authority_id=${authority_id}&group_id=${group_id}`
    case !isEmptyNumber(group_id) && isEmptyNumber(authority_id):
      return `${BASE_URL}/api/profiles-templates/managers?group_id=${group_id}`
    default:
      return `${BASE_URL}/api/profiles-templates/managers`
  }
}

/*GET_SCHEDULE_API*/
export const GET_SCHEDULE_API = (planId = null) => {
  if (planId) {
    return `${BASE_URL}/api/schedules/${planId}`;
  } else {
    return `${BASE_URL}/api/schedules/`;
  }
}

/*GET_WEEKLY_SCHEDULE_API*/
export const GET_WEEKLY_SCHEDULE_API = () => {
  return `${BASE_URL}/api/week-schedules/`;
}

/*GET_WEEKLY_ACTUAL_API*/
export const GET_WEEKLY_ACTUAL_API = () => {
  return `${BASE_URL}/api/week-actuals/`;
}

/*GET DEPARTMENT AND GROUP */
export const GET_BELONG_API = (userId = '') => {
  if (userId) {
    return `${BASE_URL}/api/belongs/?userId=${userId}`;
  } else {
    return `${BASE_URL}/api/belongs/`;
  }
}

/*GET STATUS LIST*/
export const STATUS_API = (departmentId, groupId, scheduleStart, scheduleEnd) => {
  let url = `${BASE_URL}/api/status?schedule_date_st=${scheduleStart}&schedule_date_ed=${scheduleEnd}`;
  if (departmentId) {
    url += `&department_id=${departmentId}`;
  }
  if (groupId) {
    url += `&group_id=${groupId}`;
  }
  return url
}

/*GET_STATUS_SCHEDULE_API*/
export const GET_STATUS_SCHEDULE_API = (departmentId, groupId, startDate, endDate) => {
  let url = `${BASE_URL}/api/status-schedules?department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (groupId && groupId !== 'plan') {
    url += `&group_id=${groupId}`;
  }
  else if (groupId === 'plan') {
    url += `&is_need_approval=1`;
  }
  return url;
}

/*GET_STATUS_ACTUAL_API*/
export const GET_STATUS_ACTUAL_API  = (departmentId, groupId, startDate, endDate) => {
  let url = `${BASE_URL}/api/status-actuals?department_id=${departmentId}&start_date=${startDate}&end_date=${endDate}`;
  if (groupId && groupId !== 'actual') {
    url += `&group_id=${groupId}`;
  }
  else if (groupId === 'actual') {
    url += `&is_need_approval=1`;
  }
  return url;
}

/*GET_DB_TASKS_API*/
export const GET_DB_TASKS_API = () => `${BASE_URL}/api/tasks/standard/user`

/* GET_ALL_PROFILES */
export const GET_ALL_PROFILES_API = (groupId) => `${BASE_URL}/api/profiles?group_id=${groupId}`

/*UPDATE_DB_TASKS_API*/
export const UPDATE_DB_TASKS_API = (userId = '', standardId = '') => `${BASE_URL}/api/tasks/standard/user/${userId}/${standardId}`

/*GET CATEGORIES*/
export const CATEGORIES_API = (groupId) => {
  if (groupId) {
    return `${BASE_URL}/api/categories?group_id=${groupId}`
  } else {
    return `${BASE_URL}/api/categories`
  }
}

export const SEARCH_CATEGORIES_API = (query) => {
  const qs = queryString.stringify(query);
  return `${BASE_URL}/api/categories?${qs}`
}

export const IMPORT_CATEGORY_API = () => `${BASE_URL}/api/categories/csv/import`

export const EXPORT_CATEGORY_API = (type, query) => {
  if (type == 1) {
    return `${BASE_URL}/api/categories/csv/export`;
  } else {
    const qs = queryString.stringify(queryString.clean(query));
    return `${BASE_URL}/api/categories/csv/export?${qs}`
  }
}

export const SECONDARY_CATEGORY_API = (secondCategoryId = '') => {
  if (!isEmptyNumber(secondCategoryId))
    return `${BASE_URL}/api/categories-secondary/${secondCategoryId}`;
  else return `${BASE_URL}/api/categories-secondary`
}

export const PRIMARY_SUB_CATEGORY_API = (primaryCategoryId, secondCategoryId) => {
  if (secondCategoryId)
    return `${BASE_URL}/api/categories/${primaryCategoryId}/${secondCategoryId}`;
  else
    return `${BASE_URL}/api/categories/${primaryCategoryId}`;
}

export const PRIMARY_CATEGORY_API = (id) => {
  if (id) {
    return `${BASE_URL}/api/categories-primary/${id}`
  } else {
    return `${BASE_URL}/api/categories-primary`
  }
}
/*TAG_API*/
export const TAG_API = (tagId = '') => `${BASE_URL}/api/tags/${tagId}`

/*TODO_TASK_API*/
export const TODO_TASK_API = (taskId = '') => {
  if (taskId) {
    return `${BASE_URL}/api/tasks/todo/${taskId}`;
  } else {
    return `${BASE_URL}/api/tasks/todo`;
  }
}

/*ATTACH FILE*/
export const UPLOAD_ATTACH_FILE_API = () => `${BASE_URL}/api/files/actual`
export const ATTACH_FILE_API = (fileId) => `${BASE_URL}/api/files/actual/${fileId}`

/*ACTUAL TASK*/
export const ACTUAL_TASK_API = (actualId = '') => {
  if (actualId) {
    return `${BASE_URL}/api/tasks/actual/${actualId}`;
  } else {
    return `${BASE_URL}/api/tasks/actual`;
  }
}

/*GET OR DEL ACTUAL TASK */
export const GET_OR_DEL_ACTUAL_TASK_API = (actualId, taskId) => `${BASE_URL}/api/tasks/actual/${actualId}/${taskId}`

/*DELETE COMMENT*/
export const DELETE_COMMENT_API = (commentId) => `${BASE_URL}/api/comments/${commentId}`

export const UPDATE_ACTUAL_BREAK_TIME_API = (actualId) => `${BASE_URL}/api/tasks/actual/${actualId}`

export const GET_MEMBERS_API = () => `${BASE_URL}/api/profiles-templates/members`

/*GET SCHEDULE TASK API*/
export const GET_SCHEDULE_TASK_API = (planId, taskId = '') => `${BASE_URL}/api/tasks/schedule/${planId}/${taskId}`

/*SCHEDULE_API*/
export const SCHEDULE_API = (planId) => `${BASE_URL}/api/schedules/${planId}`

/*SCHEDULE_DRAFT_API*/
export const SCHEDULE_DRAFT_API = (planId) => `${BASE_URL}/api/schedules/draft/${planId}`

/*ACTUAL API */
export const ACTUAL_API = (actualId = '') => {
  if (actualId) {
    return `${BASE_URL}/api/actuals/${actualId}`;
  } else {
    return `${BASE_URL}/api/actuals`;
  }
}

/*ACTUAL_DRAFT_API*/
export const ACTUAL_DRAFT_API = (actualId) => `${BASE_URL}/api/actuals/draft/${actualId}`

/*DB STANDARD*/
export const DB_STANDARD_API = (standardTaskId) => {
  if (standardTaskId) {
    return `${BASE_URL}/api/tasks/standard/common/${standardTaskId}`;
  } else {
    return `${BASE_URL}/api/tasks/standard/common`;
  }
}

/* PRODUCTIVITY EVALUATION */
export const PRODUCTIVITY_API = (query) => {
  const qs = queryString.stringify(queryString.clean(query))
  return `${BASE_URL}/api/productivity?${qs}`;
}

export const PRODUCTIVITY_CSV_EXPORT_API = (type, condition) => {
  if (type == 1) {
    return `${BASE_URL}/api/productivity/csv/export`;
  } else {
    const qs = queryString.stringify(queryString.clean(condition));
    let url = `${BASE_URL}/api/productivity/csv/export?${qs}`;
    return url;
  }
}

/* PRODUCTIVITY REPORT EVALUATION */
export const PRODUCTIVITY_REPORT_API = (query) => {
  const qs = queryString.stringify(queryString.clean(query))
  return `${BASE_URL}/api/productivity-report?${qs}`;
}

export const PRODUCTIVITY_REPORT_CSV_EXPORT_API = (condition) => {
  const qs = queryString.stringify(queryString.clean(condition));
  return `${BASE_URL}/api/productivity-report/csv/export?${qs}`;
}

/* STATUS LOGS API */
export const STATUS_LOGS_API = (query) => {
  const qs = queryString.stringify(queryString.clean(query))
  return `${BASE_URL}/api/status-logs?${qs}`;
}

export const STATUS_LOGS_CSV_EXPORT_API = (type, condition) => {
  if (type == 1) {
    return `${BASE_URL}/api/status-logs/csv/export`;
  } else {
    const qs = queryString.stringify(queryString.clean(condition));
    let url = `${BASE_URL}/api/status-logs/csv/export?${qs}`;
    return url;
  }
}

/*NOTIFICATION API*/
export const NOTIFICATION_API = (notice_id) => {
  if (notice_id) {
    return `${BASE_URL}/api/notices/status/${notice_id}`
  } else {
    return `${BASE_URL}/api/notices/status/`
  }
}

export const IMPORT_DB_STANDARD_API = () => `${BASE_URL}/api/tasks/standard/csv/import`

export const EXPORT_DB_STANDARD_API = (type, condition) => {
  if (type == 1) {
    return `${BASE_URL}/api/tasks/standard/csv/export`;
  } else {
    const qs = queryString.stringify(queryString.clean(condition));
    let url = `${BASE_URL}/api/tasks/standard/csv/export?${qs}`;
    return url;
  }
}

/*TEMPLATE_API*/
export const TEMPLATE_API = (templateId) => {
  if (templateId) {
    return `${BASE_URL}/api/templates/${templateId}`;
  } else {
    return `${BASE_URL}/api/templates`;
  }
}
/*TASK_SCHEDULE_API*/
export const TASK_SCHEDULE_API = (planId) => {
  return `${BASE_URL}/api/tasks/schedule/${planId}`;
}


/*BELONGS API */
export const GET_BELONGS = (userId = '') => {
  return `${BASE_URL}/api/belongs/${userId}`;
}

/*GROUP HEAD*/
export const GET_GROUP_HEAD = (groupId = '') => {
  if (groupId) {
    return `${BASE_URL}/api/profiles-templates/group-head/${groupId}`;
  } else {
    return `${BASE_URL}/api/profiles-templates/group-head/`;
  }
}

/*DEPARTMENT_HEAD API */
export const GET_DEPARTMENT_HEAD = (departmentId) => {
  if (departmentId) {
    return `${BASE_URL}/api/profiles-templates/department-head/${departmentId}`;
  } else {
    return `${BASE_URL}/api/profiles-templates/department-head`;
  }
}

/*BELONG DEPARTMENT */
export const BELONG_DEPARTMENT = (id) => {
  if (id) {
    return `${BASE_URL}/api/belongs-department/${id}`;
  } else {
    return `${BASE_URL}/api/belongs-department`;
  }
}

/*BELONG GROUP */
export const BELONG_GROUP = (groupId) => {
  if (groupId) {
    return `${BASE_URL}/api/belongs-group/${groupId}`;
  } else {
    return `${BASE_URL}/api/belongs-group`;
  }
}

/*BELONG DEPARTMENT */
export const BELONG = (departmentId, groupId) => {
  if (groupId) {
    return `${BASE_URL}/api/belongs/${departmentId}/${groupId}`;
  } else {
    return `${BASE_URL}/api/belongs/${departmentId}`;
  }
}

/*SCHEDULE_SORT_API*/
export const SCHEDULE_SORT_API = (planId) => `${BASE_URL}/api/tasks/schedule-sort/${planId}`

/*ACTUAL_SORT_API*/
export const ACTUAL_SORT_API = (actualId) => `${BASE_URL}/api/tasks/actual-sort/${actualId}`


/*SKILL LEVELS*/
export const SKILL_LEVELS_API = () => {
  return `${BASE_URL}/api/skill-levels`;
}

/*SPLIT_TASK_API*/
export const SPLIT_TASK_API = (taskId = '') => {
  if (taskId) {
    return `${BASE_URL}/api/tasks/split/${taskId}`;
  } else {
    return `${BASE_URL}/api/tasks/split`;
  }
}

/*INCOMPLETE_TASK_API*/
export const INCOMPLETE_TASK_API = () => `${BASE_URL}/api/tasks/incomplete/`

/* USER SKILLS API */
export const USER_SKILLS_API = (query) => {
  const qs = queryString.stringify(queryString.clean(query))
  return `${BASE_URL}/api/user-skills?${qs}`;
}

export const IMPORT_USER_SKILLS_API = () => `${BASE_URL}/api/user-skills/csv/import`

export const USER_SKILLS_CSV_EXPORT_API = (condition) => {
  const qs = queryString.stringify(queryString.clean(condition));
  let url = `${BASE_URL}/api/user-skills/csv/export?${qs}`;
  return url;
}

/*FAVORITE_TASK_API*/
export const FAVORITE_TASK_API = (taskId = '') => {
  if (taskId) {
    return `${BASE_URL}/api/tasks/favorite/${taskId}`;
  } else {
    return `${BASE_URL}/api/tasks/favorite`;
  }
}