<template>
  <v-dialog persistent :value="openDialog" max-width="300px">
    <v-card class="py-3">
      <v-card class="mx-5 py-0" color="primary" dark>
        <v-card-title>CSVエクスポート設定 </v-card-title>
      </v-card>
      <v-card-text class="mt-5">
        <v-radio-group v-model="radioExport">
          <v-radio value="1" :label="'全タスク'"></v-radio>
          <v-radio value="0" :label="'表示されているタスク'"></v-radio>
        </v-radio-group>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn tile color="ligth darken-1" @click="close()">{{
          $t("label.buttons.cancel")
        }}</v-btn>
        <v-btn
          tile
          :disabled="isDisable"
          color="primary darken-1"
          @click="submit()"
          >エクスポート</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { USER_SKILLS_CSV_EXPORT_API } from "../../../constants/api";
import { FC721 } from "../../../constants/fc";
import { apiErrorMessages } from "../../../helpers/messages";
import { debounce_leading, exportShiftJIS } from "../../../helpers/helpers";
import axios from "axios";
import { formatDateTime } from "../../../helpers/datetime-helpers";
import { yyyyMM1 } from "../../../constants/df";
export default {
  props: {
    show: Boolean,
    inputCondition: Object,
  },
  watch: {
    openDialog: {
      handler() {
        // this.openDialog = true;
        this.radioExport = "3";
      },
    },
    inputCondition: {
      immediate: true,
      handler(val) {
        this.condition = val;
      },
    },
  },
  data() {
    return {
      openDialog: false,
      radioExport: "3",
      condition: {},
    };
  },
  computed: {
    isDisable() {
      return !(+this.radioExport === 1 || +this.radioExport === 0);
    },
  },
  methods: {
    showDialog(value) {
      this.openDialog = value;
    },
    submit: debounce_leading(async function () {
      const year_month_num = (this.condition.year_month === formatDateTime(new Date(), yyyyMM1)) ? +this.condition.year_month : +formatDateTime(
              this.condition.year_month,
              yyyyMM1
            )
      const query =
        +this.radioExport === 1
          ? {
              year_month: year_month_num,
              department_id: this.condition.department_id,
              group_id: this.condition.group_id,
            }
          : {
              ...this.condition,
              year_month: year_month_num,
            };
      try {
        const { data, headers } = await axios.get(
          USER_SKILLS_CSV_EXPORT_API(query),
          {
            responseType: "blob",
          }
        );
        const headerval = headers["content-disposition"];
        const filename = headerval
          .split(";")[1]
          .split("=")[1]
          .replace('"', "")
          .replace('"', "");
        exportShiftJIS(data, filename);
        this.openDialog = false;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC721, status);
      }
    }),
    close() {
      this.openDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
