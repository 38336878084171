<template>
  <v-container fluid class="mx-auto mt-12 container-custom">
    <div class="text-center">
      <p>パスワード再設定リンクの期限が切れています。</p>
      <p>ログイン画面から再度パスワードを設定してください。</p>
    </div>
    <div>
      <v-btn tile @click="login" color="primary px-12" depressed>ログインに移動する</v-btn>
    </div>
  </v-container>
</template>

<script>
import * as Role from "../../constants/role";
import axios from "axios";
import { EXPIRATION_PASSWORD } from "../../constants/api";
import { apiErrorMessages } from '../../helpers/messages';
import { FC04 } from '../../constants/fc';

export default {
  middleware: Role.GUEST,
  layout: "blank",
  metaInfo() {
    return { title: this.$t("title.expiredToken") };
  },
  methods: {
    login() {
      this.$router.push("login");
    },
  },
  async created() {
    try {
      let comparisonKey = this.$route.query.key || false;
      if(!comparisonKey) return;
      const { data } = await axios.post(EXPIRATION_PASSWORD(), {
        comparison_key: comparisonKey,
      });
      if (data.result.is_expired===0) {
        this.$router.push({
          path: "update-password",
          query: {
            comparison_key: comparisonKey,
          },
        });
      }
      
    } catch (error) {
      const { status } = error.response;
      apiErrorMessages(this, FC04, status);
    }
  },
};
</script>

<style lang="scss" scoped>
.container-custom {
  max-width: 500px !important;
  text-align: center;
}
</style>
