<template>
  <v-dialog scrollable :value="show" max-width="500px" persistent>
    <v-card>
      <v-card-title class="font-weight-bold">
        <span v-if="isEmpty(inputDepartment)">新規部署追加</span>
        <span v-else>部署情報編集</span>
      </v-card-title>
      <v-card-text>
        <v-row class="form-item" align="center" justify="center">
          <v-col md="4">部署名<span class="red--text">*</span></v-col>
          <v-col md="8">
            <v-text-field
              v-model="department.departmentName"
              maxlength="64"
              :error-messages="departmentNameErrors"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="form-item" align="center" justify="center">
          <v-col md="4">組織長</v-col>
          <v-col md="8">
            <v-autocomplete
              v-model="department.organizationHead"
              :items="items"
              :search-input.sync="search"
              append-icon="$search"
              item-text="name"
              item-value="id"
              clearable
              open-on-clear
            >
              <v-row class="mb-2" slot="prepend-item" v-if="searchData.length > 0">
                <v-col class="pl-6">
                  {{ this.searchData.length }}
                  {{
                    isEmpty(inputDepartment) ? "件の検索候補" : "件の検索候補"
                  }}
                </v-col>
              </v-row>
              <template v-slot:item="{ item }">
                <div class="d-block mb-2">
                  <v-row>
                    <v-col cols="12">
                      <strong>{{ item.name }}</strong>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex">
                    <v-col>
                      <span>{{ item.department_name }}</span>
                      <span class="ml-2"> {{ item.position_name }}</span>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </div>
              </template>
              <template v-slot:no-data>
                <div>候補者がいません</div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="form-item mt-2">
          <v-col md="12" align="center" justify="center">
            <v-btn
              color="primary"
              width="90px"
              class="grey darken-1"
              @click="submit()"
              >{{
                isEmpty(inputDepartment) ? $t("label.buttons.register") : "保存"
              }}</v-btn
            >
            <v-btn width="90px" class="ml-2" @click="close()">{{
              $t("label.buttons.cancel")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { debounce_leading, deepClone, isEmpty } from "../../../helpers/helpers";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { backMixin } from "../../../mixins/back";
import { STATUS_NO_CONTENT } from "@/constants/status";
import { GET_DEPARTMENT_HEAD, BELONG_DEPARTMENT } from "../../../constants/api";
import axios from "axios";
import { apiErrorMessages } from "@/helpers/messages";
import { FC110, FC302 } from "@/constants/fc";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      organizationHeads: [],
      search: null,
      department: {},
      isEditing: false,
      entries: [],
      searchData: [],
    };
  },
  props: {
    show: Boolean,
    departments: Array,
    inputDepartment: Object,
  },
  mixins: [validationMixin, backMixin],
  validations: {
    department: {
      departmentName: { required },
    },
  },
  watch: {
    inputDepartment: {
      async handler(val) {
        let department = {
          organizationHead: val.department_manager_id,
          departmentName: val.department_name,
        };
        this.originalDepartment = deepClone(department);
        this.department = deepClone(department);
        this.isEditing = false;
        this.$v.$reset();
      },
    },
    show: {
      async handler(value) {
        if (value) {
          this.isEditing = false;
          await this.getDepartmentHead();
          this.$v.$reset();
        }
      },
    },
    department: {
      deep: true,
      handler() {
        this.isEditing = this.compareValue();
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    search(val) {
      // Items have already been requested
      this.searchData = isEmpty(val)
        ? this.organizationHeads
        : this.organizationHeads.filter(
            (x) =>
              isEmpty(x.name) ||
              x.name.toLocaleLowerCase().indexOf(val.toLocaleLowerCase()) > -1
          ) || [];
    },
  },
  computed: {
    ...mapGetters("save", ["getChangeFlag"]),
    departmentNameErrors() {
      const errors = [];
      if (this.$v.department.departmentName.$dirty) {
        !this.$v.department.departmentName.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    items() {
      return this.entries.map((entry) => {
        return Object.assign({}, entry);
      });
    },
  },

  methods: {
    compareValue() {
      return (
        JSON.stringify({
          departmentName: this.department.departmentName,
          organizationHead: this.department.organizationHead,
        }) !==
        JSON.stringify({
          departmentName: this.originalDepartment.departmentName,
          organizationHead: this.originalDepartment.organizationHead,
        })
      );
    },
    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.$emit("close");
          }, 100);
        },
        () => {}
      );
    },
    submit: debounce_leading(async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        this.$store.dispatch("save/setChangeFlag", false);
        try {
          const { data } = isEmpty(this.inputDepartment)
            ? await axios.post(BELONG_DEPARTMENT(), {
                name: this.department.departmentName,
                manager_id: this.department.organizationHead || null,
              })
            : await axios.put(
                BELONG_DEPARTMENT(this.inputDepartment.department_id),
                {
                  name: this.department.departmentName,
                  manager_id: this.department.organizationHead || null,
                }
              );
          this.department.organizationHeadName = this.organizationHeads.find(
            (x) => x.id === this.department.organizationHead
          )?.name;
          this.department.id = data.result.id;
          this.$toast(
            isEmpty(this.inputDepartment)
              ? `${this.department.departmentName}を追加しました。`
              : `${this.department.departmentName}を編集しました。`,
            3000
          );
          this.$emit("submit", this.department);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC302, status);
        }
      }
    }),
    isEmpty(val) {
      return isEmpty(val);
    },
    getDepartmentHead: debounce_leading(async function () {
      try {
        const { data, status } = await axios.get(GET_DEPARTMENT_HEAD());
        this.entries = this.organizationHeads =
          status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC110, status);
      }
    }),
  },
};
</script>
