<template>
  <v-dialog scrollable v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-card-title class="card-header py-2">
        <v-btn @click="dialog = false" class="ml-auto" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="form-item pt-3" align="center" justify="center">
          <v-col class="text-left" cols="3">業務カテゴリ</v-col>
          <v-col class="text-left py-0" cols="9">
            <v-autocomplete
              v-model="condition.primary_id"
              :items="parentCategories"
              item-text="name"
              item-value="id"
              @change="primaryCategoryChange($event)"
              clearable
            >
            </v-autocomplete>
          </v-col>
          <v-col v-if="condition.primary_id" class="ml-auto py-0" cols="9">
            <v-autocomplete
              v-model="condition.secondary_id"
              :items="childCategories"
              item-text="name"
              item-value="id"
              clearable
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="form-item" align="center" justify="center">
          <v-col class="text-left" md="3">タスク名</v-col>
          <v-col class="text-left" md="9">
            <v-text-field
              v-model="condition.task_name"
              maxlength="60"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="ml-auto">
        <v-btn depressed color="primary" @click="submit()">検索</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { isEmpty } from "../../../helpers/helpers";
import { CATEGORIES_API } from "../../../constants/api";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import axios from "axios";
import { apiErrorMessages } from "@/helpers/messages";
import { FC701 } from "@/constants/fc";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      condition: {
        primary_id: null,
        secondary_id: null,
        task_name: "",
      },
      parentCategories: [],
      childCategories: [],
    };
  },
  props: {
    show: Boolean,
    searchCondition: Object,
  },
  computed: {
    ...mapGetters("save", ["getChangeFlag"]),
  },
  watch: {
    dialog: {
      async handler() {
        // this.dialog = true;
        this.condition = {
          primary_id: this.searchCondition.primaryId,
          secondary_id: this.searchCondition.secondaryId,
          task_name: this.searchCondition.taskName,
        };
        await this.getCategories();
      },
    },
  },
  methods: {
    showDialog(value) {
      this.dialog = value;
    },
    async getCategories() {
      try {
        const { data, status } = await axios.get(CATEGORIES_API());
        this.parentCategories = status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC701, status);
      }
    },
    primaryCategoryChange(value) {
      this.childCategories = this.parentCategories.find(
        (x) => x.id === value
      )?.secondaries;
      if (!this.childCategories) {
        this.condition.secondary_id = null;
      }
    },

    submit() {
      this.dialog = false;
      this.$emit("search", this.condition);
    },

    isEmpty(val) {
      return isEmpty(val);
    },
  },
};
</script>
