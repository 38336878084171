<template>
  <v-container fill-height fluid class="pa-0">
    <v-row>
      <v-col cols="12" md="7" sm="8" class="d-flex flex-wrap">
        <div class="d-flex align-baseline mt-3">
          <span class="mr-2 __label">対象部署</span>
          <v-combobox
            class="__content"
            v-model="selectDepartment"
            :items="departments"
            item-text="name"
            item-value="id"
            hide-details
            @change="departmentChange($event)"
            solo
            :menu-props="{
              maxWidth: 300,
              allowOverflow: true,
            }"
          >
          </v-combobox>
        </div>
        <div class="d-flex align-baseline mt-3">
          <span class="mr-2 text-right __label">対象Gr.</span>
          <v-combobox
            class="__content"
            v-model="selectGroup"
            :items="groups"
            item-text="name"
            item-value="id"
            hide-details
            @change="groupChange($event)"
            solo
            :menu-props="{
              maxWidth: 300,
              allowOverflow: true,
            }"
          >
          </v-combobox>
        </div>
        <div class="d-flex align-baseline mt-3">
          <span class="mr-2 text-right __label">対象月</span>
          <v-menu
            class="__content"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            left
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="__content"
                solo
                hide-details
                :value="displayDate"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              scrollable
              locale="ja"
              :day-format="(selectedDate) => new Date(selectedDate).getDate()"
              v-model="selectedDate"
              type="month"
              @change="changeDate()"
            ></v-date-picker>
          </v-menu>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="5"
        sm="4"
        class="pb-0 ml-0 mt-3 d-flex flex-wrap justify-end"
        :class="!$vuetify.breakpoint.mobile ? 'mb-0' : 'mb-3'"
      >
        <v-btn
          v-if="isMaster && !$vuetify.breakpoint.mobile"
          @click="importCsv()"
          :loading="isSelecting"
          tile
          outlined
          color="ligth"
        >
          <v-icon small left dark>$csvimport</v-icon>
          CSVインポート
        </v-btn>
        <input
          ref="uploader"
          class="d-none"
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @change="fileChanged"
        />
        <v-btn
          class="ml-3"
          v-if="!$vuetify.breakpoint.mobile"
          @click="exportCsv()"
          tile
          outlined
          color="ligth"
        >
          <v-icon small left dark>$csvexport</v-icon>
          CSVエクスポート
        </v-btn>
        <v-btn
          elevation="2"
          @click="showSearchBox()"
          :class="!$vuetify.breakpoint.mobile ? 'ml-3' : 'mt-0'"
        >
          タスクを検索
          <v-icon right small>$search</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0 pt-0">
      <v-col cols="12" class="py-0 my-0 text-right">
        <div class="pagination-user" v-if="userList.length > 0">
          <span class="__current-page"
            >{{ userPagination.currentPage }} /
            {{ userPagination.totalPage }}</span
          >
          <button
            type="button"
            :disabled="isDisablePrev"
            :class="{
              'v-btn--disabled': isDisablePrev,
            }"
            class="
              v-btn v-btn--icon v-btn--round v-btn--text
              theme--light
              v-size--default
            "
            @click="changePageUser(--userPagination.currentPage)"
          >
            <span class="v-btn__content"
              ><i
                aria-hidden="true"
                class="v-icon notranslate mdi mdi-chevron-left theme--light"
              ></i
            ></span>
          </button>
          <button
            type="button"
            :disabled="isDisableNext"
            :class="{
              'v-btn--disabled': isDisableNext,
            }"
            class="
              v-btn v-btn--icon v-btn--round v-btn--text
              theme--light
              v-size--default
            "
            @click="changePageUser(++userPagination.currentPage)"
          >
            <span class="v-btn__content"
              ><i
                aria-hidden="true"
                class="v-icon notranslate mdi mdi-chevron-right theme--light"
              ></i
            ></span>
          </button>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12" class="contentTable" :class="tableClass">
        <v-data-table
          class="elevation-1 h-100 contentTable"
          height="100%"
          fixed-header
          item-key="users.id"
          :headers="dataTableConfig.headers"
          :items="taskList"
          :no-data-text="dataTableConfig.noDataText"
          disable-pagination
          hide-default-footer
          mobile-breakpoint="0"
        >
          <template slot="items" slot-scope="myProps">
            <td
              class="border-right"
              v-for="(header, index) in headers"
              :key="index"
            >
              {{ myProps.item[header.value] }}
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Export
      ref="export"
      :show="openExport"
      :inputCondition="queryUserSkills"
    ></Export>
    <SearchBox
      ref="searchBox"
      :show="showConditionBox"
      :searchCondition="searchCondition"
      @search="search($event)"
    ></SearchBox>
  </v-container>
</template>

<script>
import * as Role from "../../constants/role";
import * as systemAuth from "../../constants/role_code";
import Export from "./components/Export";
import { mapGetters } from "vuex";
import { debounce_leading, isEmpty } from "../../helpers/helpers";
import {
  USER_SKILLS_API,
  IMPORT_USER_SKILLS_API,
  GET_BELONG_API,
} from "../../constants/api";
import { FC301, FC720, FC722 } from "../../constants/fc";
import { STATUS_NO_CONTENT } from "../../constants/status";
import { apiErrorMessages } from "../../helpers/messages";
import axios from "axios";
import { CSV_EXTENSION } from "../../constants/system";
import { taskMixin } from "@/mixins/task.js";
import { queryRouterMixin } from "@/mixins/queryRouter.js";
import { formatDateTime } from "../../helpers/datetime-helpers";
import { yyyyMM3, yyyyMM1 } from "../../constants/df";
import SearchBox from "./components/SearchBox.vue";
import { getFileExtension, deepClone } from "../../helpers/helpers";
const GROUP_ALL = {
  id: null,
  name: "全て",
};
const HEADER_DEFAULT = [
  {
    text: "No.",
    value: "no",
    width: 10,
    sortable: false,
    align: "right",
    cellClass: "text-end",
  },
  {
    text: "大カテゴリ",
    value: "category_primary_name",
    sortable: false,
    width: 100,
  },
  {
    text: "小カテゴリ",
    value: "category_secondary_name",
    sortable: false,
    width: 100,
  },
  {
    text: "タスク名",
    value: "name",
    sortable: false,
    class: "border-right",
    width: 200,
  },
];
const HEADER_USERS_ITEM = {
  sortable: false,
  class: "border-right",
  width: 75,
  align: "center",
  cellClass: "text-center",
};
const YEAR_MONTH_DEFAULT = formatDateTime(new Date(), yyyyMM3);
export default {
  middleware: Role.AUTH,
  components: {
    Export,
    SearchBox,
  },
  mixins: [taskMixin, queryRouterMixin],
  metaInfo() {
    return { title: this.$t("title.user-skills") };
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "",
          disabled: true,
          href: "",
        },
        {
          text: "ビギナー・スタンダード・エキスパート管理",
          disabled: false,
          href: "tasks",
        },
      ],
      taskList: [],
      showConditionBox: false,
      openExport: false,
      isSelecting: false,
      selectedFile: null,
      menu: false,
      displayDate: this.$route.query.yearMonth || YEAR_MONTH_DEFAULT,
      selectedDate: "",
      departments: [],
      groups: [],
      dataTableConfig: {
        noDataText:
          "検索条件に一致するタスクは見つかりませんでした。再度検索してみてください。",
        headers: HEADER_DEFAULT,
      },
      queryUserSkills: {
        year_month: formatDateTime(new Date(), yyyyMM1),
        department_id: null,
        group_id: null,
        user_offset: 0,
        user_limit: 6,
      },
      userList: [],
      userPagination: {
        itemsPerPage: 6,
        currentPage: 1,
        totalPage: 1,
      },
      init: false,
      selectDepartment: {},
      selectGroup: {},
      searchCondition: {
        taskName: "",
        primaryId: null,
        secondaryId: null,
      },
      queryData: {},
    };
  },
  computed: {
    ...mapGetters("common", ["loginInfo"]),
    isDisablePrev() {
      return (
        this.userPagination.currentPage === 1 ||
        this.userPagination.totalPage === 1
      );
    },
    isDisableNext() {
      return (
        this.userPagination.currentPage === this.userPagination.totalPage ||
        this.userPagination.totalPage === 1
      );
    },
    isMaster() {
      if (isEmpty(this.loginInfo)) return false;
      else return this.loginInfo.system_auth_id === systemAuth.MASTER;
    },
    tableClass() {
      switch (true) {
        case this.$vuetify.breakpoint.xs:
          return "height-custom-xs";
        case this.$vuetify.breakpoint.sm:
          return "height-custom-sm";
        default:
          return "height-custom";
      }
    },
  },

  watch: {
    loginInfo: {
      deep: true,
      handler(val) {
        if (!isEmpty(val)) {
          this.initData();
        }
      },
    },
  },

  async mounted() {
    this.queryData = this.setQuery(this.$route.query);
    if (!isEmpty(this.loginInfo)) {
      this.initData();
    }
  },

  methods: {
    async initData() {
      if (this.init) return;
      else {
        this.init = true;
        await this.getBelong();
        await this.setGroup();
        await this.changePageUser();
      }
    },

    async getBelong() {
      try {
        const { data } = await axios.get(GET_BELONG_API());
        this.departments = data.result.departments;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC301, status);
      }
    },

    async setGroup() {
      if (this.departments.length === 0) return;
      const departmentId = this.$route.query.departmentId
        ? +this.$route.query.departmentId
        : this.loginInfo?.department_id;
      this.selectDepartment = this.departments.find(
        (x) => x.id === departmentId
      );
      this.groups = !this.selectDepartment
        ? []
        : deepClone([GROUP_ALL, ...this.selectDepartment?.groups]);
      const groupId = this.$route.query.groupId
        ? +this.$route.query.groupId
        : this.loginInfo?.group_id;
      this.selectGroup =
        groupId !== 0
          ? this.groups.find((x) => x.id === groupId)
          : this.groups[0];
      // this.queryUserSkills.department_id = this.loginInfo?.department_id;
      // this.queryUserSkills.group_id = this.loginInfo?.group_id;
      this.queryUserSkills.department_id = departmentId;
      this.queryUserSkills.group_id = this.selectGroup.id;
    },

    departmentChange(e) {
      if (!e) return;
      this.groups = [
        GROUP_ALL,
        ...this.departments.find((x) => x.id === e.id)?.groups,
      ];
      this.selectGroup = this.groups[0];
      this.queryUserSkills.department_id = e.id;
      this.queryUserSkills.group_id = this.selectGroup.id;
      this.resetSearchCondition();

      this.queryData["departmentId"] = this.selectDepartment.id;
      this.queryData["groupId"] = 0;
      this.setQueryRouter();

      this.changePageUser();
    },

    groupChange(e) {
      if (!e) return;
      this.queryUserSkills.group_id = e.id;
      this.resetSearchCondition();

      this.queryData["groupId"] = this.queryUserSkills.group_id || 0;
      this.setQueryRouter();

      this.changePageUser();
    },

    setQueryRouter() {
      this.$router.replace({
        path: "/user-skills",
        query: this.queryData,
      });
    },

    resetSearchCondition() {
      this.searchCondition.taskName = "";
      this.searchCondition.primaryId = null;
      this.searchCondition.secondaryId = null;
      this.queryUserSkills.name = "";
      this.queryUserSkills.primary_id = null;
      this.queryUserSkills.secondary_id = null;
    },

    changeDate() {
      this.menu = false;
      this.displayDate = formatDateTime(this.selectedDate, yyyyMM3);
      this.queryUserSkills.year_month = this.selectedDate;

      const setQueryYearMonth = this.checkExistQueryString(
        this.$route.query.yearMonth,
        this.displayDate
      );
      if (setQueryYearMonth) {
        this.queryData["yearMonth"] = this.displayDate;
        this.setQueryRouter();
      }

      this.changePageUser();
    },

    showSearchBox() {
      this.$refs.searchBox.showDialog(true);
    },

    importCsv() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },

    exportCsv() {
      this.$refs.export.showDialog(true);
    },

    async getUserSkills() {
      try {
        if (this.queryUserSkills.department_id === null) return;
        this.queryUserSkills.user_offset =
          (this.userPagination.currentPage - 1) *
            this.userPagination.itemsPerPage || 0;
        this.queryUserSkills.user_limit = this.userPagination.itemsPerPage || 0;
        const query = JSON.parse(
          JSON.stringify({
            year_month: +formatDateTime(
              this.queryUserSkills.year_month,
              yyyyMM1
            ),
            department_id: this.queryUserSkills.department_id,
            group_id: this.queryUserSkills.group_id,
            user_offset: this.queryUserSkills.user_offset,
            user_limit: this.queryUserSkills.user_limit,
            name: this.queryUserSkills.name || "",
            primary_id: this.queryUserSkills.primary_id || null,
            secondary_id: this.queryUserSkills.secondary_id || null,
          })
        );
        // Fetch API

        const { data, status } = await axios.get(USER_SKILLS_API(query));
        if (status === STATUS_NO_CONTENT) {
          this.getDefaultConfig();
        } else {
          this.setAndMapData(data.result);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC720, status);
        this.getDefaultConfig();
      } finally {
        document.getElementsByClassName(
          "v-data-table__wrapper"
        )[0].scrollTop = 0;
        document.getElementsByClassName(
          "v-data-table__wrapper"
        )[0].scrollLeft = 0;
      }
    },
    async changePageUser(currentPage = null) {
      if (!currentPage) {
        this.userPagination.currentPage = 1;
      }

      await this.getUserSkills();
      const userHeader = this.userList.map((item) => {
        return {
          text: item.name,
          value: item.name,
          ...HEADER_USERS_ITEM,
        };
      });
      // push empty user
      const numberUserHeader = userHeader.length;
      if (numberUserHeader < this.userPagination.itemsPerPage) {
        for (
          let i = 0;
          i < this.userPagination.itemsPerPage - numberUserHeader;
          i++
        ) {
          userHeader.push({
            text: " ",
            value: "",
            ...HEADER_USERS_ITEM,
          });
        }
      }
      this.dataTableConfig.headers = [...HEADER_DEFAULT, ...userHeader];
    },
    // Customize data for list
    setAndMapData(list) {
      if (list.length <= 0) {
        this.getDefaultConfig();
        return;
      }
      this.userList = list[0].users || [];
      this.userPagination.totalPage = Math.ceil(
        (list[0].user_count || 0) / this.userPagination.itemsPerPage
      );

      this.taskList = list.map((item, i) => {
        item.users.forEach((user) => {
          item[`${user.name}`] = user.skill_level_name;
        });
        item.no = i + 1;
        return item;
      });
    },

    async fileChanged(e) {
      if (!e.target.files) return;
      this.selectedFile = e.target.files[0];
      let fileSize = this.selectedFile.size / 1024 / 1024;
      if (fileSize > 10) {
        this.$toast(this.$t("label.toast.uploadable_file_10mb_until"), 3000);
        this.$refs.uploader.value = null;
        return;
      }

      if (getFileExtension(this.selectedFile.name) !== CSV_EXTENSION) {
        this.$toast(this.$t("label.toast.csv_invalid_format"), 3000);
        this.$refs.uploader.value = null;
        return;
      }

      try {
        let formData = new FormData();
        formData.append("file", this.selectedFile);
        await axios.post(IMPORT_USER_SKILLS_API(), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.$refs.uploader.value = null;
        this.changePageUser();
      } catch (error) {
        this.$refs.uploader.value = null;
        const { status } = error.response;
        if (status === 401) {
          this.$alert(
            [
              this.$t("label.alert.upload_file_error"),
              error.response.data.message,
            ],
            {
              width: "850px",
            }
          );
        } else {
          apiErrorMessages(this, FC722, status);
        }
      }
    },

    search: debounce_leading(async function (condition) {
      try {
        this.queryUserSkills.name = condition.task_name;
        this.queryUserSkills.primary_id = condition.primary_id;
        this.queryUserSkills.secondary_id = condition.secondary_id;
        this.searchCondition.taskName = condition.task_name;
        this.searchCondition.primaryId = condition.primary_id;
        this.searchCondition.secondaryId = condition.secondary_id;
        this.changePageUser();
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC720, status);
      }
    }),
    getDefaultConfig() {
      this.userList = [];
      this.userPagination = {
        itemsPerPage: 6,
        currentPage: 1,
        totalPage: 1,
      };
      this.taskList = [];
    },
  },
};
</script>

<style lang="scss" scoped >
::v-deep .contentTable {
  .border-right {
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }

  th {
    color: #598ccc !important;
  }

  .v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #fcfcdc;
  }
  @media only screen and (max-width: 1600px) {
    .w-175 {
      width: 175px !important;
      min-width: 175px !important;
    }
  }
}
::v-deep .fix-layout .v-data-table__wrapper table {
  table-layout: fixed;
  min-width: 1440px;
  tbody tr {
    height: 41px;
    min-height: 41px;
    td {
      height: auto;
      div {
        height: inherit;
        /* white-space: nowrap; */
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }
  }
  @media only screen and (max-width: 1264px) {
    min-width: 1343px;
    .text-end {
      display: none;
    }
  }
}
.pagination-user {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .__current-page {
    font-size: 0.75rem;
    margin-right: 16px;
  }
}
.height-custom {
  height: calc((var(--vh, 1vh) * 100) - 64px - 88px - 42px);
  &-sm {
    height: calc((var(--vh, 1vh) * 100) - 64px - 120px - 36px);
  }
  &-xs {
    height: calc((var(--vh, 1vh) * 100) - 64px - 240px - 36px);
  }

}
.h-100 {
  height: 100%;
}
.__label {
  width: 70px;
}
.__content {
  width: 175px;
}
@media print {
  .pagination-user {
    margin-top: 12px;
  }
}
</style>
