<template>
  <v-main>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col md="4">
          <v-card class="pa-5">
            <h3 style="text-align: center" justify="center">
              パスワードをお忘れですか？
            </h3>
            <v-row justify="center" class="mt-5">
              <v-col cols="12">
                パスワードをリセットするには、あなたのメールアドレスを入力してください。
              </v-col>
            </v-row>
            <v-row align="center" justify="center" v-if="emailErrorApi || (emailErrors && emailErrors.length > 0)">
              <v-col cols="12" class="text-center">
                <span class="error--text">
                  <v-icon small>$error</v-icon>
                  {{ emailErrorApi }}
                  {{ emailErrors[0] }}
                </span>
              </v-col>
            </v-row>
            <v-row justify="center" align="baseline">
              <v-col md="4" cols="5" align="center" >
                メールアドレス
              </v-col>
              <v-col md="8" cols="7">
                <v-text-field maxlength="256" v-model="form.email" :error-messages="emailErrors" hide-details>
                  <template v-slot:message="{ message }">
                    <v-icon small class="mr-2">$error</v-icon>
                    <span>{{ message }}</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="8" align-self="center">
                <v-btn tile @click="reset" class="grey darken-2 white--text" depressed block>パスワードをリセット</v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-divider></v-divider>
            </v-row>
            <v-row>
              <v-col cols="12" justify="center" align="center">
                <a href="/login" class="black--text text-decoration-none">ログインに移動する</a>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import * as Role from '../../constants/role';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { RESET_PASSWORD_API } from '../../constants/api';
import axios from 'axios';
import { apiErrorMessages } from '../../helpers/messages';
import { FC03 } from '../../constants/fc';
import { debounce_leading } from '@/helpers/helpers';

export default {
  middleware: Role.GUEST,
  layout: 'blank',
  metaInfo() {
    return { title: this.$t('title.forgotpassword') };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: { required, email }
    }
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (this.$v.form.email.$dirty) {
        !this.$v.form.email.required && errors.push(this.$t('label.error.please_input_email_address'));
        !this.$v.form.email.email && errors.push(this.$t('label.error.invalid_email_address'));
      }

      return errors;
    }
  },
  data: () => ({
    form: {
      email: ''
    },
    emailErrorApi: ''
  }),
  methods: {
    reset : debounce_leading(async function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        try {
          await axios.post(RESET_PASSWORD_API(), { mail: this.form.email });
          this.$toast(this.$t('label.toast.request_reset_pwd_success'), 3000);
        } catch (error) {
          const { status } = error.response;
          let errorMsg = apiErrorMessages(this, FC03, status);
          if (errorMsg) {
            this.emailErrorApi = errorMsg;
          }
        }
      }
    })
  }
};
</script>

<style></style>
