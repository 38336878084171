<template>
  <v-dialog
    v-model="openTodoTask"
    max-width="600px"
    persistent
    :fullscreen="$vuetify.breakpoint.mobile"
    scrollable
    content-class="sfreeTask"
    v-dialog-drag="{
      dialog: 'sfreeTask',
    }"
    overlay-color="transparent"
    overlay-opacity="1"
  >
    <v-card class="hidden"
      ><v-card-title class="card-header py-2">
        <v-btn @click="close()" class="ml-auto" icon color="gray">
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="card-content">
        <v-row class="py-0 my-0">
          <v-col cols="12" class="py-0 d-flex align-center">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <div v-if="$vuetify.breakpoint.mobile && isAddedFromFavorite">
              <v-icon v-if="task.is_favorite" small>$favoriteactive</v-icon>
              <v-icon v-else small>$favoriteinactive</v-icon>
            </div>
            <div v-else @click="setFavorite()">
              <v-icon v-if="task.is_favorite" small>$favoriteactive</v-icon>
              <v-icon v-else small>$favoriteinactive</v-icon>
            </div>
            
            <v-text-field
              class="ml-2"
              v-model="task.name"
              maxlength="60"
              :error-messages="nameErrors"
              placeholder="タスク名を追加"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0 my-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0">
          <v-col class="py-0 d-flex align-center">
            <v-icon small>$taskexplanation</v-icon>

            <v-textarea
              rows="1"
              class="ml-2"
              auto-grow
              maxlength="512"
              v-model="task.description"
              placeholder="説明を追加"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0">
          <v-col class="py-0">
            <div class="header">
              <span style="margin-left: -16px" class="red--text mr-2">*</span>
              <v-icon small>$taskcategory</v-icon>
              <dropdown-button
                :id="'id'"
                :display="'name'"
                :name="primaryName || '大カテゴリ名'"
                :items="parentCategories"
                :type="'text'"
                :has-total="false"
                :showMenu="true"
                @dropdownSelected="primaryCategoryChange($event)"
              ></dropdown-button>
            </div>
            <div class="mt-2 ml-5 category">
              <dropdown-button
                :id="'id'"
                :display="'name'"
                :name="secondaryName || '小カテゴリ名'"
                :items="childCategories"
                :type="'text'"
                :has-total="false"
                :showMenu="true"
                @dropdownSelected="secondaryCategoryChange($event)"
                v-if="showSecondCategory"
              ></dropdown-button>
              <div
                v-if="
                  !this.$v.task.category_secondary_id.required &&
                  this.$v.task.category_secondary_id.$dirty
                "
                class="v-text-field__details"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">
                      {{ $t("label.error.required") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0 align-baseline">
          <v-col cols="3" class="py-0 align-self-start mt-5">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskworktime</v-icon>
            <span class="ml-2 w-120 ">{{isPlan ? '日次予定' : '日次実績'}}</span></v-col
          >
          <v-col
            cols="9"
            class="py-0 d-flex align-start align-self-start"
            :class="[$vuetify.breakpoint.mobile ? 'pl-0' : '']"
          >
          <span class="ml-10 align-baseline">
            <v-text-field
              :error-messages="costErrors"
              :suffix="splitCheck ? 'm' : 'm/回'"
              type="number"
              max="999"
              min="1"
              v-model="task.cost"
              @input="changeCost"
              @input.native="updateCost"
              @keydown="checkMaxLength('cost', $event)"
              class="w-95"
            >
            </v-text-field>
          </span>
            
            <span v-if="!splitCheck" class="mx-2 mt-5">×</span>
            <v-text-field v-if="!splitCheck"
              type="number"
              max="999"
              min="1"
              :error-messages="countErrors"
              suffix="回"
              @input.native="updateCount"
              @keydown="checkMaxLength('count', $event)"
              v-model="task.count"
              @input="changeCount()"
              class="w-60 align-self-start"
            >
            </v-text-field>
            <span v-if="!splitCheck" class="mx-2 mt-5">=</span>
            <v-text-field v-if="!splitCheck"
              class="w-90 align-self-start"
              readonly
              type="number"
              :value="task.cost * task.count"
              suffix="m"
            ></v-text-field
          ></v-col>
        </v-row>

        <v-row class="py-0" v-if="splitCheck">
          <v-col class="py-0 d-flex align-center">
            <div class="d-flex">
              <span class="ml-7">累計実績</span>
              <span class="ml-1">0m</span>
            </div>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0">
          <v-col cols="12" class="py-0 d-flex align-center">
            <span style="margin-left: -16px" class="red--text mr-2">*</span>
            <v-icon small>$taskstartendtime</v-icon>
            <div class="ml-2 d-flex align-baseline">
              <v-text-field
                v-model="task.start_time"
                :error-messages="startTimeErrors"
                maxlength="5"
                type="text"
                @input="changeStartTime()"
                @blur="formatStartTime($event)"
                @keyup.enter.native="formatStartTime($event)"
              >
              </v-text-field>
              <span class="mx-2">~</span>
              <v-text-field
                class="align-self-start"
                v-model="task.end_time"
                :error-messages="endTimeErrors"
                maxlength="5"
                readonly
                type="text"
              >
              </v-text-field>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col>
            <div class="d-flex align-center">
              <v-checkbox
                class="mt-0"
                hide-details
                :disabled="task.count > 1"
                v-model="splitCheck"
                @change="updateSplitCheck"
              >
              </v-checkbox>
              <span class="mx-2"> タスクを分割実施（一部のみ実施） </span>
            </div>
            <div class="d-flex align-center">
              <span class="mx-2 ml-10"
                >※標準工数60分のうち30分作業する場合など</span
              >
            </div>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0">
          <v-col>
            <v-icon small class="mr-2">$tag</v-icon>
            <span v-if="task.tags.length === 0">無し</span>
            <v-container v-else justify="space-around">
              <v-sheet class="px-1">
                <v-chip-group mandatory>
                  <v-tooltip bottom v-for="tag in task.tags" :key="tag.tag_id">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on">
                        <span
                          class="d-inline-block text-truncate"
                          style="max-width: 100px"
                        >
                          {{ tag.tag_name }}
                        </span>
                      </v-chip>
                    </template>
                    <span>{{ tag.tag_name }}</span>
                  </v-tooltip>
                </v-chip-group>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0">
          <v-col class="py-0" v-if="!showComment">
            <v-icon small>$comment</v-icon>
            <v-btn text color="ligth" @click="updateComment()">
              コメントを追加
            </v-btn>
            <v-list v-if="hasComment">
              <v-list-item v-for="item in task.comments" :key="item.id">
                <v-list-item-icon>
                  <v-icon small>$commonuser</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <p>
                    {{ item.user_name }}
                    <span v-if="!$vuetify.breakpoint.mobile" class="ml-5">{{
                      getCommentDate(item.updated_at)
                    }}</span>
                  </p>
                  <p v-if="$vuetify.breakpoint.mobile">
                    {{ getCommentDate(item.updated_at) }}
                  </p>
                  <p class="text-wrap work-break">
                    {{ item.comment }}
                  </p>
                </v-list-item-content>
                <v-list-item-action
                  style="align-self: flex-start"
                  @click="deleteComment(item)"
                >
                  <v-icon small>$delete</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col class="py-0 d-flex align-items-baseline" v-else>
            <v-icon small>$comment</v-icon>
            <v-textarea
              class="mt-0 ml-2"
              v-model="userComment"
              placeholder="コメントを追加"
              counter
              maxlength="512"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions v-if="$vuetify.breakpoint.mobile && isAddedFromFavorite">
          <v-btn
            tile
            depressed
            class="ml-auto"
            color="primary"
            @click="addToCalendar()"
          >
            保存
          </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-btn
          tile
          class="ml-auto"
          depressed
          color="primary"
          @click="updateTask()"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import DropdownButton from "../../../components/DropdownButton.vue";
import { CATEGORIES_API, GET_SCHEDULE_TASK_API } from "../../../constants/api";
import axios from "axios";
import { backMixin } from "../../../mixins/back";
import { mapGetters } from "vuex";
import {
  workTimeValidator,
  timeValidator,
  compareDateValidator,
} from "../../../helpers/validator";
import {
  debounce_leading,
  isEmpty,
  isEmptyNumber,
} from "../../../helpers/helpers";
import {
  toMinute,
  toHHmm,
  formatDateTime,
  parseDateTime,
  toMinuteForHHmm,
  toHHmmFormat,
} from "../../../helpers/datetime-helpers";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC701, FC802 } from "../../../constants/fc";
import { yyyyMMddHHmm2, yyyyMMddHHmmss3 } from "../../../constants/df";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import DialogDragDirective from "../../../directives/dialogDrag";
export default {
  components: { DropdownButton },
  props: {
    show: Boolean,
    planId: String,
    copyFreeData: Object,
    isPlan: {
      type: Boolean,
      default: true,
    },
    isAddedFromFavorite: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    "dialog-drag": DialogDragDirective,
  },
  mixins: [validationMixin, backMixin],
  validations: {
    task: {
      name: { required },
      output_detail: {
        required: requiredIf((task) => {
          return task.is_need_output;
        }),
      },
      category_secondary_id: {
        required: requiredIf((task) => {
          return task.category_primary_id !== null;
        }),
      },
      cost: {
        required,
        workCompare: workTimeValidator("count"),
      },
      count: {
        required,
        workCompare: workTimeValidator("cost"),
      },
      start_time: { required, timeValidator },
      end_time: {
        required,
        timeValidator,
        compareDate: compareDateValidator("start_time"),
      },
    },
  },
  data() {
    return {
      isFavorite: true,
      openTodoTask: false,
      openTagDialog: false,
      showComment: false,
      parentCategories: [],
      childCategories: [],
      primaryCategory: {},
      isEditing: false,
      userComment: "",
      task: {
        name: "",
        description: "",
        cost: "",
        count: "",
        category_secondary_id: null,
        is_need_output: false,
        output_detail: "",
        start_time: "",
        end_time: "",
        is_favorite: false,
        tags: [],
      },
      splitCheck: false,
    };
  },
  mounted() {
    this.addWindowPopstateEvent(
      window.location.href,
      this.clickBrowserSystemButton
    );
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["loginInfo"]),
    ...mapGetters("save", ["getChangeFlag"]),
    nameErrors() {
      const errors = [];
      if (this.$v.task.name.$dirty) {
        !this.$v.task.name.required &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    outputDetailErrors() {
      const errors = [];
      if (this.$v.task.output_detail.$dirty) {
        !this.$v.task.output_detail.required &&
          this.task.is_need_output &&
          errors.push(this.$t("label.error.required"));
      }

      return errors;
    },
    endTimeErrors() {
      const errors = [];
      if (this.$v.task.end_time.$dirty) {
        !this.$v.task.end_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.end_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
        !this.$v.task.end_time.compareDate &&
          errors.push(this.$t("label.error.end_time"));
      }

      return errors;
    },
    startTimeErrors() {
      const errors = [];
      if (this.$v.task.start_time.$dirty) {
        !this.$v.task.start_time.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.start_time.timeValidator &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    costErrors() {
      const errors = [];
      if (this.$v.task.cost.$dirty) {
        !this.$v.task.cost.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.cost.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },
    countErrors() {
      const errors = [];
      if (this.$v.task.count.$dirty) {
        !this.$v.task.count.required &&
          errors.push(this.$t("label.error.required"));
        !this.$v.task.count.workCompare &&
          errors.push(this.$t("label.error.invalid_time"));
      }

      return errors;
    },

    primaryName() {
      let p = this.parentCategories.find(
        (x) => x.id === this.task.category_primary_id
      );
      return p ? p.name : "未分類";
    },

    secondaryName() {
      if (this.childCategories) {
        let s = this.childCategories.find(
          (x) => x.id === this.task.category_secondary_id
        );
        return s ? s.name : "";
      } else {
        return "";
      }
    },

    showSecondCategory() {
      return !isEmptyNumber(this.task.category_primary_id);
    },

    hasComment() {
      return this.task && this.task.comments && this.task.comments.length > 0;
    },
  },
  watch: {
    show() {
      this.openTodoTask = true;
      this.$v.$reset();
      this.isEditing = false;
      this.userComment = "";
      this.showComment = false;
      this.splitCheck = false;
    },
    copyFreeData: {
      deep: true,
      async handler(val) {
        this.task = {
          name: "",
          description: "",
          cost: null,
          count: null,
          category_secondary_id: null,
          category_primary_id: null,
          is_need_output: false,
          output_detail: "",
          start_time: "",
          end_time: "",
          is_favorite: false,
          tags: [],
          comments: [],
        };
        await this.getCategories();
        if (val.category_primary_id) {
          this.childCategories = this.parentCategories.find(
            (x) => x.id === val.category_primary_id
          )?.secondaries;
        }
        this.task = {
          ...this.task,
          ...val,
          ...{tags: [], comments: [] },
        };
        if (+this.task.is_split_task === 1){
          this.splitCheck = true;
        }
      },
    },
    task: {
      deep: true,
      handler(value) {
        // this.isEditing = true;
        this.isEditing =
          JSON.stringify({
            name: value.name,
            description: value.description,
            category_secondary_id: value.category_primary_id,
            category_primary_id: value.category_secondary_id,
            is_need_output: value.is_need_output,
            output_detail: value.output_detail,
            is_favorite: value.is_favorite,
            cost: value.cost,
            count: value.count,
            start_time: value.start_time,
          }) !==
          JSON.stringify({
            name: "",
            description: "",
            category_secondary_id: null,
            category_primary_id: null,
            is_need_output: false,
            output_detail: "",
            is_favorite: false,
            cost: null,
            count: null,
            start_time: this.copyFreeData.start_time,
          });
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
    userComment: {
      handler: function (value) {
        this.isEditing = !isEmpty(value);
        this.$store.dispatch("save/setChangeFlag", this.isEditing);
      },
    },
  },
  methods: {
    setFavorite() {
      if (this.task.is_favorite === 1) {
        this.task.is_favorite = 0;
      }
      else{
        this.task.is_favorite = 1;
      }
    },

    checkMaxLength(type, $event) {
      if (
        $event.keyCode === 8 ||
        $event.keyCode === 46 ||
        $event.keyCode === 38 ||
        $event.keyCode === 40 ||
        $event.keyCode === 37 ||
        $event.keyCode === 39
      )
        return true;
      if (type === "cost") {
        if (this.$v.task.cost.required) {
          if (this.task.cost.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      } else {
        if (this.$v.task.count.required) {
          if (this.task.count.toString().length < 3) return true;
          else $event.preventDefault();
        } else return true;
      }
    },

    primaryCategoryChange(e) {
      this.childCategories = this.parentCategories.find(
        (x) => x.id === e.id
      )?.secondaries;

      this.primaryCategory = e;
      this.task.category_primary_id = e.id;
      this.task.category_secondary_id = null;
    },

    secondaryCategoryChange(e) {
      this.task.category_secondary_id = e.id;
    },

    updateComment() {
      this.showComment = true;
    },

    updateTask: debounce_leading(async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        try {
          let schedule_task = {
            name: this.task.name,
            description: this.task.description,
            cost: +this.task.cost,
            count: +this.task.count,
            is_need_output: +this.task.is_need_output,
            output_detail:
              +this.task.is_need_output === 0 ? null : this.task.output_detail,
            category_secondary_id: this.task.category_secondary_id,
            is_favorite: +this.task.is_favorite,
            start_time: (this.task.start_time.replace(":", "") + "00").padStart(
              6,
              "0"
            ),
            tags: this.task.tags,
            comments:
              !isEmpty(this.userComment) && this.showComment
                ? [
                    {
                      user_id: this.loginInfo.user_id,
                      comment: this.userComment,
                    },
                  ]
                : [],
            is_remand: 0,
            is_split_task: this.splitCheck ? 1 : 0,
          };
          if (this.task.category_primary_id === null)
            schedule_task.category_secondary_id = null;
          const { data } = await axios.post(
            GET_SCHEDULE_TASK_API(this.planId),
            {
              schedule_tasks: [schedule_task],
            }
          );
          this.$store.dispatch("save/setChangeFlag", false);
          this.openTodoTask = false;
          this.task.id = data.result[0].id;
          if (+data.result[0].is_split_task === 1){
            this.task.can_split = data.result[0].can_split;
            this.task.is_first_split_task = data.result[0].is_first_split_task;
            this.task.is_last_split_task = data.result[0].is_last_split_task;
            this.task.is_source_completed = data.result[0].is_source_completed;
            this.task.is_split_task = data.result[0].is_split_task;
            this.task.split_task_id = data.result[0].split_task_id;
            this.task.split_task_no = data.result[0].split_task_no;
            this.task.split_task_source_id = data.result[0].split_task_source_id;
            this.task.changeTodoList = this.splitCheck !== this.originSplitCheck;
          }
          this.$emit("registerFreeTask", this.task);
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC802, status);
        }
        this.openDbTask = false;
      }
    }),

    addToCalendar() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        ///
      } else {
        //
        this.$emit("addToCalendar", this.task);
        this.openTodoTask = false;
      }
    },

    getCommentDate(date) {
      return formatDateTime(
        parseDateTime(date, yyyyMMddHHmmss3),
        yyyyMMddHHmm2
      );
    },

    changeCount() {
      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        this.calculateTime();
      }
    },

    changeCost() {
      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        this.calculateTime();
        return;
      }

      if (
        this.splitCheck &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        this.calculateTime();
      }
    },
    updateCost(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.cost = value;
      } else {
        this.task.cost = value.slice(0, 3);
      }
      this.$forceUpdate();
    },
    updateCount(event) {
      const value = event.target.value;
      if (String(value).length <= 3) {
        this.task.count = value;
      } else {
        this.task.count = value.slice(0, 3);
      }
      this.$forceUpdate();
    },

    changeStartTime() {
      if (
        !this.$v.task.count.$invalid &&
        !this.$v.task.cost.$invalid &&
        !this.$v.task.start_time.$invalid
      ) {
        this.calculateTime();
      }
    },

    formatStartTime($event) {
      if (!this.$v.task.start_time.$invalid) {
        const value = $event.target.value;
        let hasColon = value.indexOf(":") >= 0;
        if (!hasColon) {
          this.task.start_time = toHHmmFormat(value);
        }
      }
    },

    async getCategories() {
      try {
        const { data, status } = await axios.get(CATEGORIES_API());

        this.parentCategories = status === STATUS_NO_CONTENT ? [] : data.result;
        this.parentCategories.push({
          id: null,
          name: "未分類",
          secondaries: [],
        });
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC701, status);
      }
    },

    calculateTime() {
      let hasColon = this.task.start_time.indexOf(":") >= 0;
      let startTime = hasColon
        ? (startTime = toMinute(this.task.start_time, ":"))
        : toMinuteForHHmm(this.task.start_time);
      let endTime = hasColon
        ? toMinute(this.task.end_time, ":")
        : toMinuteForHHmm(this.task.end_time);
      if (this.task.cost && this.task.count) {
        let plusTime = parseInt(this.task.cost) * parseInt(this.task.count);
        if (!this.task.start_time && !this.task.end_time) return;
        if (this.task.start_time) {
          endTime = startTime + plusTime;
          this.task.end_time = toHHmm(endTime);
        } else {
          startTime = endTime - plusTime;
          this.task.start_time = toHHmm(startTime);
        }
      } else {
        return;
      }
    },

    close() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          setTimeout(() => {
            this.$v.$reset();
            this.openTodoTask = false;
          }, 100);
        },
        () => {}
      );
    },

    clickBrowserSystemButton() {
      this.popupUnSave(
        this.getChangeFlag,
        () => {
          this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
          this.$v.$reset();
          this.openTodoTask = false;
        },
        () => {
          this.addWindowPopstateEvent(
            window.location.href,
            this.clickBrowserSystemButton
          );
        }
      );
    },

    updateSplitCheck(event) {
      if (event) {
        this.task.count = 1;
        this.calculateTime();
      }
    },
  },
  beforeDestroy() {
    this.removeWindowPopstateEvent(this.clickBrowserSystemButton);
  },
};
</script>

<style lang="scss" scoped>
.card-header {
  background-color: #e0e0e0;
}
.w-120 {
  width: 120px;
}
.w-90 {
  width: 90px;
}
.align-items-baseline {
  align-items: baseline;
}
.v-dialog.v-dialog--active .v-card__title {
  cursor: grab;
}
.w-60 {
  width: 60px;
}
.w-95 {
  width: 95px;
}
.w-95,
.w-60,
.w-90 {
  .v-text-field__details {
    width: 100%;
  }
}
</style>
