<template>
  <v-main>
    <v-container fill-height fluid>
      <v-row>
        <v-col xl="4" md="8" cols="12" class="mx-auto">
          <v-card class="pa-5">
            <v-row align="baseline">
              <v-col cols="5" md="3" align="center">
                <label>メールアドレス</label>
              </v-col>
              <v-col cols="7" md="9" class="pb-0">
                <v-text-field v-model="form.email" :error-messages="emailErrors" maxlength="256">
                  <template v-slot:message="{ message }">
                    <v-icon small class="mr-2">$error</v-icon>
                    <span>{{ message }}</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="5" md="3" align="center">
                <label>パスワード</label>
              </v-col>
              <v-col cols="7" md="9" class="pb-0">
                <v-text-field v-model="form.password" :error-messages="passwordErrors"
                  :type="show1 ? 'text' : 'password'" maxlength="64" @input.native="allowHalfWidth($event, 'password')">
                  <template v-slot:append>
                    <v-icon @click="show1 = !show1" v-show="show1">$passwordvisible</v-icon>
                    <v-icon @click="show1 = !show1" v-show="!show1">$passwordinvisible</v-icon>
                  </template>
                  <template v-slot:message="{ message }">
                    <v-icon small class="mr-2">$error</v-icon>
                    <span>{{ message }}</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="10" offset="2" md="9" offset-md="3">
                <v-checkbox label="ログイン情報を保存する" v-model="form.remember" hide-details></v-checkbox>
              </v-col>
              <v-col cols="10" offset="2" md="9" offset-md="3">
                <a href="/reset-password" class="black--text">※パスワードを忘れた場合</a>
              </v-col>
              <v-col cols="8" md="9" offset-md="3">
                <v-btn tile :disabled="!valid" @click="login" color="primary" depressed block>ログイン</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import * as Role from "../../constants/role";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { LOGIN_API } from "../../constants/api";
import axios from "axios";
import * as RoleCode from "../../constants/role_code";
import { apiErrorMessages } from "../../helpers/messages";
import {
  isEmpty,
  jReplace,
  isJapanese,
  debounce_leading,
} from "../../helpers/helpers";
import { FC01 } from "../../constants/fc";
export default {
  middleware: Role.GUEST,
  layout: "blank",
  metaInfo() {
    return { title: this.$t("title.login") };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      password: { required, minLength: minLength(8) },
      email: { required, email },
    },
  },
  data: () => ({
    form: {
      email: "",
      password: "",
      remember: false,
    },
    show1: false,
    valid: true,
    passwordErrorsApi: "",
  }),
  methods: {
    login: debounce_leading(async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        try {
          const { data } = await axios.post(LOGIN_API(), {
            mail: this.form.email,
            password: this.form.password
          });

          await this.$store.dispatch('auth/saveToken', {
            token: data.result.token,
            user: data.result,
            form: this.form,
            remember: this.form.remember,
            expired: this.getExpiredDate()
          });

          if (this.$route.query.back_url) {
            //
            location.href = this.$route.query.back_url
          } else {
            switch (data.result.system_auth_id) {
              case RoleCode.MEMBER:
                this.$router.push('/schedule');
                break;
              case RoleCode.MANAGER:
                this.$router.push('/status-actuals');
                break;
              case RoleCode.MASTER:
                this.$router.push('/status-actuals');
                break;
              default:
                break;
            }
          }

        } catch (error) {
          const { status } = error.response;

          let errorMsg = apiErrorMessages(this, FC01, status);
          if (errorMsg) {
            this.passwordErrorsApi = errorMsg;
          }
        }
      }
    }),

    allowHalfWidth(e, field) {
      if (isJapanese(e.target.value)) {
        this.form[field] = jReplace(e.target.value);
      }
      return true;
    },

    getExpiredDate() {
      var m = new Date();
      m.setDate(m.getDate() + 30);
      var dateString =
        m.getFullYear() + "-" +
        ("0" + (m.getMonth() + 1)).slice(-2) + "-" +
        ("0" + m.getDate()).slice(-2) + " " +
        ("0" + m.getHours()).slice(-2) + ":" +
        ("0" + m.getMinutes()).slice(-2) + ":" +
        ("0" + m.getSeconds()).slice(-2);

      return dateString;
    }
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (this.$v.form.email.$dirty) {
        !this.$v.form.email.required &&
          errors.push(this.$t("label.error.no_data_input"));
        !this.$v.form.email.email &&
          errors.push(this.$t("label.error.invalid_email_address"));
      }

      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (this.$v.form.password.$dirty) {
        !this.$v.form.password.required &&
          errors.push(this.$t("label.error.no_data_input"));
        !this.$v.form.password.minLength &&
          errors.push(this.$t("label.error.pwd_length"));
        !isEmpty(this.passwordErrorsApi) && errors.push(this.passwordErrorsApi);
      }

      return errors;
    },
  },
  mounted() {
    let authInfo = localStorage.getItem("authInfo") !== null
      ? JSON.parse(localStorage.getItem("authInfo")) : null;

    let expiredDate = localStorage.getItem("expired");
    let isExpired = false;
    if (expiredDate) {
      if (new Date() > new Date(expiredDate)) {
        isExpired = true;
        this.$store.dispatch("auth/logout");
        this.$store.dispatch("common/removeLoginInfo");
      }
    }

    let user = localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : null;
    if (user) {
      switch (user.system_auth_id) {
        case RoleCode.MEMBER:
          this.$router.push("/schedule");
          return;
        case RoleCode.MANAGER:
          this.$router.push("/status-actuals");
          return;
        case RoleCode.MASTER:
          this.$router.push("/status-actuals");
          return;
        default:
          break;
      }
    }

    // authInfo expired
    if (isExpired) {
      const emailInfo = localStorage.getItem("emailInfo")
        ? JSON.parse(localStorage.getItem("emailInfo"))
        : {
          form: {
            remember: false,
          },
        };
      if (emailInfo.form.remember) {
        this.form = { ...this.form, ...emailInfo.form };
      }
      this.form.remember = emailInfo.form.remember;

      authInfo = { form: { remember: false, }, };
    }

    if (authInfo.form.remember) {
      this.form = { ...this.form, ...authInfo.form };
      this.form.password = '';
      this.form.remember = authInfo.form.remember;
    }
    this.$store.dispatch("auth/logout");
    this.$store.dispatch("common/removeLoginInfo");
  },
};
</script>

<style>

</style>
