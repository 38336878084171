<template>
  <v-dialog persistent :value="openDialog" max-width="310px">
    <v-card class="py-3">
      <v-card class="mx-5 py-0" color="primary" dark>
        <v-card-title>CSVエクスポート設定 </v-card-title>
      </v-card>
      <v-card-text class="mt-5">
        <!-- <v-radio-group v-model="radioExport">
          <v-radio value="0" :label="'表示されている期間の評価'"></v-radio>
        </v-radio-group>
        <span>※最長1年まで出力可能です</span> -->
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="checkAll"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              すべてのレポート
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="item in listItem" :key="item.id">
            <v-list-item-action>
              <v-checkbox v-model="item.check"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              {{ item.name }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn tile color="ligth darken-1" @click="close()">{{
          $t("label.buttons.cancel")
        }}</v-btn>
        <v-btn
          tile
          :disabled="!isEnable"
          color="primary darken-1"
          @click="submit()"
        >
          エクスポート
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { PRODUCTIVITY_REPORT_CSV_EXPORT_API } from "../../../constants/api";
import { FC1201 } from "../../../constants/fc";
import { apiErrorMessages } from "../../../helpers/messages";
import { debounce_leading, exportShiftJIS } from "../../../helpers/helpers";
import axios from "axios";
import { formatDateTime, parseDateTime } from "@/helpers/datetime-helpers";
import { yyyyMMdd1, yyyyMMdd2 } from "@/constants/df";
export default {
  props: {
    show: Boolean,
    queryProductivity: {},
  },
  watch: {
    show: {
      handler() {
        this.openDialog = true;
        this.listItem?.map((x) => {
          x.check = false
        })
      },
    },
  },
  data() {
    return {
      openDialog: false,
      listItem: [
        {
          id: 1,
          name: "タスク業務効率",
          check: false,
        },
        {
          id: 2,
          name: "予定タスク計画実行率",
          check: false,
        },
        {
          id: 3,
          name: "標準工数タスク率",
          check: false,
        },
        {
          id: 4,
          name: "イレギュラー勤怠取得実績",
          check: false,
        },
        {
          id: 5,
          name: "月次のタスク別実績工数",
          check: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.auth?.user,
    }),
    checkAll: {
      // getter
      get: function () {
        return !this.listItem.some((x) => !x.check);
      },
      // setter
      set: function (newValue) {
        this.listItem = this.listItem.map((x) => {
          return {
            name: x.name,
            id: x.id,
            check: newValue,
          };
        });
      },
    },
    isEnable() {
      return this.listItem.some((x) => x.check);
    },
  },
  methods: {
    submit: debounce_leading(async function () {
      let endDate = parseDateTime(
        this.queryProductivity.real_end_date,
        yyyyMMdd2
      );
      endDate.setMonth(endDate.getMonth() - 12);
      if (
        +formatDateTime(endDate, yyyyMMdd1) >
        +formatDateTime(this.queryProductivity.real_start_date, yyyyMMdd1)
      ) {
        this.$alert(
          "エクスポートできる期間は最長1年までです。表示期間を再設定してください。",
          {
            width: "800px",
          }
        );
      } else {
        try {
          let isAll = 0;
          let isTaskWork = 0;
          let isPlanActual = 0;
          let isStandard = 0;
          let isIrregular = 0;
          let isMonthlyCost = 0;
          if (this.checkAll) {
            isAll = 1;
          } else {
            this.listItem.map((x) => {
              if (x.check) {
                switch (x.id) {
                  case 1:
                    isTaskWork = 1;
                    break;
                  case 2:
                    isPlanActual = 1;
                    break;
                  case 3:
                    isStandard = 1;
                    break;
                  case 4:
                    isIrregular = 1;
                    break;
                  case 5:
                    isMonthlyCost = 1;
                    break;
                }
              }
            });
          }
          const { data, headers } = await axios.get(
            PRODUCTIVITY_REPORT_CSV_EXPORT_API({
              group_id: this.queryProductivity.group_id,
              user_id: this.queryProductivity.user_id == 0 ? null : this.queryProductivity.user_id,
              start_date: formatDateTime(
                this.queryProductivity.real_start_date,
                yyyyMMdd1
              ),
              end_date: formatDateTime(
                this.queryProductivity.real_end_date,
                yyyyMMdd1
              ),
              is_all: isAll,
              is_task_work: isTaskWork,
              is_plan_actual: isPlanActual,
              is_standard: isStandard,
              is_irregular: isIrregular,
              is_monthly_cost: isMonthlyCost,
            }),
            {
              responseType: "blob",
            }
          );
          const headerval = headers["content-disposition"];
          let filename = headerval
            .split(";")[2]
            .split("=")[1]
            .replace('"', "")
            .replace('"', "");
          filename = decodeURI(filename).substring("utf-8''".length);
          exportShiftJIS(data, filename);
          this.close();
        } catch (error) {
          const { status } = error.response;
          apiErrorMessages(this, FC1201, status);
        }
      }
    }),
    close() {
      this.openDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
