<template>
  <div>
    <v-avatar size="140">
      <img :src="imageUrl" />
    </v-avatar>
    <div class="img-upload" v-show="visibled">
      <v-btn text depressed :loading="isSelecting" @click="uploadClick"
        >画像を変更する</v-btn
      >
      <input
        ref="uploader"
        class="d-none"
        type="file"
        accept=".png,.bmp,.jpg,.gif,.jpeg"
        @change="fileChanged"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { UPLOAD_AVATAR_API, GET_PROFILE_IMAGE_API } from "../constants/api";
import { blobToBase64, isEmpty } from "../helpers/helpers";
import { apiErrorMessages } from "../helpers/messages";
import { FC1001, FC1002 } from "../constants/fc";
import { STATUS_NO_CONTENT } from "../constants/status";
const DEFAULT_IMAGE = "/assets/images/account_for_no_image.png";
export default {
  name: "AvatarUpload",
  props: {
    fileId: String || null,
    visibled: { type: Boolean, default: true },
    iscreenType: { type: String, default: "PROFILE" },
  },
  data() {
    return {
      imageUrl: "",
      selectedFile: null,
      isSelecting: false,
      screenType: "",
    };
  },
  watch: {
    fileId: {
      immediate: true,
      async handler(val) {
        this.selectedFile = null;
        if (isEmpty(val) || val === DEFAULT_IMAGE) {
          this.imageUrl = DEFAULT_IMAGE;
        } else {
          await this.getFile(val);
        }
      },
    },
    iscreenType: {
      immediate: true,
      handler(val) {
        this.screenType = val;
      },
    },
  },

  methods: {
    uploadClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      window.addEventListener(
        "touchend",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },

    isAccount() {
      return this.screenType === "ACCOUNT";
    },

    async fileChanged(e) {
      this.selectedFile = e.target.files[0];
      if (!this.selectedFile) return;
      let fileSize = this.selectedFile.size / 1024 / 1024;
      let imageTypes = ["gif", "jpg", "png", "bmp", "jpeg"];
      let fileExtension = this.selectedFile.name.replace(/^.*\./, "");
      if (!imageTypes.includes(fileExtension.toLowerCase())) {
        this.$toast(
          this.$t("label.toast.uploadable_file_jpg_png_gif_bmp_only"),
          3000
        );
        return;
      }
      if (fileSize > 10) {
        this.$toast(this.$t("label.toast.uploadable_file_10mb_until"), 3000);
        return;
      }
      try {
        if (this.isAccount()) {
          let reader = new FileReader();
          reader.onload = (e) => {
            this.imageUrl = e.target.result;
          };
          reader.readAsDataURL(this.selectedFile);
          this.$emit("imageChanged", this.selectedFile);
          this.$refs.uploader.value = null;
        } else {
          let formData = new FormData();
          formData.append("file", this.selectedFile);
          const { data } = await axios.post(UPLOAD_AVATAR_API(), formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          this.$emit("fileChanged", data.result.file_id);
          this.$refs.uploader.value = null;
          await this.getFile(data.result.file_id);
        }
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC1002, status);
      }
    },

    async getFile(fileId) {
      try {
        const { data, status } = await axios.get(
          GET_PROFILE_IMAGE_API(fileId),
          {
            responseType: "blob",
          }
        );

        this.imageUrl =
          status === STATUS_NO_CONTENT
            ? DEFAULT_IMAGE
            : await blobToBase64(data);
      } catch (error) {
        this.imageUrl = DEFAULT_IMAGE;
        const { status } = error.response;
        apiErrorMessages(this, FC1001, status);
      }
    },

    clearImage() {
      this.imageUrl = DEFAULT_IMAGE;
      this.selectedFile = null;
    },
  },
};
</script>

<style>
</style>
