var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-3",attrs:{"fill-height":"","fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex d-md-block justify-space-between align-center py-0",class:{
        'side-bar': !_vm.isMobile,
        '__sub-menu-bar': !_vm.isMobile,
      },attrs:{"cols":"12","md":"2"}},[_c('v-list',{staticClass:"d-flex d-md-block",attrs:{"rounded":""}},[_c('v-list-item-group',{staticClass:"d-flex d-md-block",attrs:{"color":"primary"},model:{value:(_vm.calendarTypeChosen),callback:function ($$v) {_vm.calendarTypeChosen=$$v},expression:"calendarTypeChosen"}},_vm._l((_vm.calendarType),function(item){return _c('v-list-item',{key:item.value,attrs:{"value":item.value},on:{"click":function($event){return _vm.changeList(item.value)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"v-list-item--active",domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1)],1),_c('v-col',{class:{
        __main: !_vm.isMobile,
      },attrs:{"cols":"12","md":"10"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"ml-4 mr-2"},[_vm._v("対象")]),(_vm.isMobile)?_c('v-icon',{staticClass:"ml-2",attrs:{"dark":""},on:{"click":function($event){return _vm.selectRangeDate()}}},[_vm._v("$calendar")]):_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","text":"","color":"light"},on:{"click":function($event){return _vm.selectRangeDate()}}},[_vm._v(" "+_vm._s(_vm.formatStartDate)+" ~ "+_vm._s(_vm.formatEndDate)+" ")]),(!_vm.isMobile)?_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.exportCsv()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","dark":""}},[_vm._v("$csvexport")]),_vm._v(" CSVエクスポート ")],1):_vm._e()],1),_c('v-col',{class:_vm.isMobile ? 'height-custom' : 'height-custom-pc',attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 h-100 contentTable",attrs:{"height":"100%","fixed-header":"","item-key":"status_id","headers":_vm.dataTableConfig.headers,"items-per-page":_vm.dataTableConfig.options.itemsPerPage,"items":_vm.statusLogs,"no-data-text":_vm.dataTableConfig.noDataText,"options":_vm.dataTableConfig.options,"server-items-length":_vm.dataTableConfig.total,"footer-props":{
              'items-per-page-options': _vm.dataTableConfig.itemsPerPageOptions,
            },"mobile-breakpoint":"0"},on:{"update:options":[function($event){return _vm.$set(_vm.dataTableConfig, "options", $event)},_vm.optionsChanged]},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.process_user_name)+" ")]}},{key:"item.dept",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"dt-comment"},[_vm._v(" "+_vm._s(_vm.getDept(item.department_name, item.group_name))+" ")])]}},{key:"item.position",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.position_name)+" ")]}},{key:"item.subject",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.subject_format)+" ")]}},{key:"item.processName",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getProcessName(item.phase_id))+" ")]}},{key:"item.updated_at",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.updated_at_format)+" ")]}},{key:"item.comment",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"dt-comment"},[_vm._v(" "+_vm._s(item.comment)+" ")])]}}],null,true)})],1)],1)],1)],1),_c('RangeDate',{attrs:{"show":_vm.openRangeDate},on:{"change":function($event){return _vm.changeDate($event)}},model:{value:(_vm.rangeDate),callback:function ($$v) {_vm.rangeDate=$$v},expression:"rangeDate"}}),_c('Export',{attrs:{"show":_vm.openExport,"queryStatusLogs":_vm.queryStatusLogs}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }