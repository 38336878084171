import { isEmpty, deepClone } from "@/helpers/helpers";
export const queryRouterMixin = {
  methods: {
    setQuery(queryData) {
      let queryRouter = {};
      for (var prop in queryData) {
        if (queryData[prop]) {
          queryRouter[`${prop}`] = queryData[prop];
        }
      }
      return queryRouter;
    },
    checkExistQueryString(query, value) {
      let originalValue = "";
      if (!isEmpty(query)) originalValue = query;
      if (isEmpty(originalValue) || value !== originalValue) {
        return true;
      } else {
        return false;
      }
    },
    checkExistQueryArray(query, value) {
      let originalValue = [];
      if (!isEmpty(query)) originalValue = deepClone(JSON.parse(query));
      if (isEmpty(originalValue) || !this.arraysEqual(value, originalValue)) {
        return true;
      } else {
        return false;
      }
    },
    arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;
    
      // If you don't care about the order of the elements inside
      // the array, you should sort both arrays here.
      // Please note that calling sort on an array will modify that array.
      // you might want to clone your array first.
    
      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },

    openNewBackgroundTab(url) {
      var a = document.createElement("a");
      a.href = url;
      var evt = document.createEvent("MouseEvents");
      //the tenth parameter of initMouseEvent sets ctrl key
      evt.initMouseEvent(
        "click",
        true,
        true,
        window,
        0,
        0,
        0,
        0,
        0,
        true,
        false,
        false,
        false,
        0,
        null
      );
      a.dispatchEvent(evt);
    },
  }
}