var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"open-on-click":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-combobox',_vm._g({attrs:{"items":_vm.items,"error-messages":_vm.dgNameErrors,"readonly":"","clearable":""},on:{"click:append":function($event){_vm.openMenu = true},"click:clear":function($event){return _vm.clearDepartment()}},model:{value:(_vm.dgName),callback:function ($$v) {_vm.dgName=$$v},expression:"dgName"}},on))]}}]),model:{value:(_vm.openMenu),callback:function ($$v) {_vm.openMenu=$$v},expression:"openMenu"}},[_c('v-list',{staticClass:"overflow-y-auto",attrs:{"max-height":"50vh"}},_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.id},[(item.group && item.group.length > 0)?_c('v-menu',{attrs:{"open-on-click":"","close-on-content-click":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex v-list-item",on:{"click":function($event){return _vm.toggle(item.id)}}},on),[_c('v-list-item-content',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.name))])])],1),(item.group)?_c('v-list-item-action',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.toggle(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-menu-right")])],1)],1):_vm._e()],1)]}}],null,true)},[(item.group && _vm.open && item.id === _vm.departmentSelected.id)?_c('group-item',{attrs:{"items":item.group,"parent":_vm.departmentSelected},on:{"groupChanged":function($event){return _vm.groupChanged($event)}}}):_vm._e()],1):_c('div',{staticClass:"d-flex v-list-item"},[_c('v-list-item-content',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.name))])])],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }