<template>
  <v-container fill-height fluid >
    <v-row  justify="center" class="gray--text">
        閲覧権限がありません。
    </v-row>
  </v-container>
</template>

<script>
import * as Role from "../constants/role";
export default {
  middleware: Role.GUEST,
  layout: "default",
  metaInfo() {
    return { title: this.$t("title.unauthorize") };
  },
};
</script>


