<template>
  <v-container fill-height fluid class="pa-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <v-breadcrumbs large :items="breadcrumbs">
          <template v-slot:divider>
            <v-icon small>$bradcrumbtrail</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <div
              v-if="item.text"
              class="text-truncate"
              :class="$vuetify.breakpoint.mobile ? 'm-w-128' : 'm-w-250'"
            >
              <a
                class="font-weight-bold black--text text-decoration-none"
                :href="item.href"
                :disabled="item.disabled"
              >
                {{ item.text }}
              </a>
            </div>
            <a
              class="
                font-weight-bold
                black--text
                text-decoration-none text-truncate
              "
              :class="$vuetify.breakpoint.mobile ? 'm-w-128' : 'm-w-250'"
              :href="item.href"
              :disabled="item.disabled"
              v-else
            >
              {{ item.text }}
            </a>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-col class="mx-auto pt-0" md="12" cols="12">
        <v-row>
          <v-col cols="12" md="6" lg="8" class="pt-0 pb-0">
            <v-btn
              dark
              tile
              class="grey darken-1 my-2 mr-2"
              @click="addAccount()"
            >
              ユーザーを追加する
            </v-btn>
            <v-btn
              v-if="!$vuetify.breakpoint.mobile"
              @click="importCsv()"
              :loading="isSelecting"
              class="my-2 mr-2"
              tile
              outlined
              color="ligth"
            >
              <v-icon small left dark>$csvimport</v-icon>
              CSVインポート
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              @change="fileChanged" />

            <v-btn
              v-if="!$vuetify.breakpoint.mobile"
              class="my-2 mr-2"
              @click="exportCsv()"
              tile
              outlined
              color="ligth"
            >
              <v-icon small left dark>$csvexport</v-icon>
              CSVエクスポート
            </v-btn>
            <department-pull-down
              :title="title"
              :items="departments"
              @groupChanged="groupChanged($event)"
              @selectedAll="selectedAll()"
            ></department-pull-down
          ></v-col>

          <v-col cols="12" md="6" lg="4" class="pt-0 pb-0">
            <v-text-field
              v-model="searchText"
              @keydown.enter="search()"
              placeholder="ユーザーを検索する"
              hide-details="auto"
            >
              <template v-slot:append>
                <v-icon @click="search()" small>$search</v-icon>
              </template>
            </v-text-field></v-col
          >
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="searched">
      <v-col class="mx-auto" md="12"
        ><span class="ml-1">{{ this.accounts.length }}</span> 件の検索結果
      </v-col>
    </v-row>

    <v-row v-if="!noAccount" class="mb-2">
      <v-col class="mx-auto" cols="12" :class="tableClass">
        <v-data-table
          fixed-header
          height="100%"
          mobile-breakpoint="0"
          :headers="headers"
          :items="accounts"
          :hide-default-footer="true"
          disable-pagination
          class="h-100"
        >
          <template v-slot:[`item.auth`]="{ item }">
            {{ item.system_auth_name }}
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:[`item.dept`]="{ item }">
            {{ item.department_name }} ＞ {{ item.group_name }}
          </template>
          <template v-slot:[`item.position`]="{ item }">
            {{ item.position_name }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div>
              <v-menu
                v-model="item.openSettingMenu"
                :close-on-content-click="true"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="ligth" dark v-bind="attrs" v-on="on">
                    <v-icon small color="black">$settings</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-actions class="justify-center">
                    <v-btn text color="light" @click="editAccount(item)">
                      ユーザー情報を編集する
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions class="justify-center">
                    <v-btn text color="light" @click="deleteAccount(item)">
                      ユーザー情報を削除する
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions class="justify-center">
                    <v-btn
                      tile
                      color="secondary"
                      dark
                      @click="item.openSettingMenu = false"
                    >
                      {{ $t("label.buttons.cancel") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- <v-row v-else>
      <v-col> データはありません。</v-col>
    </v-row> -->
    <account-dialog
      :input-modal-type="modalType"
      :inputUserInfo="userSelected"
      v-model="openAccountDialog"
      @closeAccountDialog="newOrEditAccountClose()"
      @updateAccount="updateAccount($event)"
      @createAccount="createAccount($event)"
    ></account-dialog>

    <delete-account
      v-model="openDeleteAccountDialog"
      :message="deleteMessage"
      :inputUserInfo="userSelected"
      @deleteAccountSuccess="deleteAccountSuccess($event)"
    ></delete-account>

    <export-account-dialog
      :searchCondition="searchCondition"
      v-model="openExportAccountDialog"
    ></export-account-dialog>
  </v-container>
</template>

<script>
import AccountDialog from "./AccountDialog.vue";
import DeleteAccount from "./DeleteAccountDialog.vue";
import ExportAccountDialog from "./ExportAccountDialog.vue";
import DepartmentPullDown from "../../../components/DepartmentPullDown.vue";
import { deepClone, isEmpty } from "../../../helpers/helpers";
import axios from "axios";
import {
  GET_ALL_PROFILE_API,
  IMPORT_ACCOUNT_CSV_API,
  GET_BELONG_API,
} from "../../../constants/api";
import { apiErrorMessages } from "../../../helpers/messages";
import { FC101, FC104, FC301 } from "../../../constants/fc";
import { STATUS_NO_CONTENT } from "../../../constants/status";
import { getFileExtension } from "../../../helpers/helpers";
import { CSV_EXTENSION } from "../../../constants/system";
import { queryRouterMixin } from "@/mixins/queryRouter.js";
import { taskMixin } from "@/mixins/task.js";
export default {
  components: {
    AccountDialog,
    DeleteAccount,
    ExportAccountDialog,
    DepartmentPullDown,
  },
  mixins: [queryRouterMixin, taskMixin],
  metaInfo() {
    return { title: this.$t("title.accountmaster") };
  },
  data() {
    return {
      title: "部署・Grから検索する",
      accounts: [],
      orgAccounts: [],
      openAccountDialog: false,
      openDeleteAccountDialog: false,
      openExportAccountDialog: false,
      selectedFile: null,
      isSelecting: false,
      searchText: "",
      searched: false,
      modalType: "",
      userSelected: {},
      searchCondition: {
        user_name: "",
        group_id: null,
        department_id: null,
      },
      departments: [],
      breadcrumbs: [
        {
          text: "",
          disabled: true,
          href: "",
        },
        {
          text: "全メンバー",
          disabled: false,
          href: "profiles",
        },
      ],
      deleteMessage: "",
      noAccount: false,
      headers: [
        { text: "権限", value: "auth", sortable: false, width: "100px" },
        { text: "名前", value: "name", sortable: false, width: "200px" },
        { text: "所属部署", value: "dept", sortable: false, width: "200px" },
        { text: "役職", value: "position", sortable: false, width: "100px" },
        {
          text: "編集",
          value: "action",
          sortable: false,
          align: "center",
          width: "100px",
        },
      ],
      queryData: {},
    };
  },
  computed: {
    tableClass() {
      switch (true) {
        case this.$vuetify.breakpoint.xs:
          if (this.searched) return "height-custom-xs-search";
          return "height-custom-xs";
        case this.$vuetify.breakpoint.sm:
          if (this.searched) return "height-custom-sm-search";
          return "height-custom-sm";
        default:
          if (this.searched) return "height-custom-search";
          return "height-custom";
      }
    },
  },
  async mounted() {
    await this.getBelong();
    let groupId = this.$route.query.groupId || undefined;
    let departmentId = this.$route.query.departmentId || undefined;
    if (groupId && departmentId) {
      let department = this.departments.find((x) => x.id === +departmentId);
      this.groupChanged({
        group: department.groups.find((x) => x.id === +groupId),
        department: department,
      });
    } else {
      if (+departmentId === 0) {
        this.title = "全メンバー";
      }
      let data = await this.getAccount();
      this.accounts = deepClone(data);
      this.orgAccounts = deepClone(data);
    }
  },
  methods: {
    addAccount() {
      this.openAccountDialog = true;
      this.modalType = "new";
    },

    deleteAccount(user) {
      this.userSelected = user;
      this.openDeleteAccountDialog = true;
      this.deleteMessage = this.$t("label.confirm.delete_account", {
        name: user.name,
      });
    },

    deleteAccountSuccess(user) {
      this.accounts = this.accounts.filter((x) => x.user_id !== user.user_id);
      this.orgAccounts = this.orgAccounts.filter(
        (x) => x.user_id !== user.user_id
      );
      this.$toast(
        this.$t("label.toast.delete_account_success", { username: user.name }),
        3000
      );
    },

    editAccount(user) {
      this.modalType = "edit";
      this.userSelected = deepClone(user);
      this.openAccountDialog = true;
    },

    exportCsv() {
      this.openExportAccountDialog = true;
    },

    importCsv() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },

    async fileChanged(e) {
      if (!e.target.files) return;
      this.selectedFile = e.target.files[0];
      let fileSize = this.selectedFile.size / 1024 / 1024;
      if (fileSize > 10) {
        this.$toast(this.$t("label.toast.uploadable_file_10mb_until"), 3000);
        this.$refs.uploader.value = null;
        return;
      }
      if (getFileExtension(this.selectedFile.name) !== CSV_EXTENSION) {
        this.$toast(this.$t("label.toast.csv_invalid_format"), 3000);
        this.$refs.uploader.value = null;
        return;
      }
      try {
        let formData = new FormData();
        formData.append("file", this.selectedFile);
        await axios.post(IMPORT_ACCOUNT_CSV_API(), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        this.accounts = await this.getAccount(this.searchCondition.group_id);
        this.$refs.uploader.value = null;
      } catch (error) {
        this.$refs.uploader.value = null;
        const { status } = error.response;
        if (status === 401) {
          this.$alert(
            [
              this.$t("label.alert.upload_file_error"),
              error.response.data.message,
            ],
            {
              width: "850px",
            }
          );
        } else {
          apiErrorMessages(this, FC104, status);
        }
      }
    },

    search() {
      this.searchCondition.user_name = this.searchText;
      const searchConvert = this.convertSearchKey(this.searchText);
      let accounts = this.orgAccounts.filter((x) => {
        return (
          x.name.includes(this.searchText) ||
          isEmpty(this.searchText) ||
          this.convertSearchKey(x.name).includes(searchConvert)
        );
      });

      if (!accounts || accounts.length === 0) {
        this.accounts = [];
        this.noAccount = true;
      } else {
        this.noAccount = false;
        this.accounts = accounts;
      }

      if (this.searchText !== "") {
        this.searched = true;
      } else {
        this.searched = false;
        this.noAccount = false;
      }
    },

    async groupChanged(event) {
      this.searchText = "";
      this.searched = false;
      this.noAccount = false;
      let data = await this.getAccount(event.group.id);
      this.accounts = deepClone(data);
      this.orgAccounts = deepClone(data);
      this.searchCondition.group_id = event.group.id;
      this.searchCondition.department_id = event.department.id;
      this.title = `${event.department.name} ＞ ${event.group.name}`;
      this.breadcrumbs = [
        {
          text: "",
          disabled: true,
          href: "",
        },
        {
          text: event.department.name,
          disabled: false,
          href: `profiles?groupId=${event.group.id}&departmentId=${event.department.id}`,
        },
        {
          text: event.group.name,
          disabled: false,
          href: `profiles?groupId=${event.group.id}&departmentId=${event.department.id}`,
        },
      ];

      const setQueryDepartment = this.checkExistQueryString(
        this.$route.query.departmentId,
        `${this.searchCondition.department_id}`
      );
      if (setQueryDepartment) {
        this.queryData["departmentId"] = this.searchCondition.department_id;
      }

      const setQueryGroup = this.checkExistQueryString(
        this.$route.query.groupId,
        `${this.searchCondition.group_id}`
      );
      if (setQueryGroup) {
        this.queryData["groupId"] = this.searchCondition.group_id;
      }
      if (setQueryDepartment || setQueryGroup) {
        this.setQueryRouter();
      }
    },

    async selectedAll() {
      this.searchText = "";
      this.searched = false;
      this.noAccount = false;
      let data = await this.getAccount();
      this.accounts = deepClone(data);
      this.orgAccounts = deepClone(data);
      this.breadcrumbs = [
        {
          text: "",
          disabled: true,
          href: "",
        },
        {
          text: "全メンバー",
          disabled: true,
          href: "profiles",
        },
      ];

      const setQueryDepartment = this.checkExistQueryString(
        this.$route.query.departmentId,
        "0"
      );
      if (setQueryDepartment) {
        this.$router.replace({
          path: "/profiles",
          query: { departmentId: 0 },
        });
      }
    },

    async getBelong() {
      try {
        const { data } = await axios.get(GET_BELONG_API());
        this.departments = data.result.departments;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC301, status);
      }
    },

    async getAccount(groupId = undefined) {
      try {
        const { data, status } = await axios.get(
          GET_ALL_PROFILE_API("", groupId)
        );
        return status === STATUS_NO_CONTENT ? [] : data.result;
      } catch (error) {
        const { status } = error.response;
        apiErrorMessages(this, FC101, status);
      }
    },

    createAccount(user) {
      this.openAccountDialog = false;
      this.accounts.push(user);
      this.orgAccounts.push(user);
      this.$toast(`${user.name}さんを追加しました。`, 3000);
      this.modalType = "";
    },

    updateAccount(user) {
      this.openAccountDialog = false;
      user.openSettingMenu = false;
      let accounts = this.accounts;
      let index = accounts.findIndex((item) => item.user_id == user.user_id);
      accounts[index] = { ...user };
      this.accounts = [...accounts];
      let orgAccounts = this.orgAccounts;
      let oindex = orgAccounts.findIndex(
        (item) => item.user_id == user.user_id
      );
      this.orgAccounts[oindex] = { ...user };
      this.orgAccounts = [...orgAccounts];
      this.$toast(`${user.name}さんを編集しました。`, 3000);
      this.modalType = "";
    },

    newOrEditAccountClose() {
      this.modalType = "";
      this.userSelected = null;
    },

    setQueryRouter() {
      this.$router.replace({
        path: "/profiles",
        query: this.queryData,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-button {
  display: inline-block;
  width: 60%;
}
.search-text {
  display: inline-block;
  width: 40%;
  text-align: right;
}
.m-w-128 {
  max-width: 128px;
}
.m-w-250 {
  max-width: 250px;
}

.height-custom {
  height: calc((var(--vh, 1vh) * 100) - 56px - 72px - 36px);
  &-search {
      height: calc((var(--vh, 1vh) * 100) - 56px - 72px - 36px - 50px);
    }
  &-sm {
    height: calc((var(--vh, 1vh) * 100) - 56px - 72px - 52px - 24px);
    &-search {
      height: calc((var(--vh, 1vh) * 100) - 56px - 72px - 52px - 24px - 50px);
    }
  }
  &-xs {
    height: calc((var(--vh, 1vh) * 100) - 56px - 72px - 88px - 36px);
    &-search {
      height: calc((var(--vh, 1vh) * 100) - 56px - 72px - 88px - 36px - 50px);
    }
  }
}
.h-100 {
  height: 100%;
}
</style>
